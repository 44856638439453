import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { AppState } from "../../../../redux/store/Store";
import {
  Alert,
  Card,
  Grid,
  Stack,
  Typography,
  CardHeader,
  CardContent,
} from "@mui/material";
import { t } from "i18next";

type Props = {
  selectedAppointmentLoader: boolean | any;
};
export default function SelectedOption({ selectedAppointmentLoader }: Props) {
  const getRequestAppointmentDetailsVal = useSelector(
    (state: AppState) => state.getRequestAppointmentDetails
  );
  useEffect(() => {
    selectedAppointmentLoader(getRequestAppointmentDetailsVal?.loading);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getRequestAppointmentDetailsVal]);

  // RequestAppointment Api Value from store
  let requestAppointmentListFromAPI = useSelector(
    (state: AppState) =>
      state.getRequestAppointmentDetails.getAppointmentListRes
  );

  useEffect(() => {
    // Do api call here....
    window.scrollTo(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Card>
      <CardHeader title={t("appointmentpage.selopt", { ns: ["home"] })!} />
      <CardContent>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start"
          spacing={2}
        >
          <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
            <Stack
              direction="column"
              justifyContent="flex-start"
              alignItems="stretch"
              spacing={2}
            >
              <Typography component="h6" style={{ fontWeight: "bold" }}>
                {t("patientcaselistpage.consultationtype", { ns: ["home"] })!}:
              </Typography>
              {requestAppointmentListFromAPI.consultationType !== undefined ? (
                requestAppointmentListFromAPI.consultationType !== null ? (
                  requestAppointmentListFromAPI.consultationType !== "" ? (
                    <Alert variant="outlined" severity="info">
                      <Typography>
                        {requestAppointmentListFromAPI.consultationType}
                      </Typography>
                    </Alert>
                  ) : null
                ) : null
              ) : null}
            </Stack>
          </Grid>
          <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
            <Stack
              direction="column"
              justifyContent="flex-start"
              alignItems="stretch"
              spacing={2}
            >
              <Typography component="h6" style={{ fontWeight: "bold" }}>
                {t("consultOpPage.resTime", { ns: ["home"] })!}:
              </Typography>
              {requestAppointmentListFromAPI?.responseTimeType !== undefined ? (
                requestAppointmentListFromAPI?.responseTimeType !== null ? (
                  requestAppointmentListFromAPI?.responseTimeType !== "" ? (
                    <Alert variant="outlined" severity="info">
                      <Typography>
                        {requestAppointmentListFromAPI.responseTimeType}
                      </Typography>
                    </Alert>
                  ) : null
                ) : null
              ) : null}
            </Stack>
          </Grid>
          <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
            <Stack
              direction="column"
              justifyContent="flex-start"
              alignItems="stretch"
              spacing={2}
            >
              <Typography component="h6" style={{ fontWeight: "bold" }}>
                {t("appointmentpage.docseltype", { ns: ["home"] })!}:
              </Typography>
              {requestAppointmentListFromAPI?.doctorTeamType !== undefined ? (
                requestAppointmentListFromAPI?.doctorTeamType !== null ? (
                  requestAppointmentListFromAPI.doctorTeamType !== "" ? (
                    <Alert variant="outlined" severity="info">
                      <Typography>
                        {requestAppointmentListFromAPI.doctorTeamType}
                      </Typography>
                    </Alert>
                  ) : null
                ) : null
              ) : null}
            </Stack>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
