import {
  HistoryAndIllnessActionTypes,
  GetPatientDemographicsState,
  HISTORY_AND_ILLNESS_SUCCESS,
  HISTORY_AND_ILLNESS_LOADING,
  HISTORY_AND_ILLNESS_FAIL,
} from "./ActionTypes";
import { HistoryAndIllnessResponse } from "./Model";

const initialStateGetPosts: GetPatientDemographicsState = {
  loading: false,
  historyandillnessDetails: {} as HistoryAndIllnessResponse,
};
export const historyandIllnessReducer = (
  state = initialStateGetPosts,
  action: HistoryAndIllnessActionTypes
): GetPatientDemographicsState => {
  switch (action.type) {
    case HISTORY_AND_ILLNESS_SUCCESS:
      return {
        ...state,
        loading: false,
        historyandillnessDetails: action.payload,
      };
    case HISTORY_AND_ILLNESS_LOADING:
      return {
        ...state,
        loading: true,
        historyandillnessDetails: {} as HistoryAndIllnessResponse,
      };
    case HISTORY_AND_ILLNESS_FAIL:
      return {
        ...state,
        historyandillnessDetails: {} as HistoryAndIllnessResponse,
        loading: false,
      };
    default:
      return state;
  }
};
