import {
  AddOrUpdateHealthRecordStateType,
  AddOrUpdateHealthRecordTypes,
  ADD_OR_UPDATE_HEALTH_RECORD_FAIL,
  ADD_OR_UPDATE_HEALTH_RECORD_LOADING,
  ADD_OR_UPDATE_HEALTH_RECORD_SUCCESS,
  BloodgroupListStateType,
  BloodgroupTypes,
  BLOODGROUP_LIST_FAIL,
  BLOODGROUP_LIST_LOADING,
  BLOODGROUP_LIST_SUCCESS,
  GenderListStateType,
  GenderListTypes,
  GENDER_LIST_FAIL,
  GENDER_LIST_LOADING,
  GENDER_LIST_SUCCESS,
  GetHealthRecordStateType,
  GetHealthRecordTypes,
  GET_HEALTH_RECORD_FAIL,
  GET_HEALTH_RECORD_LOADING,
  GET_HEALTH_RECORD_SUCCESS,
  LanguageListStateType,
  LanguageListTypes,
  LANGUAGE_LIST_LOADING,
  LANGUAGE_ORIENTATION_LIST_FAIL,
  LANGUAGE_ORIENTATION_LIST_SUCCESS,
  MaritalStatusListStateType,
  MaritalStatusTypes,
  MARITAL_STATUS_LIST_FAIL,
  MARITAL_STATUS_LIST_LOADING,
  MARITAL_STATUS_LIST_SUCCESS,
  RelationshipListStateType,
  RelationshipListTypes,
  RELATIONSHIP_LIST_FAIL,
  RELATIONSHIP_LIST_LOADING,
  RELATIONSHIP_LIST_SUCCESS,
  SexualOrientationListStateType,
  SexualOrientationTypes,
  SEXUAL_ORIENTATION_LIST_FAIL,
  SEXUAL_ORIENTATION_LIST_LOADING,
  SEXUAL_ORIENTATION_LIST_SUCCESS,
} from "./ActionTypes";

const initialStateGenderList: GenderListStateType = {
  loading: false,
  genderList: [],
  message: Object.assign({}),
  status: Object.assign({}),
};
// Gender Dropdownlist
export const gettGenderDropdownListReducer = (
  state = initialStateGenderList,
  action: GenderListTypes
): GenderListStateType => {
  switch (action.type) {
    case GENDER_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        genderList: action.genderList,
        message: action.message,
        status: action.status,
      };
    case GENDER_LIST_LOADING:
      return {
        ...state,
        loading: true,
      };
    case GENDER_LIST_FAIL:
      return {
        ...state,
        loading: false,
        message: action.message,
        status: action.status,
      };
    default:
      return state;
  }
};
const initialStateRelationshiplist: RelationshipListStateType = {
  loading: false,
  relationList: [],
  message: Object.assign({}),
  status: Object.assign({}),
};
// Gender Dropdownlist
export const gettRelationshipDropdownListReducer = (
  state = initialStateRelationshiplist,
  action: RelationshipListTypes
): RelationshipListStateType => {
  switch (action.type) {
    case RELATIONSHIP_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        relationList: action.relationList,
        message: action.message,
        status: action.status,
      };
    case RELATIONSHIP_LIST_LOADING:
      return {
        ...state,
        loading: true,
      };
    case RELATIONSHIP_LIST_FAIL:
      return {
        ...state,
        loading: false,
        message: action.message,
        status: action.status,
      };
    default:
      return state;
  }
};
const initialStateBloodgroupList: BloodgroupListStateType = {
  loading: false,
  bloodGroupList: [],
  message: Object.assign({}),
  status: Object.assign({}),
};
// Gender Dropdownlist
export const getBloodGroupDropdownListReducer = (
  state = initialStateBloodgroupList,
  action: BloodgroupTypes
): BloodgroupListStateType => {
  switch (action.type) {
    case BLOODGROUP_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        bloodGroupList: action.bloodGroupList,
        message: action.message,
        status: action.status,
      };
    case BLOODGROUP_LIST_LOADING:
      return {
        ...state,
        loading: true,
      };
    case BLOODGROUP_LIST_FAIL:
      return {
        ...state,
        loading: false,
        message: action.message,
        status: action.status,
      };
    default:
      return state;
  }
};
const initialStateMaritalStatusList: MaritalStatusListStateType = {
  loading: false,
  maritialStatusList: [],
  message: Object.assign({}),
  status: Object.assign({}),
};
// Gender Dropdownlist
export const getMaritalStatusDropdownListReducer = (
  state = initialStateMaritalStatusList,
  action: MaritalStatusTypes
): MaritalStatusListStateType => {
  switch (action.type) {
    case MARITAL_STATUS_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        maritialStatusList: action.maritialStatusList,
        message: action.message,
        status: action.status,
      };
    case MARITAL_STATUS_LIST_LOADING:
      return {
        ...state,
        loading: true,
      };
    case MARITAL_STATUS_LIST_FAIL:
      return {
        ...state,
        loading: false,
        message: action.message,
        status: action.status,
      };
    default:
      return state;
  }
};
const initialStateSexualOrientationList: SexualOrientationListStateType = {
  loading: false,
  sexualOrientationList: [],
  message: Object.assign({}),
  status: Object.assign({}),
};
// Gender Dropdownlist
export const getSexualorientationDropdownListReducer = (
  state = initialStateSexualOrientationList,
  action: SexualOrientationTypes
): SexualOrientationListStateType => {
  switch (action.type) {
    case SEXUAL_ORIENTATION_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        sexualOrientationList: action.sexualOrientationList,
        message: action.message,
        status: action.status,
      };
    case SEXUAL_ORIENTATION_LIST_LOADING:
      return {
        ...state,
        loading: true,
      };
    case SEXUAL_ORIENTATION_LIST_FAIL:
      return {
        ...state,
        loading: false,
        message: action.message,
        status: action.status,
      };
    default:
      return state;
  }
};
const initialStateLanguageList: LanguageListStateType = {
  loading: false,
  languageList: [],
  message: Object.assign({}),
  status: Object.assign({}),
};
// Gender Dropdownlist
export const getLanguageDropdownListReducer = (
  state = initialStateLanguageList,
  action: LanguageListTypes
): LanguageListStateType => {
  switch (action.type) {
    case LANGUAGE_ORIENTATION_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        languageList: action.languageList,
        message: action.message,
        status: action.status,
      };
    case LANGUAGE_LIST_LOADING:
      return {
        ...state,
        loading: true,
      };
    case LANGUAGE_ORIENTATION_LIST_FAIL:
      return {
        ...state,
        loading: false,
        message: action.message,
        status: action.status,
      };
    default:
      return state;
  }
};
const initialStateAddOrupdateHealthRecord: AddOrUpdateHealthRecordStateType = {
  loading: false,
  message: Object.assign({}),
  status: Object.assign({}),
};
// Gender Dropdownlist
export const addOrupdateHealthRecordReducer = (
  state = initialStateAddOrupdateHealthRecord,
  action: AddOrUpdateHealthRecordTypes
): AddOrUpdateHealthRecordStateType => {
  switch (action.type) {
    case ADD_OR_UPDATE_HEALTH_RECORD_SUCCESS:
      return {
        ...state,
        loading: false,
        message: action.message,
        status: action.status,
      };
    case ADD_OR_UPDATE_HEALTH_RECORD_LOADING:
      return {
        ...state,
        loading: true,
      };
    case ADD_OR_UPDATE_HEALTH_RECORD_FAIL:
      return {
        ...state,
        loading: false,
        message: action.message,
        status: action.status,
      };
    default:
      return state;
  }
};

const initialStateGetHealthRecord: GetHealthRecordStateType = {
  loading: false,
  personalDetails: Object.assign({}),
  message: Object.assign({}),
  status: Object.assign({}),
};
// Gender Dropdownlist
export const getHealthRecordReducer = (
  state = initialStateGetHealthRecord,
  action: GetHealthRecordTypes
): GetHealthRecordStateType => {
  switch (action.type) {
    case GET_HEALTH_RECORD_SUCCESS:
      return {
        ...state,
        loading: false,
        personalDetails: action.personalDetails,
        message: action.message,
        status: action.status,
      };
    case GET_HEALTH_RECORD_LOADING:
      return {
        ...state,
        loading: true,
      };
    case GET_HEALTH_RECORD_FAIL:
      return {
        ...state,
        loading: false,
        message: action.message,
        status: action.status,
      };
    default:
      return state;
  }
};
