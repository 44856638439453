import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../../ApiConfig";
import { RequestAppointmentActionTypes } from "./ActionTypes";
import { requestAppointmentDataBody, RequestAppointmentDataRes } from "./Model";
import {
  requestAppointmentAPIResClearAction,
  requestAppointmentErrorAction,
  requestAppointmentLoadingAction,
  requestAppointmentSuccessAction,
  requestAppointmentAPIMsgAction,
} from "./Actions";
import { headers } from "../../../component/Utility";

let apiRes = {} as RequestAppointmentDataRes;
export const addBookAppointment = (payload: requestAppointmentDataBody) => {
  return function (dispatch: Dispatch<RequestAppointmentActionTypes>) {
    dispatch(requestAppointmentLoadingAction(true));
    axios
      .post(
        Url.baseUrl + `/appointmentapp/api/appointment/book_appointment`,
        payload,
        { headers: headers }
      )
      .then((res) => {
        apiRes = res.data;
        dispatch(requestAppointmentLoadingAction(false));
        dispatch(
          requestAppointmentSuccessAction(
            res.data,
            res.data.message !== undefined
              ? res.data.message
              : "Request Appointment has been added successfully"
          )
        );
      })
      .catch((error) => {
        dispatch(requestAppointmentLoadingAction(false));
        dispatch(
          requestAppointmentErrorAction(
            {} as RequestAppointmentDataRes,
            error.response !== undefined
              ? error.response.data.message || error.response.data.detail
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || 0 : 0
          )
        );
      });
  };
};

export const RequestAppointmentDataAPIResMsg = () => {
  return function (dispatch: Dispatch<RequestAppointmentActionTypes>) {
    dispatch(
      requestAppointmentAPIMsgAction(
        apiRes as RequestAppointmentDataRes,
        "",
        "",
        0
      )
    );
  };
};

export const clearRequestAppointmentDataAPIRes = () => {
  return function (dispatch: Dispatch<RequestAppointmentActionTypes>) {
    dispatch(
      requestAppointmentAPIResClearAction(
        {} as RequestAppointmentDataRes,
        "",
        "",
        0
      )
    );
  };
};
