import {
    AddPastMedicalActionTypes,
    GetPatientDemographicsState,
    ADD_PAST_MEDICAL_SUCCESS,
    ADD_PAST_MEDICAL_LOADING,
    ADD_PAST_MEDICAL_FAIL,
  } from "./ActionTypes";
  import { AddPastMedicalResponse } from "./Model";
  
  const initialStateGetPosts: GetPatientDemographicsState = {
    loading: false,
    addPastMedical: {} as AddPastMedicalResponse,
  };
  export const addPastMedicalReducer = (
    state = initialStateGetPosts,
    action: AddPastMedicalActionTypes
  ): GetPatientDemographicsState => {
    switch (action.type) {
      case ADD_PAST_MEDICAL_SUCCESS:
        return {
          ...state,
          loading: false,
          addPastMedical: action.payload,
        };
      case ADD_PAST_MEDICAL_LOADING:
        return {
          ...state,
          loading: true,
          addPastMedical: {} as AddPastMedicalResponse,
        };
      case ADD_PAST_MEDICAL_FAIL:
        return {
          ...state,
          addPastMedical: {} as AddPastMedicalResponse,
          loading: false,
        };
      default:
        return state;
    }
  };