import React from "react";
import DemographicDetails from "./DemographicDetails";
import ImportedData from "./ImportedData";
import FileData from "./FIleData";
interface FilePreparationTabList {
  type?: string;
  label: string;
  content: any;
}
export const TabList = [
  {
    label: `demoDetail`,
    content: <DemographicDetails />,
  },
  {
    label: `presentingcomp`,
    content: (
      <FileData type={"chiefCompliants"} label={`presentingcomp`} />
    ),
  },
  {
    label: `ideasConExp`,
    content: (
      <FileData
        type={"ideasConcernsandExpectations"}
        label={`ideasConExp`}
      />
    ),
  },
  {
    label: `hispresentingcomp`,
    content: (
      <FileData
        type={"historyofChiefPresentingComplaints"}
        label={`hispresentingcomp`}
      />
    ),
  },
  {
    label: `revSys`,
    content: <FileData type={"systemsReview"} label={`revSys`} />,
  },
  {
    label: `pastHis`,
    content: <FileData type={"pastHistory"} label={`pastHis`} />,
  },
  {
    label: `testProcEnv`,
    content: (
      <FileData
        type={"testsProceduresandInvestigations"}
        label={`testProcEnv`}
      />
    ),
  },
  {
    label: `MedPresc`,
    content: (
      <FileData
        type={"medication/DrugHistory"}
        label={`MedPresc`}
      />
    ),
  },
  {
    label: `allergy`,
    content: <FileData type={"allergies"} label={`allergy`} />,
  },
  {
    label: `imm`,
    content: <FileData type={"immunization"} label={`imm`} />,
  },
  {
    label: `famHis`,
    content: <FileData type={"familyHistory"} label={`famHis`} />,
  },

  {
    label: `nutrHis`,
    content: (
      <FileData type={"nutritionalHistory"} label={`nutrHis`} />
    ),
  },
  {
    label: `personalSocialHis`,
    content: (
      <FileData
        type={"personalandSocialHistory"}
        label={`personalSocialHis`}
      />
    ),
  },
  {
    label: `healthHis`,
    content: (
      <FileData
        type={"healthMaintenanceHistory"}
        label={`healthHis`}
      />
    ),
  },
  {
    label: `riskfac`,
    content: <FileData type={"riskFactors"} label={`riskfac`} />,
  },
  {
    label: `diag`,
    content: <FileData type={"diagnosis"} label={`diag`} />,
  },
  {
    label: `medSum`,
    content: <FileData type={"medicalSummary"} label={`medSum`} />,
  },
  {
    label: `probList`,
    content: (
      <FileData type={"problemListandTreatmentPlan"} label={`probList`} />
    ),
  },
  {
    label: `treatmentpln`,
    content: <FileData type={"treatmentPlan"} label={`treatmentpln`} />,
  },
  {
    label: `impData`,
    content: <ImportedData />,
  },
] as FilePreparationTabList[];
