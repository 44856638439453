import {
  AddDrNotesTypes, AddNotesForDrActionTypes, ADD_DR_NOTES, ADD_NOTES_FOR_DR, ChangeDoctorNotesActionTypes, ChangeDrNoteStatusTypes,
  CHANGE_NOTES_FOR_DR,
  CHANGE_NOTE_STATUS, DoctorNotesActionTypes, GET_DRNOTE_LIST, NotesForDoctorActionTypes, NOTE_LIST_FOR_DR
} from "./ActionTypes";
import { DrNoteList } from "./Model";

export const getDrNoteAction = (drNoteResponse: DrNoteList[]): DoctorNotesActionTypes => {
  return {
    type: GET_DRNOTE_LIST,
    payload: drNoteResponse
  };
};

export const AddDoctorNote = (addDrNotes: any): AddDrNotesTypes => {
  return {
    type: ADD_DR_NOTES,
    payload: addDrNotes
  };
};

export const DrNoteStatusChangeAction = (changeStatus: any): ChangeDrNoteStatusTypes => {
  return {
    type: CHANGE_NOTE_STATUS,
    payload: changeStatus
  };
};
export const getNoteForDrAction = (noteList: DrNoteList[], message: any, status: any): NotesForDoctorActionTypes => {
  return {
    type: NOTE_LIST_FOR_DR,
    noteList: noteList,
    message: message,
    status: status
  };
};

export const AddNotesForDr = (message: any, status: any): AddNotesForDrActionTypes => {
  return {
    type: ADD_NOTES_FOR_DR,
    message: message,
    status: status
  };
};
export const ChangeNotesForDr = (message: any, status: any): ChangeDoctorNotesActionTypes => {
  return {
    type: CHANGE_NOTES_FOR_DR,
    message: message,
    status: status
  };
};