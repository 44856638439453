import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getTelehealthMeetingList } from "../../redux/Telehealth/API";
import { AppState } from "../../redux/store/Store";
import { DataGrid, GridColDef, GridToolbar } from "@mui/x-data-grid";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Typography,
} from "@mui/material";
import MWExceptionList from "../../component/MWExceptionList";
import dayjs from "dayjs";
import { MeetingList } from "../../redux/Telehealth/Model";
import RefreshIcon from "@mui/icons-material/Refresh";
import { t } from "i18next";

export default function InactiveMeeting(props: any) {
  const inActiveMeetingColumns: GridColDef[]= [
    {
      field: "id",
      headerName: "Id",
      width: 200,
    },
    {
      field: "patientCase",
      headerName: `${t("telehealthpage.patientCaseId", { ns: ["home"] })}`,
      width: 250,
    },
    {
      field: "displayName",
      headerName: `${t("telehealthpage.appoinmentname", { ns: ["home"] })}`,
      width: 300,
    },
    {
      field: "appointmentDate",
      headerName: `${t("telehealthpage.appoDateTime", { ns: ["home"] })}`,
      width: 300,
    },
    {
      field: "dateCreated",
      headerName: `${t("appointmentpage.createdate", { ns: ["home"] })}`,
      width: 300,
    },
  ];
  const { inactivemeetingLoader } = props;
  const dispatch = useDispatch();
  // Meeting List Body
  const inActiveMeetingListBody = {
    type: false,
  };
  // Meeting List GET API
  const getMeetingList = () => {
    dispatch(getTelehealthMeetingList(inActiveMeetingListBody));
  };
  // Call Meeting List Store
  const inActiveMeetingListFromModel = useSelector(
    (state: AppState) => state.telehealthMeetingValue
  );
  const inActiveMeetingListLoadingValues = useSelector(
    (state: AppState) => state.telehealthMeetingValue.loading
  );
  const [dataGridArray, setDataArray] = React.useState([] as any[]);
  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(getTelehealthMeetingList(inActiveMeetingListBody));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      inActiveMeetingListFromModel?.teleHealthRes?.meetingList !== undefined
    ) {
      let activeMeetingList =
        inActiveMeetingListFromModel?.teleHealthRes?.meetingList.map(
          (element: any) => ({
            id: element.id,
            patientCase: element.patientCase,
            displayName: element.displayName,
            appointmentDate: dayjs(element.appointmentDate).format(
              "ddd, MMMM D, YYYY h:mm A"
            ),
            dateCreated: dayjs(element.dateCreated).format("ddd, MMMM D, YYYY"),
          })
        );
      setDataArray(activeMeetingList);
    } else setDataArray([] as MeetingList[]);
  }, [inActiveMeetingListFromModel]);

  const activeMeetingTableBody = (
    <Card>
      <CardHeader
        title={
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="flex-start"
          >
            <Grid item>
              <Typography variant="h6">{t("telehealthpage.inactMeetDetail", { ns: ["home"] })}</Typography>
            </Grid>
            <Grid item>
              <Grid
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="flex-start"
              >
                <Grid item>
                  <Button
                    variant="outlined"
                    startIcon={<RefreshIcon />}
                    onClick={getMeetingList}
                  >
                    {t("common.reload", { ns: ["home"] })}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        }
      ></CardHeader>
      <CardContent>
        {dataGridArray !== undefined ? (
          dataGridArray.length > 0 ? (
            <DataGrid
              rows={dataGridArray}
              columns={inActiveMeetingColumns}
              pageSize={10}
              hideFooter
              hideFooterPagination
              disableSelectionOnClick
              experimentalFeatures={{ newEditingApi: true }}
              autoHeight
              components={{ Toolbar: GridToolbar }}
              disableColumnFilter
              disableColumnSelector
              disableDensitySelector
            />
          ) : (
            <MWExceptionList />
          )
        ) : (
          <MWExceptionList />
        )}
      </CardContent>
    </Card>
  );

  return (
    <Box>
      {inActiveMeetingListLoadingValues === true
        ? inactivemeetingLoader(true)
        : inactivemeetingLoader(false) && null}
      {activeMeetingTableBody}
    </Box>
  );
}
