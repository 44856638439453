import {
  ADD_NOTE_FAIL,
  ADD_NOTE_LOADING,
  ADD_NOTE_SUCCESS,
  ADD_NOTE_UPDATE_API_MSG,
  ADD_NOTE_UPDATE_API_RES,
  AddNoteActionTypes,
} from "./ActionTypes";
import { AddNoteRes } from "./Model";

export const addNoteLoadingAction = (loading: boolean): AddNoteActionTypes => {
  return {
    type: ADD_NOTE_LOADING,
    loading: loading,
  };
};

export const addNoteSuccessAction = (
  addNoteResponse: AddNoteRes,
  successMsg: string
): AddNoteActionTypes => {
  return {
    type: ADD_NOTE_SUCCESS,
    payload: addNoteResponse,
    successMsg: successMsg,
  };
};

export const addNoteErrorAction = (
  addNoteResponse: AddNoteRes,
  errMsg: string,
  status: number
): AddNoteActionTypes => {
  return {
    type: ADD_NOTE_FAIL,
    payload: addNoteResponse,
    errorMsg: errMsg,
    status: status,
  };
};
export const addNoteUpdateAPIMsgAction = (
  addNoteResponse: AddNoteRes,
  successMsg: string,
  errMsg: string,
  status: number
): AddNoteActionTypes => {
  return {
    type: ADD_NOTE_UPDATE_API_MSG,
    payload: addNoteResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};

export const addNoteAPIResClearAction = (
  addNoteResponse: AddNoteRes,
  successMsg: string,
  errMsg: string,
  status: number
): AddNoteActionTypes => {
  return {
    type: ADD_NOTE_UPDATE_API_RES,
    payload: addNoteResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};
