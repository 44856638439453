import { AddDrNotesResponse, DrNoteList, DrNoteStatusChangeResponse, SetDrNoteList } from "./Model";

export const DRNOTE_LIST_LOADING = "DRNOTE_LOADING";
export const GET_DRNOTE_LIST = 'GET_DRNOTE_LIST'
export const DRNOTE_LIST_SUCCESS = "DRNOTE_LIST_SUCCESS";
export const DRNOTE_LIST_FAIL = "DRNOTE_LIST_FAIL";


export interface GetDrNoteStateType {
  patientCaseNoteListRes: DrNoteList[];
  loading: boolean;
}
export interface DrNoteListLoading {
  type: typeof DRNOTE_LIST_LOADING;
}
export interface DRNoteListSuccess {
  type: typeof DRNOTE_LIST_SUCCESS;
  payload: DrNoteList[];
}
export interface DrNoteListFail {
  type: typeof DRNOTE_LIST_FAIL;
}

interface GetDrNoteActionType {
  type: typeof GET_DRNOTE_LIST;
  payload: DrNoteList[];
}

export const ADD_DR_NOTE_LOADING = "ADD_DR_NOTE_LOADING";
export const ADD_DR_NOTE_SUCCESS = "ADD_DR_NOTE_SUCCESS";
export const ADD_DR_NOTE_FAIL = "ADD_DR_NOTE_FAIL";
export const ADD_DR_NOTES = 'ADD_DR_NOTES';

export interface AddDrNoteStateType {
  addDrNoteList: SetDrNoteList[];
  loading: boolean;
  response: AddDrNotesResponse;
}
export interface AddDrNoteLoading {
  type: typeof ADD_DR_NOTE_LOADING;
}
export interface AddDrNoteSuccess {
  type: typeof ADD_DR_NOTE_SUCCESS;
  payload: AddDrNotesResponse;
}
export interface AddDrNoteFail {
  type: typeof ADD_DR_NOTE_FAIL;
}

interface AddChiefComplaintType {
  type: typeof ADD_DR_NOTES;
  payload: SetDrNoteList[];
}

export const CHANGE_DR_NOTE_STATUS_LOADING = "CHANGE_DR_NOTE_STATUS_LOADING";
export const CHANGE_DR_NOTE_STATUS_SUCCESS = "CHANGE_DR_NOTE_STATUS_SUCCESS";
export const CHANGE_DR_NOTE_STATUS_FAIL = "CHANGE_DR_NOTE_STATUS_FAIL";
export const CHANGE_NOTE_STATUS = 'CHANGE_NOTE_STATUS';

export interface DRNoteStatusChangeStateType {
  //addCC: SetChiefComplaint[];
  loading: boolean;
  response: DrNoteStatusChangeResponse;
}
export interface DRNoteStatusChangeLoading {
  type: typeof CHANGE_DR_NOTE_STATUS_LOADING;
}
export interface DRNoteStatusChangeSuccess {
  type: typeof CHANGE_DR_NOTE_STATUS_SUCCESS;
  payload: DrNoteStatusChangeResponse;
}
export interface DRNoteStatusChangeFail {
  type: typeof CHANGE_DR_NOTE_STATUS_FAIL;
}

interface DRNoteStatusChangeType {
  type: typeof CHANGE_NOTE_STATUS;
  payload: DrNoteStatusChangeResponse;
}

export const NOTE_LIST_FOR_DR_LOADING = "NOTE_LIST_FOR_DR_LOADING";
export const NOTE_LIST_FOR_DR = 'NOTE_LIST_FOR_DR'
export const NOTE_LIST_FOR_DR_SUCCESS = "NOTE_LIST_FOR_DR_SUCCESS";
export const NOTE_LIST_FOR_DR_FAIL = "NOTE_LIST_FOR_DR_FAIL";

export interface GetNoteForDrStateType {
  noteList: DrNoteList[];
  loading: boolean;
  message: string;
  status: number;
}
export interface NoteForDrLoading {
  type: typeof NOTE_LIST_FOR_DR_LOADING;
}
export interface NoteForDrSuccess {
  type: typeof NOTE_LIST_FOR_DR_SUCCESS;
  noteList: DrNoteList[];
  message: string;
  status: number;
}
export interface NoteForDrFail {
  type: typeof NOTE_LIST_FOR_DR_FAIL;
  message: string;
  status: number;
}

interface NoteForDrActionType {
  type: typeof NOTE_LIST_FOR_DR;
  noteList: DrNoteList[];
  message: string;
  status: number;
}
export const ADD_NOTES_FOR_DR_LOADING = "ADD_NOTES_FOR_DR_LOADING";
export const ADD_NOTES_FOR_DR = 'ADD_NOTES_FOR_DR'
export const ADD_NOTES_FOR_DR_SUCCESS = "ADD_NOTES_FOR_DR_SUCCESS";
export const ADD_NOTES_FOR_DR_FAIL = "ADD_NOTES_FOR_DR_FAIL";

export interface AddNotesForDrStateType {
  loading: boolean;
  message: string;
  status: number;
}
export interface AddNotesForDrLoading {
  type: typeof ADD_NOTES_FOR_DR_LOADING;
}
export interface AddNotesForDrSuccess {
  type: typeof ADD_NOTES_FOR_DR_SUCCESS;
  message: string;
  status: number;
}
export interface AddNotesForDrFail {
  type: typeof ADD_NOTES_FOR_DR_FAIL;
  message: string;
  status: number;
}

interface AddNotesForDrActionType {
  type: typeof ADD_NOTES_FOR_DR;
  message: string;
  status: number;
}
export const CHANGE_NOTES_FOR_DR_LOADING = "CHANGE_NOTES_FOR_DR_LOADING";
export const CHANGE_NOTES_FOR_DR_SUCCESS = "CHANGE_NOTES_FOR_DR_SUCCESS";
export const CHANGE_NOTES_FOR_DR_FAIL = "CHANGE_NOTES_FOR_DR_FAIL";
export const CHANGE_NOTES_FOR_DR = 'CHANGE_NOTES_FOR_DR'

export interface ChangeNotesForDrStateType {
  loading: boolean;
  message: string;
  status: number;
}
export interface ChangeNotesForDrLoading {
  type: typeof CHANGE_NOTES_FOR_DR_LOADING;
}
export interface ChangeNotesForDrSuccess {
  type: typeof CHANGE_NOTES_FOR_DR_SUCCESS;
  message: string;
  status: number;
}
export interface ChangeNotesForDrFail {
  type: typeof CHANGE_NOTES_FOR_DR_FAIL;
  message: string;
  status: number;
}

interface ChangeNotesForDrActionType {
  type: typeof CHANGE_NOTES_FOR_DR;
  message: string;
  status: number;
}

export type DoctorNotesActionTypes = GetDrNoteActionType | DrNoteListLoading | DRNoteListSuccess | DrNoteListFail;
export type AddDrNotesTypes = AddChiefComplaintType | AddDrNoteLoading | AddDrNoteSuccess | AddDrNoteFail | GetDrNoteActionType | DrNoteListLoading | DRNoteListSuccess | DrNoteListFail;
export type ChangeDrNoteStatusTypes = DRNoteStatusChangeType | DRNoteStatusChangeLoading | DRNoteStatusChangeSuccess | DRNoteStatusChangeFail ;
export type NotesForDoctorActionTypes = NoteForDrActionType | NoteForDrFail | NoteForDrSuccess | NoteForDrLoading;
export type AddNotesForDrActionTypes = AddNotesForDrActionType | AddNotesForDrLoading | AddNotesForDrSuccess | AddNotesForDrFail;
export type ChangeDoctorNotesActionTypes = ChangeNotesForDrActionType | ChangeNotesForDrLoading | ChangeNotesForDrSuccess | ChangeNotesForDrFail;