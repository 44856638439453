import { DoctorList, MeetingList, QuestionList } from "./Model";

// Doctor Dropdown List
export const DOCTOR_LIST_LOADING = "DOCTOR_LIST_LOADING";
export const DOCTOR_LIST_SUCCESS = "DOCTOR_LIST_SUCCESS";
export const DOCTOR_LIST_FAIL = "DOCTOR_LIST_FAIL";
export const DOCTOR_LIST_TYPE = 'DOCTOR_LIST_TYPE';

export interface DoctorListForCaseStateType {
    loading: boolean;
    patientCaseDoctorlist: DoctorList[];
    message: string;
    status: number;
}
export interface DoctorListForCaseLoading {
    type: typeof DOCTOR_LIST_LOADING;
}
export interface DoctorListForCaseSuccess {
    type: typeof DOCTOR_LIST_SUCCESS;
    patientCaseDoctorlist: DoctorList[];
    message: string;
    status: number;
}
export interface DoctorListForCaseFail {
    type: typeof DOCTOR_LIST_FAIL;
    message: string;
    status: number;
}

interface DoctorListForCaseType {
    type: typeof DOCTOR_LIST_TYPE;
    patientCaseDoctorlist: DoctorList[];
    message: string;
    status: number;
}

export type DoctorListForCaseTypes = DoctorListForCaseType | DoctorListForCaseLoading | DoctorListForCaseSuccess | DoctorListForCaseFail;
// Meeting List
export const MEETING_LIST_LOADING = "MEETING_LIST_LOADING";
export const MEETING_LIST_SUCCESS = "MEETING_LIST_SUCCESS";
export const MEETING_LIST_FAIL = "MEETING_LIST_FAIL";
export const MEETING_LIST_TYPE = 'MEETING_LIST_TYPE';

export interface MeetingListStateType {
    loading: boolean;
    meetingList: MeetingList[];
    message: string;
    status: number;
}
export interface MeetingListLoading {
    type: typeof MEETING_LIST_LOADING;
}
export interface MeetingListSuccess {
    type: typeof MEETING_LIST_SUCCESS;
    meetingList: MeetingList[];
    message: string;
    status: number;
}
export interface MeetingListFail {
    type: typeof MEETING_LIST_FAIL;
    message: string;
    status: number;
}

interface MeetingListType {
    type: typeof MEETING_LIST_TYPE;
    meetingList: MeetingList[];
    message: string;
    status: number;
}

export type MeetingListTypes = MeetingListType | MeetingListLoading | MeetingListSuccess | MeetingListFail;
// Create or Schedule Meeting
export const CREATE_OR_SCHEDULE_LOADING = "CREATE_OR_SCHEDULE_LOADING";
export const CREATE_OR_SCHEDULE_SUCCESS = "CREATE_OR_SCHEDULE_SUCCESS";
export const CREATE_OR_SCHEDULE_FAIL = "CREATE_OR_SCHEDULE_FAIL";
export const CREATE_OR_SCHEDULE_TYPE = 'CREATE_OR_SCHEDULE_TYPE';

export interface CreateOrScheduleMeetingStateType {
    loading: boolean;
    message: string;
    status: number;
}
export interface CreateOrScheduleMeetingLoading {
    type: typeof CREATE_OR_SCHEDULE_LOADING;
}
export interface CreateOrScheduleMeetingSuccess {
    type: typeof CREATE_OR_SCHEDULE_SUCCESS;
    message: string;
    status: number;
}
export interface CreateOrScheduleMeetingFail {
    type: typeof CREATE_OR_SCHEDULE_FAIL;
    message: string;
    status: number;
}

interface CreateOrScheduleMeetingType {
    type: typeof CREATE_OR_SCHEDULE_TYPE;
    message: string;
    status: number;
}

export type CreateOrScheduleMeetingTypes = CreateOrScheduleMeetingType | CreateOrScheduleMeetingLoading | CreateOrScheduleMeetingSuccess | CreateOrScheduleMeetingFail;
// Delete Meeting
export const DELETE_MEETING_LOADING = "DELETE_MEETING_LOADING";
export const DELETE_MEETING_SUCCESS = "DELETE_MEETING_SUCCESS";
export const DELETE_MEETING_FAIL = "DELETE_MEETING_FAIL";
export const DELETE_MEETING_TYPE = 'DELETE_MEETING_TYPE';

export interface DeleteMeetingStateType {
    loading: boolean;
    message: string;
    status: number;
}
export interface DeleteMeetingLoading {
    type: typeof DELETE_MEETING_LOADING;
}
export interface DeleteMeetingSuccess {
    type: typeof DELETE_MEETING_SUCCESS;
    message: string;
    status: number;
}
export interface DeleteMeetingFail {
    type: typeof DELETE_MEETING_FAIL;
    message: string;
    status: number;
}

interface DeleteMeetingType {
    type: typeof DELETE_MEETING_TYPE;
    message: string;
    status: number;
}

export type DeleteMeetingTypes = DeleteMeetingType | DeleteMeetingLoading | DeleteMeetingSuccess | DeleteMeetingFail;
// Meeting List
export const QUESTION_LIST_LOADING = "QUESTION_LIST_LOADING";
export const QUESTION_LIST_SUCCESS = "QUESTION_LIST_SUCCESS";
export const QUESTION_LIST_FAIL = "QUESTION_LIST_FAIL";
export const QUESTION_LIST_TYPE = 'QUESTION_LIST_TYPE';

export interface QuestionListStateType {
    loading: boolean;
    questionList: QuestionList[];
    message: string;
    status: number;
}
export interface QuestionListLoading {
    type: typeof QUESTION_LIST_LOADING;
}
export interface QuestionListSuccess {
    type: typeof QUESTION_LIST_SUCCESS;
    questionList: QuestionList[];
    message: string;
    status: number;
}
export interface QuestionListFail {
    type: typeof QUESTION_LIST_FAIL;
    message: string;
    status: number;
}

interface QuestionListType {
    type: typeof QUESTION_LIST_TYPE;
    questionList: QuestionList[];
    message: string;
    status: number;
}

export type QuestionListTypes = QuestionListType | QuestionListLoading | QuestionListSuccess | QuestionListFail;
// Ask Question
export const ASK_QUESTION_LOADING = "ASK_QUESTION_LOADING";
export const ASK_QUESTION_SUCCESS = "ASK_QUESTION_SUCCESS";
export const ASK_QUESTION_FAIL = "ASK_QUESTION_FAIL";
export const ASK_QUESTION_TYPE = 'ASK_QUESTION_TYPE';

export interface AskQuestionStateType {
    loading: boolean;
    message: string;
    status: number;
}
export interface AskQuestionLoading {
    type: typeof ASK_QUESTION_LOADING;
}
export interface AskQuestionSuccess {
    type: typeof ASK_QUESTION_SUCCESS;
    message: string;
    status: number;
}
export interface AskQuestionFail {
    type: typeof ASK_QUESTION_FAIL;
    message: string;
    status: number;
}

interface AskQuestionType {
    type: typeof ASK_QUESTION_TYPE;
    message: string;
    status: number;
}

export type AskQuestionTypes = AskQuestionType | AskQuestionLoading | AskQuestionSuccess | AskQuestionFail;
// Ask Question
export const ANSWER_QUESTION_LOADING = "ANSWER_QUESTION_LOADING";
export const ANSWER_QUESTION_SUCCESS = "ANSWER_QUESTION_SUCCESS";
export const ANSWER_QUESTION_FAIL = "ANSWER_QUESTION_FAIL";
export const ANSWER_QUESTION_TYPE = 'ANSWER_QUESTION_TYPE';

export interface AnswerQuestionStateType {
    loading: boolean;
    message: string;
    status: number;
}
export interface AnswerQuestionLoading {
    type: typeof ANSWER_QUESTION_LOADING;
}
export interface AnswerQuestionSuccess {
    type: typeof ANSWER_QUESTION_SUCCESS;
    message: string;
    status: number;
}
export interface AnswerQuestionFail {
    type: typeof ANSWER_QUESTION_FAIL;
    message: string;
    status: number;
}

interface AnswerQuestionType {
    type: typeof ANSWER_QUESTION_TYPE;
    message: string;
    status: number;
}

export type AnswerQuestionTypes = AnswerQuestionType | AnswerQuestionLoading | AnswerQuestionSuccess | AnswerQuestionFail;