import React, { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import AdminLoader from "../AdminLoader";
import Schedule from "./Schedule/Schedule";
import ContactDetails from "./ContactDetails";
import Notes from "../Notes/Notes";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../redux/store/Store";
import {
  Button,
  Card,
  CardHeader,
  Grid,
  Typography,
  ButtonGroup,
  Box,
} from "@mui/material";
import MWPageTitle from "../../component/MWPageTitle";
import DoctorDetails from "./DoctorDetails";
import { getDoctorDetailsById } from "../../redux/Doctor/GetDoctorDetailsById/API";
import PageLayout from "../Layout/PageLayout";
import AddSchedule from "./Schedule/AddSchedule";
import { getDoctorWorkScheduleList } from "../../redux/Doctor/Schedule/GetDoctorWorkScheduleList/API";
import { GetDoctorWorkScheduleListBody } from "../../redux/Doctor/Schedule/GetDoctorWorkScheduleList/Model";
import { t } from "i18next";
import AddNoteDialog from "../Notes/AddNoteDialog";


export default function DoctorDetailsById() {
  const dispatch = useDispatch();

  const { id } = useParams() as {
    id: string;
  };
  const [Loader, setLoader] = useState(false);
  console.log("setLoader", setLoader);
  const [contactDetailsLoader, setContactDetailsLoader] = useState(false);
  console.log("setContactDetailsLoader", setContactDetailsLoader);
  const [notesLoader, setNotesLoader] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const loadingMarkup =
    isLoading === true ||
      Loader === true ||
      contactDetailsLoader === true ||
      notesLoader === true ? (
      <AdminLoader />
    ) : null;
  const getScheduleBody = {
    doctorId: id,
  } as GetDoctorWorkScheduleListBody;
  const workSchedule = useSelector((state: AppState) => state.workSchedule);
  useEffect(() => {
    setIsLoading(workSchedule?.loading);
  }, [workSchedule]);

  const [isFirstButtonActive, setIsFirstButtonActive] = useState(true);
  const [isSecondButtonActive, setIsSecondButtonActive] = useState(false);
  const [isThirdButtonActive, setIsThirdButtonActive] = useState(false);
  const [isFourthButtonActive, setIsFourthButtonActive] = useState(false);

  const [updateDoctorDetailsDialog, setUpdateDoctorDetailsDialog] =
    useState(false);
  const updateDoctorDetailsDialogUpdatedState = (passedVal: boolean) => {
    setUpdateDoctorDetailsDialog(passedVal);
  };
  const doctorDetailsLoaderVal = (passedVal: boolean) => {
    setIsLoading(passedVal);
  };

  const [
    updateDoctorContactDetailsDialog,
    setUpdateDoctorContactDetailsDialog,
  ] = useState(false);
  const updateDoctorContactDetailsDialogUpdatedState = (passedVal: boolean) => {
    setUpdateDoctorContactDetailsDialog(passedVal);
  };
  const doctorContactDetailsLoaderVal = (passedVal: boolean) => {
    setIsLoading(passedVal);
  };

  const [addScheduleDialog, setAddScheduleDialog] = useState(false);
  const updateAddScheduleDialogUpdatedState = (passedVal: boolean) => {
    setAddScheduleDialog(passedVal);
  };

  const [addNoteDialog, setAddNoteDialog] = React.useState(false);
  const updateAddNoteDialogValFromChild = (passedVal: boolean) => {
    setAddNoteDialog(passedVal);
  };

  // Doctor Summary
  const handleFirstButtonClick = useCallback(() => {
    if (isFirstButtonActive) return;
    setIsFirstButtonActive(true);
    setIsSecondButtonActive(false);
    setIsThirdButtonActive(false);
    setIsFourthButtonActive(false);
  }, [isFirstButtonActive]);

  // Schedule Details Button Toggle
  const handleSecondButtonClick = useCallback(() => {
    if (!isFirstButtonActive && !isThirdButtonActive && !isFourthButtonActive)
      return;
    setIsFirstButtonActive(false);
    setIsSecondButtonActive(true);
    setIsThirdButtonActive(false);
    setIsFourthButtonActive(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSecondButtonActive]);

  // Contact Details Button Toggle
  const handleThirdButtonClick = useCallback(() => {
    if (!isFirstButtonActive && !isSecondButtonActive && !isFourthButtonActive)
      return;
    setIsFirstButtonActive(false);
    setIsSecondButtonActive(false);
    setIsThirdButtonActive(true);
    setIsFourthButtonActive(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isThirdButtonActive]);

  // Notes Details Button Toggle
  const handleFourthButtonClick = useCallback(() => {
    if (!isFirstButtonActive && !isSecondButtonActive && !isThirdButtonActive)
      return;
    setIsFirstButtonActive(false);
    setIsSecondButtonActive(false);
    setIsThirdButtonActive(false);
    setIsFourthButtonActive(true);
    localStorage.setItem("doctorID", String(id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFourthButtonActive]);

  // getDoctorDetailsList api call ..
  const doctorsDetailsValue = useSelector(
    (state: AppState) => state.doctorDetails
  );

  useEffect(() => {
    // Do api call here....
    dispatch(getDoctorDetailsById(id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (
      doctorsDetailsValue?.getDoctorDetailsByIdRes?.doctorDetails?.language !==
      undefined
    ) {
      // let str =
      //   doctorsDetailsValue?.getDoctorDetailsByIdRes?.doctorDetails?.language.map(
      //     (item) => {
      //       return item.label;
      //     }
      //   );
    }
  }, [doctorsDetailsValue]);

  const pageName =
    doctorsDetailsValue?.getDoctorDetailsByIdRes?.doctorDetails?.firstname !==
      undefined &&
      doctorsDetailsValue?.getDoctorDetailsByIdRes?.doctorDetails?.lastname !==
      undefined
      ? doctorsDetailsValue?.getDoctorDetailsByIdRes?.doctorDetails?.firstname +
      " " +
      doctorsDetailsValue?.getDoctorDetailsByIdRes?.doctorDetails?.lastname +
      " Details"
      : null;

  const doctorDetails = (
    <Card>
      <CardHeader
        title={
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item>
              <Typography>
                {isFirstButtonActive ? (
                  <Typography variant="h6">{t("doctorlistpage.doctordetails", { ns: ["home"] })}</Typography>
                ) : isSecondButtonActive ? (
                  <Typography variant="h6">{t("Workschedulepage.schelist", { ns: ["home"] })}</Typography>
                ) : isThirdButtonActive ? (
                  <Typography variant="h6">{t("common.contactDetails", { ns: ["home"] })}</Typography>
                ) : (
                  <Typography variant="h6">{t("common.noteList", { ns: ["home"] })}</Typography>
                )}
              </Typography>
            </Grid>
            <Grid item>
              {isFirstButtonActive ? (
                <Button
                  variant="contained"
                  sx={{ textTransform: "none" }}
                  onClick={() => setUpdateDoctorDetailsDialog(true)}
                >
                  {t("common.updateSummaryDetail", { ns: ["home"] })}
                </Button>
              ) : isThirdButtonActive ? (
                <Button
                  variant="contained"
                  sx={{ textTransform: "none" }}
                  onClick={() => {
                    setUpdateDoctorContactDetailsDialog(true);
                  }}
                >
                  {t("common.uploadDetails", { ns: ["home"] })}
                </Button>
              ) : isSecondButtonActive ? (
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-end"
                  alignItems="center"
                  spacing={2}
                >
                  <Grid item>
                    <Button
                      variant="outlined"
                      sx={{ textTransform: "none" }}
                      onClick={() =>
                        dispatch(getDoctorWorkScheduleList(getScheduleBody))
                      }
                    >
                      {t("common.reload", { ns: ["home"] })}
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      variant="contained"
                      sx={{ textTransform: "none" }}
                      onClick={() => setAddScheduleDialog(true)}
                    >
                      {t("common.addSchedule", { ns: ["home"] })!}
                    </Button>
                  </Grid>
                </Grid>
              ) : (
                <Button variant="contained" sx={{ textTransform: "none" }} onClick={() => setAddNoteDialog(true)}>
                  {t("common.addNote", { ns: ["home"] })!}
                </Button>
              )}
            </Grid>
          </Grid>
        }
      />
      {isFirstButtonActive === true ? (
        <DoctorDetails
          doctorDetailsLoader={doctorDetailsLoaderVal}
          updateDoctorDetailsDialog={updateDoctorDetailsDialog}
          updateDoctorDetailsDialogUpdatedState={
            updateDoctorDetailsDialogUpdatedState
          }
        />
      ) : isSecondButtonActive === true ? (
        <Schedule />
      ) : isThirdButtonActive === true ? (
        <ContactDetails
          contactDetailsLoader={doctorContactDetailsLoaderVal}
          updateDoctorContactDetailsDialog={updateDoctorContactDetailsDialog}
          updateDoctorContactDetailsDialogUpdatedState={
            updateDoctorContactDetailsDialogUpdatedState
          }
        />
      ) : (
        <Notes notesLoader={setNotesLoader} />
      )}
    </Card>
  );

  return (
    <Box>
      {loadingMarkup}
      <PageLayout>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item>
            <MWPageTitle title={pageName} backButton={true} />
          </Grid>
          <Grid item>
            <Grid
              container
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
            >
              <Grid item>
                <ButtonGroup
                  variant="outlined"
                  aria-label="outlined button group"
                >
                  <Button
                    variant={isFirstButtonActive ? "contained" : "outlined"}
                    onClick={() => handleFirstButtonClick()}
                    sx={{ textTransform: "none" }}
                  >
                    {t("userprofilepage.docdetail", { ns: ["home"] })!}
                  </Button>
                  <Button
                    variant={isThirdButtonActive ? "contained" : "outlined"}
                    onClick={handleThirdButtonClick}
                    sx={{ textTransform: "none" }}
                  >
                    {t("common.contactDetails", { ns: ["home"] })}
                  </Button>
                  <Button
                    variant={isSecondButtonActive ? "contained" : "outlined"}
                    onClick={handleSecondButtonClick}
                    sx={{ textTransform: "none" }}
                  >
                    {t("common.scheduleDetails", { ns: ["home"] })}
                  </Button>
                  <Button
                    variant={isFourthButtonActive ? "contained" : "outlined"}
                    onClick={handleFourthButtonClick}
                    sx={{ textTransform: "none" }}
                  >
                    {t("common.noteList", { ns: ["home"] })}
                  </Button>
                </ButtonGroup>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {doctorDetails}
      </PageLayout>
      <AddSchedule
        addScheduleDialog={addScheduleDialog}
        updateAddScheduleDialogUpdatedState={
          updateAddScheduleDialogUpdatedState
        }
      />
      {addNoteDialog ?
        <AddNoteDialog
          addNoteDialogEnable={addNoteDialog}
          addNoteDialogUpdateState={updateAddNoteDialogValFromChild}
          doctorID={id}
        />
        : null}
    </Box>
  );
}
