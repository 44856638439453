import React, { useEffect, useState } from "react";
import { Box, Chip, Grid, Tab, Typography } from "@mui/material";
import CorporatePackageActive from "./CorporatePackageActive";
import CorporatePackageInactive from "./CorporatePackageInactive";
import AdminLoader from "../AdminLoader";
import MWPageTitle from "../../component/MWPageTitle";
import PageLayout from "../Layout/PageLayout";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { t } from "i18next";
import RefreshIcon from "@mui/icons-material/Refresh";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../redux/store/Store";
import { getCorporatePackageList } from "../../redux/CorporatePackage/Package/ApiCall";
import { getInactiveCorporatePackageList } from "../../redux/CorporatePackage/InactiveCorporatePackage/ApiCall";

export default function CorporatePackage() {
  const dispatch = useDispatch();
  const loadingMarkup = <AdminLoader />;
  const [selected, setSelected] = React.useState("1");
  const handleTabChange = (_event: React.SyntheticEvent, newValue: string) => {
    setSelected(newValue);
  };
  const [corporateActivePackageLoader, setCorporateActivePackageLoader] =
    useState(false);
  const [corporateInActivePackageLoader, setCorporateInActivePackageLoader] =
    useState(false);
  const [activeCorporate, setActiveCorporate] = React.useState("");
  const [inactiveListCount, setInactiveListCount] = React.useState("");

  // call Store
  const activeCorporatePackageList = useSelector(
    (state: AppState) => state.corporatePackageValue
  );

  const inActiveCorporatePackageList = useSelector(
    (state: AppState) => state.getInactiveCorporatePackageListValue
  );

  useEffect(() => {
    activeCorporatePackageList?.corporatePackageListValue !== undefined
      ? setActiveCorporate(
          String(activeCorporatePackageList?.corporatePackageListValue.length)
        )
      : setActiveCorporate("0");

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeCorporatePackageList]);

  // get corporate package list body
  const corporatePackageBody = {
    active: Number(1),
  };
  useEffect(() => {
    dispatch(getCorporatePackageList(corporatePackageBody));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);
  const activeCorporateHandles = () => {
    dispatch(getCorporatePackageList(corporatePackageBody));
  };

  useEffect(() => {
    inActiveCorporatePackageList?.inactiveCorporatePackageListValue !==
    undefined
      ? setInactiveListCount(
          String(
            inActiveCorporatePackageList?.inactiveCorporatePackageListValue
              .length
          )
        )
      : setInactiveListCount("0");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inActiveCorporatePackageList]);

  // get corporate package list body
  const inactiveCorporatePackageBody = {
    active: Number(0),
  };
  useEffect(() => {
    dispatch(getInactiveCorporatePackageList(inactiveCorporatePackageBody));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);
  const inactiveCorporateApiCall = () => {
    dispatch(getCorporatePackageList(inactiveCorporatePackageBody));
  };

  const corporatePage = (
    <Grid>
      <Grid item>
        <Box>
          <TabContext value={String(selected)}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList onChange={handleTabChange}>
                <Tab
                  label={
                    <Grid
                      container
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                      spacing={1}
                    >
                      <Grid item>
                        <Typography>
                          {t("corporatepackage.active", { ns: ["home"] })!}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Chip
                          label={activeCorporate}
                          variant="outlined"
                          size="small"
                        />
                      </Grid>
                    </Grid>
                  }
                  icon={
                    <RefreshIcon
                      onClick={() => {
                        activeCorporateHandles();
                      }}
                    />
                  }
                  iconPosition="end"
                  value="1"
                />
                <Tab
                  label={
                    <Grid
                      container
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                      spacing={1}
                    >
                      <Grid item>
                        <Typography>
                          {t("corporatepackage.inactive", { ns: ["home"] })!}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Chip
                          label={String(inactiveListCount)}
                          variant="outlined"
                          size="small"
                        />
                      </Grid>
                    </Grid>
                  }
                  icon={
                    <RefreshIcon
                      onClick={() => {
                        inactiveCorporateApiCall();
                      }}
                    />
                  }
                  iconPosition="end"
                  value="2"
                />
              </TabList>
            </Box>
            <TabPanel value="1">
              <CorporatePackageActive
                corporateActivePackageLoader={setCorporateActivePackageLoader}
              />
            </TabPanel>
            <TabPanel value="2">
              <CorporatePackageInactive
                corporateInActivePackageLoader={
                  setCorporateInActivePackageLoader
                }
              />
            </TabPanel>
          </TabContext>
        </Box>
      </Grid>
    </Grid>
  );
  return (
    <Box>
      {corporateActivePackageLoader === true ||
      corporateInActivePackageLoader === true
        ? loadingMarkup
        : ""}
      <PageLayout>
        <MWPageTitle title={t("corporatepackage.title", { ns: ["home"] })!} />
        {corporatePage}
      </PageLayout>
    </Box>
  );
}
