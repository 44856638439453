import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../redux/store/Store";
import AdminLoader from "../AdminLoader";
import { DeletePatientCaseBody } from "../../redux/Patient/FilePreparation/deletePatientCase/Model";
import { deletePatientCase } from "../../redux/Patient/FilePreparation/deletePatientCase/API";
import { t } from "i18next";
type Props = {
  deleteCaseDialogEnable?: boolean | any;
  deleteCaseDialogUpdateState?: boolean | any;
};
export default function AlertDialog({
  deleteCaseDialogEnable,
  deleteCaseDialogUpdateState,
}: Props) {
  const { caseid } = useParams() as {
    caseid: string;
  };
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    deleteCaseDialogUpdateState(false);
  };
  React.useEffect(() => {
    setOpen(deleteCaseDialogEnable);
  }, [deleteCaseDialogEnable]);

  // Delete body....
  const deletePastHistoryBody = {
    patientCaseId: Number(caseid),
    isActive: false,
  } as DeletePatientCaseBody;
  const deleteCase = () => {
    dispatch(deletePatientCase(deletePastHistoryBody));
  };
  const deleteCaseValue = useSelector(
    (state: AppState) => state.deleteCaseValue
  );

  React.useEffect(() => {
    if (deleteCaseValue?.deletePatientCaseRes?.message !== undefined) {
      if (deleteCaseValue?.deletePatientCaseRes?.message !== "") {
        deleteCaseDialogUpdateState(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteCaseValue]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      {deleteCaseValue?.loading ? <AdminLoader /> : null}
      <DialogTitle id="alert-dialog-title">
        {t("common.delCase", { ns: ["home"] })!}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {t("common.wantDelCase", { ns: ["home"] })!}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={handleClose}>
          {t("filePrepPage.no", { ns: ["home"] })!}
        </Button>
        <Button variant="contained" onClick={deleteCase} autoFocus>
          {t("filePrepPage.yes", { ns: ["home"] })!}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
