import { GetLastModifiedPatientListRes, PageCount } from "./Model";
export const GET_LAST_MODIFIED_PATIENT_LIST_LOADING =
  "GET_LAST_MODIFIED_PATIENT_LIST_LOADING";
export const GET_LAST_MODIFIED_PATIENT_LIST_SUCCESS =
  "GET_LAST_MODIFIED_PATIENT_LIST_SUCCESS";
export const GET_LAST_MODIFIED_PATIENT_LIST_FAIL =
  "GET_LAST_MODIFIED_PATIENT_LIST_FAIL";
export const GET_LAST_MODIFIED_PATIENT_LIST_DATA = "GET_LAST_MODIFIED_PATIENT_LIST_DATA";
export const GET_LAST_MODIFIED_PATIENT_LIST_API_MSG =
  "GET_LAST_MODIFIED_PATIENT_LIST_API_MSG";
export const GET_LAST_MODIFIED_PATIENT_LIST_API_RES =
  "GET_LAST_MODIFIED_PATIENT_LIST_API_RES";

export interface GetLastModifiedPatientListState {
  getLastModifiedPatientListRes: GetLastModifiedPatientListRes;
  loading: boolean;
  errorMsg: string;
  status: number;
}
export interface GetLastModifiedPatientListLoading {
  type: typeof GET_LAST_MODIFIED_PATIENT_LIST_LOADING;
  loading: boolean;
}
export interface GetLastModifiedPatientListSuccess {
  type: typeof GET_LAST_MODIFIED_PATIENT_LIST_SUCCESS;
  payload: GetLastModifiedPatientListRes;
}
export interface GetLastModifiedPatientListFail {
  type: typeof GET_LAST_MODIFIED_PATIENT_LIST_FAIL;
  payload: GetLastModifiedPatientListRes;
  errorMsg: string;
  status: number;
}
export interface GetLastModifiedPatientListAPIMsg {
  type: typeof GET_LAST_MODIFIED_PATIENT_LIST_API_MSG;
  payload: GetLastModifiedPatientListRes;
  errorMsg: string;
  status: number;
}
export interface GetLastModifiedPatientListAPIRes {
  type: typeof GET_LAST_MODIFIED_PATIENT_LIST_API_RES;
  payload: GetLastModifiedPatientListRes;
  errorMsg: string;
  status: number;
}

interface GetLastModifiedPatientListDataAction {
  type: typeof GET_LAST_MODIFIED_PATIENT_LIST_DATA;
  payload: GetLastModifiedPatientListRes;
  pageCount: PageCount;
}

export type GetLastModifiedPatientListActionTypes =
  | GetLastModifiedPatientListLoading
  | GetLastModifiedPatientListSuccess
  | GetLastModifiedPatientListFail
  | GetLastModifiedPatientListAPIMsg
  | GetLastModifiedPatientListDataAction
  | GetLastModifiedPatientListAPIRes;
