import { CreateInstantMeetingValueRes } from "./Model";
export const  CREATE_INSTANT_MEETING_LOADING = " CREATE_INSTANT_MEETING_LOADING";
export const  CREATE_INSTANT_MEETING_SUCCESS = " CREATE_INSTANT_MEETING_SUCCESS";
export const  CREATE_INSTANT_MEETING_FAIL = " CREATE_INSTANT_MEETING_FAIL";
export const  CREATE_INSTANT_MEETING_UPDATE_API_MSG =
  " CREATE_INSTANT_MEETING_UPDATE_API_MSG";
export const  CREATE_INSTANT_MEETING_UPDATE_API_RES =
  " CREATE_INSTANT_MEETING_UPDATE_API_RES";

export interface  CreateInstantMeetingState {
  createInstantMeetingRes: CreateInstantMeetingValueRes;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface  CreateInstantMeetingLoading {
  type: typeof  CREATE_INSTANT_MEETING_LOADING;
  loading: boolean;
}
export interface  CreateInstantMeetingSuccess {
  type: typeof  CREATE_INSTANT_MEETING_SUCCESS;
  payload: CreateInstantMeetingValueRes;
  successMsg: string;
}
export interface  CreateInstantMeetingFail {
  type: typeof  CREATE_INSTANT_MEETING_FAIL;
  payload: CreateInstantMeetingValueRes;
  errorMsg: string;
  status: number;
}
export interface  CreateInstantMeetingUpdateAPIMsg {
  type: typeof  CREATE_INSTANT_MEETING_UPDATE_API_MSG;
  payload: CreateInstantMeetingValueRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface  CreateInstantMeetingUpdateAPIRes {
  type: typeof  CREATE_INSTANT_MEETING_UPDATE_API_RES;
  payload: CreateInstantMeetingValueRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}

export type  CreateInstantMeetingActionTypes =
  |  CreateInstantMeetingLoading
  |  CreateInstantMeetingSuccess
  |  CreateInstantMeetingFail
  |  CreateInstantMeetingUpdateAPIMsg
  |  CreateInstantMeetingUpdateAPIRes;
