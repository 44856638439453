import { Dispatch } from "redux";
import Url from "../../ApiConfig";
import axios from "axios";
import {
  AddSummaryActionTypes,
  AddWorkScheduleActionTypes,
  ADD_SUMMARY_FAIL,
  ADD_SUMMARY_LOADING,
  ADD_SUMMARY_SUCCESS,
  ADD_WORKSCHEDULE_FAIL,
  ADD_WORKSCHEDULE_LOADING,
  ADD_WORKSCHEDULE_STATUS,
  ADD_WORKSCHEDULE_SUCCESS,
  AdminAppointmentGetActionTypes,
  APPOINTMENT_LIST_FAIL,
  APPOINTMENT_LIST_LOADING,
  APPOINTMENT_LIST_SUCCESS,
  ContactDetailsActionTypes,
  CONTACT_DETAILS_FAIL,
  CONTACT_DETAILS_LOADING,
  CONTACT_DETAILS_SUCCESS,
  DeleteWorkScheduleActionTypes,
  DELETE_WORK_SCHEDULE_FAIL,
  DELETE_WORK_SCHEDULE_LOADING,
  DELETE_WORK_SCHEDULE_SUCCESS,
  DoctorDetailsActionTypes,
  DOCTOR_DETAILS_FAIL,
  DOCTOR_DETAILS_LOADING,
  DOCTOR_DETAILS_SUCCESS,
  WeekDayListActionTypes,
  WEEKDAY_LIST_FAIL,
  WEEKDAY_LIST_LOADING,
  WEEKDAY_LIST_SUCCESS,
  WorkScheduleActionTypes,
  WORK_SCHEDULE_FAIL,
  WORK_SCHEDULE_LOADING,
  WORK_SCHEDULE_SUCCESS,
} from "./ActionTypes";
import {
  addSummaryAction,
  AddWorkSchedule,
  contactDetailAction,
  deleteworkScheduleAction,
  getAptAction,
  getDoctorDetailsAction,
  getWeekdayAction,
  workScheduleAction,
} from "./Action";
import { setupInterceptorsTo } from "../Authentication/Interceptors";
import { headers } from "../../component/Utility";

export const getAdminAppointmentList = (state: {}) => {
  return function (dispatch: Dispatch<AdminAppointmentGetActionTypes>) {
    dispatch({
      type: APPOINTMENT_LIST_LOADING,
    });
    setupInterceptorsTo(axios)
      .post(
        Url.baseUrl +
          `/appointmentapp/api/appointment/get_appointment_list_for_doctor_by_admin`,
        state,
        { headers: headers }
      )
      .then((res) => {
        let appointmentResData = res.data.appointmentRequestList;
        dispatch(getAptAction(appointmentResData));
        dispatch({
          type: APPOINTMENT_LIST_SUCCESS,
          payload: appointmentResData,
        });
      })
      .catch((error) => {
        dispatch({
          type: APPOINTMENT_LIST_FAIL,
        });
      });
  };
};

export const updateSummary = (state: {}) => {
  return function (dispatch: Dispatch<AddSummaryActionTypes>) {
    dispatch({
      type: ADD_SUMMARY_LOADING,
    });
    setupInterceptorsTo(axios)
      .post(
        Url.baseUrl + `/adminapp/api/adminActions/update_doctor_summary`,
        state,
        { headers: headers }
      )
      .then((res) => {
        let ResData = res.data;
        dispatch(addSummaryAction(ResData));
        dispatch({
          type: ADD_SUMMARY_SUCCESS,
          payload: ResData,
        });
      })
      .catch((error) => {
        dispatch({
          type: ADD_SUMMARY_FAIL,
        });
      });
  };
};

export const contactDetails = (id: any) => {
  return function (dispatch: Dispatch<ContactDetailsActionTypes>) {
    dispatch({
      type: CONTACT_DETAILS_LOADING,
    });
    setupInterceptorsTo(axios)
      .post(
        Url.baseUrl + `/adminapp/api/doctorContact/contactDetailsOfDoctor`,
        { doctorId: Number(id) },
        { headers: headers }
      )
      .then((res) => {
        let ResData = res.data.doctorContactDetails;
        dispatch(contactDetailAction(ResData));
        dispatch({
          type: CONTACT_DETAILS_SUCCESS,
          payload: ResData,
        });
      })
      .catch((error) => {
        dispatch({
          type: CONTACT_DETAILS_FAIL,
        });
      });
  };
};

export const getWorkSchedule = (state: {}) => {
  return function (dispatch: Dispatch<WorkScheduleActionTypes>) {
    dispatch({
      type: WORK_SCHEDULE_LOADING,
    });
    setupInterceptorsTo(axios)
      .post(
        Url.baseUrl + `/appointmentapp/api/workSchedule/get_workScheduleList`,
        state,
        { headers: headers }
      )
      .then((res) => {
        let ResData = res.data.workSchedule;
        dispatch(workScheduleAction(ResData));
        dispatch({
          type: WORK_SCHEDULE_SUCCESS,
          payload: ResData,
        });
      })
      .catch((error) => {
        dispatch({
          type: WORK_SCHEDULE_FAIL,
        });
      });
  };
};

export const deleteScheduleApi = (state: {}) => {
  return function (dispatch: Dispatch<DeleteWorkScheduleActionTypes>) {
    dispatch({
      type: DELETE_WORK_SCHEDULE_LOADING,
    });
    setupInterceptorsTo(axios)
      .post(
        Url.baseUrl + `/appointmentapp/api/workSchedule/delete_workSchedule`,
        state,
        { headers: headers }
      )
      .then((res) => {
        let ResData = res.data;
        dispatch(deleteworkScheduleAction(ResData));
        dispatch({
          type: DELETE_WORK_SCHEDULE_SUCCESS,
          payload: ResData,
        });
      })
      .catch((error) => {
        dispatch({
          type: DELETE_WORK_SCHEDULE_FAIL,
        });
      });
  };
};

// work schedule week list api call
export const weeklistApicall = () => {
  return function (dispatch: Dispatch<WeekDayListActionTypes>) {
    dispatch({
      type: WEEKDAY_LIST_LOADING,
    });
    setupInterceptorsTo(axios)
      .get(
        Url.baseUrl + "/appointmentapp/api/workSchedule/weekDayList",
        { headers: headers }
      )
      .then((res) => {
        let weekDaysList = res.data.weekDays;
        let message = res.data.message;
        let status = res.data.status;
        dispatch(getWeekdayAction(weekDaysList, message, status));
        dispatch({
          type: WEEKDAY_LIST_SUCCESS,
          payload: weekDaysList,
          message: message,
          status: status,
        });
      })
      .catch((error) => {
        let message = error.response.data.message;
        let status = error.response.data.status;
        dispatch({
          type: WEEKDAY_LIST_FAIL,
          message: message,
          status: status,
        });
      });
  };
};

// Add work schedule api call
export const addWorkSchedule = (state = {}) => {
  return function (dispatch: Dispatch<AddWorkScheduleActionTypes>) {
    dispatch({
      type: ADD_WORKSCHEDULE_LOADING,
    });
    dispatch(AddWorkSchedule(addWorkSchedule(ADD_WORKSCHEDULE_STATUS)));
    setupInterceptorsTo(axios)
      .post(
        Url.baseUrl + "/appointmentapp/api/workSchedule/add_workSchedule",
        state,
        { headers: headers }
      )
      .then((result) => {
        if (result) {
          dispatch({
            type: ADD_WORKSCHEDULE_SUCCESS,
            payload: result.data,
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: ADD_WORKSCHEDULE_FAIL,
          payload: error.response.data,
        });
      });
  };
};

// doctor details api call
export const getDoctorDetailsList = (id: any) => {
  return function (dispatch: Dispatch<DoctorDetailsActionTypes>) {
    dispatch({
      type: DOCTOR_DETAILS_LOADING,
    });
    setupInterceptorsTo(axios)
      .get(
        Url.baseUrl + `/landingeapp/api/doctor-details-by-id/${String(id)}`,
        { headers: headers }
      )
      .then((res) => {
        let doctorDetails = res.data.doctorDetails;
        let message = res.data.message;
        let status = res.data.status;
        dispatch(getDoctorDetailsAction(doctorDetails, message, status));
        dispatch({
          type: DOCTOR_DETAILS_SUCCESS,
          payload: doctorDetails,
          message: message,
          status: status,
        });
      })
      .catch((error) => {
        let message = error.response.data.message;
        let status = error.response.data.status;
        dispatch({
          type: DOCTOR_DETAILS_FAIL,
          message: message,
          status: status,
        });
      });
  };
};
