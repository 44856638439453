import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../ApiConfig";
import { GetInactiveCaseListActionTypes } from "./ActionTypes";
import { GetInactiveCaseListRes } from "./Model";
import {
  getInactiveCaseListAPIResClearAction,
  getInactiveCaseListErrorAction,
  getInactiveCaseListLoadingAction,
  getInactiveCaseListSuccessAction,
  getInactiveCaseListAPIMsgAction,
} from "./Actions";
import { headers } from "../../component/Utility";

let apiRes = {} as GetInactiveCaseListRes;
export const getInactiveCaseListValue = (pageNo: any, isactive: any) => {
  return function (dispatch: Dispatch<GetInactiveCaseListActionTypes>) {
    dispatch(getInactiveCaseListLoadingAction(true));
    axios
      .post(
        Url.baseUrl +
          `/adminapp/api/patientcase/patient_case_list_all?page=${Number(
            pageNo
          )}&size=50`,
        { isActive: isactive },
        { headers: headers }
      )
      .then((res) => {
        apiRes = res.data;
        dispatch(getInactiveCaseListLoadingAction(false));
        dispatch(getInactiveCaseListSuccessAction(res.data));
      })
      .catch((error) => {
        dispatch(getInactiveCaseListLoadingAction(false));
        dispatch(
          getInactiveCaseListErrorAction(
            {} as GetInactiveCaseListRes,
            error.response !== undefined
              ? error.response.data.message || error.response.data.detail
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || -1 : -1
          )
        );
      });
  };
};

export const getInactiveCaseListAPIResMsg = () => {
  return function (dispatch: Dispatch<GetInactiveCaseListActionTypes>) {
    dispatch(
      getInactiveCaseListAPIMsgAction(apiRes as GetInactiveCaseListRes, "", 0)
    );
  };
};

export const clearInactiveCaseListAPIRes = () => {
  return function (dispatch: Dispatch<GetInactiveCaseListActionTypes>) {
    dispatch(
      getInactiveCaseListAPIResClearAction({} as GetInactiveCaseListRes, "", 0)
    );
  };
};
