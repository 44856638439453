import {
   PatientImportConfigListActionTypes,
   PATIENT_IMPORT_CONFIG_LIST_FAIL,
   PATIENT_IMPORT_CONFIG_LIST_LOADING,
   PATIENT_IMPORT_CONFIG_LIST_SUCCESS,
   PatientImportConfigListState,
   PATIENT_IMPORT_CONFIG_LIST_UPDATE_API_MSG,
   PATIENT_IMPORT_CONFIG_LIST_UPDATE_API_RES,
} from "./ActionTypes";
import { PatientImportConfigListValueRes } from "./Model";

const initialStateGetPosts:  PatientImportConfigListState = {
  loading: false,
  patientImportConfigListRes: {} as PatientImportConfigListValueRes,
  successMsg: "",
  errorMsg: "",
  status: 0,
};
export const getPatientImportConfigListReducer = (
  state = initialStateGetPosts,
  action:  PatientImportConfigListActionTypes
):  PatientImportConfigListState => {
  switch (action.type) {
    case  PATIENT_IMPORT_CONFIG_LIST_LOADING:
      return {
        ...state,
        loading: action.loading,
      };
    case  PATIENT_IMPORT_CONFIG_LIST_SUCCESS:
      return {
        ...state,
        patientImportConfigListRes: action.payload,
        successMsg: action.successMsg,
      };
    case  PATIENT_IMPORT_CONFIG_LIST_FAIL:
      return {
        ...state,
        patientImportConfigListRes: action.payload,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case  PATIENT_IMPORT_CONFIG_LIST_UPDATE_API_MSG:
      return {
        ...state,
        patientImportConfigListRes: action.payload,
        successMsg: action.successMsg,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case  PATIENT_IMPORT_CONFIG_LIST_UPDATE_API_RES:
      return {
        ...state,
        patientImportConfigListRes: action.payload,
        successMsg: action.successMsg,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    default:
      return state;
  }
};

