import { GET_LIST } from "../../types/ActionTypes";
import { PastMedicalResponse } from "./Model";

// Patient Demographics Loading State
export const GET_PAST_MEDICAL_LOADING = "GET_PAST_MEDICAL_LOADING";
export const GET_PAST_MEDICAL_SUCCESS = "GET_PAST_MEDICAL_SUCCESS";
export const GET_PAST_MEDICAL_FAIL = "GET_PAST_MEDICAL_FAIL";

export interface GetPatientDemographicsState {
  getPastMedicalDetails: PastMedicalResponse;
  loading: boolean;
}
export interface DoctorListLoading {
  type: typeof GET_PAST_MEDICAL_LOADING;
}
export interface DoctorListSuccess {
  type: typeof GET_PAST_MEDICAL_SUCCESS;
  payload: PastMedicalResponse;
}
export interface DoctorListFail {
  type: typeof GET_PAST_MEDICAL_FAIL;
}
interface GetDoctorAction {
  type: typeof GET_LIST;
  payload: PastMedicalResponse;
}

export type GetPastMedicalActionTypes =
  | GetDoctorAction
  | DoctorListLoading
  | DoctorListSuccess
  | DoctorListFail;