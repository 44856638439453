import { DoctorActiveListResponse } from "./Model";
export const DOCTOR_ACTIVE_NOTE_LIST_LOADING = "DOCTOR_ACTIVE_NOTE_LIST_LOADING";
export const DOCTOR_ACTIVE_NOTE_LIST_SUCCESS = "DOCTOR_ACTIVE_NOTE_LIST_SUCCESS";
export const DOCTOR_ACTIVE_NOTE_LIST_FAIL = "DOCTOR_ACTIVE_NOTE_LIST_FAIL";
export const DOCTOR_ACTIVE_NOTE_LIST_API_MSG = "DOCTOR_ACTIVE_NOTE_LIST_API_MSG";
export const DOCTOR_ACTIVE_NOTE_LIST_API_RES = "DOCTOR_ACTIVE_NOTE_LIST_API_RES";

export interface DoctorActiveNoteListState {
  doctorActiveNoteListRes: DoctorActiveListResponse;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface DoctorActiveNoteListLoading {
  type: typeof DOCTOR_ACTIVE_NOTE_LIST_LOADING;
  loading: boolean;
}
export interface DoctorActiveNoteListSuccess {
  type: typeof DOCTOR_ACTIVE_NOTE_LIST_SUCCESS;
  payload: DoctorActiveListResponse;
  successMsg: string;
}
export interface DoctorActiveNoteListFail {
  type: typeof DOCTOR_ACTIVE_NOTE_LIST_FAIL;
  payload: DoctorActiveListResponse;
  errorMsg: string;
  status: number;
}
export interface DoctorActiveNoteListAPIMsg {
  type: typeof DOCTOR_ACTIVE_NOTE_LIST_API_MSG;
  payload: DoctorActiveListResponse;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface DoctorActiveNoteListAPIRes {
  type: typeof DOCTOR_ACTIVE_NOTE_LIST_API_RES;
  payload: DoctorActiveListResponse;
  successMsg: string;
  errorMsg: string;
  status: number;
}

export type DoctorActiveListActionTypes =
  | DoctorActiveNoteListLoading
  | DoctorActiveNoteListSuccess
  | DoctorActiveNoteListFail
  | DoctorActiveNoteListAPIMsg
  | DoctorActiveNoteListAPIRes;