import { GetAdminDetailsRes } from "./Model";
export const GET_ADMIN_LOGIN_DETAILS_LOADING = "GET_ADMIN_LOGIN_DETAILS_LOADING";
export const GET_ADMIN_LOGIN_DETAILS_SUCCESS = "GET_ADMIN_LOGIN_DETAILS_SUCCESS";
export const GET_ADMIN_LOGIN_DETAILS_FAIL = "GET_ADMIN_LOGIN_DETAILS_FAIL";
export const GET_ADMIN_LOGIN_DETAILS_API_MSG =
  "GET_ADMIN_LOGIN_DETAILS_API_MSG";
export const GET_ADMIN_LOGIN_DETAILS_API_RES =
  "GET_ADMIN_LOGIN_DETAILS_API_RES";

export interface GetAdminLoginDetailsState {
  getAdminLoginDetailsRes: GetAdminDetailsRes;
  loading: boolean;
  errorMsg: string;
  status: number;
}
export interface GetAdminLoginDetailsLoading {
  type: typeof GET_ADMIN_LOGIN_DETAILS_LOADING;
  loading: boolean;
}
export interface GetAdminLoginDetailsSuccess {
  type: typeof GET_ADMIN_LOGIN_DETAILS_SUCCESS;
  payload: GetAdminDetailsRes;
  successMsg: string;
}
export interface GetAdminLoginDetailsFail {
  type: typeof GET_ADMIN_LOGIN_DETAILS_FAIL;
  payload: GetAdminDetailsRes;
  errorMsg: string;
  status: number;
}
export interface GetAdminLoginDetailsAPIMsg {
  type: typeof GET_ADMIN_LOGIN_DETAILS_API_MSG;
  payload: GetAdminDetailsRes;
  errorMsg: string;
  status: number;
}
export interface GetAdminLoginDetailsAPIRes {
  type: typeof GET_ADMIN_LOGIN_DETAILS_API_RES;
  payload: GetAdminDetailsRes;
  errorMsg: string;
  status: number;
}

export type GetAdminDetailsActionTypes =
  | GetAdminLoginDetailsLoading
  | GetAdminLoginDetailsSuccess
  | GetAdminLoginDetailsFail
  | GetAdminLoginDetailsAPIMsg
  | GetAdminLoginDetailsAPIRes;
