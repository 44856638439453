import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../../ApiConfig";
import { GetPatientNoteListActionTypes } from "./ActionTypes";
import { GetPatientNoteListBody, GetPatientNoteListRes } from "./Model";
import {
  getAdminNoteListByPatientAPIResClearAction,
  getAdminNoteListByPatientErrorAction,
  getAdminNoteListByPatientLoadingAction,
  getAdminNoteListByPatientSuccessAction,
  getAdminNoteListByPatientUpdateAPIMsgAction,
} from "./Actions";
import { setupInterceptorsTo } from "../../Authentication/Interceptors";
import { headers } from "../../../component/Utility";


let apiRes = {} as GetPatientNoteListRes;
export const getAdminNoteListByPatient = (payload: GetPatientNoteListBody) => {
  return function (dispatch: Dispatch<GetPatientNoteListActionTypes>) {
    dispatch(getAdminNoteListByPatientLoadingAction(true));
    setupInterceptorsTo(axios)
      .post(
        Url.baseUrl + `/erpapp/api/adminNote/get_admin_notes_by_patient`,
        payload,
        { headers: headers }
      )
      .then((res) => {
        apiRes = res.data;
        dispatch(getAdminNoteListByPatientLoadingAction(false));
        dispatch(
          getAdminNoteListByPatientSuccessAction(
            res.data !== undefined ? res.data : ({} as GetPatientNoteListRes),
            res.data.message !== undefined
              ? res.data.message
              : "Patient note list fetched successfully"
          )
        );
      })
      .catch((error) => {
        dispatch(getAdminNoteListByPatientLoadingAction(false));
        dispatch(
          getAdminNoteListByPatientErrorAction(
            {} as GetPatientNoteListRes,
            error.response !== undefined
              ? error.response.data.message || error.response.data.message
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || 0 : 0
          )
        );
      });
  };
};

export const updateAdminNoteListByPatientEditAPIResMsg = () => {
  return function (dispatch: Dispatch<GetPatientNoteListActionTypes>) {
    dispatch(
      getAdminNoteListByPatientUpdateAPIMsgAction(
        apiRes as GetPatientNoteListRes,
        "",
        "",
        0
      )
    );
  };
};

export const clearAdminNoteListByPatientEditAPIRes = () => {
  return function (dispatch: Dispatch<GetPatientNoteListActionTypes>) {
    dispatch(
      getAdminNoteListByPatientAPIResClearAction(
        {} as GetPatientNoteListRes,
        "",
        "",
        0
      )
    );
  };
};
