import {
    APPOINTMENT_LIST_DATA,
    APPOINTMENT_LIST_FAIL,
    APPOINTMENT_LIST_LOADING,
    APPOINTMENT_LIST_SUCCESS,
    AppointmentListActionTypes,
  } from "./ActionTypes";
  import { AppointmentListResponse } from "./Model";
  
  export const appointmentListAction = (
    appointmentListRes: AppointmentListResponse
  ): AppointmentListActionTypes => {
    return {
      type: APPOINTMENT_LIST_DATA,
      payload: appointmentListRes,
    };
  };
  
  export const appointmentListLoadingAction = (
    loading: boolean
  ): AppointmentListActionTypes => {
    return {
      type: APPOINTMENT_LIST_LOADING,
      loading: loading,
    };
  };
  
  export const appointmentListSuccessAction = (
    appointmentListRes: AppointmentListResponse,
    successMsg: string,
    status: number    
  ): AppointmentListActionTypes => {
    return {
      type: APPOINTMENT_LIST_SUCCESS,
      payload: appointmentListRes,
      successMsg: successMsg,
      status: status      
    };
  };
  
  export const appointmentListErrorAction = (
    appointmentListRes: AppointmentListResponse,
    errMsg: string,
    status: number
  ): AppointmentListActionTypes => {
    return {
      type: APPOINTMENT_LIST_FAIL,
      payload: appointmentListRes,
      errorMsg: errMsg,
      status: status,
    };
  };
  export const appointmentListUpdateAPIMsgAction = (
    appointmentListRes: AppointmentListResponse,
    errMsg: string,
    status: number
  ): AppointmentListActionTypes => {
    return {
      type: APPOINTMENT_LIST_FAIL,
      payload: appointmentListRes,
      errorMsg: errMsg,
      status: status,
    };
  };
  
  export const appointmentListAPIResClearAction = (
    appointmentListRes: AppointmentListResponse,
    errMsg: string,
    status: number
  ): AppointmentListActionTypes => {
    return {
      type: APPOINTMENT_LIST_FAIL,
      payload: appointmentListRes,
      errorMsg: errMsg,
      status: status,
    };
  };
  