import React, { useState } from "react";
import {
  FormControl,
  FormHelperText,
  Grid,
  MenuItem,
  Select,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import Flagde from "../image/de.png";
import Flagen from "../image/en.png";
import Flagfr from "../image/fr.png";
import Flagit from "../image/it.png";
interface Props {
  isMenu?: boolean;
}
const MWSelectLang: React.FC<Props> = ({ isMenu = false }) => {
  const { t, i18n } = useTranslation(["home"]);
  const [selectedLang, setSelectedLang] = useState(
    localStorage.getItem("lng") || "de"
  );
  const onClickLanguageChange = (e: any) => {
    const language = e.target.value;
    localStorage.setItem("lng", language);
    setSelectedLang(language);
    i18n.changeLanguage(language); //change the language
    window.location.reload();
  };

  return (
    <FormControl sx={{ m: 1, minHeight: 1 }} size="small">
      <Select value={selectedLang} onChange={onClickLanguageChange}>
        <MenuItem value="de">
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={2}
          >
            <Grid item>
              <img alt="" src={Flagde} height="30px" width="30px" />
            </Grid>
            <Grid item>Deutsch</Grid>
          </Grid>
        </MenuItem>
        <MenuItem value="en">
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={2}
          >
            <Grid item>
              <img alt="" src={Flagen} height="30px" width="30px" />
            </Grid>
            <Grid item>English</Grid>
          </Grid>
        </MenuItem>
        <MenuItem value="fr">
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={2}
          >
            <Grid item>
              <img alt="" src={Flagfr} height="30px" width="30px" />
            </Grid>
            <Grid item>French</Grid>
          </Grid>
        </MenuItem>
        <MenuItem value="it">
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={2}
          >
            <Grid item>
              <img alt="" src={Flagit} height="30px" width="30px" />
            </Grid>
            <Grid item>Italiano</Grid>
          </Grid>
        </MenuItem>
      </Select>
      {!isMenu && (
        <FormHelperText variant="standard" sx={{ fontSize: 12 }}>
          {t("home.chooseLng", { ns: ["home"] })}
        </FormHelperText>
      )}
    </FormControl>
  );
};

export default MWSelectLang;
