import {
    AnswerQuestionStateType,
    AnswerQuestionTypes,
    ANSWER_QUESTION_FAIL,
    ANSWER_QUESTION_LOADING,
    ANSWER_QUESTION_SUCCESS,
    AskQuestionStateType,
    AskQuestionTypes,
    ASK_QUESTION_FAIL,
    ASK_QUESTION_LOADING,
    ASK_QUESTION_SUCCESS,
    CreateOrScheduleMeetingStateType,
    CreateOrScheduleMeetingTypes,
    CREATE_OR_SCHEDULE_FAIL,
    CREATE_OR_SCHEDULE_LOADING,
    CREATE_OR_SCHEDULE_SUCCESS,
    DeleteMeetingStateType,
    DeleteMeetingTypes,
    DELETE_MEETING_FAIL,
    DELETE_MEETING_LOADING,
    DELETE_MEETING_SUCCESS,
    DoctorListForCaseStateType, DoctorListForCaseTypes, DOCTOR_LIST_FAIL, DOCTOR_LIST_LOADING, DOCTOR_LIST_SUCCESS,
    MeetingListStateType, MeetingListTypes, MEETING_LIST_FAIL, MEETING_LIST_LOADING, MEETING_LIST_SUCCESS,
    QuestionListStateType, QuestionListTypes, QUESTION_LIST_FAIL, QUESTION_LIST_LOADING, QUESTION_LIST_SUCCESS
} from "./ActionTypes";

// get doctor dropdown list
const initialStatePaymentList: DoctorListForCaseStateType = {
    loading: false, patientCaseDoctorlist: [], message: Object.assign({}), status: Object.assign({})
};

export const doctorListForCaseReducer = (state = initialStatePaymentList, action: DoctorListForCaseTypes): DoctorListForCaseStateType => {
    switch (action.type) {
        case DOCTOR_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                patientCaseDoctorlist: action.patientCaseDoctorlist,
                message: action.message,
                status: action.status
            };
        case DOCTOR_LIST_LOADING:
            return {
                ...state,
                loading: true,
            };
        case DOCTOR_LIST_FAIL:
            return {
                ...state,
                loading: false,
                message: action.message,
                status: action.status,
            };
        default:
            return state;
    }
};
// get meeting list
const initialStateMeetingList: MeetingListStateType = {
    loading: false, meetingList: [], message: Object.assign({}), status: Object.assign({})
};

export const meetingListReducer = (state = initialStateMeetingList, action: MeetingListTypes): MeetingListStateType => {
    switch (action.type) {
        case MEETING_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                meetingList: action.meetingList,
                message: action.message,
                status: action.status
            };
        case MEETING_LIST_LOADING:
            return {
                ...state,
                loading: true,
            };
        case MEETING_LIST_FAIL:
            return {
                ...state,
                loading: false,
                message: action.message,
                status: action.status,
            };
        default:
            return state;
    }
};
// create or schedule meeting
const initialStateCreateOrScheduleMeeting: CreateOrScheduleMeetingStateType = {
    loading: false, message: Object.assign({}), status: Object.assign({})
};

export const createOrScheduleReducer = (state = initialStateCreateOrScheduleMeeting, action: CreateOrScheduleMeetingTypes): CreateOrScheduleMeetingStateType => {
    switch (action.type) {
        case CREATE_OR_SCHEDULE_SUCCESS:
            return {
                ...state,
                loading: false,
                message: action.message,
                status: action.status
            };
        case CREATE_OR_SCHEDULE_LOADING:
            return {
                ...state,
                loading: true,
            };
        case CREATE_OR_SCHEDULE_FAIL:
            return {
                ...state,
                loading: false,
                message: action.message,
                status: action.status,
            };
        default:
            return state;
    }
};
// create or schedule meeting
const initialStateDeleteMeeting: DeleteMeetingStateType = {
    loading: false, message: Object.assign({}), status: Object.assign({})
};

export const deleteMeetingReducer = (state = initialStateDeleteMeeting, action: DeleteMeetingTypes): DeleteMeetingStateType => {
    switch (action.type) {
        case DELETE_MEETING_SUCCESS:
            return {
                ...state,
                loading: false,
                message: action.message,
                status: action.status
            };
        case DELETE_MEETING_LOADING:
            return {
                ...state,
                loading: true,
            };
        case DELETE_MEETING_FAIL:
            return {
                ...state,
                loading: false,
                message: action.message,
                status: action.status,
            };
        default:
            return state;
    }
};
// get meeting list
const initialStateQuestionList: QuestionListStateType = {
    loading: false, questionList: [], message: Object.assign({}), status: Object.assign({})
};

export const questionListReducer = (state = initialStateQuestionList, action: QuestionListTypes): QuestionListStateType => {
    switch (action.type) {
        case QUESTION_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                questionList: action.questionList,
                message: action.message,
                status: action.status
            };
        case QUESTION_LIST_LOADING:
            return {
                ...state,
                loading: true,
            };
        case QUESTION_LIST_FAIL:
            return {
                ...state,
                loading: false,
                message: action.message,
                status: action.status,
            };
        default:
            return state;
    }
};
// ask question
const initialStateAskQuestion: DeleteMeetingStateType = {
    loading: false, message: Object.assign({}), status: Object.assign({})
};

export const askQuestionReducer = (state = initialStateAskQuestion, action: AskQuestionTypes): AskQuestionStateType => {
    switch (action.type) {
        case ASK_QUESTION_SUCCESS:
            return {
                ...state,
                loading: false,
                message: action.message,
                status: action.status
            };
        case ASK_QUESTION_LOADING:
            return {
                ...state,
                loading: true,
            };
        case ASK_QUESTION_FAIL:
            return {
                ...state,
                loading: false,
                message: action.message,
                status: action.status,
            };
        default:
            return state;
    }
};
// answer question
const initialStateAnswerQuestion: AnswerQuestionStateType = {
    loading: false, message: Object.assign({}), status: Object.assign({})
};

export const answerQuestionReducer = (state = initialStateAnswerQuestion, action: AnswerQuestionTypes): AnswerQuestionStateType => {
    switch (action.type) {
        case ANSWER_QUESTION_SUCCESS:
            return {
                ...state,
                loading: false,
                message: action.message,
                status: action.status
            };
        case ANSWER_QUESTION_LOADING:
            return {
                ...state,
                loading: true,
            };
        case ANSWER_QUESTION_FAIL:
            return {
                ...state,
                loading: false,
                message: action.message,
                status: action.status,
            };
        default:
            return state;
    }
};