import React from "react";
import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  Typography,
  Box,
  Divider,
  Alert,
  Stack,
} from "@mui/material";
import { useSelector } from "react-redux";
import { AppState } from "../../../../redux/store/Store";
import { t } from "i18next";

export default function SelectedOptions() {
  const getRequestAppointmentDetailsVal = useSelector(
    (state: AppState) => state.getRequestAppointmentDetails
  );

  return (
    <Card>
      <CardHeader title={t("payment.selOp", { ns: ["home"] })!} />
      <CardContent>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start"
          spacing={2}
        >
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <Stack
              direction="column"
              justifyContent="flex-start"
              alignItems="stretch"
              spacing={2}
            >
              <Typography component="h6" style={{ fontWeight: "bold" }}>
                {t("patientcaselistpage.consultationtype", { ns: ["home"] })!}:
              </Typography>
              {getRequestAppointmentDetailsVal?.getAppointmentListRes
                ?.consultationType !== undefined ? (
                getRequestAppointmentDetailsVal?.getAppointmentListRes
                  ?.consultationType !== null ? (
                  <Box>
                    <Divider />
                    <Alert variant="outlined" severity="info">
                      <strong>
                        {
                          getRequestAppointmentDetailsVal?.getAppointmentListRes
                            ?.consultationType
                        }
                      </strong>
                    </Alert>
                  </Box>
                ) : null
              ) : null}
            </Stack>
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <Stack
              direction="column"
              justifyContent="flex-start"
              alignItems="stretch"
              spacing={2}
            >
              <Typography component="h6" style={{ fontWeight: "bold" }}>
                {t("consultOpPage.resTime", { ns: ["home"] })!}:
              </Typography>
              {getRequestAppointmentDetailsVal?.getAppointmentListRes
                ?.responseTimeType !== undefined ? (
                getRequestAppointmentDetailsVal?.getAppointmentListRes
                  ?.responseTimeType !== null ? (
                  <Box>
                    <Divider />
                    <Alert variant="outlined" severity="info">
                      <strong>
                        {
                          getRequestAppointmentDetailsVal?.getAppointmentListRes
                            ?.responseTimeType
                        }
                      </strong>
                    </Alert>
                  </Box>
                ) : null
              ) : null}
            </Stack>
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <Stack
              direction="column"
              justifyContent="flex-start"
              alignItems="stretch"
              spacing={2}
            >
              <Typography component="h6" style={{ fontWeight: "bold" }}>
                {t("appointmentpage.docseltype", { ns: ["home"] })!}:
              </Typography>
              {getRequestAppointmentDetailsVal?.getAppointmentListRes
                ?.doctorTeamType !== undefined ? (
                getRequestAppointmentDetailsVal?.getAppointmentListRes
                  ?.doctorTeamType !== null ? (
                  <Box>
                    <Divider />
                    <Alert variant="outlined" severity="info">
                      <strong>
                        {
                          getRequestAppointmentDetailsVal?.getAppointmentListRes
                            ?.doctorTeamType
                        }
                      </strong>
                    </Alert>
                  </Box>
                ) : null
              ) : null}
            </Stack>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
