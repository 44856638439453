import React, { useEffect, useState } from "react";
import { Box, Chip, Grid, Tab, Typography } from "@mui/material";
import MWPageTitle from "../../component/MWPageTitle";
import PageLayout from "../Layout/PageLayout";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import RefreshIcon from "@mui/icons-material/Refresh";
import { useDispatch, useSelector } from "react-redux";
import { t } from "i18next";
import { AppState } from "../../redux/store/Store";
import AdminLoader from "../AdminLoader";
import DashboardTaskList from "./DashboardTaskList";
import { getTaskListValue } from "../../redux/effects/ApiCall";

export default function AdminDashboard() {
  const dispatch = useDispatch();
  const loadingMarkup = <AdminLoader />;
  const [taskListLoader, setTaskListLoader] = useState(false);
  const [selected, setSelected] = React.useState("1");
  const [listCount, setListCount] = React.useState("0");
  const handleTabChange = (_event: React.SyntheticEvent, newValue: string) => {
    setSelected(newValue);
  };
  const AllTaskList = useSelector(
    (state: AppState) => state.taskListValue
  );
  useEffect(() => {
    dispatch(getTaskListValue());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  function taskListApi() {
    dispatch(getTaskListValue());
  }

  const taskListValues = AllTaskList?.taskListValue;
  useEffect(() => {
    taskListValues !== undefined
      ? setListCount(
          String(AllTaskList?.taskListValue?.length)
        )
      : setListCount("0");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [AllTaskList, selected]);


  const dashboardPage = (
    <Grid>
      <Grid item>
        <Box>
          <TabContext value={String(selected)}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList onChange={handleTabChange}>
                <Tab
                  label={
                    <Grid
                      container
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                      spacing={1}
                    >
                      <Grid item>
                        <Typography>{t("admindashboardpage.admintasklist", { ns: ["home"] })!}</Typography>
                      </Grid>
                      <Grid item>
                        <Chip
                          label={listCount}
                          variant="outlined"
                          size="small"
                        />
                      </Grid>
                    </Grid>
                  }
                  icon={
                    <RefreshIcon
                      onClick={() => {
                        taskListApi();
                      }}
                    />
                  }
                  iconPosition="end"
                  value="1"
                />
              </TabList>
            </Box>
            <TabPanel value="1">
              <DashboardTaskList
                taskListLoader={setTaskListLoader}
              />
            </TabPanel>
          </TabContext>
        </Box>
      </Grid>
    </Grid>
  );
  return (
    <Box>
      {taskListLoader === true ? loadingMarkup
        : ""}
      <PageLayout>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start"
        >
          <Grid item>
            <MWPageTitle title={t("admindashboardpage.admindashboard", { ns: ["home"] })!} />
          </Grid>
        </Grid>
        {dashboardPage}
      </PageLayout>
    </Box>
  );
}