import React, { useEffect, useState } from "react";
import { Theme } from "@mui/material/styles";
import { WithStyles } from "@mui/styles";
import createStyles from "@mui/styles/createStyles";
import withStyles from "@mui/styles/withStyles";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import MuiDialogContent from "@mui/material/DialogContent";
import MuiDialogActions from "@mui/material/DialogActions";
import {
    AlertColor,
    Box,
    DialogContentText,
    DialogTitle,
    Divider,
    Grid,
    IconButton,
    TextField,
    Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import { AppState } from "../../../redux/store/Store";
import { t } from "i18next";
import MWSnackbar from "../../../component/MWSnackbar";
import { answerQuestion, getQuestionListValue } from "../../../redux/QuestionAnswer/ApiCall";

const styles = (theme: Theme) =>
    createStyles({
        root: {
            margin: 0,
            padding: "16px",
        },
    });

type Props = {
    addAnswerDialogEnable?: boolean | any;
    addAnswerDialogUpdateState?: boolean | any;
    caseId?: boolean | any;
    questionId?: boolean | any;
    questionTextShow?: boolean | any;
};

export interface DialogTitleProps extends WithStyles<typeof styles> {
    id: string;
    children: React.ReactNode;
    onClose: () => void;
}

const DialogContent = withStyles((theme: Theme) => ({
    root: {
        padding: "16px",
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme: Theme) => ({
    root: {
        margin: 0,
        padding: "8px",
    },
}))(MuiDialogActions);

export default function AddAnswerDialog({
    addAnswerDialogEnable,
    addAnswerDialogUpdateState,
    caseId,
    questionId,
    questionTextShow,
}: Props) {
    const dispatch = useDispatch();
    // Call Add Answer store
    const addQuestionAnswerValue = useSelector(
        (state: AppState) => state.adminQuestionAnswerValue
    );
    const [addAnswerMsgType, setAddAnswerMsgType] =
        useState<AlertColor>("success");
    const [addAnswerMsg, setAddAnswerMsg] = useState("");
    const [answerDescription, setAnswerDescription] = useState("");

    const [dialogState, setDialogState] = React.useState(false);
    const handleDialogClose = () => {
        setDialogState(false);
        addAnswerDialogUpdateState(false);
    };

    React.useEffect(() => {
        setDialogState(addAnswerDialogEnable);
    }, [addAnswerDialogEnable]);


    // question answer body
    const questionBody = {
        questionId: questionId,
        type: "admin",
        caseId: caseId,
        answerText: answerDescription,
    };

    // call submit answer api
    const answerSubmit = () => {
        dispatch(answerQuestion(questionBody));
        dispatch(getQuestionListValue());
    };

    useEffect(() => {
        if (addQuestionAnswerValue?.payload?.message !== undefined) {
            setAddAnswerMsg(addQuestionAnswerValue?.payload?.message);
            setAddAnswerMsgType("success");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [addQuestionAnswerValue])

    const alertCloseAction = (alertState: boolean) => {
        if (alertState) {
            dispatch(getQuestionListValue());
        }
    };

    return (
        <div>
            <Dialog
                open={dialogState}
                onClose={handleDialogClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth
            >
                <DialogTitle>
                    <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="flex-start"
                    >
                        <Grid item>
                            <Typography variant="h4">{t("docpatientintpage.addAns", { ns: ["home"] })}</Typography>
                        </Grid>
                        <Grid item>
                            <IconButton onClick={handleDialogClose}>
                                <CloseIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                </DialogTitle>
                <Divider />
                <DialogContent>
                    <DialogContentText>
                        <Typography variant="h6" gutterBottom>
                            {t("questionList.text", { ns: ["home"] })!} :&nbsp;&nbsp;{questionTextShow}
                        </Typography>
                    </DialogContentText>
                    <Box>
                        <TextField
                            label={t("questionList.label", { ns: ["home"] })!}
                            placeholder={t("questionList.placeholder", { ns: ["home"] })!}
                            value={answerDescription}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                setAnswerDescription(event.target.value);
                            }}
                            id="questionDescriptionID"
                            fullWidth
                            multiline
                            rows={4}
                        />
                    </Box>
                </DialogContent>
                <Divider />
                <DialogActions>
                    <Button
                        variant="contained"
                        onClick={answerSubmit}
                        sx={{ textTransform: "none" }}
                    >
                        {t("Workschedulepage.submit", { ns: ["home"] })}
                    </Button>
                </DialogActions>
                {addAnswerMsg !== undefined ? (
                    <Box my={2}>
                        <MWSnackbar
                            msg={addAnswerMsg}
                            type={addAnswerMsgType}
                            alertClose={alertCloseAction}
                        />
                    </Box>
                ) : null}
            </Dialog>
        </div>
    );
}
