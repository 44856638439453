import {
  GetStepsDetailsActionTypes,
  GET_STEPS_DETAILS_FAIL,
  GET_STEPS_DETAILS_LOADING,
  GET_STEPS_DETAILS_SUCCESS,
  GetStepsDetailsState,
  GET_STEPS_DETAILS_API_MSG,
  GET_STEPS_DETAILS_API_RES,
} from "./ActionTypes";
import { GetStepsDetailsRes } from "./Model";

const initialStateEditPosts: GetStepsDetailsState = {
  loading: false,
  getStepsDetailsRes: {} as GetStepsDetailsRes,
  successMsg: "",
  errorMsg: "",
  status: 0,
};
export const getStepsDetailsReducer = (
  state = initialStateEditPosts,
  action: GetStepsDetailsActionTypes
): GetStepsDetailsState => {
  switch (action.type) {
    case GET_STEPS_DETAILS_LOADING:
      return {
        ...state,
        loading: action.loading,
      };
    case GET_STEPS_DETAILS_SUCCESS:
      return {
        ...state,
        getStepsDetailsRes: action.payload,
        successMsg: action.successMsg,
      };
    case GET_STEPS_DETAILS_FAIL:
      return {
        ...state,
        getStepsDetailsRes: action.payload,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case GET_STEPS_DETAILS_API_MSG:
      return {
        ...state,
        getStepsDetailsRes: action.payload,
        successMsg: action.successMsg,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case GET_STEPS_DETAILS_API_RES:
      return {
        ...state,
        getStepsDetailsRes: action.payload,
        successMsg: action.successMsg,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    default:
      return state;
  }
};
