import { DeleteOpinionActionTypes, DeleteOpinionStateType, DELETE_OPINION_LIST_FAIL, DELETE_OPINION_LIST_LOADING, DELETE_OPINION_LIST_SUCCESS, GetOpinionStateType, OpinionActionTypes, OPINION_LIST_FAIL, OPINION_LIST_LOADING, OPINION_LIST_SUCCESS } from "./ActionTypes";

const initialStateOpinion: GetOpinionStateType = { loading: false, opinionValue: [] };
const initialStateDeleteOpinion: DeleteOpinionStateType = { loading: false, response: Object.assign({}) };

export const getOpinion = (state = initialStateOpinion, action: OpinionActionTypes): GetOpinionStateType => {
    switch (action.type) {
        case OPINION_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                opinionValue: action.payload
            };
        case OPINION_LIST_LOADING:
            return {
                ...state,
                loading: true,
                opinionValue: []
            };
        case OPINION_LIST_FAIL:
            return {
                ...state,
                opinionValue: [],
                loading: false
            };
        default:
            return state;
    }
};

export const deleteOpinion = (state = initialStateDeleteOpinion, action: DeleteOpinionActionTypes): DeleteOpinionStateType => {
    switch (action.type) {
        case DELETE_OPINION_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                response: action.payload
            };
        case DELETE_OPINION_LIST_LOADING:
            return {
                ...state,
                loading: true,
            };
        case DELETE_OPINION_LIST_FAIL:
            return {
                ...state,
                loading: false
            };
        default:
            return state;
    }
};