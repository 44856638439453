import {
  GetCaseStatusListActionTypes,
  CASE_STATUS_LIST_FAIL,
  CASE_STATUS_LIST_LOADING,
  CASE_STATUS_LIST_SUCCESS,
  GetCaseStatusListState,
  CASE_STATUS_LIST_UPDATE_API_MSG,
  CASE_STATUS_LIST_UPDATE_API_RES,
} from "./ActionTypes";
import { GetCaseStatusListRes } from "./Model";

const initialStateGetPosts: GetCaseStatusListState = {
  loading: false,
  getCaseStatusListRes: {} as GetCaseStatusListRes,
  successMsg: "",
  errorMsg: "",
  status: 0,
};
export const getCaseStatusListReducer = (
  state = initialStateGetPosts,
  action: GetCaseStatusListActionTypes
): GetCaseStatusListState => {
  switch (action.type) {
    case CASE_STATUS_LIST_LOADING:
      return {
        ...state,
        loading: action.loading,
      };
    case CASE_STATUS_LIST_SUCCESS:
      return {
        ...state,
        getCaseStatusListRes: action.payload,
      };
    case CASE_STATUS_LIST_FAIL:
      return {
        ...state,
        loading: false,
        getCaseStatusListRes: action.payload,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case CASE_STATUS_LIST_UPDATE_API_MSG:
      return {
        ...state,
        loading: false,
        getCaseStatusListRes: action.payload,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case CASE_STATUS_LIST_UPDATE_API_RES:
      return {
        ...state,
        loading: false,
        getCaseStatusListRes: action.payload,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    default:
      return state;
  }
};
