import { GetAppointmentPaymentListRes } from "./Model";
export const GET_APPOINTMENT_PAYMENT_LIST_LOADING =
  "GET_APPOINTMENT_PAYMENT_LIST_LOADING";
export const GET_APPOINTMENT_PAYMENT_LIST_SUCCESS =
  "GET_APPOINTMENT_PAYMENT_LIST_SUCCESS";
export const GET_APPOINTMENT_PAYMENT_LIST_FAIL =
  "GET_APPOINTMENT_PAYMENT_LIST_FAIL";
export const GET_APPOINTMENT_PAYMENT_LIST_UPDATE_API_MSG =
  "GET_APPOINTMENT_PAYMENT_LIST_UPDATE_API_MSG";
export const GET_APPOINTMENT_PAYMENT_LIST_UPDATE_API_RES =
  "GET_APPOINTMENT_PAYMENT_LIST_UPDATE_API_RES";

export interface GetAppointmentPaymentListState {
  getAppointmentPaymentListRes: GetAppointmentPaymentListRes;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface GetAppointmentPaymentListLoading {
  type: typeof GET_APPOINTMENT_PAYMENT_LIST_LOADING;
  loading: boolean;
}
export interface GetAppointmentPaymentListSuccess {
  type: typeof GET_APPOINTMENT_PAYMENT_LIST_SUCCESS;
  payload: GetAppointmentPaymentListRes;
  successMsg: string;
}
export interface GetAppointmentPaymentListFail {
  type: typeof GET_APPOINTMENT_PAYMENT_LIST_FAIL;
  payload: GetAppointmentPaymentListRes;
  errorMsg: string;
  status: number;
}
export interface GetAppointmentPaymentListUpdateAPIMsg {
  type: typeof GET_APPOINTMENT_PAYMENT_LIST_UPDATE_API_MSG;
  payload: GetAppointmentPaymentListRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface GetAppointmentPaymentListUpdateAPIRes {
  type: typeof GET_APPOINTMENT_PAYMENT_LIST_UPDATE_API_RES;
  payload: GetAppointmentPaymentListRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}

export type GetAppointmentPaymentListActionTypes =
  | GetAppointmentPaymentListLoading
  | GetAppointmentPaymentListSuccess
  | GetAppointmentPaymentListFail
  | GetAppointmentPaymentListUpdateAPIMsg
  | GetAppointmentPaymentListUpdateAPIRes;
