import { AddMasterConfigValue } from "./Model";

// Patient Meeting Loading State
export const ADD_MASTER_CONFIG_LOADING =
  "ADD_MASTER_CONFIG_LOADING";
export const ADD_MASTER_CONFIG_SUCCESS =
  "ADD_MASTER_CONFIG_SUCCESS";
export const ADD_MASTER_CONFIG_FAIL = "ADD_MASTER_CONFIG_FAIL";
export const EMAIL_OTP_SIGNUP_LIST = "EMAIL_OTP_SIGNUP_LIST";

export interface AddMasterConfigDetails {
  addMasterConfigRes: AddMasterConfigValue;
  loading: boolean;
  errRes: string;
}
export interface addMasterConfigLoading {
  type: typeof ADD_MASTER_CONFIG_LOADING;
}
export interface addMasterConfigSuccess {
  type: typeof ADD_MASTER_CONFIG_SUCCESS;
  payload: AddMasterConfigValue;
  errRes: string;
}
export interface addMasterConfigFail {
  type: typeof ADD_MASTER_CONFIG_FAIL;
  errRes: string;
}

interface addMasterConfigAction {
  type: typeof EMAIL_OTP_SIGNUP_LIST;
  payload: AddMasterConfigValue;
  errRes: string;
}

export type addMasterConfigActionTypes =
  | addMasterConfigAction
  | addMasterConfigLoading
  | addMasterConfigSuccess
  | addMasterConfigFail;
