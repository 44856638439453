import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  addPatientCase,
  editPatientCase,
  getcaseDetails,
} from "../../../redux/ConsultationOption/ApiCall";
import { AppState } from "../../../redux/store/Store";
import { useParams } from "react-router-dom";
import PageLayout from "../../Layout/PageLayout";
import {
  ResponseTimeTypeBody,
  ResponseTimeTypeList,
} from "../../../redux/ConsultationOption/ResponseTimeType/Model";
import { getResponseTimeTypeList } from "../../../redux/ConsultationOption/ResponseTimeType/ApiCall";
import { t } from "i18next";

export default function ResponseTimeType() {
  const dispatch = useDispatch();

  const { caseid } = useParams() as {
    caseid: string;
  };

  const ResponseTimeTypeListValue = useSelector(
    (state: AppState) => state.responseTimeValue
  );

  const PatientCaseDetailsValue = useSelector(
    (state: AppState) => state.getPatientCaseDetails.patientCaseDetail
  );

  const [ResponseTimeTypeValue, setResponseTimeTypeValue] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [isEnableSubmit, setEnableSubmit] = useState(true);
  const [value, setValue] = React.useState("");

  const addBody = {
    caseId: caseid,
    responseTimeTypeId: value,
  };

  const createCase = async () => {
    if (caseid !== "") {
      await dispatch(
        editPatientCase(Object.assign(addBody, { caseId: caseid }))
      );
      await setIsEdit(false);
      await setEnableSubmit((isEnableSubmit) => !isEnableSubmit);
      await dispatch(getcaseDetails(JSON.stringify({ caseId: caseid })));
    } else {
      await dispatch(addPatientCase(addBody));
      await dispatch(getcaseDetails(JSON.stringify({ caseId: caseid })));
    }
  };

  useEffect(() => {
    dispatch(
      getResponseTimeTypeList({
        caseId: caseid,
      } as ResponseTimeTypeBody)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(
      getcaseDetails(
        JSON.stringify({
          caseId: caseid,
        })
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setValue(
      String(
        PatientCaseDetailsValue === undefined
          ? ""
          : PatientCaseDetailsValue?.responseTimeType?.map((item) => item.value)
      )
    );
    setResponseTimeTypeValue(PatientCaseDetailsValue?.consultationType?.label!);
  }, [PatientCaseDetailsValue]);

  const thirdComponent = (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="stretch"
      spacing={2}
    >
      <Grid item>
        <Box>
          {ResponseTimeTypeListValue?.responseTimeTypeRes?.responseTimeTypeList?.map(
            (opinion: ResponseTimeTypeList) => (
              <FormControl
                key={opinion.value}
                disabled={ResponseTimeTypeValue ? !isEdit : isEdit}
              >
                <RadioGroup
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="controlled-radio-buttons-group"
                  value={value}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    setValue((event.target as HTMLInputElement).value)
                  }
                >
                  <FormControlLabel
                    value={opinion.value}
                    control={<Radio />}
                    label={opinion.label}
                  />
                </RadioGroup>
              </FormControl>
            )
          )}
        </Box>
      </Grid>
      <Grid item>
        {ResponseTimeTypeValue ? (
          <Grid
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            spacing={1}
          >
            <Grid item>
              <Button
                size="large"
                variant="outlined"
                onClick={() => {
                  setIsEdit((isEdit) => !isEdit);
                  setEnableSubmit((isEnableSubmit) => !isEnableSubmit);
                }}
              >
                {isEdit
                  ? `${t("accountdetail.cancel", { ns: ["home"] })}`
                  : `${t("userprofilepage.edit", { ns: ["home"] })}`}
              </Button>
            </Grid>
            <Grid item>
              <Button
                size="large"
                variant="contained"
                disabled={isEnableSubmit}
                onClick={createCase}
              >
                {t("demographicdetailpage.update", { ns: ["home"] })}
              </Button>
            </Grid>
          </Grid>
        ) : (
          <Grid
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
          >
            <Button size="large" variant="contained" onClick={createCase}>
              {t("common.add", { ns: ["home"] })}
            </Button>
          </Grid>
        )}
      </Grid>
    </Grid>
  );

  return <PageLayout>{thirdComponent}</PageLayout>;
}
