import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../../ApiConfig";
import { UpdateAdminNoteByPatientActionTypes } from "./ActionTypes";
import { UpdateAdminNoteByPatientRes } from "./Model";
import {
  updateAdminNoteByPatientAPIResClearAction,
  updateAdminNoteByPatientErrorAction,
  updateAdminNoteByPatientLoadingAction,
  updateAdminNoteByPatientSuccessAction,
  updateAdminNoteByPatientUpdateAPIMsgAction,
} from "./Actions";
import { headers } from "../../../component/Utility";

let apiRes = {} as UpdateAdminNoteByPatientRes;
export const updateNote = (noteId: Number) => {
  return function (dispatch: Dispatch<UpdateAdminNoteByPatientActionTypes>) {
    dispatch(updateAdminNoteByPatientLoadingAction(true));
    axios
      .get(
        Url.baseUrl + `/erpapp/api/adminNote/${noteId}/toggle_active`,
        { headers: headers }
      )
      .then((res) => {
        apiRes = res.data;
        dispatch(updateAdminNoteByPatientLoadingAction(false));
        dispatch(
          updateAdminNoteByPatientSuccessAction(
            res.data !== undefined
              ? res.data
              : ({} as UpdateAdminNoteByPatientRes),
            res.data.message !== undefined
              ? res.data.message
              : "Note status has been changed successfully"
          )
        );
      })
      .catch((error) => {
        dispatch(updateAdminNoteByPatientLoadingAction(false));
        dispatch(
          updateAdminNoteByPatientErrorAction(
            {} as UpdateAdminNoteByPatientRes,
            error.response !== undefined
              ? error.response.data.message || error.response.data.detail
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || 0 : 0
          )
        );
      });
  };
};

export const updateNoteAPIResMsg = () => {
  return function (dispatch: Dispatch<UpdateAdminNoteByPatientActionTypes>) {
    dispatch(
      updateAdminNoteByPatientUpdateAPIMsgAction(
        apiRes as UpdateAdminNoteByPatientRes,
        "",
        "",
        0
      )
    );
  };
};

export const clearNoteAPIRes = () => {
  return function (dispatch: Dispatch<UpdateAdminNoteByPatientActionTypes>) {
    dispatch(
      updateAdminNoteByPatientAPIResClearAction(
        {} as UpdateAdminNoteByPatientRes,
        "",
        "",
        0
      )
    );
  };
};
