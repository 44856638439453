import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { Card, Container, Typography } from "@mui/material";
import AdminLoader from "./AdminLoader";
import MWPageTitle from "../component/MWPageTitle";
export default function PatientQueriesDetails() {
  const [isLoading, setIsLoading] = useState(false);
  console.log("setIsLoading", setIsLoading);
  const patientQuriesId = useParams();
  console.log("===Patient Queries Id===");
  console.log(patientQuriesId);
  const importdataDetails = (
    <div style={{ padding: "10px" }}>
      <Typography>No Data Available yet.</Typography>
    </div>
  );
  const loadingMarkup = isLoading ? <AdminLoader /> : null;

  return (
    <Container maxWidth="xl" sx={{ mt: 3 }}>
      <MWPageTitle backButton={true} title="Patient Queries Details" />
      {loadingMarkup}
      <Card>{importdataDetails}</Card>
    </Container>
  );
}
