import { GetPatientCaseNoteListRes } from "./Model";
export const PATIENT_CASE_NOTE_LIST_LOADING = "PATIENT_CASE_NOTE_LIST_LOADING";
export const PATIENT_CASE_NOTE_LIST_SUCCESS = "PATIENT_CASE_NOTE_LIST_SUCCESS";
export const PATIENT_CASE_NOTE_LIST_FAIL = "PATIENT_CASE_NOTE_LIST_FAIL";
export const PATIENT_CASE_NOTE_LIST_DATA = "PATIENT_CASE_NOTE_LIST_DATA";
export const PATIENT_CASE_NOTE_LIST_UPDATE_API_MSG =
  "PATIENT_CASE_NOTE_LIST_UPDATE_API_MSG";
export const PATIENT_CASE_NOTE_LIST_UPDATE_API_RES =
  "PATIENT_CASE_NOTE_LIST_UPDATE_API_RES";

export interface GetPatientCaseNoteListState {
  getPatientCaseNoteListRes: GetPatientCaseNoteListRes;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface GetPatientCaseNoteListLoading {
  type: typeof PATIENT_CASE_NOTE_LIST_LOADING;
  loading: boolean;
}
export interface GetPatientCaseNoteListSuccess {
  type: typeof PATIENT_CASE_NOTE_LIST_SUCCESS;
  payload: GetPatientCaseNoteListRes;
}
export interface GetPatientCaseNoteListFail {
  type: typeof PATIENT_CASE_NOTE_LIST_FAIL;
  payload: GetPatientCaseNoteListRes;
  errorMsg: string;
  status: number;
}
export interface GetPatientCaseNoteListUpdateAPIMsg {
  type: typeof PATIENT_CASE_NOTE_LIST_UPDATE_API_MSG;
  payload: GetPatientCaseNoteListRes;
  errorMsg: string;
  status: number;
}
export interface GetPatientCaseNoteListUpdateAPIRes {
  type: typeof PATIENT_CASE_NOTE_LIST_UPDATE_API_RES;
  payload: GetPatientCaseNoteListRes;
  errorMsg: string;
  status: number;
}

export type GetPatientCaseNoteListActionTypes =
  | GetPatientCaseNoteListLoading
  | GetPatientCaseNoteListSuccess
  | GetPatientCaseNoteListFail
  | GetPatientCaseNoteListUpdateAPIMsg
  | GetPatientCaseNoteListUpdateAPIRes;
