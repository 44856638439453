import {
  GET_INACTIVE_CASE_LIST_FAIL,
  GET_INACTIVE_CASE_LIST_LOADING,
  GET_INACTIVE_CASE_LIST_SUCCESS,
  GET_INACTIVE_CASE_LIST_API_MSG,
  GET_INACTIVE_CASE_LIST_API_RES,
  GetInactiveCaseListActionTypes,
  GET_INACTIVE_CASE_LIST_DATA,
} from "./ActionTypes";
import { GetInactiveCaseListRes, PageCount } from "./Model";

export const inactiveCaseListAction = (
  GetInactiveCaseListRes: GetInactiveCaseListRes,
  pageCount: PageCount
): GetInactiveCaseListActionTypes => {
  return {
    type: GET_INACTIVE_CASE_LIST_DATA,
    payload: GetInactiveCaseListRes,
    pageCount: pageCount,
  };
};

export const getInactiveCaseListLoadingAction = (
  loading: boolean
): GetInactiveCaseListActionTypes => {
  return {
    type: GET_INACTIVE_CASE_LIST_LOADING,
    loading: loading,
  };
};

export const getInactiveCaseListSuccessAction = (
  getInactiveCaseListResponse: GetInactiveCaseListRes,
): GetInactiveCaseListActionTypes => {
  return {
    type: GET_INACTIVE_CASE_LIST_SUCCESS,
    payload: getInactiveCaseListResponse,
  };
};

export const getInactiveCaseListErrorAction = (
  getInactiveCaseListResponse: GetInactiveCaseListRes,
  errMsg: string,
  status: number
): GetInactiveCaseListActionTypes => {
  return {
    type: GET_INACTIVE_CASE_LIST_FAIL,
    payload: getInactiveCaseListResponse,
    errorMsg: errMsg,
    status: status,
  };
};
export const getInactiveCaseListAPIMsgAction = (
  getInactiveCaseListResponse: GetInactiveCaseListRes,
  errMsg: string,
  status: number
): GetInactiveCaseListActionTypes => {
  return {
    type: GET_INACTIVE_CASE_LIST_API_MSG,
    payload: getInactiveCaseListResponse,
    errorMsg: errMsg,
    status: status,
  };
};

export const getInactiveCaseListAPIResClearAction = (
  getInactiveCaseListResponse: GetInactiveCaseListRes,
  errMsg: string,
  status: number
): GetInactiveCaseListActionTypes => {
  return {
    type: GET_INACTIVE_CASE_LIST_API_RES,
    payload: getInactiveCaseListResponse,
    errorMsg: errMsg,
    status: status,
  };
};
