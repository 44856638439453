import axios from "axios";
import Url from "../../ApiConfig";
import { Dispatch } from "react";
import {
  AdminGetExecutiveListActionTypes,
  EXECUTIVE_LOADING,
  EXECUTIVE_SUCCESS,
  EXECUTIVE_FAIL,
  DELETE_EXECUTIVE_LOADING,
  DELETE_EXECUTIVE_STATUS,
  DELETE_EXECUTIVE_SUCCESS,
  DELETE_EXECUTIVE_FAIL,
  DeleteExecutiveTypes,
  ADD_EXECUTIVE_LOADING,
  AddExecutiveTypes,
  ADD_EXECUTIVE_STATUS,
  ADD_EXECUTIVE_SUCCESS,
  ADD_EXECUTIVE_FAIL,
  EditExecutiveTypes,
  EDIT_EXECUTIVE_FAIL,
  EDIT_EXECUTIVE_SUCCESS,
  EDIT_EXECUTIVE_LOADING,
  EDIT_EXECUTIVE_STATUS,
} from "./ActionTypes";
import {
  getExecutiveAction,
  DeleteExecutive,
  AddExecutive,
  EditExecutive,
} from "./Actions";
import { setupInterceptorsTo } from "../Authentication/Interceptors";
import { headers } from "../../component/Utility";

// Admin get All executive List API
export const getExecutiveList = () => {
  return function (dispatch: Dispatch<AdminGetExecutiveListActionTypes>) {
    dispatch({
      type: EXECUTIVE_LOADING,
    });
    setupInterceptorsTo(axios)
      .get(
        Url.baseUrl + "/adminapp/api/executives/executive_list_all",
        { headers: headers }
      )
      .then((res) => {
        let executiveList = res.data.executiveList;
        let message = res.data.message;
        let status = res.data.status;
        dispatch(getExecutiveAction(executiveList, message, status));
        dispatch({
          type: EXECUTIVE_SUCCESS,
          payload: executiveList,
          message: message,
          status: status,
        });
      })
      .catch((error) => {
        let message = error.response.data.message;
        let status = error.response.data.status;
        dispatch({
          type: EXECUTIVE_FAIL,
          message: message,
          status: status,
        });
      });
  };
};
// Admin  executive Delete API...
export const deleteExecutive = (state = {}) => {
  return function (dispatch: Dispatch<DeleteExecutiveTypes>) {
    dispatch({
      type: DELETE_EXECUTIVE_LOADING,
    });
    dispatch(DeleteExecutive(deleteExecutive(DELETE_EXECUTIVE_STATUS)));
    setupInterceptorsTo(axios)
      .post(
        Url.baseUrl + "/adminapp/api/executives/delete_excutive",
        state,
        { headers: headers }
      )
      .then((result) => {
        if (result) {
          dispatch({
            type: DELETE_EXECUTIVE_SUCCESS,
            payload: result.data,
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: DELETE_EXECUTIVE_FAIL,
          payload: error.response.data,
        });
      });
  };
};

// Admin  executive add  API...
export const addExecutive = (state = {}) => {
  return function (dispatch: Dispatch<AddExecutiveTypes>) {
    dispatch({
      type: ADD_EXECUTIVE_LOADING,
    });
    dispatch(AddExecutive(addExecutive(ADD_EXECUTIVE_STATUS)));
    setupInterceptorsTo(axios)
      .post(
        Url.baseUrl + "/adminapp/api/executives/add_excutive",
        state,
        { headers: headers }
      )
      .then((result) => {
        if (result) {
          dispatch({
            type: ADD_EXECUTIVE_SUCCESS,
            payload: result.data,
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: ADD_EXECUTIVE_FAIL,
          payload: error.response.data,
        });
      });
  };
};

// Admin  executive edit  API...
export const editExecutiveList = (state = {}) => {
  return function (dispatch: Dispatch<EditExecutiveTypes>) {
    dispatch({
      type: EDIT_EXECUTIVE_LOADING,
    });
    dispatch(EditExecutive(editExecutiveList(EDIT_EXECUTIVE_STATUS)));
    setupInterceptorsTo(axios)
      .post(
        Url.baseUrl + "/adminapp/api/executives/edit_excutive",
        state,
        { headers: headers }
      )
      .then((result) => {
        if (result) {
          dispatch({
            type: EDIT_EXECUTIVE_SUCCESS,
            payload: result.data,
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: EDIT_EXECUTIVE_FAIL,
          payload: error.response.data,
        });
      });
  };
};
