import React, { useEffect } from "react";
import { Box, Chip, Grid, Tab, Typography } from "@mui/material";
import MWPageTitle from "../../component/MWPageTitle";
import PageLayout from "../Layout/PageLayout";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { t } from "i18next";
import RefreshIcon from "@mui/icons-material/Refresh";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../redux/store/Store";
import AdminLoader from "../AdminLoader";
import ActiveAllCaseList from "./ActiveAllCaseList";
import InactiveAllCaseList from "./InactiveAllCaseList";
import { getAllPatientCaseList } from "../../redux/effects/ApiCall";
import { getInactiveCaseListValue } from "../../redux/GetInactiveCaseList/API";

export default function AllCaseList() {
  const dispatch = useDispatch();
  const loadingMarkup = <AdminLoader />;
  const [selected, setSelected] = React.useState("1");
  const [listCount, setListCount] = React.useState("0");
  const [inactiveCaseListCount, setInactiveCaseListCount] =
    React.useState("0");
  const handleTabChange = (_event: React.SyntheticEvent, newValue: string) => {
    setSelected(newValue);
  };
  const AllCaseListValue = useSelector((state: AppState) => state.allcaseValue);
  const inactiveCaseListValue = useSelector(
    (state: AppState) => state.getInactiveCaseListValue
  );

  useEffect(() => {
    dispatch(getAllPatientCaseList(1, 1));
    dispatch(getInactiveCaseListValue(1, 0));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  function activeCaseListApi() {
    dispatch(getAllPatientCaseList(1, 1));
  }

  function inactiveCaseListApi() {
    dispatch(getInactiveCaseListValue(1, 0));
  }

  useEffect(() => {
    AllCaseListValue?.allcaseValue?.count !== undefined
      ? setListCount(String(AllCaseListValue?.allcaseValue?.count))
      : setListCount("0");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [AllCaseListValue, selected]);

  useEffect(() => {
    inactiveCaseListValue?.getInactiveCaseListRes?.count !== undefined
      ? setInactiveCaseListCount(
          String(inactiveCaseListValue?.getInactiveCaseListRes?.count)
        )
      : setInactiveCaseListCount("0");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inactiveCaseListValue, selected]);

  const patientCaseListPage = (
    <Grid>
      <Grid item>
        <Box>
          <TabContext value={String(selected)}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList onChange={handleTabChange}>
                <Tab
                  label={
                    <Grid
                      container
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                      spacing={1}
                    >
                      <Grid item>
                        <Typography>{t("patientcaselistpage.activelist", { ns: ["home"] })!}</Typography>
                      </Grid>
                      <Grid item>
                        <Chip
                          label={listCount}
                          variant="outlined"
                          size="small"
                        />
                      </Grid>
                    </Grid>
                  }
                  icon={
                    <RefreshIcon
                      onClick={() => {
                        activeCaseListApi();
                      }}
                    />
                  }
                  iconPosition="end"
                  value="1"
                />
                <Tab
                  label={
                    <Grid
                      container
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                      spacing={1}
                    >
                      <Grid item>
                        <Typography>{t("patientcaselistpage.inactivelist", { ns: ["home"] })!}</Typography>
                      </Grid>
                      <Grid item>
                        <Chip
                          label={inactiveCaseListCount}
                          variant="outlined"
                          size="small"
                        />
                      </Grid>
                    </Grid>
                  }
                  icon={
                    <RefreshIcon
                      onClick={() => {
                        inactiveCaseListApi();
                      }}
                    />
                  }
                  iconPosition="end"
                  value="2"
                />
              </TabList>
            </Box>
            <TabPanel value="1">
              <ActiveAllCaseList />
            </TabPanel>
            <TabPanel value="2">
              <InactiveAllCaseList />
            </TabPanel>
          </TabContext>
        </Box>
      </Grid>
    </Grid>
  );
  return (
    <Box>
      {AllCaseListValue.loading === true ||
      inactiveCaseListValue.loading === true
        ? loadingMarkup
        : ""}
      <PageLayout>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start"
        >
          <Grid item>
            <MWPageTitle title={t("patientcaselistpage.title", { ns: ["home"] })!} />
          </Grid>
        </Grid>
        {patientCaseListPage}
      </PageLayout>
    </Box>
  );
}
