import React, { useEffect } from "react";
import { getDoctorListValueAdmin } from "../redux/effects/ApiCall";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../redux/store/Store";
import { useHistory } from "react-router-dom";
import AdminLoader from "./AdminLoader";
import { t } from "i18next";
import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Grid,
  Stack,
} from "@mui/material";
import {
  DataGrid,
  GridCellParams,
  GridColDef,
  GridToolbar,
} from "@mui/x-data-grid";
import MWExceptionList from "../component/MWExceptionList";
import MWPageTitle from "../component/MWPageTitle";

export default function Doctor() {
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(getDoctorListValueAdmin());
  }, [dispatch]);

  const loadingMarkup = <AdminLoader />;

  const doctorDetails = (doctorId: any) => {
    history.push(`/doctordetails/${doctorId}`);
  };
  const viewDoctorAppointment = (doctorId: any) => {
    history.push(`/doctorapoointment/${doctorId}`);
  };
  const [dataGridArray, setDataGridArray] = React.useState([] as any[]);
  const [listCount, setListCount] = React.useState("");
  // Api call from store.....
  const doctorList = useSelector((state: AppState) => state.doctorListValue);
  const doctorListValue = doctorList.doctorListValue;
  useEffect(() => {
    doctorList?.doctorListValue !== undefined
      ? setListCount(String(doctorList?.doctorListValue.length))
      : setListCount("0");

    if (doctorListValue !== undefined) {
      let dataPointArr = doctorListValue.map((element: any) => ({
        id: element.id,
        doctorName: element.fullName,
        appoinmentDetails: element.id,
        doctorQualification: element.qualification,
        doctorExperience: element.specialtyWithYearsOfExperience,
      }));
      setDataGridArray(dataPointArr);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [doctorList]);
  const doctorListReload = () => {
    dispatch(getDoctorListValueAdmin());
  };
  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: `${t("doctorlistpage.doctordetails", { ns: ["home"] })!}`,
      width: 150,
      renderCell: (params: GridCellParams) => (
        <Button
          startIcon={<VisibilityIcon />}
          variant="outlined"
          onClick={() => {
            doctorDetails(params.row.id);
          }}
        >
          {t("doctorlistpage.view", { ns: ["home"] })!}
        </Button>
      ),
    },
    {
      field: "doctorName",
      headerName: `${t("doctorlistpage.doctorname", { ns: ["home"] })!}`,
      width: 300,
    },
    {
      field: "appoinmentDetails",
      headerName: `${t("doctorlistpage.appointmentdetails", { ns: ["home"] })!}`,
      width: 300,
      renderCell: (params: GridCellParams) => (
        <Button
          onClick={() => {
            viewDoctorAppointment(params.value);
          }}
          variant="outlined"
        >
         {t("doctorlistpage.appointmentdetails", { ns: ["home"] })!}
        </Button>
      ),
    },
    {
      field: "doctorQualification",
      headerName: `${t("doctorlistpage.qualification", { ns: ["home"] })!}`,
      width: 400,
    },
    {
      field: "doctorExperience",
      headerName: `${t("doctorlistpage.experience", { ns: ["home"] })!}`,
      width: 300,
    },
  ];
  const DoctorListTable = (
    <Card>
      <CardContent>
        {doctorListValue && doctorListValue.length ? (
          <DataGrid
            rows={dataGridArray}
            columns={columns}
            disableSelectionOnClick
            hideFooter
            hideFooterPagination
            experimentalFeatures={{ newEditingApi: true }}
            autoHeight
            components={{ Toolbar: GridToolbar }}
            componentsProps={{
              toolbar: {
                csvOptions: { disableToolbarButton: true },
                printOptions: { disableToolbarButton: true },
                showQuickFilter: true,
                quickFilterProps: { debounceMs: 250 },
              },
            }}
            disableColumnFilter
            disableColumnSelector
            disableDensitySelector
          />
        ) : (
          <MWExceptionList />
        )}
      </CardContent>
    </Card>
  );

  return (
    <Box>
      {doctorList.loading === true ? loadingMarkup : ""}
      <Container maxWidth="xl">
        <Stack
          direction="column"
          justifyContent="flex-start"
          alignItems="stretch"
          spacing={2}
          pt={2}
        >
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="flex-start"
          >
            <Grid item width={300}>
              <MWPageTitle
                title={t("doctorlistpage.title", { ns: ["home"] })!}
                enableCount={true}
                count={listCount}
                reload={true}
                reloadAction={doctorListReload}
              />
            </Grid>
          </Grid>
          {DoctorListTable}
        </Stack>
      </Container>
    </Box>
  );
}
