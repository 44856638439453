import { OrganizationListMappedWithHealthCampDetails } from "./Model";

// Patient Demographics Loading State
export const ORGANIZATION_LIST_BY_HEALTHCAMP_ID_LOADING =
  "ORGANIZATION_LIST_BY_HEALTHCAMP_ID_LOADING";
export const ORGANIZATION_LIST_BY_HEALTHCAMP_ID_SUCCESS =
  "ORGANIZATION_LIST_BY_HEALTHCAMP_ID_SUCCESS";
export const ORGANIZATION_LIST_BY_HEALTHCAMP_ID_FAIL =
  "ORGANIZATION_LIST_BY_HEALTHCAMP_ID_FAIL";
export const ORGANIZATION_LIST_BY_HEALTHCAMP_ID_DATA =
  "ORGANIZATION_LIST_BY_HEALTHCAMP_ID_DATA";
export const ORGANIZATION_LIST_BY_HEALTHCAMP_ID_UPDATE_API_MSG =
  "ORGANIZATION_LIST_BY_HEALTHCAMP_ID_UPDATE_API_MSG";
export const ORGANIZATION_LIST_BY_HEALTHCAMP_ID_UPDATE_API_RES =
  "ORGANIZATION_LIST_BY_HEALTHCAMP_ID_UPDATE_API_RES";

export interface OrganizationListByHealthCampIdDetailsState {
  patientSearchRes: OrganizationListMappedWithHealthCampDetails;
  loading: boolean;
  message: string;
  status: number;
}
export interface OrganizationListByHealthCampIdLoading {
  type: typeof ORGANIZATION_LIST_BY_HEALTHCAMP_ID_LOADING;
  loading: boolean;
}
export interface OrganizationListByHealthCampIdSuccess {
  type: typeof ORGANIZATION_LIST_BY_HEALTHCAMP_ID_SUCCESS;
  payload: OrganizationListMappedWithHealthCampDetails;
}
export interface OrganizationListByHealthCampIdFail {
  type: typeof ORGANIZATION_LIST_BY_HEALTHCAMP_ID_FAIL;
  payload: OrganizationListMappedWithHealthCampDetails;
  message: string;
  status: number;
}
export interface OrganizationListByHealthCampIdUpdateAPIMsg {
  type: typeof ORGANIZATION_LIST_BY_HEALTHCAMP_ID_UPDATE_API_MSG;
  payload: OrganizationListMappedWithHealthCampDetails;
  message: string;
  status: number;
}
export interface OrganizationListByHealthCampIdUpdateAPIRes {
  type: typeof ORGANIZATION_LIST_BY_HEALTHCAMP_ID_UPDATE_API_RES;
  payload: OrganizationListMappedWithHealthCampDetails;
  message: string;
  status: number;
}
interface OrganizationListByHealthCampIdAction {
  type: typeof ORGANIZATION_LIST_BY_HEALTHCAMP_ID_DATA;
  payload: OrganizationListMappedWithHealthCampDetails;
}

export type OrganizationListByHealthCampIdActionTypes =
  | OrganizationListByHealthCampIdAction
  | OrganizationListByHealthCampIdLoading
  | OrganizationListByHealthCampIdSuccess
  | OrganizationListByHealthCampIdFail
  | OrganizationListByHealthCampIdUpdateAPIMsg
  | OrganizationListByHealthCampIdUpdateAPIRes;
