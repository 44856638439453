import { ExecutiveListData, Message, Status, DeleteExecutiveResponse, AddExecutiveResponse, EditExecutiveResponse } from "./Model";

export const EXECUTIVE_LOADING = "EXECUTIVE_LOADING";
export const EXECUTIVE_SUCCESS = "EXECUTIVE_SUCCESS";
export const EXECUTIVE_FAIL = "EXECUTIVE_FAIL";
export const EXECUTIVE_LIST = "EXECUTIVE_LIST";

// Get executive list
export interface ExecutiveListDataListStateType {
  executiveListValue: ExecutiveListData[];
  loading: boolean;
  message: Message;
  status: Status;
}

export interface ExecutiveListLoading {
  type: typeof EXECUTIVE_LOADING;
}
export interface ExecutiveListSuccess {
  type: typeof EXECUTIVE_SUCCESS;
  payload: ExecutiveListData[];
  message: Message;
  status: Status;
}
export interface ExecutiveListFail {
  type: typeof EXECUTIVE_FAIL;
  message: Message;
  status: Status;
}
interface ExecutiveActionType {
  type: typeof EXECUTIVE_LIST;
  payload: ExecutiveListData[];
  message: Message;
  status: Status;
}
export type AdminGetExecutiveListActionTypes = ExecutiveActionType | ExecutiveListLoading | ExecutiveListSuccess | ExecutiveListFail;

// Delete Executive..
export const DELETE_EXECUTIVE_LOADING = "DELETE_EXECUTIVE_LOADING";
export const DELETE_EXECUTIVE_SUCCESS = "DELETE_EXECUTIVE_SUCCESS";
export const DELETE_EXECUTIVE_FAIL = "DELETE_EXECUTIVE_FAIL";
export const DELETE_EXECUTIVE_STATUS = 'DELETE_EXECUTIVE_STATUS';

export interface DeleteExecutiveStateType {
  loading: boolean;
  response: DeleteExecutiveResponse;
}
export interface DeleteExecutiveLoading {
  type: typeof DELETE_EXECUTIVE_LOADING;
}
export interface DeleteExecutiveSuccess {
  type: typeof DELETE_EXECUTIVE_SUCCESS;
  payload: DeleteExecutiveResponse;
}
export interface DeleteExecutiveFail {
  type: typeof DELETE_EXECUTIVE_FAIL;
  payload: DeleteExecutiveResponse;
}

interface DeleteExecutiveType {
  type: typeof DELETE_EXECUTIVE_STATUS;
  payload: DeleteExecutiveResponse;
}
export type DeleteExecutiveTypes = DeleteExecutiveType | DeleteExecutiveLoading | DeleteExecutiveSuccess | DeleteExecutiveFail;

// Add  Executive..
export const ADD_EXECUTIVE_LOADING = "ADD_EXECUTIVE_LOADING";
export const ADD_EXECUTIVE_SUCCESS = "ADD_EXECUTIVE_SUCCESS";
export const ADD_EXECUTIVE_FAIL = "ADD_EXECUTIVE_FAIL";
export const ADD_EXECUTIVE_STATUS = 'ADD_EXECUTIVE_STATUS';

export interface AddExecutiveStateType {
  loading: boolean;
  response: AddExecutiveResponse;
}
export interface AddExecutiveLoading {
  type: typeof ADD_EXECUTIVE_LOADING;
}
export interface AddExecutiveSuccess {
  type: typeof ADD_EXECUTIVE_SUCCESS;
  payload: AddExecutiveResponse;
}
export interface AddExecutiveFail {
  type: typeof ADD_EXECUTIVE_FAIL;
  payload: AddExecutiveResponse;
}

interface AddExecutiveType {
  type: typeof ADD_EXECUTIVE_STATUS;
  payload: AddExecutiveResponse;
}
export type AddExecutiveTypes = AddExecutiveType | AddExecutiveLoading | AddExecutiveSuccess | AddExecutiveFail;

// Edit  Executive..
export const EDIT_EXECUTIVE_LOADING = "EDIT_EXECUTIVE_LOADING";
export const EDIT_EXECUTIVE_SUCCESS = "EDIT_EXECUTIVE_SUCCESS";
export const EDIT_EXECUTIVE_FAIL = "EDIT_EXECUTIVE_FAIL";
export const EDIT_EXECUTIVE_STATUS = 'EDIT_EXECUTIVE_STATUS';

export interface EditExecutiveStateType {
  loading: boolean;
  response: EditExecutiveResponse;
}
export interface EditExecutiveLoading {
  type: typeof EDIT_EXECUTIVE_LOADING;
}
export interface EditExecutiveSuccess {
  type: typeof EDIT_EXECUTIVE_SUCCESS;
  payload: EditExecutiveResponse;
}
export interface EditExecutiveFail {
  type: typeof EDIT_EXECUTIVE_FAIL;
  payload: EditExecutiveResponse;
}

interface EditExecutiveType {
  type: typeof EDIT_EXECUTIVE_STATUS;
  payload: EditExecutiveResponse;
}
export type EditExecutiveTypes = EditExecutiveType | EditExecutiveLoading | EditExecutiveSuccess | EditExecutiveFail;