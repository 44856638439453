import {
  EXECUTIVE_LIST_CLEAR_API_RES,
  EXECUTIVE_LIST_FAIL,
  EXECUTIVE_LIST_LOADING,
  EXECUTIVE_LIST_SUCCESS,
  EXECUTIVE_LIST_API_MSG,
  ExecutiveListActionTypes,
} from "./ActionTypes";
import { ExecutiveListResponse } from "./Model";

export const executiveListLoadingAction = (
  loading: boolean
): ExecutiveListActionTypes => {
  return {
    type: EXECUTIVE_LIST_LOADING,
    loading: loading,
  };
};

export const executiveListSuccessAction = (
  executiveRes: ExecutiveListResponse
): ExecutiveListActionTypes => {
  return {
    type: EXECUTIVE_LIST_SUCCESS,
    payload: executiveRes,
  };
};

export const executiveListErrorAction = (
  executiveRes: ExecutiveListResponse,
  errMsg: string,
  status: number
): ExecutiveListActionTypes => {
  return {
    type: EXECUTIVE_LIST_FAIL,
    payload: executiveRes,
    message: errMsg,
    status: status,
  };
};

export const executiveListUpdateAPIMsgAction = (
  executiveRes: ExecutiveListResponse,
  errMsg: string,
  status: number
): ExecutiveListActionTypes => {
  return {
    type: EXECUTIVE_LIST_API_MSG,
    payload: executiveRes,
    message: errMsg,
    status: status,
  };
};

export const executiveListAPIResClearAction = (
  executiveRes: ExecutiveListResponse,
  errMsg: string,
  status: number
): ExecutiveListActionTypes => {
  return {
    type: EXECUTIVE_LIST_CLEAR_API_RES,
    payload: executiveRes,
    message: errMsg,
    status: status,
  };
};
