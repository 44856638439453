import {
    GetDoctorTeamTypeListActionTypes,
    DOCTOR_TEAM_TYPE_LIST_FAIL,
    DOCTOR_TEAM_TYPE_LIST_LOADING,
    DOCTOR_TEAM_TYPE_LIST_SUCCESS,
    GetDoctorTeamTypeListState,
    DOCTOR_TEAM_TYPE_LIST_UPDATE_API_MSG,
    DOCTOR_TEAM_TYPE_LIST_UPDATE_API_RES,
  } from "./ActionTypes";
  import { GetDoctorTeamTypeListRes } from "./Model";
  
  const initialStateGetPosts: GetDoctorTeamTypeListState = {
    loading: false,
    getDoctorTeamTypeListRes: {} as GetDoctorTeamTypeListRes,
    successMsg: "",
    errorMsg: "",
    status: 0,
  };
  export const getDoctorTeamTypeListReducer = (
    state = initialStateGetPosts,
    action: GetDoctorTeamTypeListActionTypes
  ): GetDoctorTeamTypeListState => {
    switch (action.type) {
      case DOCTOR_TEAM_TYPE_LIST_LOADING:
        return {
          ...state,
          loading: action.loading,
        };
      case DOCTOR_TEAM_TYPE_LIST_SUCCESS:
        return {
          ...state,
          getDoctorTeamTypeListRes: action.payload,
          successMsg: action.successMsg,
        };
      case DOCTOR_TEAM_TYPE_LIST_FAIL:
        return {
          ...state,
          getDoctorTeamTypeListRes: action.payload,
          errorMsg: action.errorMsg,
          status: action.status,
        };
      case DOCTOR_TEAM_TYPE_LIST_UPDATE_API_MSG:
        return {
          ...state,
          getDoctorTeamTypeListRes: action.payload,
          successMsg: action.successMsg,
          errorMsg: action.errorMsg,
          status: action.status,
        };
      case DOCTOR_TEAM_TYPE_LIST_UPDATE_API_RES:
        return {
          ...state,
          getDoctorTeamTypeListRes: action.payload,
          successMsg: action.successMsg,
          errorMsg: action.errorMsg,
          status: action.status,
        };
      default:
        return state;
    }
  };
  