import {
  AddChiefComplaintTypes, ADD_CC, ChiefComplaintGetActionTypes,
  DeleteCaseTypes,
  DeleteDocumentTypes, DELETE_CASE_TYPE, DELETE_DOCUMENT, GET_CC_LIST, GET_QUESTION_LIST,
  QuestionGetActionTypes,
  StepsGetActionTypes,
  STEPS_LIST
} from "./ActionTypes";
import { ChiefComplaintList, QuestionList, Steps } from "./Model";

export const getQuestionAction = (question: QuestionList[]): QuestionGetActionTypes => {
  return {
    type: GET_QUESTION_LIST,
    payload: question
  };
};

export const getChiefComplaintAction = (cc: ChiefComplaintList[]): ChiefComplaintGetActionTypes => {
  return {
    type: GET_CC_LIST,
    payload: cc
  };
};

export const AddChiefComplaintAction = (addCC: any): AddChiefComplaintTypes => {
  return {
    type: ADD_CC,
    payload: addCC
  };
};

export const DeleteDocumentAction = (deletedocument: any): DeleteDocumentTypes => {
  return {
    type: DELETE_DOCUMENT,
    payload: deletedocument
  };
};

export const getStepAction = (steps: Steps[]): StepsGetActionTypes => {
  return {
    type: STEPS_LIST,
    payload: steps
  };
};
export const deleteCaseAction = (message: any, status: any): DeleteCaseTypes => {
  return {
    type: DELETE_CASE_TYPE,
    message: message,
    status: status
  };
};