import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Pagination from "@mui/material/Pagination";
import Typography from "@mui/material/Typography";
import { AppState } from "../redux/store/Store";
import Box from "@mui/material/Box";
import AdminLoader from "./AdminLoader";
import dayjs from "dayjs";
import {
  DataGrid,
  GridCellParams,
  GridColDef,
  GridToolbar,
} from "@mui/x-data-grid";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Stack,
} from "@mui/material";
import MWPageTitle from "../component/MWPageTitle";
import MWExceptionList from "../component/MWExceptionList";
import PageLayout from "./Layout/PageLayout";
import RefreshIcon from "@mui/icons-material/Refresh";
import {
  AppointmentListBody,
  AppointmentListValue,
} from "../redux/Appointment/AppointmentList/Model";
import { appointmentListCallApi } from "../redux/Appointment/AppointmentList/ApiCall";
import { appointmentDeactivateCallApi } from "../redux/Appointment/AppointmentDeactive/ApiCall";
import MWResponseBanner from "../component/MWResponseBanner";
import { t } from "i18next";

export default function AppointmentList() {
  const dispatch = useDispatch();
  const loadingMarkup = <AdminLoader />;
  const [apptArray, setApptGridArray] = useState<any[]>([]);
  const [listCount, setListCount] = React.useState("");
  const [page, setPage] = useState(1);
  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
    dispatch(appointmentListCallApi(appointmentBody, value));
  };

  const closeBannerState = (passedVal: boolean) => {
    setBannerOpen(passedVal);
  }
  const appointmentBody = {
    appointmentStatus: "",
  } as AppointmentListBody;

  function deactivate(appointmentId: number) {
    dispatch(appointmentDeactivateCallApi(appointmentId));
    dispatch(appointmentListCallApi(appointmentBody, page));
  }
  const DeactivateResponse = useSelector(
    (state: AppState) => state.deleteAppointment //.successMsg//response.message
  );

  const AdminAppointmentList = useSelector(
    (state: AppState) => state.allApptList
  );
  const pageCount = useSelector(
    (state: AppState) => state.allApptList?.appointmentListRes?.numberOfPages
  );

  const [bannerOpen, setBannerOpen] = useState(false);
  const [bannerColor, setBannerColor] = useState("");
  const [bannerMsg, setBannerMsg] = useState("");

  useEffect(() => {
    AdminAppointmentList?.appointmentListRes?.count !== undefined
      ? setListCount(String(AdminAppointmentList?.appointmentListRes?.count))
      : setListCount("0");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [AdminAppointmentList]);

  useEffect(() => {
    if (
      AdminAppointmentList?.appointmentListRes?.appointmentList !== undefined
    ) {
      let paymentList =
        AdminAppointmentList?.appointmentListRes?.appointmentList?.map(
          (element: AppointmentListValue) => ({
            patientName: element.patientName !== "" ? element.patientName : "",
            createDate:
              element.createDate !== null
                ? dayjs(element.createDate).format("ddd, MMM D, YYYY")
                : "",
            endDate:
              element.end !== null
                ? dayjs(element.end).format("ddd, MMM D, YYYY")
                : "",
            modifiedDate:
              element.modifiedDate !== null
                ? dayjs(element.modifiedDate).format("ddd, MMM D, YYYY")
                : "",
            appoinmentStatus: element.status !== "" ? element.status : "",
            id: element.id,
          })
        );
      console.log("RUPAKpaymentList", paymentList);
      setApptGridArray(paymentList);
    } else {
      setApptGridArray([] as AppointmentListValue[]);
    }
  }, [AdminAppointmentList]);

  useEffect(() => {
    if (DeactivateResponse?.successMsg !== "") {
      setBannerOpen(true);
      setBannerColor("success");
      setBannerMsg(DeactivateResponse?.successMsg);
    } else if (DeactivateResponse?.errorMsg !== "") {
      setBannerOpen(true);
      setBannerColor("error");
      setBannerMsg(DeactivateResponse?.errorMsg);
    }
  }, [DeactivateResponse]);

  const columns: GridColDef[] = [
    {
      field: "patientName",
      headerName: `${t("common.patientname", { ns: ["home"] })!}`,
      editable: true,
      width: 200,
      align: "left",
      flex: 1,
    },
    {
      field: "createDate",
      headerName: `${t("appointmentpage.createdate", { ns: ["home"] })!}`,
      type: "string",
      width: 200,
      align: "left",
      flex: 1,
    },
    {
      field: "endDate",
      headerName: `${t("appointmentpage.enddate", { ns: ["home"] })!}`,
      sortable: false,
      width: 100,
      align: "left",
      flex: 1,
    },
    {
      field: "modifiedDate",
      headerName: `${t("appointmentpage.modifieddate", { ns: ["home"] })!}`,
      sortable: false,
      width: 100,
      align: "left",
      flex: 1,
    },
    {
      field: "appoinmentStatus",
      headerName: `${t("appointmentpage.status", { ns: ["home"] })!}`,
      sortable: false,
      width: 100,
      align: "left",
      flex: 1,
    },
    {
      field: "id",
      headerName: `${t("common.deactive", { ns: ["home"] })!}`,
      width: 100,
      align: "left",
      flex: 1,
      renderCell: (params: GridCellParams) => (
        <Button
          startIcon={<DeleteIcon />}
          onClick={() => {
            deactivate(params.row.id);
          }}
          variant="outlined"
          sx={{ textTransform: "none" }}
        >
          {t("common.deactive", { ns: ["home"] })!}
        </Button>
      ),
    },
  ];

  // Reload Appointment API
  const reloadAppointmentList = () => {
    dispatch(appointmentListCallApi(appointmentBody, page));
  };
  useEffect(() => {
    // Do api call here....
    dispatch(appointmentListCallApi(appointmentBody, page));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const tableMarkup = (
    <Card>
      <CardHeader
        title={
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item></Grid>
            <Grid item>
              <Grid
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
              >
                <Grid item>
                  <Button
                    variant="outlined"
                    startIcon={<RefreshIcon />}
                    onClick={reloadAppointmentList}
                  >
                    {t("common.reload", { ns: ["home"] })!}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        }
      />
      <CardContent>
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="stretch"
          spacing={2}
        >
          {AdminAppointmentList &&
          AdminAppointmentList?.appointmentListRes?.appointmentList?.length ? (
            <DataGrid
              rows={apptArray}
              columns={columns}
              disableSelectionOnClick
              hideFooter
              hideFooterPagination
              experimentalFeatures={{ newEditingApi: true }}
              autoHeight
              components={{ Toolbar: GridToolbar }}
              componentsProps={{
                toolbar: {
                  csvOptions: { disableToolbarButton: true },
                  printOptions: { disableToolbarButton: true },
                  showQuickFilter: true,
                  quickFilterProps: { debounceMs: 250 },
                },
              }}
              disableColumnFilter
              disableColumnSelector
              disableDensitySelector
              getRowId={(row) => row.id}
            />
          ) : (
            <MWExceptionList />
          )}

          <Grid
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="flex-end"
          >
            <Grid item>
              <Typography>Page: {page}</Typography>
            </Grid>
            <Grid item>
              <Pagination
                color="primary"
                count={Number(pageCount)}
                page={page}
                onChange={handleChange}
                sx={{ flex: "end" }}
              />
            </Grid>
          </Grid>
        </Stack>
      </CardContent>
    </Card>
  );

  return (
    <Box>
      {AdminAppointmentList.loading || DeactivateResponse.loading
        ? loadingMarkup
        : null}
      <PageLayout>
        <MWPageTitle
          title={t("common.appointmentlist", { ns: ["home"] })}
          enableCount={true}
          count={listCount}
          reload={false}
          reloadAction={reloadAppointmentList}
        />
        {tableMarkup}
        {
          <MWResponseBanner
            bannerOpen={bannerOpen}
            bannerColor={bannerColor}
            responseMsg={bannerMsg}
            bannerClose={closeBannerState}
          />
        }
      </PageLayout>
    </Box>
  );
}
