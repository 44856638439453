import axios from "axios";
import { Dispatch } from "redux";
import baseUrl from "../../ApiConfig";
import { setupInterceptorsTo } from "../Authentication/Interceptors";
import { LogOutActionTypes } from "./ActionTypes";
import { LogOutBody, LogOutResponse } from "./Model";
import {
  logOutSuccessAction,
  logOutErrorAction,
  imageUploadLoadingAction,
} from "./Action";
import { headers } from "../../component/Utility";

export const logOut = (payload: LogOutBody) => {
  return function (dispatch: Dispatch<LogOutActionTypes>) {
    dispatch(imageUploadLoadingAction(true));
    setupInterceptorsTo(axios)
      .post(baseUrl.baseUrl + `/api/logout/`, payload, {
        headers: headers,
      })
      .then((res) => {
        dispatch(imageUploadLoadingAction(false));
        dispatch(logOutSuccessAction(res.data));
        localStorage.clear();
        window.location.reload();
      })
      .catch((error) => {
        dispatch(imageUploadLoadingAction(false));
        dispatch(
          logOutErrorAction(
            {} as LogOutResponse,
            error.response !== undefined
              ? error.response.data.message
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || -1 : -1
          )
        );       
        localStorage.clear();
        window.location.reload();
      });
  };
};
