import {
  UpdatePatientDataDetailsActionTypes,
  UPDATE_PATIENT_DATA_DETAILS_FAIL,
  UPDATE_PATIENT_DATA_DETAILS_LOADING,
  UPDATE_PATIENT_DATA_DETAILS_SUCCESS,
  UpdatePatientDataDetailsState,
  UPDATE_PATIENT_DATA_DETAILS_UPDATE_API_MSG,
  UPDATE_PATIENT_DATA_DETAILS_UPDATE_API_RES,
} from "./ActionTypes";
import { UpdatePatientDataDetailsRes } from "./Model";

const initialStateGetPosts: UpdatePatientDataDetailsState = {
  loading: false,
  updatePatientDataDetailsRes: {} as UpdatePatientDataDetailsRes,
  message: "",
  status: 0,
};
export const updatePatientDataDetailsReducer = (
  state = initialStateGetPosts,
  action: UpdatePatientDataDetailsActionTypes
): UpdatePatientDataDetailsState => {
  switch (action.type) {
    case UPDATE_PATIENT_DATA_DETAILS_LOADING:
      return {
        ...state,
        loading: true,
        updatePatientDataDetailsRes: {} as UpdatePatientDataDetailsRes,
      };
    case UPDATE_PATIENT_DATA_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        updatePatientDataDetailsRes: action.payload,
      };
    case UPDATE_PATIENT_DATA_DETAILS_FAIL:
      return {
        ...state,
        loading: false,
        updatePatientDataDetailsRes: {} as UpdatePatientDataDetailsRes,
        message: action.message,
        status: action.status,
      };
    case UPDATE_PATIENT_DATA_DETAILS_UPDATE_API_MSG:
      return {
        ...state,
        loading: false,
        updatePatientDataDetailsRes: action.payload,
        message: action.message,
        status: action.status,
      };
    case UPDATE_PATIENT_DATA_DETAILS_UPDATE_API_RES:
      return {
        ...state,
        loading: false,
        updatePatientDataDetailsRes: {} as UpdatePatientDataDetailsRes,
        message: action.message,
        status: action.status,
      };
    default:
      return state;
  }
};
