import { RequestAppointmentDetails, RequestAppointmentListDataDetails } from "./Model";

export const REQUEST_APPOINTMENT_DETAILS_LIST_LOADING = "REQUEST_APPOINTMENT_DETAILS_LIST_LOADING";
export const REQUEST_APPOINTMENT_DETAILS_LIST_SUCCESS = "REQUEST_APPOINTMENT_DETAILS_LIST_SUCCESS";
export const REQUEST_APPOINTMENT_DETAILS_LIST_FAIL = "REQUEST_APPOINTMENT_DETAILS_LIST_FAIL";
export const REQUEST_APPOINTMENT_DETAILS_LIST = 'REQUEST_APPOINTMENT_DETAILS_LIST';

export interface RequestAppointmentStateType {
  loading: boolean;
  response: RequestAppointmentDetails;
  listData: RequestAppointmentListDataDetails[];
}
export interface RequestAppointmentTypeLoading {
  type: typeof REQUEST_APPOINTMENT_DETAILS_LIST_LOADING;
}
export interface RequestAppointmentTypeSuccess {
  type: typeof REQUEST_APPOINTMENT_DETAILS_LIST_SUCCESS;
  payload: RequestAppointmentDetails;
  listData: RequestAppointmentListDataDetails[];
}
export interface RequestAppointmentTypeFail {
  type: typeof REQUEST_APPOINTMENT_DETAILS_LIST_FAIL;
}

interface RequestAppointmentType {
  type: typeof REQUEST_APPOINTMENT_DETAILS_LIST;
  payload: RequestAppointmentDetails;
  listData: RequestAppointmentListDataDetails[];
}
export type RequestAppointmentTypes = RequestAppointmentType | RequestAppointmentTypeLoading | RequestAppointmentTypeSuccess | RequestAppointmentTypeFail ;


export const ADD_REQUEST_APPOINTMENT_LOADING = "ADD_REQUEST_APPOINTMENT_LOADING";
export const ADD_REQUEST_APPOINTMENT_SUCCESS = "ADD_REQUEST_APPOINTMENT_SUCCESS";
export const ADD_REQUEST_APPOINTMENT_FAIL = "ADD_REQUEST_APPOINTMENT_FAIL";
export const ADD_REQUEST_APPOINTMENT = 'ADD_REQUEST_APPOINTMENT';

export interface AddRequestAppointmentStateType {
  loading: boolean;
  response: Response;
}
export interface AddRequestAppointmentTypeLoading {
  type: typeof ADD_REQUEST_APPOINTMENT_LOADING;
}
export interface AddRequestAppointmentTypeSuccess {
  type: typeof ADD_REQUEST_APPOINTMENT_SUCCESS;
  payload: Response;
}
export interface AddRequestAppointmentTypeFail {
  type: typeof ADD_REQUEST_APPOINTMENT_FAIL;
  payload: Response;
}

interface AddRequestAppointmentType {
  type: typeof ADD_REQUEST_APPOINTMENT;
  payload: Response;
}
export type AddRequestAppointmentTypes = AddRequestAppointmentType | AddRequestAppointmentTypeLoading | AddRequestAppointmentTypeSuccess | AddRequestAppointmentTypeFail ;