import { AllCaseList } from "../interfaces/Model";
import {
  GET_LIST,
  DOCTOR_REQUEST_LIST_LOADING,
  DOCTOR_REQUEST_LIST_SUCCESS,
  DOCTOR_REQUEST_LIST_FAIL,
  DOCTOR_REJECT_LIST_LOADING,
  DOCTOR_REJECT_LIST_SUCCESS,
  DOCTOR_REJECT_LIST_FAIL,
  DOCTOR_INCOMPLETE_LIST_LOADING,
  DOCTOR_INCOMPLETE_LIST_SUCCESS,
  DOCTOR_INCOMPLETE_LIST_FAIL,
  GetDoctorStateType,
  GetOnboardingDoctorRequestStateType,
  GetOnboardingDoctorRejectStateType,
  GetOnboardingDoctorIncompleteStateType,
  DoctorGetActionTypes,
  DoctorOnboardingRequestActionTypes,
  DoctorOnboardingRejectActionTypes,
  DoctorOnboardingIncompleteActionTypes,
  DoctorAdminGetActionStateType,
  DoctorAdminGetActionTypes,
  PatientGetActionTypes,
  GetPatientStateType,
  PATIENT_LIST_SUCCESS,
  PATIENT_LIST_LOADING,
  PATIENT_LIST_FAIL,
  ORGANIZATION_LIST_SUCCESS,
  ORGANIZATION_LIST_LOADING,
  ORGANIZATION_LIST_FAIL,
  AdminGetTaskListActionTypes,
  GetTaskListStateType,
  GetOrganizationListStateType,
  TASK_LIST_SUCCESS,
  TASK_LIST_LOADING,
  TASK_LIST_FAIL,
  DOCTOR_LIST_SUCCESS,
  DOCTOR_LIST_LOADING,
  DOCTOR_LIST_FAIL,
  GetQuestionListStateType,
  AdminGetQuestionListActionTypes,
  QUESTION_LIST_SUCCESS,
  QUESTION_LIST_LOADING,
  QUESTION_LIST_FAIL,
  CASE_LIST_SUCCESS,
  CASE_LIST_LOADING,
  CASE_LIST_FAIL,
  GetCaseStateType,
  CaseGetActionTypes,
  GetQuestionStateType,
  GetChiefComplaintStateType,
  SetAddChiefComplaintStateType,
  SetDeleteDocumentStateType,
  DeleteDocumentTypes,
  DELETE_DOCUMENT_LIST_SUCCESS,
  QuestionGetActionTypes,
  CC_LIST_LOADING,
  CC_LIST_SUCCESS,
  ChiefComplaintGetActionTypes,
  CC_LIST_FAIL,
  AddChiefComplaintTypes,
  CC_ADD_LIST_SUCCESS,
  CC_ADD_LIST_LOADING,
  CC_ADD_LIST_FAIL,
  DELETE_DOCUMENT_LIST_LOADING,
  DELETE_DOCUMENT_LIST_FAIL,
  ALL_TASK_LIST_SUCCESS,
  ALL_TASK_LIST_FAIL,
  ALL_TASK_LIST_LOADING,
  AdminGetAllTaskListActionTypes,
  GetAllTaskListStateType,
  ALL_CASE_LIST_SUCCESS,
  ALL_CASE_LIST_FAIL,
  ALL_CASE_LIST_LOADING,
  AllGetCaseStateType,
  AllCaseGetActionTypes,
  GetAllAppointmentListStateType,
  AdminAllAppointmentGetActionTypes,
  APPOINTMENT_LIST_SUCCESS,
  APPOINTMENT_LIST_LOADING,
  APPOINTMENT_LIST_FAIL,
  GetAllPatientListStateType,
  AdminGetPatientTeamListActionTypes,
  ALL_PATIENT_TEAM_FAIL,
  ALL_PATIENT_TEAM_SUCCESS,
  ALL_PATIENT_TEAM_LOADING,
  AdminGetAllInactiveTaskListActionTypes,
  GetAllInactiveTaskListStateType,
  ALL_INACTIVE_TASK_LIST_SUCCESS,
  ALL_INACTIVE_TASK_LIST_LOADING,
  ALL_INACTIVE_TASK_LIST_FAIL,
  ACTIVE_CORPORATE_LIST_SUCCESS,
  ACTIVE_CORPORATE_LIST_LOADING,
  ACTIVE_CORPORATE_LIST_FAIL,
  ActiveCorporatePackagesTypes,
  GetActiveCorporateListStateType,
  GetInctiveCorporateListStateType,
  InactiveCorporatePackagesTypes,
  INACTIVE_CORPORATE_LIST_SUCCESS,
  INACTIVE_CORPORATE_LIST_LOADING,
  INACTIVE_CORPORATE_LIST_FAIL,
  ActiveCorporatePatientTypes,
  GetActiveCorporatePatientListStateType,
  ACTIVE_CORPORATE_PATIENT_LIST_SUCCESS,
  ACTIVE_CORPORATE_PATIENT_LIST_LOADING,
  ACTIVE_CORPORATE_PATIENT_LIST_FAIL,
  GetInactiveCorporatePatientListStateType,
  InactiveCorporatePatientTypes,
  INACTIVE_CORPORATE_PATIENT_LIST_SUCCESS,
  INACTIVE_CORPORATE_PATIENT_LIST_LOADING,
  INACTIVE_CORPORATE_PATIENT_LIST_FAIL,
  AdminGetOrganizationListActionTypes,
  OrganizationPatientGetActionTypes,
  GetOrganizationPatientStateType,
  ORGPATIENT_LIST_SUCCESS,
  ORGPATIENT_LIST_LOADING,
  ORGPATIENT_LIST_FAIL,
  AcceptRejectAppointmentTypes,
  AcceptRejectAppointmentStateType,
  ACCEPT_REJECT_APPOINTMENT_SUCCESS,
  ACCEPT_REJECT_APPOINTMENT_LOADING,
  ACCEPT_REJECT_APPOINTMENT_FAIL,
  TaskCloseStateType,
  TaskCloseTypes,
  TASK_CLOSE_SUCCESS,
  TASK_CLOSE_LOADING,
  TASK_CLOSE_FAIL,
} from "../types/ActionTypes";

const initialStateGetPosts: GetDoctorStateType = { doctorValue: [] };
const initialStateRequestPosts: GetOnboardingDoctorRequestStateType = {
  loading: false,
  doctorRequestValue: [],
};
const initialStateRejectPosts: GetOnboardingDoctorRejectStateType = {
  loading: false,
  doctorRejectValue: [],
};
const initialStateIncompletePosts: GetOnboardingDoctorIncompleteStateType = {
  loading: false,
  doctorIncompleteValue: [],
};
const initialStateGetDoctorList: DoctorAdminGetActionStateType = {
  loading: false,
  doctorListValue: [],
};
const initialStateGetPatient: GetPatientStateType = {
  loading: false,
  patientValue: [],
  patientPageCount: Object.assign({}),
  count: 0,
};
const initialStateGetTasklist: GetTaskListStateType = {
  loading: false,
  taskListValue: [],
};

const initialStateGetOrganizationlist: GetOrganizationListStateType = {
  loading: false,
  organizationListValue: [],
};
const initialStateGetQuestionlist: GetQuestionListStateType = {
  loading: false,
  questionListValue: [],
};
const initialStateGetCase: GetCaseStateType = {
  loading: false,
  caseValue: [],
  patientname: Object.assign({}),
};
const initialStateGetCaseAll: AllGetCaseStateType = {
  loading: false,
  allcaseValue: {} as AllCaseList,
  pageCount: Object.assign({}),
};

const initialStateGetOrganizationPatient: GetOrganizationPatientStateType = {
  loading: false,
  organizationPatientValue: [],
};
const initialStateGetQuestion: GetQuestionStateType = {
  loading: false,
  questionValue: [],
};
const initialStateGetCC: GetChiefComplaintStateType = {
  loading: false,
  ccValue: [],
};
const initialStateGetCCAdd: SetAddChiefComplaintStateType = {
  loading: false,
  addCC: [],
  response: Object.assign({}),
};
const initialStateGetDeleteDocument: SetDeleteDocumentStateType = {
  loading: false,
  deleteDoc: [],
  response: Object.assign({}),
};
const initialStateGetAllTasklist: GetAllTaskListStateType = {
  loading: false,
  alltaskListValue: [],
  count: Object.assign({}),
  pageCount: Object.assign({}),
};
const initialStateGetAllInactiveTasklist: GetAllInactiveTaskListStateType = {
  loading: false,
  allinactivetasklist: [],
  count: Object.assign({}),
  pageCount: Object.assign({}),
};
const initialStateGetAllAppointmentlist: GetAllAppointmentListStateType = {
  loading: false,
  allAppointmentListValue: [],
  appointmentPageCount: Object.assign({}),
};
const initialStateGetAllPatientlist: GetAllPatientListStateType = {
  loading: false,
  allPatientTeamListValue: [],
  patientListPageCount: Object.assign({}),
  patientTeamCurrentPageCount: Object.assign({}),
};
const initialStateGetActiveCorporateList: GetActiveCorporateListStateType = {
  loading: false,
  activeCorporateListValue: [],
};
const initialStateGetInactiveCorporateList: GetInctiveCorporateListStateType = {
  loading: false,
  inactiveCorporateListValue: [],
};
const initialStateGetActiveCorporatePatientList: GetActiveCorporatePatientListStateType =
  {
    loading: false,
    activeCorporatePatientListValue: [],
    totalNoOfPatientTeam: "",
  };
const initialStateGetInactiveCorporatePatientList: GetInactiveCorporatePatientListStateType =
  {
    loading: false,
    inActiveCorporatePatientListValue: [],
    totalNoOfPatientTeam: "",
  };

const initialStateBookingStatus: AcceptRejectAppointmentStateType = {
  loading: false,
  response: Object.assign({}),
};
const initialStateTaskCloseStatus: TaskCloseStateType = {
  loading: false,
  response: Object.assign({}),
};

export const getDoctorReducer = (
  state = initialStateGetPosts,
  action: DoctorGetActionTypes
): GetDoctorStateType => {
  switch (action.type) {
    case GET_LIST:
      return {
        ...state,
        doctorValue: action.payload,
      };
    default:
      return state;
  }
};

export const getOnboardingDoctorRequestReducer = (
  state = initialStateRequestPosts,
  action: DoctorOnboardingRequestActionTypes
): GetOnboardingDoctorRequestStateType => {
  switch (action.type) {
    case DOCTOR_REQUEST_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        doctorRequestValue: action.payload,
      };
    case DOCTOR_REQUEST_LIST_LOADING:
      return {
        ...state,
        loading: true,
        doctorRequestValue: [],
      };
    case DOCTOR_REQUEST_LIST_FAIL:
      return {
        ...state,
        doctorRequestValue: [],
        loading: false,
      };
    default:
      return state;
  }
};

export const getOnboardingDoctorRejectReducer = (
  state = initialStateRejectPosts,
  action: DoctorOnboardingRejectActionTypes
): GetOnboardingDoctorRejectStateType => {
  switch (action.type) {
    case DOCTOR_REJECT_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        doctorRejectValue: action.payload,
      };
    case DOCTOR_REJECT_LIST_LOADING:
      return {
        ...state,
        loading: true,
        doctorRejectValue: [],
      };
    case DOCTOR_REJECT_LIST_FAIL:
      return {
        ...state,
        doctorRejectValue: [],
        loading: false,
      };
    default:
      return state;
  }
};

export const getOnboardingDoctorIncompleteReducer = (
  state = initialStateIncompletePosts,
  action: DoctorOnboardingIncompleteActionTypes
): GetOnboardingDoctorIncompleteStateType => {
  switch (action.type) {
    case DOCTOR_INCOMPLETE_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        doctorIncompleteValue: action.payload,
      };
    case DOCTOR_INCOMPLETE_LIST_LOADING:
      return {
        ...state,
        loading: true,
        doctorIncompleteValue: [],
      };
    case DOCTOR_INCOMPLETE_LIST_FAIL:
      return {
        ...state,
        doctorIncompleteValue: [],
        loading: false,
      };
    default:
      return state;
  }
};

export const getDoctorListReducer = (
  state = initialStateGetDoctorList,
  action: DoctorAdminGetActionTypes
): DoctorAdminGetActionStateType => {
  switch (action.type) {
    case DOCTOR_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        doctorListValue: action.payload,
      };
    case DOCTOR_LIST_LOADING:
      return {
        ...state,
        loading: true,
        doctorListValue: [],
      };
    case DOCTOR_LIST_FAIL:
      return {
        ...state,
        doctorListValue: [],
        loading: false,
      };
    default:
      return state;
  }
};

export const getPatientReducer = (
  state = initialStateGetPatient,
  action: PatientGetActionTypes
): GetPatientStateType => {
  switch (action.type) {
    case PATIENT_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        patientValue: action.payload,
        patientPageCount: action.patientPageCount,
        count: action.count,
      };
    case PATIENT_LIST_LOADING:
      return {
        ...state,
        loading: true,
        patientValue: [],
      };
    case PATIENT_LIST_FAIL:
      return {
        ...state,
        patientValue: [],
        loading: false,
      };
    default:
      return state;
  }
};

export const getTaskListReducer = (
  state = initialStateGetTasklist,
  action: AdminGetTaskListActionTypes
): GetTaskListStateType => {
  switch (action.type) {
    case TASK_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        taskListValue: action.payload,
      };
    case TASK_LIST_LOADING:
      return {
        ...state,
        loading: true,
        taskListValue: [],
      };
    case TASK_LIST_FAIL:
      return {
        ...state,
        taskListValue: [],
        loading: false,
      };
    default:
      return state;
  }
};

export const getOrganizationListReducer = (
  state = initialStateGetOrganizationlist,
  action: AdminGetOrganizationListActionTypes
): GetOrganizationListStateType => {
  switch (action.type) {
    case ORGANIZATION_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        organizationListValue: action.payload,
      };
    case ORGANIZATION_LIST_LOADING:
      return {
        ...state,
        loading: true,
        organizationListValue: [],
      };
    case ORGANIZATION_LIST_FAIL:
      return {
        ...state,
        organizationListValue: [],
        loading: false,
      };
    default:
      return state;
  }
};

export const getAllTaskListReducer = (
  state = initialStateGetAllTasklist,
  action: AdminGetAllTaskListActionTypes
): GetAllTaskListStateType => {
  switch (action.type) {
    case ALL_TASK_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        alltaskListValue: action.payload,
        count: action.count,
        pageCount: action.pageCount,
      };
    case ALL_TASK_LIST_LOADING:
      return {
        ...state,
        loading: true,
        alltaskListValue: [],
      };
    case ALL_TASK_LIST_FAIL:
      return {
        ...state,
        alltaskListValue: [],
        loading: false,
      };
    default:
      return state;
  }
};
export const getAllInactiveTaskListReducer = (
  state = initialStateGetAllInactiveTasklist,
  action: AdminGetAllInactiveTaskListActionTypes
): GetAllInactiveTaskListStateType => {
  switch (action.type) {
    case ALL_INACTIVE_TASK_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        allinactivetasklist: action.payload,
        count: action.count,
        pageCount: action.pageCount,
      };
    case ALL_INACTIVE_TASK_LIST_LOADING:
      return {
        ...state,
        loading: true,
        allinactivetasklist: [],
      };
    case ALL_INACTIVE_TASK_LIST_FAIL:
      return {
        ...state,
        allinactivetasklist: [],
        loading: false,
      };
    default:
      return state;
  }
};
export const getQuestionListReducer = (
  state = initialStateGetQuestionlist,
  action: AdminGetQuestionListActionTypes
): GetQuestionListStateType => {
  switch (action.type) {
    case QUESTION_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        questionListValue: action.payload,
      };
    case QUESTION_LIST_LOADING:
      return {
        ...state,
        loading: true,
        questionListValue: [],
      };
    case QUESTION_LIST_FAIL:
      return {
        ...state,
        questionListValue: [],
        loading: false,
      };
    default:
      return state;
  }
};

export const getCaseReducer = (
  state = initialStateGetCase,
  action: CaseGetActionTypes
): GetCaseStateType => {
  switch (action.type) {
    case CASE_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        caseValue: action.payload,
        patientname: action.patientname,
      };
    case CASE_LIST_LOADING:
      return {
        ...state,
        loading: true,
        caseValue: [],
      };
    case CASE_LIST_FAIL:
      return {
        ...state,
        caseValue: [],
        loading: false,
      };
    default:
      return state;
  }
};

export const getOrganizationPatientReducer = (
  state = initialStateGetOrganizationPatient,
  action: OrganizationPatientGetActionTypes
): GetOrganizationPatientStateType => {
  switch (action.type) {
    case ORGPATIENT_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        organizationPatientValue: action.payload,
      };
    case ORGPATIENT_LIST_LOADING:
      return {
        ...state,
        loading: true,
        organizationPatientValue: [],
      };
    case ORGPATIENT_LIST_FAIL:
      return {
        ...state,
        organizationPatientValue: [],
        loading: false,
      };
    default:
      return state;
  }
};

export const getAllCaseReducer = (
  state = initialStateGetCaseAll,
  action: AllCaseGetActionTypes
): AllGetCaseStateType => {
  switch (action.type) {
    case ALL_CASE_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        allcaseValue: action.payload,
        pageCount: action.pageCount,
      };
    case ALL_CASE_LIST_LOADING:
      return {
        ...state,
        loading: true,
        allcaseValue: {} as AllCaseList,
      };
    case ALL_CASE_LIST_FAIL:
      return {
        ...state,
        allcaseValue: {} as AllCaseList,
        loading: false,
      };
    default:
      return state;
  }
};

export const getQuestionReducer = (
  state = initialStateGetQuestion,
  action: QuestionGetActionTypes
): GetQuestionStateType => {
  switch (action.type) {
    case QUESTION_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        questionValue: action.payload,
      };
    case QUESTION_LIST_LOADING:
      return {
        ...state,
        loading: true,
        questionValue: [],
      };
    case QUESTION_LIST_FAIL:
      return {
        ...state,
        questionValue: [],
        loading: false,
      };
    default:
      return state;
  }
};

export const getCCReducer = (
  state = initialStateGetCC,
  action: ChiefComplaintGetActionTypes
): GetChiefComplaintStateType => {
  switch (action.type) {
    case CC_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        ccValue: action.payload,
      };
    case CC_LIST_LOADING:
      return {
        ...state,
        loading: true,
        ccValue: [],
      };
    case CC_LIST_FAIL:
      return {
        ...state,
        ccValue: [],
        loading: false,
      };
    default:
      return state;
  }
};

export const addCCReducer = (
  state = initialStateGetCCAdd,
  action: AddChiefComplaintTypes
): SetAddChiefComplaintStateType => {
  switch (action.type) {
    case CC_ADD_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        response: action.payload,
      };
    case CC_ADD_LIST_LOADING:
      return {
        ...state,
        loading: true,
        addCC: [],
      };
    case CC_ADD_LIST_FAIL:
      return {
        ...state,
        addCC: [],
        loading: false,
      };
    default:
      return state;
  }
};

export const deleteDocumentReducer = (
  state = initialStateGetDeleteDocument,
  action: DeleteDocumentTypes
): SetDeleteDocumentStateType => {
  switch (action.type) {
    case DELETE_DOCUMENT_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        response: action.payload,
      };
    case DELETE_DOCUMENT_LIST_LOADING:
      return {
        ...state,
        loading: true,
        deleteDoc: [],
      };
    case DELETE_DOCUMENT_LIST_FAIL:
      return {
        ...state,
        deleteDoc: [],
        loading: false,
      };
    default:
      return state;
  }
};

export const getAllAppointmentListReducer = (
  state = initialStateGetAllAppointmentlist,
  action: AdminAllAppointmentGetActionTypes
): GetAllAppointmentListStateType => {
  switch (action.type) {
    case APPOINTMENT_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        allAppointmentListValue: action.payload,
        appointmentPageCount: action.appointmentPageCount,
      };
    case APPOINTMENT_LIST_LOADING:
      return {
        ...state,
        loading: true,
        allAppointmentListValue: [],
      };
    case APPOINTMENT_LIST_FAIL:
      return {
        ...state,
        allAppointmentListValue: [],
        loading: false,
      };
    default:
      return state;
  }
};

export const getpatientTeamListReducer = (
  state = initialStateGetAllPatientlist,
  action: AdminGetPatientTeamListActionTypes
): GetAllPatientListStateType => {
  switch (action.type) {
    case ALL_PATIENT_TEAM_SUCCESS:
      return {
        ...state,
        loading: false,
        allPatientTeamListValue: action.payload,
        patientListPageCount: action.patientListPageCount,
        patientTeamCurrentPageCount: action.patientTeamCurrentPageCount,
      };
    case ALL_PATIENT_TEAM_LOADING:
      return {
        ...state,
        loading: true,
        allPatientTeamListValue: [],
      };
    case ALL_PATIENT_TEAM_FAIL:
      return {
        ...state,
        allPatientTeamListValue: [],
        loading: false,
      };
    default:
      return state;
  }
};

export const getActiveCorporateListReducer = (
  state = initialStateGetActiveCorporateList,
  action: ActiveCorporatePackagesTypes
): GetActiveCorporateListStateType => {
  switch (action.type) {
    case ACTIVE_CORPORATE_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        activeCorporateListValue: action.payload,
      };
    case ACTIVE_CORPORATE_LIST_LOADING:
      return {
        ...state,
        loading: true,
        activeCorporateListValue: [],
      };
    case ACTIVE_CORPORATE_LIST_FAIL:
      return {
        ...state,
        activeCorporateListValue: [],
        loading: false,
      };
    default:
      return state;
  }
};

export const getInactiveCorporateListReducer = (
  state = initialStateGetInactiveCorporateList,
  action: InactiveCorporatePackagesTypes
): GetInctiveCorporateListStateType => {
  switch (action.type) {
    case INACTIVE_CORPORATE_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        inactiveCorporateListValue: action.payload,
      };
    case INACTIVE_CORPORATE_LIST_LOADING:
      return {
        ...state,
        loading: true,
        inactiveCorporateListValue: [],
      };
    case INACTIVE_CORPORATE_LIST_FAIL:
      return {
        ...state,
        inactiveCorporateListValue: [],
        loading: false,
      };
    default:
      return state;
  }
};

export const getActiveCorporatePatientListReducer = (
  state = initialStateGetActiveCorporatePatientList,
  action: ActiveCorporatePatientTypes
): GetActiveCorporatePatientListStateType => {
  switch (action.type) {
    case ACTIVE_CORPORATE_PATIENT_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        activeCorporatePatientListValue: action.payload,
        totalNoOfPatientTeam: action.totalNoOfPatientTeam,
      };
    case ACTIVE_CORPORATE_PATIENT_LIST_LOADING:
      return {
        ...state,
        loading: true,
        activeCorporatePatientListValue: [],
      };
    case ACTIVE_CORPORATE_PATIENT_LIST_FAIL:
      return {
        ...state,
        activeCorporatePatientListValue: [],
        loading: false,
      };
    default:
      return state;
  }
};

export const getInactiveCorporatePatientListReducer = (
  state = initialStateGetInactiveCorporatePatientList,
  action: InactiveCorporatePatientTypes
): GetInactiveCorporatePatientListStateType => {
  switch (action.type) {
    case INACTIVE_CORPORATE_PATIENT_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        inActiveCorporatePatientListValue: action.payload,
        totalNoOfPatientTeam: action.totalNoOfPatientTeam,
      };
    case INACTIVE_CORPORATE_PATIENT_LIST_LOADING:
      return {
        ...state,
        loading: true,
        inActiveCorporatePatientListValue: [],
      };
    case INACTIVE_CORPORATE_PATIENT_LIST_FAIL:
      return {
        ...state,
        inActiveCorporatePatientListValue: [],
        loading: false,
      };
    default:
      return state;
  }
};

export const acceptOrRejectBookingAppointment = (
  state = initialStateBookingStatus,
  action: AcceptRejectAppointmentTypes
): AcceptRejectAppointmentStateType => {
  switch (action.type) {
    case ACCEPT_REJECT_APPOINTMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        response: action.payload,
      };
    case ACCEPT_REJECT_APPOINTMENT_LOADING:
      return {
        ...state,
        loading: true,
      };
    case ACCEPT_REJECT_APPOINTMENT_FAIL:
      return {
        ...state,
        loading: false,
        response: action.payload,
      };
    default:
      return state;
  }
};
// Task Close By Admin Reducer
export const taskCloseByAdmiin = (
  state = initialStateTaskCloseStatus,
  action: TaskCloseTypes
): TaskCloseStateType => {
  switch (action.type) {
    case TASK_CLOSE_SUCCESS:
      return {
        ...state,
        loading: false,
        response: action.payload,
      };
    case TASK_CLOSE_LOADING:
      return {
        ...state,
        loading: true,
      };
    case TASK_CLOSE_FAIL:
      return {
        ...state,
        loading: false,
        response: action.payload,
      };
    default:
      return state;
  }
};
