import {
  DrApproveRejectActionTypes,
  DR_APPROVE_REJECT_FAIL,
  DR_APPROVE_REJECT_LOADING,
  DR_APPROVE_REJECT_SUCCESS,
  DrApproveRejectState,
  DR_APPROVE_REJECT_API_MSG,
  DR_APPROVE_REJECT_API_RES,
} from "./ActionTypes";
import { DoctorOnboardIncompleteResponse } from "./Model";

const initialStateEditPosts: DrApproveRejectState = {
  loading: false,
  drApproveRejectRes: {} as DoctorOnboardIncompleteResponse,
  successMsg: "",
  errorMsg: "",
  status: 0,
};
export const doctorsOnboardingImcompleteReducer = (
  state = initialStateEditPosts,
  action: DrApproveRejectActionTypes
): DrApproveRejectState => {
  switch (action.type) {
    case DR_APPROVE_REJECT_LOADING:
      return {
        ...state,
        loading: action.loading,
      };
    case DR_APPROVE_REJECT_SUCCESS:
      return {
        ...state,
        drApproveRejectRes: action.payload,
        successMsg: action.successMsg,
      };
    case DR_APPROVE_REJECT_FAIL:
      return {
        ...state,
        drApproveRejectRes: action.payload,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case DR_APPROVE_REJECT_API_MSG:
      return {
        ...state,
        drApproveRejectRes: action.payload,
        successMsg: action.successMsg,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case DR_APPROVE_REJECT_API_RES:
      return {
        ...state,
        drApproveRejectRes: action.payload,
        successMsg: action.successMsg,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    default:
      return state;
  }
};