import {
  PatientSearchActionTypes,
  PATIENT_SEARCH_FAIL,
  PATIENT_SEARCH_LOADING,
  PATIENT_SEARCH_SUCCESS,
  PatientSearchDetailsState,
  PATIENT_SEARCH_UPDATE_API_MSG,
  PATIENT_SEARCH_UPDATE_API_RES,
} from "./ActionTypes";
import { PatientSearchDetails } from "./Model";

const initialStateGetPosts: PatientSearchDetailsState = {
  loading: false,
  patientSearchRes: {} as PatientSearchDetails,
  message: "",
  status: 0,
};
export const patientSearchReducer = (
  state = initialStateGetPosts,
  action: PatientSearchActionTypes
): PatientSearchDetailsState => {
  switch (action.type) {
    case PATIENT_SEARCH_LOADING:
      return {
        ...state,
        loading: true,
        patientSearchRes: {} as PatientSearchDetails,
      };
    case PATIENT_SEARCH_SUCCESS:
      return {
        ...state,
        loading: false,
        patientSearchRes: action.payload,
      };
    case PATIENT_SEARCH_FAIL:
      return {
        ...state,
        loading: false,
        patientSearchRes: {} as PatientSearchDetails,
        message: action.message,
        status: action.status,
      };
    case PATIENT_SEARCH_UPDATE_API_MSG:
      return {
        ...state,
        loading: false,
        patientSearchRes: action.payload,
        message: action.message,
        status: action.status,
      };
    case PATIENT_SEARCH_UPDATE_API_RES:
      return {
        ...state,
        loading: false,
        patientSearchRes: {} as PatientSearchDetails,
        message: action.message,
        status: action.status,
      };
    default:
      return state;
  }
};
