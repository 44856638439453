import {
  RequestAppointmentActionTypes,
  REQUEST_APPOINTMENT_FAIL,
  REQUEST_APPOINTMENT_LOADING,
  REQUEST_APPOINTMENT_SUCCESS,
  RequestAppointmentDataState,
  REQUEST_APPOINTMENT_API_MSG,
  REQUEST_APPOINTMENT_API_RES,
} from "./ActionTypes";
import { RequestAppointmentDataRes } from "./Model";

const initialStateGetPosts: RequestAppointmentDataState = {
  loading: false,
  requestAppointmentDataRes: {} as RequestAppointmentDataRes,
  successMsg: "",
  errorMsg: "",
  status: 0,
};
export const addRequestAppointmentReducer = (
  state = initialStateGetPosts,
  action: RequestAppointmentActionTypes
): RequestAppointmentDataState => {
  switch (action.type) {
    case REQUEST_APPOINTMENT_LOADING:
      return {
        ...state,
        loading: action.loading,
      };
    case REQUEST_APPOINTMENT_SUCCESS:
      return {
        ...state,
        requestAppointmentDataRes: action.payload,
        successMsg: action.successMsg,
      };
    case REQUEST_APPOINTMENT_FAIL:
      return {
        ...state,
        requestAppointmentDataRes: action.payload,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case REQUEST_APPOINTMENT_API_MSG:
      return {
        ...state,
        requestAppointmentDataRes: action.payload,
        successMsg: action.successMsg,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case REQUEST_APPOINTMENT_API_RES:
      return {
        ...state,
        requestAppointmentDataRes: action.payload,
        successMsg: action.successMsg,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    default:
      return state;
  }
};
