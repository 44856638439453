import { GetCaseStatusListRes } from "./Model";
export const CASE_STATUS_LIST_LOADING =
  "CASE_STATUS_LIST_LOADING";
export const CASE_STATUS_LIST_SUCCESS =
  "CASE_STATUS_LIST_SUCCESS";
export const CASE_STATUS_LIST_FAIL =
  "CASE_STATUS_LIST_FAIL";
export const CASE_STATUS_LIST_DATA =
  "CASE_STATUS_LIST_DATA";
export const CASE_STATUS_LIST_UPDATE_API_MSG =
  "CASE_STATUS_LIST_UPDATE_API_MSG";
export const CASE_STATUS_LIST_UPDATE_API_RES =
  "CASE_STATUS_LIST_UPDATE_API_RES";

export interface GetCaseStatusListState {
  getCaseStatusListRes: GetCaseStatusListRes;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface GetCaseStatusListLoading {
  type: typeof CASE_STATUS_LIST_LOADING;
  loading: boolean;
}
export interface GetCaseStatusListSuccess {
  type: typeof CASE_STATUS_LIST_SUCCESS;
  payload: GetCaseStatusListRes;
}
export interface GetCaseStatusListFail {
  type: typeof CASE_STATUS_LIST_FAIL;
  payload: GetCaseStatusListRes;
  errorMsg: string;
  status: number;
}
export interface GetCaseStatusListUpdateAPIMsg {
  type: typeof CASE_STATUS_LIST_UPDATE_API_MSG;
  payload: GetCaseStatusListRes;
  errorMsg: string;
  status: number;
}
export interface GetCaseStatusListUpdateAPIRes {
  type: typeof CASE_STATUS_LIST_UPDATE_API_RES;
  payload: GetCaseStatusListRes;
  errorMsg: string;
  status: number;
}

export type GetCaseStatusListActionTypes =
  | GetCaseStatusListLoading
  | GetCaseStatusListSuccess
  | GetCaseStatusListFail
  | GetCaseStatusListUpdateAPIMsg
  | GetCaseStatusListUpdateAPIRes;
