import { AddRequestAppointmentTypes, ADD_REQUEST_APPOINTMENT, RequestAppointmentTypes, REQUEST_APPOINTMENT_DETAILS_LIST } from "./ActionTypes";
import { RequestAppointmentDetails, RequestAppointmentListDataDetails } from "./Model";

export const requestAppointmentAction = (requestAppointmentDetails: RequestAppointmentDetails, requestlis: RequestAppointmentListDataDetails[]): RequestAppointmentTypes => {
  return {
    type: REQUEST_APPOINTMENT_DETAILS_LIST,
    payload: requestAppointmentDetails,
    listData: requestlis,
  };
};

export const addrequestAppointmentAction = (addrequestAppointment: Response): AddRequestAppointmentTypes => {
  return {
    type: ADD_REQUEST_APPOINTMENT,
    payload: addrequestAppointment,
  };
};