import {
  GetDoctorListByCategoryActionTypes,
  GET_DOCTOR_LIST_BY_CATEGORY_FAIL,
  GET_DOCTOR_LIST_BY_CATEGORY_LOADING,
  GET_DOCTOR_LIST_BY_CATEGORY_SUCCESS,
  GetDoctorListByCategoryState,
  GET_DOCTOR_LIST_BY_CATEGORY_UPDATE_API_MSG,
  GET_DOCTOR_LIST_BY_CATEGORY_UPDATE_API_RES,
} from "./ActionTypes";
import { GetDoctorListByCategoryRes } from "./Model";

const initialStateGetPosts: GetDoctorListByCategoryState = {
  loading: false,
  getDoctorListByCategoryRes: {} as GetDoctorListByCategoryRes,
  successMsg: "",
  errorMsg: "",
  status: 0,
};
export const getDoctorListByCategoryReducer = (
  state = initialStateGetPosts,
  action: GetDoctorListByCategoryActionTypes
): GetDoctorListByCategoryState => {
  switch (action.type) {
    case GET_DOCTOR_LIST_BY_CATEGORY_LOADING:
      return {
        ...state,
        loading: action.loading,
      };
    case GET_DOCTOR_LIST_BY_CATEGORY_SUCCESS:
      return {
        ...state,
        getDoctorListByCategoryRes: action.payload,
        successMsg: action.successMsg,
      };
    case GET_DOCTOR_LIST_BY_CATEGORY_FAIL:
      return {
        ...state,
        getDoctorListByCategoryRes: action.payload,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case GET_DOCTOR_LIST_BY_CATEGORY_UPDATE_API_MSG:
      return {
        ...state,
        getDoctorListByCategoryRes: action.payload,
        successMsg: action.successMsg,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case GET_DOCTOR_LIST_BY_CATEGORY_UPDATE_API_RES:
      return {
        ...state,
        getDoctorListByCategoryRes: action.payload,
        successMsg: action.successMsg,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    default:
      return state;
  }
};
