import React, { useState, useEffect } from "react";
import { Redirect, useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  acceptOrRejectBooking,
  getTaskListValue,
} from "../redux/effects/ApiCall";
import { AppState } from "../redux/store/Store";
import { AdminTaskList } from "../redux/interfaces/Model";
import _ from "lodash";
import AdminLoader from "./AdminLoader";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";
import MWPageTitle from "../component/MWPageTitle";
import MWToast from "../component/MWToast";
import PageLayout from "./Layout/PageLayout";
import { t } from "i18next";

export default function SelfTaskDetails() {
  const dispatch = useDispatch();

  const [toSignin] = useState(false);
  const { rowIndex } = useParams() as {
    rowIndex: string;
  };
  const [taskId, setTaskId] = useState("");

  const adminTaskList = useSelector((state: AppState) => state.taskListValue);
  const taskValues: AdminTaskList = _.filter(adminTaskList.taskListValue, [
    "id",
    Number(rowIndex),
  ])[0];

  const history = useHistory();
  const [isLoading] = useState(false);
  const loadingMarkup = <AdminLoader />;

  const [listCount] = React.useState("");
  // call Store
  const acceptRejectBookingLoadingValues = useSelector(
    (state: AppState) => state.bookingValue.loading
  );
  const acceptRejectBookingMessage = useSelector(
    (state: AppState) => state.bookingValue.response.message
  );
  const acceptRejectBookingStatus = useSelector(
    (state: AppState) => state.bookingValue.response.status
  );

  // Add Chief complaint success toast
  const [successAcceptBookingToastActive, setSuccessAcceptBookingToastActive] =
    useState(false);

  const toastSuccessAcceptBookingDiv = successAcceptBookingToastActive ? (
    <MWToast message={acceptRejectBookingMessage} />
  ) : null;

  // Add Chief complaint error toast
  const [successRejectBookingToastActive, setSuccessRejectBookingToastActive] =
    useState(false);
  const toastSuccessRejectBookingDiv = successRejectBookingToastActive ? (
    <MWToast message={acceptRejectBookingMessage} />
  ) : null;

  // call Store
  const taskCloseLoadingValues = useSelector(
    (state: AppState) => state.taskCloseValue.loading
  );
  const taskCloseMessage = useSelector(
    (state: AppState) => state.taskCloseValue.response.message
  );
  const taskCloseStatus = useSelector(
    (state: AppState) => state.taskCloseValue.response.status
  );

  // Add Chief complaint success toast
  const [successTaskCloseToastActive, setSuccessTaskCloseToastActive] =
    useState(false);
  console.log(setSuccessTaskCloseToastActive);
  const toastSuccessTaskCloseDiv = successTaskCloseToastActive ? (
    <MWToast message={taskCloseMessage} />
  ) : null;

  // Add Chief complaint error toast
  const [errorTaskCloseToastActive, setErrorTaskCloseToastActive] =
    useState(false);
  console.log(setErrorTaskCloseToastActive);
  const toastErrorTaskCloseDiv = errorTaskCloseToastActive ? (
    <MWToast message={taskCloseMessage} />
  ) : null;

  function gotoCaseDetails() {
    history.push(`/casedetails/${taskValues.patientId}/${taskValues.caseId}`);
  }

  // Accept Booking Appointment Body
  const acceptAppointmentBody = {
    taskId: Number(taskId),
    requestStatus: Number(1),
  };
  // Accept Booking Appointment API
  const acceptBooking = () => {
    dispatch(acceptOrRejectBooking(acceptAppointmentBody));
    setSuccessAcceptBookingToastActive(
      (successAcceptBookingToastActive) => !successAcceptBookingToastActive
    );
    setSuccessRejectBookingToastActive(
      (successRejectBookingToastActive) => !successRejectBookingToastActive
    );
  };

  // Accept Booking Appointment Body
  const rejectAppointmentBody = {
    taskId: Number(taskId),
    requestStatus: Number(-1),
  };
  // Receject Appointment Appointment API
  const rejectBooking = () => {
    dispatch(acceptOrRejectBooking(rejectAppointmentBody));
    setSuccessAcceptBookingToastActive(
      (successAcceptBookingToastActive) => !successAcceptBookingToastActive
    );
    setSuccessRejectBookingToastActive(
      (successRejectBookingToastActive) => !successRejectBookingToastActive
    );
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    // getExecutiveList();
    if (taskValues === undefined) {
      dispatch(getTaskListValue());
    } else {
      setTaskId(taskValues.id);
    }
  }, [dispatch, taskValues]);

  function selfTaskDetailsApi() {
    dispatch(getTaskListValue());
  }

  const taskTable = (
    <Card>
      <CardHeader
        title={
          <Grid
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="flex-end"
            spacing={1}
          >
            <Grid item>
              <Button
                //   pressed={isFirstButtonActive}
                variant="outlined"
                onClick={acceptBooking}
                sx={{ textTransform: "none" }}
              >
                {t("taskDetails.acceptAppoForDoc", { ns: ["home"] })}
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="outlined"
                onClick={rejectBooking}
                sx={{ textTransform: "none" }}
              >
                {t("taskDetails.rejectAppoForDoc", { ns: ["home"] })}
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                onClick={gotoCaseDetails}
                sx={{ textTransform: "none" }}
              >
                {t("taskDetails.viewCaseDetail", { ns: ["home"] })}
              </Button>
            </Grid>
          </Grid>
        }
      />
      <CardContent>
        <TableContainer>
          <Table>
            <TableBody>
              {[
                {
                  term: `${t("taskDetails.viewCaseDetail", { ns: ["home"] })}:`,
                  description: `${
                    taskValues === undefined ? "" : taskValues.title
                  }`,
                },
                {
                  term: `${t("taskDetails.taskFor", { ns: ["home"] })}:`,
                  description: `${
                    taskValues === undefined ? "" : taskValues.patientName
                  }`,
                },
                {
                  term: `${t("common.why2ndOpinion", { ns: ["home"] })}?`,
                  description: `${
                    taskValues === undefined ? "" : taskValues.whyWant2ndOpinion
                  }`,
                },
                {
                  term: `${t("common.reason2ndOpinion", { ns: ["home"] })}:`,
                  description: `${
                    taskValues === undefined
                      ? ""
                      : taskValues.secondOpinionReasons
                  }`,
                },
                {
                  term: `${t("patientcaselistpage.consultationtype", { ns: ["home"] })}:`,
                  description: `${
                    taskValues === undefined ? "" : taskValues.consultationType
                  }`,
                },
                {
                  term: `${t("taskDetails.taskCreateDate", { ns: ["home"] })}:`,
                  description: `${
                    taskValues === undefined ? "" : taskValues.created_date
                  }`,
                },
                {
                  term: `${t("common.note", { ns: ["home"] })}:`,
                  description: `${
                    taskValues === undefined || taskValues === null
                      ? ""
                      : taskValues.note === null
                      ? ""
                      : taskValues.note
                  }`,
                },
                {
                  term: `${t("common.completed", { ns: ["home"] })}:`,
                  description: `${
                    taskValues === undefined || taskValues === null
                      ? ""
                      : taskValues.completed === false
                      ? "No"
                      : "yes"
                  }`,
                },
              ].map((row) => (
                <TableRow key={row.term}>
                  <TableCell component="th" scope="row" width={250}>
                    <Typography variant="subtitle1">{row.term}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body1">{row.description}</Typography>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>
    </Card>
  );

  return (
    <div>
      {taskValues === undefined ||
      isLoading === true ||
      acceptRejectBookingLoadingValues === true ||
      taskCloseLoadingValues === true
        ? loadingMarkup
        : ""}
      {toSignin ? <Redirect to="/signin" /> : null}
      <PageLayout>
        <MWPageTitle
          title={t("taskDetails.taskDetail", { ns: ["home"] })}
          enableCount={false}
          count={listCount}
          reload={true}
          reloadAction={selfTaskDetailsApi}
        />
        {taskTable}
        {acceptRejectBookingStatus! === 1 ? toastSuccessAcceptBookingDiv : null}
        {acceptRejectBookingStatus! !== 1 ? toastSuccessRejectBookingDiv : null}
        {taskCloseStatus! === 1 ? toastSuccessTaskCloseDiv : null}
        {taskCloseStatus! !== 1 ? toastErrorTaskCloseDiv : null}
      </PageLayout>
    </div>
  );
}
