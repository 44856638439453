import axios from "axios";
import { Dispatch } from "redux";
import baseUrl from "../../ApiConfig";
// import { setupInterceptorsTo } from "../../Authentication/Interceptors";
import { ImageUploadActionTypes } from "./ActionTypes";
import { ImageUploadResponse } from "./Model";
import {
  imageUploadAPIResClearAction,
  imageUploadSuccessAction,
  imageUploadErrorAction,
  imageUploadLoadingAction,
  imageUploadProgress,
  imageUploadUpdateAPIMsgAction,
} from "./Action";
import { headers } from "../../component/Utility";

let apiRes = {} as ImageUploadResponse;

export const imageUploadApi = (typename: any) => {
  return function (dispatch: Dispatch<ImageUploadActionTypes>) {
    dispatch(imageUploadLoadingAction(true));
    axios({
      baseURL: baseUrl.baseUrl,
      url: "/storageapp/api/upload-image/",
      method: "post",
      data: typename,
      onUploadProgress: (progress) => {
        const { loaded, total } = progress;
        const percentageProgress = Math.floor((loaded / total!) * 100);
        dispatch(imageUploadProgress(percentageProgress));
      },
      headers: headers,
    })
      .then((res) => {
        dispatch(imageUploadLoadingAction(false));
        dispatch(
          imageUploadSuccessAction(
            res.data,
            res.data.message,
            String(res.data.status)
          )
        );
      })
      .catch((error) => {
        dispatch(imageUploadLoadingAction(false));
        dispatch(
          imageUploadErrorAction(
            {} as ImageUploadResponse,
            error.response !== undefined
              ? error.response.data.message
              : "Could not upload the Image!",
            error.status !== undefined
              ? String(error.response.data.status) || "0"
              : "0"
          )
        );
      });
  };
};

export const updateImageUploadAPIResMsg = () => {
  return function (dispatch: Dispatch<ImageUploadActionTypes>) {
    dispatch(
      imageUploadUpdateAPIMsgAction(apiRes as ImageUploadResponse, "", "", "0")
    );
  };
};

export const clearImageUploadAPIRes = () => {
  return function (dispatch: Dispatch<ImageUploadActionTypes>) {
    dispatch(
      imageUploadAPIResClearAction({} as ImageUploadResponse, "", "", "0")
    );
  };
};
