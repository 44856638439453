import * as React from "react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DataGrid, GridCellParams, GridColDef } from "@mui/x-data-grid";
import { AppState } from "../../redux/store/Store";
import { useHistory } from "react-router-dom";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Pagination,
  Typography,
  Autocomplete,
  TextField,
  AlertColor,
} from "@mui/material";
import MWExceptionList from "../../component/MWExceptionList";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { getAllTaskListValue } from "../../redux/TaskAdmin/List/API";
import { adminExecutiveList } from "../../redux/TaskAdmin/ExecutiveList/API";
import { AssignExecutiveBody } from "../../redux/assignExecutive/Model";
import {
  assignExecutiveApiCall,
  clearAssignExecutiveAPIRes,
} from "../../redux/TaskAdmin/AssignExecutive/API";
import MWSnackbar from "../../component/MWSnackbar";
import { t } from "i18next";

export default function ActiveTaskList(props: any) {
  const { activeTaskLoader } = props;
  const dispatch = useDispatch();
  const [isLoadinghorizontal] = useState(false);
  activeTaskLoader(isLoadinghorizontal);
  const AdminAllTaskList = useSelector(
    (state: AppState) => state.alltaskListValue
  );
  const pageCount = useSelector(
    (state: AppState) => state.alltaskListValue?.adminTaskRes?.numberOfPages
  );
  const taskValues = AdminAllTaskList?.adminTaskRes?.taskDataList;
  const currentPage = AdminAllTaskList?.adminTaskRes?.current_page_no;
  const executiveListData = useSelector(
    (state: AppState) =>
      state.executiveDropdownListvalue?.executiveRes?.executiveList
  );
  const assignExecutiveData = useSelector(
    (state: AppState) => state.assignExecutiveValues
  );
  const [selectedRows, setSelectedRows] = React.useState([] as any[]);
  const [isEdit, setIsEdit] = useState(true);
  const [executiveValues, setExecutiveValue] = useState<any[]>([]);
  const handleSelectedExecutiveChange = (_event: any, newvalue: any) => {
    if (newvalue.label !== null) {
      if (selectedRows.length > 0) {
        let id = selectedRows;
        id.forEach((element) => {
          dispatch(
            assignExecutiveApiCall({
              taskId: element,
              executiveId: Number(newvalue.value),
            } as AssignExecutiveBody)
          );
        });
      }
    }
  };
  const activeTaskListcolumns: GridColDef[] = [
    {
      field: "id",
      headerName: `${t("taskpage.view", { ns: ["home"] })!}`,
      width: 150,
      renderCell: (params: GridCellParams) => (
        <Button
          variant="outlined"
          startIcon={<RemoveRedEyeIcon />}
          onClick={() => {
            gotoTaskDetails(currentPage, params.value);
          }}
        >
          {t("taskpage.view", { ns: ["home"] })!}
        </Button>
      ),
    },
    { field: "assigned_to", headerName: `${t("taskpage.assignto", { ns: ["home"] })!}`, width: 300 },
    { field: "title", headerName: `${t("taskpage.title", { ns: ["home"] })!}`, width: 400, flex: 1 },
    {
      field: "consultationType",
      headerName: `${t("taskpage.type", { ns: ["home"] })!}`,
      width: 200,
      flex: 1
    },
    { field: "patientName", headerName: `${t("taskpage.patientname", { ns: ["home"] })!}`, width: 230, flex: 1 },
  ];
  const [page, setPage] = useState(1);
  const handleChange = (_event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
    dispatch(getAllTaskListValue(value, 1));
  };
  const history = useHistory();
  const gotoTaskDetails = (currentPage: any, rowIndex: any) => {
    history.push(`/taskdetails/${currentPage}/${rowIndex}`);
  };
  const [responseType, setResponseType] = useState<AlertColor>("success");
  const [responseMsg, setResponseMsg] = useState("");
  const alertCloseAction = (alertState: boolean) => {
    if (alertState) {
      dispatch(clearAssignExecutiveAPIRes());
    }
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(getAllTaskListValue(page, 1));
    dispatch(adminExecutiveList());
  }, [dispatch, page]);
  useEffect(() => {
    setExecutiveValue(executiveListData);
  }, [executiveListData]);
  useEffect(() => {
    if (selectedRows.length > 0) {
      setIsEdit(false);
    } else {
      setIsEdit(true);
    }
  }, [selectedRows]);

  useEffect(() => {
    if (assignExecutiveData?.message !== "") {
      setResponseType("error");
      setResponseMsg(assignExecutiveData?.message);
    } else if (assignExecutiveData?.assignExecutiveRes?.status !== undefined) {
      setResponseType("success");
      setResponseMsg("Task assign successfull");
      dispatch(getAllTaskListValue(page, 1));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assignExecutiveData]);

  const DataGridMarkup = (
    <Card>
      <CardHeader
        title={
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item>
              <Autocomplete
                id="templateId"
                options={executiveValues}
                onChange={(event: any, newValue: any) => {
                  handleSelectedExecutiveChange(event, newValue);
                }}
                renderInput={(params) => (
                  <TextField {...params} placeholder="Select Executive" />
                )}
                sx={{ width: 300 }}
                disableClearable
                getOptionLabel={(option) => option.label}
                disabled={isEdit}
                renderOption={(props, option) => {
                  return (
                    <li {...props} key={option.value}>
                      {option.label}
                    </li>
                  );
                }}
                size="small"
              />
            </Grid>
            <Grid item>
              <Grid
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
              >
                <Grid item>
                  <Typography sx={{ paddingTop: "5px" }}>
                    Page: {page}
                  </Typography>
                </Grid>
                <Grid item>
                  <Pagination
                    color="primary"
                    count={Number(pageCount)}
                    page={page}
                    onChange={handleChange}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        }
      ></CardHeader>
      <CardContent>
        <Box style={{ height: 600, width: "100%" }}>
          {taskValues && taskValues.length ? (
            <DataGrid
              rows={taskValues}
              columns={activeTaskListcolumns}
              pageSize={10}
              disableSelectionOnClick
              checkboxSelection
              hideFooter={true}
              onSelectionModelChange={(ids) => {
                const selectedIDs = new Set(ids);
                const selectedRows = taskValues.filter((row) =>
                  selectedIDs.has(row.id)
                );
                var result = selectedRows.map((element) => {
                  return element.id;
                });
                setSelectedRows(result);
              }}
            />
          ) : (
            <MWExceptionList />
          )}
        </Box>
      </CardContent>
    </Card>
  );

  return (
    <Box>
      {activeTaskLoader(
        AdminAllTaskList.loading || assignExecutiveData.loading
      )}
      {DataGridMarkup}
      {/* <PageLayout>{DataGridMarkup}</PageLayout> */}
      {responseMsg !== "" ? (
        <MWSnackbar
          msg={responseMsg}
          type={responseType}
          alertClose={alertCloseAction}
        />
      ) : null}
    </Box>
  );
}
