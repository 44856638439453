import {
  DOCTOR_INACTIVE_NOTE_LIST_FAIL,
  DOCTOR_INACTIVE_NOTE_LIST_LOADING,
  DOCTOR_INACTIVE_NOTE_LIST_SUCCESS,
  DOCTOR_INACTIVE_NOTE_LIST_API_MSG,
  DOCTOR_INACTIVE_NOTE_LIST_API_RES,
  DoctorInactiveNoteListAPIRes,
} from "./ActionTypes";
import { DoctorInactiveListResponse } from "./Model";

export const doctorInactiveListLoadingAction = (
  loading: boolean
): DoctorInactiveNoteListAPIRes => {
  return {
    type: DOCTOR_INACTIVE_NOTE_LIST_LOADING,
    loading: loading,
  };
};

export const doctorInactiveListSuccessAction = (
  doctorInactiveNoteListResponse: DoctorInactiveListResponse,
  successMsg: string
): DoctorInactiveNoteListAPIRes => {
  return {
    type: DOCTOR_INACTIVE_NOTE_LIST_SUCCESS,
    payload: doctorInactiveNoteListResponse,
    successMsg: successMsg,
  };
};

export const doctorInactiveListErrorAction = (
  doctorInactiveNoteListResponse: DoctorInactiveListResponse,
  errMsg: string,
  status: number
): DoctorInactiveNoteListAPIRes => {
  return {
    type: DOCTOR_INACTIVE_NOTE_LIST_FAIL,
    payload: doctorInactiveNoteListResponse,
    errorMsg: errMsg,
    status: status,
  };
};
export const doctorInactiveListAPIMsgAction = (
  doctorInactiveNoteListResponse: DoctorInactiveListResponse,
  successMsg: string,
  errMsg: string,
  status: number
): DoctorInactiveNoteListAPIRes => {
  return {
    type: DOCTOR_INACTIVE_NOTE_LIST_API_MSG,
    payload: doctorInactiveNoteListResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};

export const doctorInactiveListAPIResClearAction = (
  doctorInactiveNoteListResponse: DoctorInactiveListResponse,
  successMsg: string,
  errMsg: string,
  status: number
): DoctorInactiveNoteListAPIRes => {
  return {
    type: DOCTOR_INACTIVE_NOTE_LIST_API_RES,
    payload: doctorInactiveNoteListResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};