import {
  CASE_STATUS_LIST_FAIL,
  CASE_STATUS_LIST_LOADING,
  CASE_STATUS_LIST_SUCCESS,
  CASE_STATUS_LIST_UPDATE_API_MSG,
  CASE_STATUS_LIST_UPDATE_API_RES,
  GetCaseStatusListActionTypes,
} from "./ActionTypes";
import { GetCaseStatusListRes } from "./Model";

export const getCaseStatusListLoadingAction = (
  loading: boolean
): GetCaseStatusListActionTypes => {
  return {
    type: CASE_STATUS_LIST_LOADING,
    loading: loading,
  };
};

export const getCaseStatusListSuccessAction = (
  getCaseStatusListResponse: GetCaseStatusListRes
): GetCaseStatusListActionTypes => {
  return {
    type: CASE_STATUS_LIST_SUCCESS,
    payload: getCaseStatusListResponse,
  };
};

export const getCaseStatusListErrorAction = (
  getCaseStatusListResponse: GetCaseStatusListRes,
  errMsg: string,
  status: number
): GetCaseStatusListActionTypes => {
  return {
    type: CASE_STATUS_LIST_FAIL,
    payload: getCaseStatusListResponse,
    errorMsg: errMsg,
    status: status,
  };
};
export const getCaseStatusListUpdateAPIMsgAction = (
  getCaseStatusListResponse: GetCaseStatusListRes,
  errMsg: string,
  status: number
): GetCaseStatusListActionTypes => {
  return {
    type: CASE_STATUS_LIST_UPDATE_API_MSG,
    payload: getCaseStatusListResponse,
    errorMsg: errMsg,
    status: status,
  };
};

export const getCaseStatusListAPIResClearAction = (
  getCaseStatusListResponse: GetCaseStatusListRes,
  successMsg: string,
  errMsg: string,
  status: number
): GetCaseStatusListActionTypes => {
  return {
    type: CASE_STATUS_LIST_UPDATE_API_RES,
    payload: getCaseStatusListResponse,
    errorMsg: errMsg,
    status: status,
  };
};
