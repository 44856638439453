import {
    CREATE_INSTANT_MEETING_FAIL,
    CREATE_INSTANT_MEETING_LOADING,
    CREATE_INSTANT_MEETING_SUCCESS,
    CREATE_INSTANT_MEETING_UPDATE_API_MSG,
    CREATE_INSTANT_MEETING_UPDATE_API_RES,
    CreateInstantMeetingActionTypes,
 } from "./ActionTypes";
 import { CreateInstantMeetingValueRes } from "./Model";
 
 export const createInstantMeetingLoadingAction = (
   loading: boolean
 ):  CreateInstantMeetingActionTypes => {
   return {
     type:  CREATE_INSTANT_MEETING_LOADING,
     loading: loading,
   };
 };
 
 export const createInstantMeetingSuccessAction = (
   createInstantMeetingResponse: CreateInstantMeetingValueRes,
   successMsg: string
 ):  CreateInstantMeetingActionTypes => {
   return {
     type:  CREATE_INSTANT_MEETING_SUCCESS,
     payload: createInstantMeetingResponse,
     successMsg: successMsg,
   };
 };
 
 export const createInstantMeetingErrorAction = (
   createInstantMeetingResponse: CreateInstantMeetingValueRes,
   errMsg: string,
   status: number
 ):  CreateInstantMeetingActionTypes => {
   return {
     type:  CREATE_INSTANT_MEETING_FAIL,
     payload: createInstantMeetingResponse,
     errorMsg: errMsg,
     status: status,
   };
 };
 export const createInstantMeetingUpdateAPIMsgAction = (
   createInstantMeetingResponse: CreateInstantMeetingValueRes,
   successMsg: string,
   errMsg: string,
   status: number
 ):  CreateInstantMeetingActionTypes => {
   return {
     type:  CREATE_INSTANT_MEETING_UPDATE_API_MSG,
     payload: createInstantMeetingResponse,
     successMsg: successMsg,
     errorMsg: errMsg,
     status: status,
   };
 };
 
 export const createInstantMeetingAPIResClearAction = (
   createInstantMeetingResponse: CreateInstantMeetingValueRes,
   successMsg: string,
   errMsg: string,
   status: number
 ):  CreateInstantMeetingActionTypes => {
   return {
     type:  CREATE_INSTANT_MEETING_UPDATE_API_RES,
     payload: createInstantMeetingResponse,
     successMsg: successMsg,
     errorMsg: errMsg,
     status: status,
   };
 };
 