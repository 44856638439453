import {
  CONFIRM_APPOINTMENT_FAIL,
  CONFIRM_APPOINTMENT_LOADING,
  CONFIRM_APPOINTMENT_SUCCESS,
  CONFIRM_APPOINTMENT_UPDATE_API_MSG,
  CONFIRM_APPOINTMENT_UPDATE_API_RES,
  ConfirmAppointmentActionTypes,
} from "./ActionTypes";
import { ConfirmAppointmentRes } from "./Model";

export const confirmAppointmentLoadingAction = (
  loading: boolean
): ConfirmAppointmentActionTypes => {
  return {
    type: CONFIRM_APPOINTMENT_LOADING,
    loading: loading,
  };
};

export const confirmAppointmentSuccessAction = (
  confirmAppointmentResponse: ConfirmAppointmentRes,
  successMsg: string
): ConfirmAppointmentActionTypes => {
  console.log(
    "confirmAppointmentResponse",
    confirmAppointmentResponse
  );
  return {
    type: CONFIRM_APPOINTMENT_SUCCESS,
    payload: confirmAppointmentResponse,
    successMsg: successMsg,
  };
};

export const confirmAppointmentErrorAction = (
  confirmAppointmentResponse: ConfirmAppointmentRes,
  errMsg: string,
  status: number
): ConfirmAppointmentActionTypes => {
  return {
    type: CONFIRM_APPOINTMENT_FAIL,
    payload: confirmAppointmentResponse,
    errorMsg: errMsg,
    status: status,
  };
};
export const confirmAppointmentUpdateAPIMsgAction = (
  confirmAppointmentResponse: ConfirmAppointmentRes,
  successMsg: string,
  errMsg: string,
  status: number
): ConfirmAppointmentActionTypes => {
  return {
    type: CONFIRM_APPOINTMENT_UPDATE_API_MSG,
    payload: confirmAppointmentResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};

export const confirmAppointmentAPIResClearAction = (
  confirmAppointmentResponse: ConfirmAppointmentRes,
  successMsg: string,
  errMsg: string,
  status: number
): ConfirmAppointmentActionTypes => {
  return {
    type: CONFIRM_APPOINTMENT_UPDATE_API_RES,
    payload: confirmAppointmentResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};
