import {
  PatientDetailsActionType,
  PATIENT_DETAILS_FAIL,
  PATIENT_DETAILS_LOADING,
  PATIENT_DETAILS_SUCCESS,
  PatientDetailsState,
} from "./ActionTypes";
import { PatientDetailsById } from "./Model";

const initialStateGetPosts: PatientDetailsState = {
  loading: false,
  patientDetailsById: {} as PatientDetailsById,
};
export const getPatientDetailsByIdReducer = (
  state = initialStateGetPosts,
  action: PatientDetailsActionType
): PatientDetailsState => {
  switch (action.type) {
    case PATIENT_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        patientDetailsById: action.payload,
      };
    case PATIENT_DETAILS_LOADING:
      return {
        ...state,
        loading: true,
        patientDetailsById: {} as PatientDetailsById,
      };
    case PATIENT_DETAILS_FAIL:
      return {
        ...state,
        patientDetailsById: {} as PatientDetailsById,
        loading: false,
      };
    default:
      return state;
  }
};
