import React, { useEffect, useState } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { NavLink } from "react-router-dom";
import {
  AppBar,
  Avatar,
  Box,
  Divider,
  Drawer,
  Grid,
  Button,
  Popover,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
  Toolbar,
  Stack,
} from "@mui/material";
import { DrawerList } from "./component/DrawerList";
import ComponentRouter from "./Routes";
import medicalWISDOM from "./image/logo/appLogo.png";
import AdminLoader from "./pages/AdminLoader";
import { logOut } from "./redux/logOut/API";
import { LogOutBody } from "./redux/logOut/Model";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "./redux/store/Store";
import { GetAdminDetailsRes } from "./redux/jwtAdminLogIn/adminLoginDetails/Model";
import MWSelectLang from "./component/MWSelectLang";
import { useTranslation } from "react-i18next";

const drawerWidth = 240;

export default function App() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const loadingMarkup = isLoading ? <AdminLoader /> : null;
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const Bearer = localStorage.getItem("access");
  const refresh = localStorage.getItem("refresh");
  let userDetails = JSON.parse(
    localStorage.getItem("loginDetails")!
  ) as GetAdminDetailsRes;

  const logOutBody = {
    refresh_token: refresh!,
  } as LogOutBody;

  async function handleLogout() {
    dispatch(logOut(logOutBody));
  }

  const logOutRes = useSelector((state: AppState) => state.logOutRes);
  useEffect(() => {
    setIsLoading(logOutRes?.loading);
  }, [logOutRes]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Box sx={{ display: "flex" }}>
      <Router>
        {Bearer ? (
          <AppBar
            position="fixed"
            sx={{
              zIndex: (theme) => theme.zIndex.drawer + 1,
            }}
          >
            <Toolbar>
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                px={1}
              >
                <Grid item>
                  <img alt="" src={medicalWISDOM} height="42px" />
                </Grid>
                <Grid item>
                  <MWSelectLang isMenu />
                  <Button aria-describedby={id} onClick={handleClick}>
                    <Grid container direction="row" spacing={1}>
                      <Grid item>
                        <Avatar />
                      </Grid>
                      <Grid item>
                        <Stack
                          direction="column"
                          justifyContent="flex-start"
                          alignItems="flex-start"
                        >
                          <Typography variant="body2" noWrap>
                            {userDetails?.user?.first_name !== undefined &&
                            userDetails?.user?.last_name !== undefined
                              ? userDetails?.user?.first_name +
                                " " +
                                userDetails?.user?.last_name
                              : null}
                          </Typography>
                          <Typography
                            variant="caption"
                            noWrap
                            style={{
                              textTransform: "lowercase",
                            }}
                          >
                            {userDetails?.user?.email !== undefined
                              ? userDetails?.user?.email
                              : null}
                          </Typography>
                        </Stack>
                      </Grid>
                    </Grid>
                  </Button>
                  <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "right",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                  >
                    <List>
                      <ListItem component={NavLink} to="/admindetails">
                        <ListItemButton onClick={() => handleClose()}>
                          <ListItemText>
                            <Typography>{t(`common.userprofile`, { ns: ["home"] })}</Typography>
                          </ListItemText>
                        </ListItemButton>
                      </ListItem>
                    </List>
                  </Popover>
                </Grid>
              </Grid>
            </Toolbar>
          </AppBar>
        ) : null}

        {Bearer ? (
          <Drawer
            variant="permanent"
            sx={{
              width: drawerWidth,
              flexShrink: 0,
              [`& .MuiDrawer-paper`]: {
                width: drawerWidth,
                boxSizing: "border-box",
              },
            }}
          >
            <Stack
              direction="column"
              justifyContent="space-between"
              alignItems="flex-start"
              spacing={2}
              py={1}
              sx={{
                overflow: "auto",
                display: "flex",
                flexDirection: "column",
                height: "100%",
              }}
            >
              <Stack
                direction="column"
                justifyContent="flex-start"
                alignItems="stretch"
                style={{
                  width: "100%",
                }}
              >
                <Toolbar />
                <List
                  style={{
                    width: "100%",
                  }}
                  dense
                >
                  {DrawerList.slice(0, 1).map((item) => {
                    return (
                      <ListItem
                        component={NavLink}
                        to={item.path}
                        key={item.name}
                        disablePadding
                      >
                        <ListItemButton sx={{ py: 0, minHeight: 32 }}>
                          <ListItemIcon sx={{ minWidth: 40 }}>
                            {item.icon}
                          </ListItemIcon>
                          <ListItemText
                            primary={t(`common.${item.name}`, { ns: ["home"] })}
                            primaryTypographyProps={{
                              fontSize: 14,
                              fontWeight: "medium",
                            }}
                          >
                          </ListItemText>
                        </ListItemButton>
                      </ListItem>
                    );
                  })}
                  <Divider />
                  {DrawerList.slice(1, 17).map((item) => {
                    return (
                      <ListItem
                        component={NavLink}
                        activeClassName="Mui-selected"
                        to={item.path}
                        key={item.name}
                        disablePadding
                      >
                        <ListItemButton sx={{ py: 0, minHeight: 32 }}>
                          <ListItemIcon sx={{ minWidth: 40 }}>
                            {item.icon}
                          </ListItemIcon>
                          <ListItemText
                            primary={t(`common.${item.name}`, { ns: ["home"] })}
                            primaryTypographyProps={{
                              fontSize: 14,
                              fontWeight: "medium",
                            }}
                          >
                          </ListItemText>
                        </ListItemButton>
                      </ListItem>
                    );
                  })}
                </List>
              </Stack>
              <Box
                style={{
                  textDecoration: "none",
                  width: "100%",
                }}
              >
                <Divider />
                <List
                  dense
                  style={{
                    width: "100%",
                  }}
                >
                  {DrawerList.slice(-2, -1).map((item) => {
                    return (
                      <ListItem
                        component={NavLink}
                        activeClassName="Mui-selected"
                        to={item.path}
                        key={item.name}
                        disablePadding
                      >
                        <ListItemButton sx={{ py: 0, minHeight: 32 }}>
                          <ListItemIcon sx={{ minWidth: 40 }}>
                            {item.icon}
                          </ListItemIcon>
                          <ListItemText
                            primary={t(`common.${item.name}`, { ns: ["home"] })}
                            primaryTypographyProps={{
                              fontSize: 14,
                              fontWeight: "medium",
                            }}
                          >
                          </ListItemText>
                        </ListItemButton>
                      </ListItem>
                    );
                  })}
                  {DrawerList.slice(-1).map((item) => {
                    return (
                      <ListItem key={item.name} disablePadding>
                        <ListItemButton
                          onClick={() => handleLogout()}
                          sx={{ py: 0, minHeight: 32 }}
                        >
                          <ListItemIcon sx={{ minWidth: 40 }}>
                            {item.icon}
                          </ListItemIcon>
                          <ListItemText
                            primary={t(`common.${item.name}`, { ns: ["home"] })}
                            primaryTypographyProps={{
                              fontSize: 14,
                              fontWeight: "medium",
                            }}
                          ></ListItemText>
                        </ListItemButton>
                      </ListItem>
                    );
                  })}
                </List>
              </Box>
            </Stack>
          </Drawer>
        ) : null}
        <Box component="main" sx={{ flexGrow: 1 }}>
          {Bearer ? <Toolbar /> : null}
          {Bearer ? (
            <Box>
              {loadingMarkup}
              <ComponentRouter />
            </Box>
          ) : (
            <ComponentRouter />
          )}
        </Box>
      </Router>
    </Box>
  );
}
