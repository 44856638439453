import { DoctorInactiveListResponse } from "./Model";
export const DOCTOR_INACTIVE_NOTE_LIST_LOADING = "DOCTOR_INACTIVE_NOTE_LIST_LOADING";
export const DOCTOR_INACTIVE_NOTE_LIST_SUCCESS = "DOCTOR_INACTIVE_NOTE_LIST_SUCCESS";
export const DOCTOR_INACTIVE_NOTE_LIST_FAIL = "DOCTOR_INACTIVE_NOTE_LIST_FAIL";
export const DOCTOR_INACTIVE_NOTE_LIST_API_MSG = "DOCTOR_INACTIVE_NOTE_LIST_API_MSG";
export const DOCTOR_INACTIVE_NOTE_LIST_API_RES = "DOCTOR_INACTIVE_NOTE_LIST_API_RES";

export interface DoctorInactiveNoteListState {
  doctorInactiveNoteListRes: DoctorInactiveListResponse;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface DoctorInctiveNoteListLoading {
  type: typeof DOCTOR_INACTIVE_NOTE_LIST_LOADING;
  loading: boolean;
}
export interface DoctorInctiveNoteListSuccess {
  type: typeof DOCTOR_INACTIVE_NOTE_LIST_SUCCESS;
  payload: DoctorInactiveListResponse;
  successMsg: string;
}
export interface DoctorInactiveNoteListFail {
  type: typeof DOCTOR_INACTIVE_NOTE_LIST_FAIL;
  payload: DoctorInactiveListResponse;
  errorMsg: string;
  status: number;
}
export interface DoctorInactiveNoteListAPIMsg {
  type: typeof DOCTOR_INACTIVE_NOTE_LIST_API_MSG;
  payload: DoctorInactiveListResponse;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface DoctorInctiveNoteListsAPIRes {
  type: typeof DOCTOR_INACTIVE_NOTE_LIST_API_RES;
  payload: DoctorInactiveListResponse;
  successMsg: string;
  errorMsg: string;
  status: number;
}

export type DoctorInactiveNoteListAPIRes =
  | DoctorInctiveNoteListLoading
  | DoctorInctiveNoteListSuccess
  | DoctorInactiveNoteListFail
  | DoctorInactiveNoteListAPIMsg
  | DoctorInctiveNoteListsAPIRes;