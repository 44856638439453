import { EditDoctorTeamTypeListRes } from "./Model";

export const EDIT_DOCTOR_TEAM_TYPE_LOADING = "EDIT_DOCTOR_TEAM_TYPE_LOADING ";
export const EDIT_DOCTOR_TEAM_TYPE_SUCCESS = "EDIT_DOCTOR_TEAM_TYPE_SUCCESS";
export const EDIT_DOCTOR_TEAM_TYPE_FAIL = "EDIT_DOCTOR_TEAM_TYPE_FAIL";
export const EDIT_DOCTOR_TEAM_TYPE_UPDATE_API_MSG =
  "EDIT_DOCTOR_TEAM_TYPE_UPDATE_API_MSG";
export const EDIT_DOCTOR_TEAM_TYPE_UPDATE_API_RES =
  "EDIT_DOCTOR_TEAM_TYPE_UPDATE_API_RES";

export interface EditDoctorTeamTypeListState {
  editDoctorTeamTypeRes: EditDoctorTeamTypeListRes;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface EditDoctorTeamTypeListLoading {
  type: typeof EDIT_DOCTOR_TEAM_TYPE_LOADING;
  loading: boolean;
}
export interface EditDoctorTeamTypeListSuccess {
  type: typeof EDIT_DOCTOR_TEAM_TYPE_SUCCESS;
  payload: EditDoctorTeamTypeListRes;
  successMsg: string;
}
export interface EditDoctorTeamTypeListFail {
  type: typeof EDIT_DOCTOR_TEAM_TYPE_FAIL;
  payload: EditDoctorTeamTypeListRes;
  errorMsg: string;
  status: number;
}
export interface EditDoctorTeamTypeListUpdateAPIMsg {
  type: typeof EDIT_DOCTOR_TEAM_TYPE_UPDATE_API_MSG;
  payload: EditDoctorTeamTypeListRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface EditDoctorTeamTypeListUpdateAPIRes {
  type: typeof EDIT_DOCTOR_TEAM_TYPE_UPDATE_API_RES;
  payload: EditDoctorTeamTypeListRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}

export type EditDoctorTeamTypeListActionTypes =
  | EditDoctorTeamTypeListLoading
  | EditDoctorTeamTypeListSuccess
  | EditDoctorTeamTypeListFail
  | EditDoctorTeamTypeListUpdateAPIMsg
  | EditDoctorTeamTypeListUpdateAPIRes;
