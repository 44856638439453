import { AlertColor, Box, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { GetPatientDataDetailsBody } from "../../../../redux/FilePreparation/Questionnaire/Model";
import { getQuestionnaireList } from "../../../../redux/FilePreparation/Questionnaire/API";
import { useDispatch, useSelector } from "react-redux";
import Questionnaire from "./Questionnaire";
import DataList from "./DataList";
import { getPatientDataList } from "../../../../redux/FilePreparation/GetPatientDataList/API";
import PersonalHistory from "./PersonalHistory";
import { AppState } from "../../../../redux/store/Store";
import MWSnackbar from "../../../../component/MWSnackbar";
import {
  clearAddPatientDataAPIRes,
  updateAddPatientDataAPIResMsg,
} from "../../../../redux/FilePreparation/AddPatientDataList/API";
import {
  updateDeletePatientDataByIdAPIResMsg,
  clearDeletePatientDataByIdAPIRes,
} from "../../../../redux/FilePreparation/DeletePatientDataById/API";
import { t } from "i18next";
interface Props {
  type?: string;
  label?: string;
}
export default function FileData({ type, label }: Props) {
  const { caseid } = useParams() as {
    caseid: string;
  };

  const getPatientDataDetails = {
    caseId: caseid,
    secondOpinionUiSection: type,
  } as GetPatientDataDetailsBody;
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getQuestionnaireList(getPatientDataDetails));
    dispatch(getPatientDataList(getPatientDataDetails));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type]);
  const [addPatientDataSuccessMsg, setAddPatientDataSuccessMsg] = useState("");
  const [addPatientDataMsgType, setAddPatientDataMsgType] =
    useState<AlertColor>("success");
  const addPatientDataListValRes = useSelector(
    (state: AppState) => state.addPatientDataListRes
  );
  useEffect(() => {
    if (addPatientDataListValRes?.successMsg !== "") {
      setAddPatientDataSuccessMsg(addPatientDataListValRes?.successMsg);
      setAddPatientDataMsgType("success");
    }
  }, [addPatientDataListValRes]);

  const closeAddPatientDataMsg = (passedVal: boolean) => {
    if (passedVal) {
      dispatch(updateAddPatientDataAPIResMsg());
      dispatch(clearAddPatientDataAPIRes());
    }
  };
  const deletePatientDataListRes = useSelector(
    (state: AppState) => state.deletePatientDataByIdRes
  );
  const [deletePatientDataSuccessMsg, setDeletePatientDataSuccessMsg] =
    useState("");
  const [deletePatientDataMsgType, setDeletePatientDataMsgType] =
    useState<AlertColor>("success");
  useEffect(() => {
    if (deletePatientDataListRes?.successMsg !== "") {
      setDeletePatientDataSuccessMsg(deletePatientDataListRes?.successMsg);
      setDeletePatientDataMsgType("success");
    }
  }, [deletePatientDataListRes]);
  const closeDeletePatientDataMsg = (passedVal: boolean) => {
    if (passedVal) {
      dispatch(updateDeletePatientDataByIdAPIResMsg());
      dispatch(clearDeletePatientDataByIdAPIRes());
    }
  };
  return (
    <Box>
      <Stack
        direction="column"
        justifyContent="flex-start"
        alignItems="stretch"
        spacing={2}
      >
        <Typography variant="h5" fontWeight="bold">
        {t(`filePrepPage.${label}`, { ns: ["home"] })}
        </Typography>
        <Questionnaire />
        {type === "personalandSocialHistory" ? <PersonalHistory /> : null}
        <DataList label={label} type={type} />
      </Stack>
      {addPatientDataSuccessMsg !== "" ? (
        <MWSnackbar
          msg={addPatientDataSuccessMsg}
          type={addPatientDataMsgType}
          alertClose={closeAddPatientDataMsg}
        />
      ) : null}
      {deletePatientDataSuccessMsg !== "" ? (
        <MWSnackbar
          msg={deletePatientDataSuccessMsg}
          type={deletePatientDataMsgType}
          alertClose={closeDeletePatientDataMsg}
        />
      ) : null}
    </Box>
  );
}
