import React, { useEffect, useState } from "react";
import { Theme } from "@mui/material/styles";
import { WithStyles } from "@mui/styles";
import createStyles from "@mui/styles/createStyles";
import withStyles from "@mui/styles/withStyles";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import MuiDialogContent from "@mui/material/DialogContent";
import MuiDialogActions from "@mui/material/DialogActions";
import {
  AlertColor,
  Box,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import { AppState } from "../../../redux/store/Store";
import { getDoctorOnboardingIncompleteList } from "../../../redux/effects/ApiCall";
import MWTextField from "../../../component/MWTextField";
import { t } from "i18next";
import { addDrOnBoarding, clearAddDoctorAPIRes } from "../../../redux/AddDoctorApi/API";
import MWSnackbar from "../../../component/MWSnackbar";
const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: "16px",
    },
  });

type Props = {
  addDoctorDialogEnable?: boolean | any;
  addDoctorDialogUpdateState?: boolean | any;
};

export interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}

const DialogContent = withStyles((theme: Theme) => ({
  root: {
    padding: "16px",
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme: Theme) => ({
  root: {
    margin: 0,
    padding: "8px",
  },
}))(MuiDialogActions);

export default function AddDoctorDialog({
  addDoctorDialogEnable,
  addDoctorDialogUpdateState,
}: Props) {
  const dispatch = useDispatch();
  // Call Add Doctor store
  const AddDrLoadingValue = useSelector(
    (state: AppState) => state.addDoctorvalue
  );
  const [addDoctorMsgType, setAddDoctorMsgType] =
    useState<AlertColor>("success");
  const [addDoctorMsg, setAddDoctorMsg] = useState("");


  const [dialogState, setDialogState] = React.useState(false);
  const handleDialogClose = () => {
    setDialogState(false);
    addDoctorDialogUpdateState(false);
    dispatch(clearAddDoctorAPIRes());
  };
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");  

  useEffect(() => {
    if (AddDrLoadingValue?.successMsg !== "") {
      setAddDoctorMsg(AddDrLoadingValue?.successMsg);
      setAddDoctorMsgType("success");
      dispatch(getDoctorOnboardingIncompleteList());
    }
    if (AddDrLoadingValue?.errorMsg !== "") {
      setAddDoctorMsg(AddDrLoadingValue?.errorMsg);
      setAddDoctorMsgType("error");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [AddDrLoadingValue]);

  const [emailError, setEmailError] = useState("");
  const emailErrordiv = emailError ? (
    <Typography variant="body1">{emailError}</Typography>
  ) : (
    ""
  );

  const [firstNameError, setFirstNameError] = useState("");
  const firstNameErrordiv = firstNameError ? (
    <Typography variant="body1">{firstNameError}</Typography>
  ) : (
    ""
  );
  const [lastNameError, setLastNameError] = useState("");
  const lastNameErrordiv = lastNameError ? (
    <Typography variant="body1">{lastNameError}</Typography>
  ) : (
    ""
  );
  // Add Doctor Body
  const addDoctorBody = {
    email: email.toLowerCase(),
    first_name: firstName,
    last_name: lastName,
  };

  // Add patient eam API
  const addUser = () => {
    if (email === "" && firstName === "" && lastName === "") {
      setEmailError(`${t("common.pleaseEnterPhone", { ns: ["home"] })!}`);
      setFirstNameError(`${t("common.pleaseFirstName", { ns: ["home"] })!}`);
      setLastNameError(`${t("common.pleaseLastName", { ns: ["home"] })!}`);
    } else if (
      new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g).test(
        email.toLowerCase()
      ) === false &&
      new RegExp(/^[0]?[6789]\d{9}$/).test(email.toLowerCase()) === false
    ) {
      setEmailError(`${t("common.pleaseEnterPhone", { ns: ["home"] })!}`);
    } else if (email === "" && firstName === "" && lastName !== "") {
      setEmailError(`${t("common.pleaseEnterPhone", { ns: ["home"] })!}`);
      setFirstNameError(`${t("common.pleaseFirstName", { ns: ["home"] })!}`);
      setLastNameError("");
    } else if (email === "" && firstName !== "" && lastName === "") {
      setEmailError(`${t("common.pleaseEnterPhone", { ns: ["home"] })!}`);
      setFirstNameError("");
      setLastNameError(`${t("common.pleaseLastName", { ns: ["home"] })!}`);
    } else if (email === "" && firstName !== "" && lastName !== "") {
      setEmailError(`${t("common.pleaseEnterPhone", { ns: ["home"] })!}`);
      setFirstNameError("");
      setLastNameError("");
    } else if (email !== "" && firstName === "" && lastName === "") {
      setEmailError("");
      setFirstNameError(`${t("common.pleaseFirstName", { ns: ["home"] })!}`);
      setLastNameError(`${t("common.pleaseLastName", { ns: ["home"] })!}`);
    } else if (email !== "" && firstName === "" && lastName !== "") {
      setEmailError("");
      setFirstNameError(`${t("common.pleaseFirstName", { ns: ["home"] })!}`);
      setLastNameError("");
    } else if (email !== "" && firstName !== "" && lastName === "") {
      setEmailError("");
      setFirstNameError("");
      setLastNameError(`${t("common.pleaseLastName", { ns: ["home"] })!}`);
    } else {
      dispatch(addDrOnBoarding(addDoctorBody));
    }
  };

  React.useEffect(() => {
    setDialogState(addDoctorDialogEnable);
  }, [addDoctorDialogEnable]);

  const alertCloseAction = (alertState: boolean) => {
    if (alertState) {
      dispatch(clearAddDoctorAPIRes());
    }
  };

  return (
    <div>
      <Dialog
        onClose={() => null}
        aria-labelledby="customized-dialog-title"
        open={dialogState}
        disableEscapeKeyDown
        PaperProps={{
          style: {
            minHeight: "20vh",
            minWidth: "40vw",
            overflow: "hidden",
          },
        }}
      >
        <DialogTitle id="customized-dialog-title">
          <Typography variant="h5">
            {t("docOnboarding.addDoc", { ns: ["home"] })}
          </Typography>
          <IconButton
            aria-label="close"
            onClick={handleDialogClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: "grey",
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            spacing={2}
          >
            <Grid item xs={12}>
              <MWTextField
                label={t("docOnboarding.emailPhnNum", { ns: ["home"] })}
                value={email}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setEmail(event.target.value);
                }}
                placeholder={t("docOnboarding.pleaseEmailPhnNum", { ns: ["home"] })!}
                id="emailPhoneID"
                fullWidth
                size="small"
              />
              <Typography mt={1}>{emailErrordiv}</Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <MWTextField
                label={t("common.firstname", { ns: ["home"] })}
                value={firstName}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setFirstName(event.target.value);
                }}
                placeholder={t("docOnboarding.pleaseFstName", { ns: ["home"] })!}
                id="firstNameId"
                fullWidth
                size="small"
              />
              <Typography mt={1}>{firstNameErrordiv}</Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <MWTextField
                label={t("common.lastname", { ns: ["home"] })}
                value={lastName}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setLastName(event.target.value);
                }}
                placeholder={t("docOnboarding.pleaseLastName", { ns: ["home"] })!}
                id="lastNameId"
                fullWidth
                size="small"
              />
              <Typography mt={1}>{lastNameErrordiv}</Typography>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={addUser}
            variant="contained"
            color="primary"
            style={{ fontSize: "12px" }}
          >
            {t("Workschedulepage.submit", { ns: ["home"] })}
          </Button>
        </DialogActions>
        {addDoctorMsg !== "" ? (
          <Box my={2}>
            <MWSnackbar
              msg={addDoctorMsg}
              type={addDoctorMsgType}
              alertClose={alertCloseAction}
            />
          </Box>
        ) : null}
      </Dialog>
    </div>
  );
}
