import {
  GetInsuranceActionTypes,
  GetPatientDemographicsState,
  GET_INSURANCE_SUCCESS,
  GET_INSURANCE_LOADING,
  GET_INSURANCE_FAIL,
} from "./ActionTypes";
import { GetInsuranceResponse } from "./Model";

const initialStateGetPosts: GetPatientDemographicsState = {
  loading: false,
  getInsuranceDetails: {} as GetInsuranceResponse,
};
export const getInsuranceReducer = (
  state = initialStateGetPosts,
  action: GetInsuranceActionTypes
): GetPatientDemographicsState => {
  switch (action.type) {
    case GET_INSURANCE_SUCCESS:
      return {
        ...state,
        loading: false,
        getInsuranceDetails: action.payload,
      };
    case GET_INSURANCE_LOADING:
      return {
        ...state,
        loading: true,
        getInsuranceDetails: {} as GetInsuranceResponse,
      };
    case GET_INSURANCE_FAIL:
      return {
        ...state,
        getInsuranceDetails: {} as GetInsuranceResponse,
        loading: false,
      };
    default:
      return state;
  }
};
