import {
  UPDATE_NOTE_FAIL,
  UPDATE_NOTE_LOADING,
  UPDATE_NOTE_SUCCESS,
  UPDATE_NOTE_UPDATE_API_MSG,
  UPDATE_NOTE_UPDATE_API_RES,
  UpdateAdminNoteByPatientActionTypes,
} from "./ActionTypes";
import { UpdateAdminNoteByPatientRes } from "./Model";

export const updateAdminNoteByPatientLoadingAction = (
  loading: boolean
): UpdateAdminNoteByPatientActionTypes => {
  return {
    type: UPDATE_NOTE_LOADING,
    loading: loading,
  };
};

export const updateAdminNoteByPatientSuccessAction = (
  updateAdminNoteByPatientResponse: UpdateAdminNoteByPatientRes,
  successMsg: string
): UpdateAdminNoteByPatientActionTypes => {
  return {
    type: UPDATE_NOTE_SUCCESS,
    payload: updateAdminNoteByPatientResponse,
    successMsg: successMsg,
  };
};

export const updateAdminNoteByPatientErrorAction = (
  updateAdminNoteByPatientResponse: UpdateAdminNoteByPatientRes,
  errMsg: string,
  status: number
): UpdateAdminNoteByPatientActionTypes => {
  return {
    type: UPDATE_NOTE_FAIL,
    payload: updateAdminNoteByPatientResponse,
    errorMsg: errMsg,
    status: status,
  };
};
export const updateAdminNoteByPatientUpdateAPIMsgAction = (
  updateAdminNoteByPatientResponse: UpdateAdminNoteByPatientRes,
  successMsg: string,
  errMsg: string,
  status: number
): UpdateAdminNoteByPatientActionTypes => {
  return {
    type: UPDATE_NOTE_UPDATE_API_MSG,
    payload: updateAdminNoteByPatientResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};

export const updateAdminNoteByPatientAPIResClearAction = (
  updateAdminNoteByPatientResponse: UpdateAdminNoteByPatientRes,
  successMsg: string,
  errMsg: string,
  status: number
): UpdateAdminNoteByPatientActionTypes => {
  return {
    type: UPDATE_NOTE_UPDATE_API_RES,
    payload: updateAdminNoteByPatientResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};
