import React, { useEffect, useState } from "react";
import { Alert, Snackbar } from "@mui/material";

interface Props {
  message: string;
}

const MWToast: React.FC<Props> = ({ message }) => {
  const [messageValue, setMessage] = useState("");
  const [openValue, setOpen] = useState(false);

  const closeToast = () => {
    setOpen(true);
  };

  useEffect(() => {
    setMessage(message);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [message]);

  return (
    <Snackbar open={openValue} autoHideDuration={6000} onClose={closeToast}>        
      <Alert
        onClose={closeToast}
        sx={{ width: "100%" }}
      >
        {messageValue}
      </Alert>
    </Snackbar>
  );
};

export default MWToast;
