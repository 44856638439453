import {
  AddMasterConfigDetails,
  addMasterConfigActionTypes,
  ADD_MASTER_CONFIG_FAIL,
  ADD_MASTER_CONFIG_LOADING,
  ADD_MASTER_CONFIG_SUCCESS,
} from "./ActionTypes";
import { AddMasterConfigValue } from "./Model";

const initialStateGetPosts: AddMasterConfigDetails = {
  loading: false,
  addMasterConfigRes: {} as AddMasterConfigValue,
  errRes: "",
};
export const addMasterConfigReducer = (
  state = initialStateGetPosts,
  action: addMasterConfigActionTypes
): AddMasterConfigDetails => {
  switch (action.type) {
    case ADD_MASTER_CONFIG_SUCCESS:
      return {
        ...state,
        loading: false,
        addMasterConfigRes: action.payload,
        errRes: "",
      };
    case ADD_MASTER_CONFIG_LOADING:
      return {
        ...state,
        loading: true,
        addMasterConfigRes: {} as AddMasterConfigValue,
      };
    case ADD_MASTER_CONFIG_FAIL:
      return {
        ...state,
        addMasterConfigRes: {} as AddMasterConfigValue,
        loading: false,
        errRes: action.errRes,
      };
    default:
      return state;
  }
};
