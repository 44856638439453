import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../../redux/store/Store";
import { InActiveCorporatePatientList } from "../../../../redux/interfaces/Model";
import * as _ from "lodash";
import { getActiveCorporatePatinetList } from "../../../../redux/effects/ApiCall";
import { CorporatePackageList } from "../../../../redux/CorporatePackage/Package/Model";
import { getCorporatePackageList } from "../../../../redux/CorporatePackage/Package/ApiCall";
import { getCorporatePackagePaymentList } from "../../../../redux/CorporatePackage/Payment/ApiCall";
import PaymentIcon from "@mui/icons-material/Payment";
import RefreshIcon from "@mui/icons-material/Refresh";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import MWExceptionList from "../../../../component/MWExceptionList";
import { t } from "i18next";
export default function InActivePatientTeamPaymentDetails(props: any) {
  const { inActivePatientTeamPaymentDetailsLoader } = props;
  const { packageId } = useParams() as {
    packageId: string;
  };
  const { profileId } = useParams() as {
    profileId: string;
  };

  const dispatch = useDispatch();

  const DATE_OPTIONS = {
    weekday: "short",
    year: "numeric",
    month: "short",
    day: "numeric",
  } as const;

  // call Inactive Patient List Store
  const inActiveCorporatePatientList = useSelector(
    (state: AppState) => state.inactiveCorporatePatientValue
  );
  const inActiveCorporatePatientListValues: InActiveCorporatePatientList =
    _.filter(inActiveCorporatePatientList.inActiveCorporatePatientListValue, [
      "profileId",
      Number(profileId),
    ])[0];

  // call Inactive Package List Store

  const inActiveCorporatePackageList = useSelector(
    (state: AppState) => state.corporatePackageValue
  );
  const inActiveCorporateListDetails: CorporatePackageList = _.filter(
    inActiveCorporatePackageList.corporatePackageListValue,
    ["id", Number(packageId)]
  )[0];

  // call Patient list Store
  const patientPaymentListLoadingValue = useSelector(
    (state: AppState) => state.patientteamPatientPaymentValue.loading
  );
  const patientPaymentList = useSelector(
    (state: AppState) => state.patientteamPatientPaymentValue.paymentListValue
  );

  let patientPaymentConfig = [];
  for (var i = 0; i < patientPaymentList.length; i++) {
    let create_date = patientPaymentList[i].hasOwnProperty("create_date")
      ? patientPaymentList[i].create_date == null
        ? ""
        : new Date(patientPaymentList[i].create_date).toLocaleDateString(
            "en-US",
            DATE_OPTIONS
          ) +
          " at " +
          new Date(patientPaymentList[i].create_date).getHours() +
          ":" +
          new Date(patientPaymentList[i].create_date).getMinutes()
      : "";
    let paidTime = patientPaymentList[i].hasOwnProperty("paidTime")
      ? patientPaymentList[i].paidTime == null
        ? ""
        : new Date(patientPaymentList[i].paidTime).toLocaleDateString(
            "en-US",
            DATE_OPTIONS
          ) +
          " at " +
          new Date(patientPaymentList[i].paidTime).getHours() +
          ":" +
          new Date(patientPaymentList[i].paidTime).getMinutes()
      : "";
    let amount =
      patientPaymentList[i].amount === null ||
      patientPaymentList[i].amount === ""
        ? ""
        : patientPaymentList[i].amount;
    let note =
      patientPaymentList[i].note === null || patientPaymentList[i].note === ""
        ? ""
        : patientPaymentList[i].note;
    let paymentLink =
      patientPaymentList[i].paymentLink === null ||
      patientPaymentList[i].paymentLink === ""
        ? ""
        : patientPaymentList[i].paymentLink;
    let status =
      patientPaymentList[i].status === null ||
      patientPaymentList[i].status === ""
        ? ""
        : patientPaymentList[i].status;
    patientPaymentConfig.push([
      <Button
        variant="outlined"
        onClick={() => paymentLink(paymentLink)}
        startIcon={<PaymentIcon />}
        disabled={status === "paid" || status === "free" ? true : false}
      >
        {t("payment.payNow", { ns: ["home"] })}
      </Button>,
      amount,
      note,
      status,
      create_date,
      paidTime,
    ]);
  }
  const patientPaymentListTableValue: any[] = patientPaymentConfig;

  const corporatePackageTeamPatientPaymentBody = {
    packageId: Number(packageId),
    profileId: Number(profileId),
  };
  const reloadPatientPaymentList = () => {
    dispatch(
      getCorporatePackagePaymentList(corporatePackageTeamPatientPaymentBody)
    );
  };
  const corporatePackageBody = {
    active: Number(0),
  };
  useEffect(() => {
    dispatch(
      getCorporatePackagePaymentList(corporatePackageTeamPatientPaymentBody)
    );
    if (
      inActiveCorporateListDetails === undefined &&
      inActiveCorporatePatientListValues === undefined
    ) {
      dispatch(getCorporatePackageList(corporatePackageBody));
      dispatch(getActiveCorporatePatinetList(packageId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dispatch,
    inActiveCorporateListDetails,
    inActiveCorporatePatientListValues,
  ]);

  const actualPageMarkup = (
    <Card>
      <CardHeader title={t("payment.patientPayDetails", { ns: ["home"] })}>
        <Grid
          container
          direction="row"
          justifyContent="flex-end"
          alignItems="flex-end"
        >
          <Grid item>
            <Button
              variant="outlined"
              startIcon={<RefreshIcon />}
              onClick={() => reloadPatientPaymentList()}
              sx={{ textTransform: "none" }}
            >
              {t("common.reload", { ns: ["home"] })}
            </Button>
          </Grid>
        </Grid>
      </CardHeader>
      <CardContent>
        {patientPaymentListTableValue && patientPaymentListTableValue.length ? (
          <TableContainer>
            <Table size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <TableCell>
                    {t("payment.paymentLink", { ns: ["home"] })}
                  </TableCell>
                  <TableCell>{t("payment.amt", { ns: ["home"] })}</TableCell>
                  <TableCell>{t("common.note", { ns: ["home"] })}</TableCell>
                  <TableCell>
                    {t("appointmentpage.status", { ns: ["home"] })}
                  </TableCell>
                  <TableCell>
                    {t("appointmentpage.createdate", { ns: ["home"] })}
                  </TableCell>
                  <TableCell>
                    {t("payment.paidDate", { ns: ["home"] })}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {patientPaymentListTableValue.map((row: any) => (
                  <TableRow
                    key={row.description}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    <TableCell align="left">{row[0]}</TableCell>
                    <TableCell align="left">{row[1]}</TableCell>
                    <TableCell align="left">{row[2]}</TableCell>
                    <TableCell align="left">{row[3]}</TableCell>
                    <TableCell align="left">{row[4]}</TableCell>
                    <TableCell align="left">{row[5]}</TableCell>
                    <TableCell align="left">{row[6]}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <MWExceptionList />
        )}
      </CardContent>
    </Card>
  );

  return (
    <Box>
      {inActiveCorporatePatientListValues === undefined ||
      inActiveCorporateListDetails === undefined ||
      patientPaymentListLoadingValue === true
        ? inActivePatientTeamPaymentDetailsLoader(true)
        : inActivePatientTeamPaymentDetailsLoader(false)}
      <Container maxWidth="xl">{actualPageMarkup}</Container>
    </Box>
  );
}
