import { AddDoctorForCaseRes } from "./Model";
export const ADD_DOCTOR_FOR_CASE_LOADING = "ADD_DOCTOR_FOR_CASE_LOADING";
export const ADD_DOCTOR_FOR_CASE_SUCCESS = "ADD_DOCTOR_FOR_CASE_SUCCESS";
export const ADD_DOCTOR_FOR_CASE_FAIL = "ADD_DOCTOR_FOR_CASE_FAIL";
export const ADD_DOCTOR_FOR_CASE_UPDATE_API_MSG =
  "ADD_DOCTOR_FOR_CASE_UPDATE_API_MSG";
export const ADD_DOCTOR_FOR_CASE_UPDATE_API_RES =
  "ADD_DOCTOR_FOR_CASE_UPDATE_API_RES";

export interface AddDoctorForCaseState {
  addDoctorForCaseRes: AddDoctorForCaseRes;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface AddDoctorForCaseLoading {
  type: typeof ADD_DOCTOR_FOR_CASE_LOADING;
  loading: boolean;
}
export interface AddDoctorForCaseSuccess {
  type: typeof ADD_DOCTOR_FOR_CASE_SUCCESS;
  payload: AddDoctorForCaseRes;
  successMsg: string;
}
export interface AddDoctorForCaseFail {
  type: typeof ADD_DOCTOR_FOR_CASE_FAIL;
  payload: AddDoctorForCaseRes;
  errorMsg: string;
  status: number;
}
export interface AddDoctorForCaseUpdateAPIMsg {
  type: typeof ADD_DOCTOR_FOR_CASE_UPDATE_API_MSG;
  payload: AddDoctorForCaseRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface AddDoctorForCaseUpdateAPIRes {
  type: typeof ADD_DOCTOR_FOR_CASE_UPDATE_API_RES;
  payload: AddDoctorForCaseRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}

export type AddDoctorForCaseActionTypes =
  | AddDoctorForCaseLoading
  | AddDoctorForCaseSuccess
  | AddDoctorForCaseFail
  | AddDoctorForCaseUpdateAPIMsg
  | AddDoctorForCaseUpdateAPIRes;
