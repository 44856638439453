import { AddPatientCaseResponse, ConsultationTypeListData, EditPatientCaseResponse, Message, OpinionTypeListData, 
  PatientCaseDetailsResponse, ResponseTimeResponse, SecondOpinionListData, Status, ResponseTimeErrorResponse } from "./Model";

export const OPINIONTYPELIST_LOADING = "OPINIONTYPELIST_LOADING";
export const OPINIONTYPELIST_SUCCESS = "OPINIONTYPELIST_SUCCESS";
export const OPINIONTYPELIST_FAIL = "OPINIONTYPELIST_FAIL";
export const OPINIONTYPELIST_LIST = "OPINIONTYPELIST_LIST";

// Get opinion type list
export interface OpinionTypeListStateType {
  opinionTypeListValue: OpinionTypeListData[];
  loading: boolean;
  message: Message;
  status: Status;
}

export interface OpinionTypeListLoading {
  type: typeof OPINIONTYPELIST_LOADING;
}
export interface OpinionTypeListSuccess {
  type: typeof OPINIONTYPELIST_SUCCESS;
  payload: OpinionTypeListData[];
  message: Message;
  status: Status;
}
export interface OpinionTypeListFail {
  type: typeof OPINIONTYPELIST_FAIL;
  message: Message;
  status: Status;
}
interface OpinionTypeListActionType {
  type: typeof OPINIONTYPELIST_LIST;
  payload: OpinionTypeListData[];
  message: Message;
  status: Status;
}
export type AdminGetOpinionTypeListActionTypes = OpinionTypeListActionType | OpinionTypeListLoading | OpinionTypeListSuccess | OpinionTypeListFail;

// Get get 2nd opinion list..

export const SECOND_OPINION_LIST_LOADING = "SECOND_OPINION_LIST_LOADING";
export const SECOND_OPINION_LIST_SUCCESS = "SECOND_OPINION_LIST_SUCCESS";
export const SECOND_OPINION_LIST_FAIL = "SECOND_OPINION_LIST_FAIL";
export const SECOND_OPINION_LIST = "SECOND_OPINION_LIST";

export interface SecondOpinionListStateType {
  secondOpinionListValue: SecondOpinionListData[];
  loading: boolean;
  message: Message;
  status: Status;
}

export interface SecondOpinionListLoading {
  type: typeof SECOND_OPINION_LIST_LOADING;
}
export interface SecondOpinionListSuccess {
  type: typeof SECOND_OPINION_LIST_SUCCESS;
  payload: SecondOpinionListData[];
  message: Message;
  status: Status;
}
export interface SecondOpinionListFail {
  type: typeof SECOND_OPINION_LIST_FAIL;
  message: Message;
  status: Status;
}
interface SecondOpinionListActionType {
  type: typeof SECOND_OPINION_LIST;
  payload: SecondOpinionListData[];
  message: Message;
  status: Status;
}
export type AdminGetSecondOpinionListActionTypes = SecondOpinionListActionType | SecondOpinionListLoading | SecondOpinionListSuccess | SecondOpinionListFail;

// for Get consultation type ..

export const CONSULTATION_TYPE_LIST_LOADING = "CONSULTATION_TYPE_LIST_LOADING";
export const CONSULTATION_TYPE_LIST_SUCCESS = "CONSULTATION_TYPE_LIST_SUCCESS";
export const CONSULTATION_TYPE_LIST_FAIL = "CONSULTATION_TYPE_LIST_FAIL";
export const CONSULTATION_TYPE_LIST = "CONSULTATION_TYPE_LIST";

export interface ConsultationTypeListStateType {
  consultationTypeListValue: ConsultationTypeListData[];
  loading: boolean;
  message: Message;
  status: Status;
}

export interface ConsultationTypeListLoading {
  type: typeof CONSULTATION_TYPE_LIST_LOADING;
}
export interface ConsultationTypeListSuccess {
  type: typeof CONSULTATION_TYPE_LIST_SUCCESS;
  payload: ConsultationTypeListData[];
  message: Message;
  status: Status;
}
export interface ConsultationTypeListFail {
  type: typeof CONSULTATION_TYPE_LIST_FAIL;
  message: Message;
  status: Status;
}
interface ConsultationTypeListActionType {
  type: typeof CONSULTATION_TYPE_LIST;
  payload: ConsultationTypeListData[];
  message: Message;
  status: Status;
}
export type AdminGetConsultationTypeListActionTypes = ConsultationTypeListActionType | ConsultationTypeListLoading | ConsultationTypeListSuccess | ConsultationTypeListFail;

// for  response time ..
export const RESPONSE_TIME_LOADING = "RESPONSE_TIME_LOADING";
export const RESPONSE_TIME_SUCCESS = "RESPONSE_TIME_SUCCESS";
export const RESPONSE_TIME_FAIL = "RESPONSE_TIME_FAIL";
export const RESPONSE_TIME_STATUS = 'RESPONSE_TIME_STATUS';

export interface ResponseTimeStateType {
  loading: boolean;
  responseTimeTypeList: ResponseTimeResponse[];
}
export interface ResponseTimeLoading {
  type: typeof RESPONSE_TIME_LOADING;
}
export interface ResponseTimeSuccess {
  type: typeof RESPONSE_TIME_SUCCESS;
  payload: ResponseTimeResponse[];
}
export interface ResponseTimeFail {
  type: typeof RESPONSE_TIME_FAIL;
  payload: ResponseTimeErrorResponse;
}

interface ResponseTimeActionType {
  type: typeof RESPONSE_TIME_STATUS;
  payload: ResponseTimeResponse[];
}
export type ResponseTimeActionTypes = ResponseTimeActionType | ResponseTimeLoading | ResponseTimeSuccess | ResponseTimeFail;

// add patient case .. 
export const ADD_PATIENT_CASE_LOADING = "ADD_PATIENT_CASE_LOADING";
export const ADD_PATIENT_CASE_SUCCESS = "ADD_PATIENT_CASE_SUCCESS";
export const ADD_PATIENT_CASE_FAIL = "ADD_PATIENT_CASE_FAIL";
export const ADD_PATIENT_CASE_STATUS = 'ADD_PATIENT_CASE_STATUS';

export interface AddPatientCaseStateType {
  loading: boolean;
  response: AddPatientCaseResponse;
}
export interface AddPatientCaseLoading {
  type: typeof ADD_PATIENT_CASE_LOADING;
}
export interface AddPatientCaseSuccess {
  type: typeof ADD_PATIENT_CASE_SUCCESS;
  payload: AddPatientCaseResponse;
}
export interface AddPatientCaseFail {
  type: typeof ADD_PATIENT_CASE_FAIL;
  payload: AddPatientCaseResponse;
}

interface AddPatientCaseActionType {
  type: typeof ADD_PATIENT_CASE_STATUS;
  payload: AddPatientCaseResponse;
}
export type AddPatientCaseActionTypes = AddPatientCaseActionType | AddPatientCaseLoading | AddPatientCaseSuccess | AddPatientCaseFail;

// edit patient case .. 
export const EDIT_PATIENT_CASE_LOADING = "EDIT_PATIENT_CASE_LOADING";
export const EDIT_PATIENT_CASE_SUCCESS = "EDIT_PATIENT_CASE_SUCCESS";
export const EDIT_PATIENT_CASE_FAIL = "EDIT_PATIENT_CASE_FAIL";
export const EDIT_PATIENT_CASE_STATUS = 'EDIT_PATIENT_CASE_STATUS';

export interface EditPatientCaseStateType {
  loading: boolean;
  response: EditPatientCaseResponse;
}
export interface EditPatientCaseLoading {
  type: typeof EDIT_PATIENT_CASE_LOADING;
}
export interface EditPatientCaseSuccess {
  type: typeof EDIT_PATIENT_CASE_SUCCESS;
  payload: EditPatientCaseResponse;
}
export interface EditPatientCaseFail {
  type: typeof EDIT_PATIENT_CASE_FAIL;
  payload: EditPatientCaseResponse;
}

interface EditPatientCaseActionType {
  type: typeof EDIT_PATIENT_CASE_STATUS;
  payload: EditPatientCaseResponse;
}
export type EditPatientCaseActionTypes = EditPatientCaseActionType | EditPatientCaseLoading | EditPatientCaseSuccess | EditPatientCaseFail;

//  patient case DETAILS.. 
export const PATIENT_CASE_DETAILS_LOADING = "PATIENT_CASE_DETAILS_LOADING";
export const PATIENT_CASE_DETAILS_SUCCESS = "PATIENT_CASE_DETAILS_SUCCESS";
export const PATIENT_CASE_DETAILS_FAIL = "PATIENT_CASE_DETAILS_FAIL";
export const PATIENT_CASE_DETAILS_STATUS = 'PATIENT_CASE_DETAILS_STATUS';

export interface PatientCaseDetailsStateType {
  loading: boolean;
  patientCaseDetail: PatientCaseDetailsResponse;
  status: number;
}
export interface PatientCaseDetailsLoading {
  type: typeof PATIENT_CASE_DETAILS_LOADING;
}
export interface PatientCaseDetailsSuccess {
  type: typeof PATIENT_CASE_DETAILS_SUCCESS;
  patientCaseDetail: PatientCaseDetailsResponse;
  status: number;
}
export interface PatientCaseDetailsFail {
  type: typeof PATIENT_CASE_DETAILS_FAIL;
  patientCaseDetail: PatientCaseDetailsResponse;
  status: number;
}

interface PatientCaseDetailsActionType {
  type: typeof PATIENT_CASE_DETAILS_STATUS;
  patientCaseDetail: PatientCaseDetailsResponse;
  status: number;
}
export type PatientCaseDetailsActionTypes = PatientCaseDetailsActionType | PatientCaseDetailsLoading | PatientCaseDetailsSuccess | PatientCaseDetailsFail;