import { ExtractObservationDetailsRes } from "./Model";
export const EXTRACT_OBSERVATION_DETAILS_LOADING = "EXTRACT_OBSERVATION_DETAILS_LOADING";
export const EXTRACT_OBSERVATION_DETAILS_SUCCESS = "EXTRACT_OBSERVATION_DETAILS_SUCCESS";
export const EXTRACT_OBSERVATION_DETAILS_FAIL = "EXTRACT_OBSERVATION_DETAILS_FAIL";
export const EXTRACT_OBSERVATION_DETAILS_DATA = "EXTRACT_OBSERVATION_DETAILS_DATA";
export const EXTRACT_OBSERVATION_DETAILS_API_MSG =
  "EXTRACT_OBSERVATION_DETAILS_API_MSG";
export const EXTRACT_OBSERVATION_DETAILS_API_RES =
  "EXTRACT_OBSERVATION_DETAILS_API_RES";

export interface ExtractObservationDetailsState {
  extractObservationDetailsRes: ExtractObservationDetailsRes;
  loading: boolean;
  errorMsg: string;
  status: number;
}
export interface ExtractObservationDetailsLoading {
  type: typeof EXTRACT_OBSERVATION_DETAILS_LOADING;
  loading: boolean;
}
export interface ExtractObservationDetailsSuccess {
  type: typeof EXTRACT_OBSERVATION_DETAILS_SUCCESS;
  payload: ExtractObservationDetailsRes;
}
export interface ExtractObservationDetailsFail {
  type: typeof EXTRACT_OBSERVATION_DETAILS_FAIL;
  payload: ExtractObservationDetailsRes;
  errorMsg: string;
  status: number;
}
export interface ExtractObservationDetailsAPIMsg {
  type: typeof EXTRACT_OBSERVATION_DETAILS_API_MSG;
  payload: ExtractObservationDetailsRes;
  errorMsg: string;
  status: number;
}
export interface ExtractObservationDetailsAPIRes {
  type: typeof EXTRACT_OBSERVATION_DETAILS_API_RES;
  payload: ExtractObservationDetailsRes;
  errorMsg: string;
  status: number;
}

export type ExtractObservationDetailsActionTypes =
  | ExtractObservationDetailsLoading
  | ExtractObservationDetailsSuccess
  | ExtractObservationDetailsFail
  | ExtractObservationDetailsAPIMsg
  | ExtractObservationDetailsAPIRes;
