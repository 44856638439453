import { GetInactiveCaseListRes, PageCount } from "./Model";
export const GET_INACTIVE_CASE_LIST_LOADING =
  "GET_INACTIVE_CASE_LIST_LOADING";
export const GET_INACTIVE_CASE_LIST_SUCCESS =
  "GET_INACTIVE_CASE_LIST_SUCCESS";
export const GET_INACTIVE_CASE_LIST_FAIL =
  "GET_INACTIVE_CASE_LIST_FAIL";
export const GET_INACTIVE_CASE_LIST_DATA = "GET_INACTIVE_CASE_LIST_DATA";
export const GET_INACTIVE_CASE_LIST_API_MSG =
  "GET_INACTIVE_CASE_LIST_API_MSG";
export const GET_INACTIVE_CASE_LIST_API_RES =
  "GET_INACTIVE_CASE_LIST_API_RES";

export interface GetInactiveCaseListState {
  getInactiveCaseListRes: GetInactiveCaseListRes;
  loading: boolean;
  errorMsg: string;
  status: number;
}
export interface GetInactiveCaseListLoading {
  type: typeof GET_INACTIVE_CASE_LIST_LOADING;
  loading: boolean;
}
export interface GetInactiveCaseListSuccess {
  type: typeof GET_INACTIVE_CASE_LIST_SUCCESS;
  payload: GetInactiveCaseListRes;
}
export interface GetInactiveCaseListFail {
  type: typeof GET_INACTIVE_CASE_LIST_FAIL;
  payload: GetInactiveCaseListRes;
  errorMsg: string;
  status: number;
}
export interface GetInactiveCaseListAPIMsg {
  type: typeof GET_INACTIVE_CASE_LIST_API_MSG;
  payload: GetInactiveCaseListRes;
  errorMsg: string;
  status: number;
}
export interface GetInactiveCaseListAPIRes {
  type: typeof GET_INACTIVE_CASE_LIST_API_RES;
  payload: GetInactiveCaseListRes;
  errorMsg: string;
  status: number;
}

interface GetInactiveCaseListDataAction {
  type: typeof GET_INACTIVE_CASE_LIST_DATA;
  payload: GetInactiveCaseListRes;
  pageCount: PageCount;
}

export type GetInactiveCaseListActionTypes =
  | GetInactiveCaseListLoading
  | GetInactiveCaseListSuccess
  | GetInactiveCaseListFail
  | GetInactiveCaseListAPIMsg
  | GetInactiveCaseListDataAction
  | GetInactiveCaseListAPIRes;
