import { GetStepsDetailsRes } from "./Model";
export const GET_STEPS_DETAILS_LOADING = "GET_STEPS_DETAILS_LOADING";
export const GET_STEPS_DETAILS_SUCCESS = "GET_STEPS_DETAILS_SUCCESS";
export const GET_STEPS_DETAILS_FAIL = "GET_STEPS_DETAILS_FAIL";
export const GET_STEPS_DETAILS_API_MSG = "GET_STEPS_DETAILS_API_MSG";
export const GET_STEPS_DETAILS_API_RES = "GET_STEPS_DETAILS_API_RES";

export interface GetStepsDetailsState {
  getStepsDetailsRes: GetStepsDetailsRes;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface GetStepsDetailsLoading {
  type: typeof GET_STEPS_DETAILS_LOADING;
  loading: boolean;
}
export interface GetStepsDetailsSuccess {
  type: typeof GET_STEPS_DETAILS_SUCCESS;
  payload: GetStepsDetailsRes;
  successMsg: string;
}
export interface GetStepsDetailsFail {
  type: typeof GET_STEPS_DETAILS_FAIL;
  payload: GetStepsDetailsRes;
  errorMsg: string;
  status: number;
}
export interface GetStepsDetailsAPIMsg {
  type: typeof GET_STEPS_DETAILS_API_MSG;
  payload: GetStepsDetailsRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface GetStepsDetailsAPIRes {
  type: typeof GET_STEPS_DETAILS_API_RES;
  payload: GetStepsDetailsRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}

export type GetStepsDetailsActionTypes =
  | GetStepsDetailsLoading
  | GetStepsDetailsSuccess
  | GetStepsDetailsFail
  | GetStepsDetailsAPIMsg
  | GetStepsDetailsAPIRes;
