import { AdminGetQuestionListActionTypes, AnswerStateType, AnswerTypes, ANSWER_FAIL, ANSWER_LOADING, ANSWER_SUCCESS, GetQuestionListStateType, QuestionVerifyStateType, QUESTION_LIST_FAIL, QUESTION_LIST_LOADING, QUESTION_LIST_SUCCESS, QUESTION_VERIFY_FAIL, QUESTION_VERIFY_LOADING, QUESTION_VERIFY_SUCCESS, verifyActionTypes,
} from "./ActionTypes";
const initialStateGetQuestionlist: GetQuestionListStateType = { loading: false, questionListValue: [], };

// Get Question List Reducer
export const getQuestionListReducer = (state = initialStateGetQuestionlist, action: AdminGetQuestionListActionTypes): GetQuestionListStateType => {
  switch (action.type) {
    case QUESTION_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        questionListValue: action.payload
      };
    case QUESTION_LIST_LOADING:
      return {
        ...state,
        loading: true,
        questionListValue: []
      };
    case QUESTION_LIST_FAIL:
      return {
        ...state,
        questionListValue: [],
        loading: false
      };
    default:
      return state;
  }
};

const initialStateQuestionAnswer: AnswerStateType = { loading: false, payload: Object.assign({}) };
// Add Question Answer Reducer
export const addAnswer = (state = initialStateQuestionAnswer, action: AnswerTypes): AnswerStateType => {
  switch (action.type) {
    case ANSWER_SUCCESS:
      return {
        ...state,
        loading: false,
        payload: action.payload,
      };
    case ANSWER_LOADING:
      return {
        ...state,
        loading: true,
      };
    case ANSWER_FAIL:
      return {
        ...state,
        loading: false,
        payload:action.payload,
      };
    default:
      return state;
  }
};

const initialStateQuestionVerifyAnswer: QuestionVerifyStateType = { loading: false, payload: Object.assign({}) };
// Add Question Answer Reducer
export const questionverifyReducer = (state = initialStateQuestionVerifyAnswer, action: verifyActionTypes): QuestionVerifyStateType => {
  switch (action.type) {
    case QUESTION_VERIFY_SUCCESS:
      return {
        ...state,
        loading: false,
        payload: action.payload,
      };
    case QUESTION_VERIFY_LOADING:
      return {
        ...state,
        loading: true,
      };
    case QUESTION_VERIFY_FAIL:
      return {
        ...state,
        loading: false,
        payload: action.payload,
      };
    default:
      return state;
  }
};