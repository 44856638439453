import {
  AlertColor,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../../redux/store/Store";
import {
  GetPaymentListBody,
  PaymentList as AppointmentPaymentList,
} from "../../../../redux/Patient/AppointmentPaymentList/Model";
import dayjs from "dayjs";
import { ModifiedPaymentList } from "./Model";
import {
  DataGrid,
  GridCellParams,
  GridColDef,
  GridToolbar,
} from "@mui/x-data-grid";
import MWExceptionList from "../../../../component/MWExceptionList";
import { useParams } from "react-router-dom";
import { getAppointmentPaymentList } from "../../../../redux/Patient/AppointmentPaymentList/API";
import PaymentsIcon from "@mui/icons-material/Payments";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { CheckPaymentStatusBody } from "../../../../redux/Patient/AppointmentPaymentList/CheckPaymentStatus/Model";
import {
  checkAppointmentPaymentStatus,
  clearAppointmentPaymentStatusAPIRes,
} from "../../../../redux/Patient/AppointmentPaymentList/CheckPaymentStatus/API";
import MWSnackbar from "../../../../component/MWSnackbar";
import RefreshIcon from "@mui/icons-material/Refresh";
import { t } from "i18next";

export default function PaymentList() {
  const { caseid } = useParams() as {
    caseid: string;
  };
  const dispatch = useDispatch();
  const [paymentList, setPaymentList] = useState([] as ModifiedPaymentList[]);

  let getAppointmentPaymentListRes = useSelector(
    (state: AppState) => state.getAppointmentPaymentListRes
  );

  let checkAppointmentPaymentStatusRes = useSelector(
    (state: AppState) => state.checkAppointmentPaymentStatusRes
  );

  useEffect(() => {
    if (
      getAppointmentPaymentListRes?.getAppointmentPaymentListRes
        ?.paymentList !== undefined
    ) {
      if (
        getAppointmentPaymentListRes?.getAppointmentPaymentListRes?.paymentList
          .length > 0
      ) {
        let paymentListItem =
          getAppointmentPaymentListRes?.getAppointmentPaymentListRes?.paymentList.map(
            (item: AppointmentPaymentList) => {
              return {
                id: item.id,
                description:
                  item?.note !== undefined
                    ? item?.note !== null
                      ? item.note
                      : ""
                    : "",
                createDate:
                  item?.create_date !== undefined
                    ? item?.create_date !== null
                      ? dayjs(item?.create_date).format("ddd, MM/DD/YYYY")
                      : ""
                    : "",
                amount:
                  item?.amount !== undefined
                    ? item?.amount !== null
                      ? "₹" + item.amount
                      : ""
                    : "",
                paymentLink:
                  item?.paymentLink !== undefined
                    ? item?.paymentLink !== null
                      ? item.paymentLink
                      : ""
                    : "",
                paidOn:
                  item?.paidTime !== undefined
                    ? item?.paidTime !== null
                      ? dayjs(item?.paidTime).format("ddd, MM/DD/YYYY")
                      : ""
                    : "",
                status:
                  item?.status !== undefined
                    ? item?.status !== null
                      ? item.status
                      : ""
                    : "",
              } as ModifiedPaymentList;
            }
          );
        setPaymentList(paymentListItem);
      } else setPaymentList([] as ModifiedPaymentList[]);
    } else setPaymentList([] as ModifiedPaymentList[]);
  }, [getAppointmentPaymentListRes]);
  const columns: GridColDef[] = [
    {
      field: "description",
      headerName: `${t("patientlistpage.des", { ns: ["home"] })!}`,
      flex: 1,
    },
    {
      field: "createDate",
      headerName: `${t("appointmentpage.createdate", { ns: ["home"] })!}`,
      minWidth: 130,
    },
    {
      field: "amount",
      headerName: `${t("payment.amt", { ns: ["home"] })!}`,
      minWidth: 3,
    },
    {
      field: "paymentLink",
      headerName: `${t("payment.pay", { ns: ["home"] })!}`,
      minWidth: 120,
      renderCell: (params: GridCellParams) => (
        <Button
          startIcon={<PaymentsIcon />}
          variant="outlined"
          onClick={() => {
            window.open(params.row.paymentLink);
          }}
          sx={{ textTransform: "none" }}
          disabled={
            params.row.status === "paid" || params.row.status === "free"
          }
        >
          {t("payment.payNow", { ns: ["home"] })!}
        </Button>
      ),
    },
    {
      field: "paidOn",
      headerName: `${t("payment.paidOn", { ns: ["home"] })!}`,
      minWidth: 30,
    },
    {
      field: "status",
      headerName: `${t("payment.paystat", { ns: ["home"] })!}`,
      minWidth: 130,
    },
    {
      field: "id",
      headerName: `${t("payment.checkstat", { ns: ["home"] })!}`,
      minWidth: 150,
      renderCell: (params: GridCellParams) => (
        <Button
          startIcon={<VisibilityIcon />}
          variant="outlined"
          onClick={() => {
            dispatch(
              checkAppointmentPaymentStatus({
                paymentId: params.row.id,
              } as CheckPaymentStatusBody)
            );
          }}
          sx={{ textTransform: "none" }}
          disabled={
            params.row.status === "paid" ||
            params.row.status === "free" ||
            checkAppointmentPaymentStatusRes?.loading
          }
        >
          {t("payment.checkstat", { ns: ["home"] })!}
        </Button>
      ),
    },
  ];
  const [checkPaymentStatusMsg, setCheckPaymentStatusMsg] = useState("");
  const [checkPaymentStatusMsgType, setCheckPaymentStatusMsgType] =
    useState<AlertColor>("success");
  useEffect(() => {
    if (checkAppointmentPaymentStatusRes?.successMsg !== "") {
      setCheckPaymentStatusMsgType("success");
      setCheckPaymentStatusMsg(checkAppointmentPaymentStatusRes?.successMsg);
    }
    if (checkAppointmentPaymentStatusRes?.errorMsg !== "") {
      setCheckPaymentStatusMsgType("error");
      setCheckPaymentStatusMsg(checkAppointmentPaymentStatusRes?.errorMsg);
    }
  }, [checkAppointmentPaymentStatusRes]);

  const checkAlertClose = (passedVal: boolean) => {
    if (passedVal) {
      dispatch(clearAppointmentPaymentStatusAPIRes());
    }
  };

  return (
    <Box>
      <Card>
        <CardHeader
          title={
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              spacing={2}
            >
              <Grid item>{t("payment.paylist", { ns: ["home"] })!}</Grid>
              <Grid item>
                <IconButton
                  onClick={() =>
                    dispatch(
                      getAppointmentPaymentList({
                        caseId: caseid,
                      } as GetPaymentListBody)
                    )
                  }
                >
                  <RefreshIcon />
                </IconButton>
              </Grid>
            </Grid>
          }
        />
        <CardContent>
          {paymentList.length > 0 ? (
            <DataGrid
              rows={paymentList}
              columns={columns}
              pageSize={10}
              hideFooter
              hideFooterPagination
              disableSelectionOnClick
              experimentalFeatures={{ newEditingApi: true }}
              autoHeight
              components={{ Toolbar: GridToolbar }}
              componentsProps={{
                toolbar: {
                  csvOptions: { disableToolbarButton: true },
                  printOptions: { disableToolbarButton: true },
                  showQuickFilter: true,
                  quickFilterProps: { debounceMs: 250 },
                },
              }}
              disableColumnFilter
              disableColumnSelector
              disableDensitySelector
            />
          ) : (
            <MWExceptionList />
          )}
        </CardContent>
      </Card>
      <MWSnackbar
        msg={checkPaymentStatusMsg}
        type={checkPaymentStatusMsgType}
        alertClose={checkAlertClose}
      />
    </Box>
  );
}
