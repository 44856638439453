import {
    PATIENT_QURIES_LIST_CLEAR_API_RES,
    PATIENT_QURIES_LIST_FAIL,
    PATIENT_QURIES_LIST_LOADING,
    PATIENT_QURIES_LIST_SUCCESS,
    PATIENT_QURIES_LIST_API_MSG,
    PatientQuriesListActionTypes,
  } from "./ActionTypes";
  import { PatientQuriesListResponse } from "./Model";
  
  export const patientQuriesListLoadingAction = (
    loading: boolean
  ): PatientQuriesListActionTypes => {
    return {
      type: PATIENT_QURIES_LIST_LOADING,
      loading: loading,
    };
  };
  
  export const patientQuriesListSuccessAction = (
    executiveRes: PatientQuriesListResponse
  ): PatientQuriesListActionTypes => {
    return {
      type: PATIENT_QURIES_LIST_SUCCESS,
      payload: executiveRes,
    };
  };
  
  export const executiveListErrorAction = (
    executiveRes: PatientQuriesListResponse,
    errMsg: string,
    status: number
  ): PatientQuriesListActionTypes => {
    return {
      type: PATIENT_QURIES_LIST_FAIL,
      payload: executiveRes,
      message: errMsg,
      status: status,
    };
  };
  
  export const executiveListUpdateAPIMsgAction = (
    executiveRes: PatientQuriesListResponse,
    errMsg: string,
    status: number
  ): PatientQuriesListActionTypes => {
    return {
      type: PATIENT_QURIES_LIST_API_MSG,
      payload: executiveRes,
      message: errMsg,
      status: status,
    };
  };
  
  export const executiveListAPIResClearAction = (
    executiveRes: PatientQuriesListResponse,
    errMsg: string,
    status: number
  ): PatientQuriesListActionTypes => {
    return {
      type: PATIENT_QURIES_LIST_CLEAR_API_RES,
      payload: executiveRes,
      message: errMsg,
      status: status,
    };
  };
  