import React, { useState, useEffect } from "react";
import EditIcon from "@mui/icons-material/Edit";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../redux/store/Store";
import {
  changeNotesForDoctorStatus,
  getDoctorNoteListForDoctor,
} from "../../redux/Notes/ApiCall";
import {
  Alert,
  Box,
  Button,
  Snackbar,
} from "@mui/material";
import MWExceptionList from "../../component/MWExceptionList";
import { doctorInactiveListApi } from "../../redux/Notes/DoctorInactiveNote/API";
import { DoctorInactiveListModel } from "../../redux/Notes/DoctorInactiveNote/Model";
import dayjs from "dayjs";
import { DataGrid, GridCellParams, GridColDef, GridToolbar } from "@mui/x-data-grid";
import { t } from "i18next";

export default function InActiveNotes(props: any) {
  const dispatch = useDispatch();

  const { inactiveNoteLoader } = props;

  const doctorId = localStorage.getItem("doctorID");

  // call Change Dr. Notes Status Store
  const changeDrNoteStatusLoadingvalue = useSelector(
    (state: AppState) => state.changeDrNotesStatusValue.loading
  );
  const changeDrNoteStatusStatus = useSelector(
    (state: AppState) => state.changeDrNotesStatusValue.status
  );
  const changeDrNoteStatusMessage = useSelector(
    (state: AppState) => state.changeDrNotesStatusValue.message
  );

  const docInactiveNoteListFromAPI = useSelector(
    (state: AppState) => state.doctorInactiveNoteListValue
  );

  // Change Dr notes status Success toast
  const [
    successChangeDrNoteStatusToastActive,
    setSuccessChangeDrNoteStatusToastActive,
  ] = useState(false);
  const toggleSuccessChangeDrNoteStatusActive = () => {
    setSuccessChangeDrNoteStatusToastActive(
      (successChangeDrNoteStatusToastActive) =>
        !successChangeDrNoteStatusToastActive
    );
    dispatch(getDoctorNoteListForDoctor(getDrNoteListBody));
  };

  const toastChangeDrNoteStatusSuccessMarkup =
    successChangeDrNoteStatusToastActive ? (
      <Snackbar
        open={successChangeDrNoteStatusToastActive}
        autoHideDuration={6000}
        onClose={toggleSuccessChangeDrNoteStatusActive}
      >
        <Alert
          severity="success"
          onClose={toggleSuccessChangeDrNoteStatusActive}
          sx={{ width: "100%" }}
        >
          {changeDrNoteStatusMessage}
        </Alert>
      </Snackbar>
    ) : null;

  // Change Dr notes status Error toast
  const [changeDrNoteStatusToastActive, setErrorChangeDrNoteStatusToastActive] =
    useState(false);
  const handleErrorChangeDrNoteStatusToastChange = () => {
    setErrorChangeDrNoteStatusToastActive(
      (changeDrNoteStatusToastActive) => !changeDrNoteStatusToastActive
    );
  };
  const toastChangeDrNoteStatusErrorMarkup = changeDrNoteStatusToastActive ? (
    <Snackbar
      open={changeDrNoteStatusToastActive}
      autoHideDuration={6000}
      onClose={handleErrorChangeDrNoteStatusToastChange}
    >
      <Alert
        severity="success"
        onClose={handleErrorChangeDrNoteStatusToastChange}
        sx={{ width: "100%" }}
      >
        {changeDrNoteStatusMessage}
      </Alert>
    </Snackbar>
  ) : null;

  // Get Doctor Note List Body
  const getDrNoteListBody = {
    doctorId: doctorId,
    isActive: false,
  };


  // Notes Status change API
  function toggleNoteStatus(response: any) {
    dispatch(changeNotesForDoctorStatus(response));
    setSuccessChangeDrNoteStatusToastActive(
      (successChangeDrNoteStatusToastActive) =>
        !successChangeDrNoteStatusToastActive
    );
    setErrorChangeDrNoteStatusToastActive(
      (changeDrNoteStatusToastActive) => !changeDrNoteStatusToastActive
    );
  }

  const getDrInactiveNoteListBody = {
    doctorId: doctorId,
    isActive: false,
  } as DoctorInactiveListModel;

  useEffect(() => {
    dispatch(doctorInactiveListApi(getDrInactiveNoteListBody));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const [dataGridArray, setDataGridArray] = useState([] as any[]);
  useEffect(() => {
    if (docInactiveNoteListFromAPI?.doctorInactiveNoteListRes?.noteList !== undefined) {
      let dataPointArr = docInactiveNoteListFromAPI?.doctorInactiveNoteListRes?.noteList.map(
        (element: any) => ({
          id: element.id,
          note: element.note,
          createBy: element.createdBy.user.first_name +
            " " + element.createdBy.user.last_name,
          modifyBy: element.modifiedBy.user.first_name +
            " " + element.modifiedBy.user.last_name,
          createdDate: dayjs(element.createdDate).format("MMMM D, YYYY"),
          modifiedDate: dayjs(element.modifiedDate).format("MMMM D, YYYY"),
        })
      );
      setDataGridArray(dataPointArr);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [docInactiveNoteListFromAPI]);

  const columns: GridColDef[] = [
    {
      field: "note",
      headerName: `${t("docnotelist.note", { ns: ["home"] })!}`,
      width: 200,
      flex: 1,
    },
    {
      field: "createdDate",
      headerName: `${t("docnotelist.createddate", { ns: ["home"] })!}`,
      width: 200,
      flex: 1,
    },
    {
      field: "createBy",
      headerName: `${t("docnotelist.createdby", { ns: ["home"] })!}`,
      width: 250,
      flex: 1,
    },
    {
      field: "id",
      headerName: `${t("docnotelist.modifiedstatus", { ns: ["home"] })!}`,
      width: 250,
      flex: 1,
      renderCell: (params: GridCellParams) => (
        <Button
          startIcon={<EditIcon />}
          variant="outlined"
          onClick={() => {
            toggleNoteStatus(params.value);
          }}
        >
          {
            t("docnotelist.edit", {
              ns: ["home"],
            })!
          }
        </Button>
      ),
    },
    {
      field: "modifyBy",
      headerName: `${t("docnotelist.modifyby", { ns: ["home"] })!}`,
      width: 250,
      flex: 1,
    },
    {
      field: "modifiedDate",
      headerName: `${t("docnotelist.modifydate", { ns: ["home"] })!}`,
      width: 250,
      flex: 1,
    },
  ];

  // Active Notes List
  const getInActiveNoteList = (
    <Box pt={3}>
      {docInactiveNoteListFromAPI?.doctorInactiveNoteListRes?.noteList !== undefined ? (
        docInactiveNoteListFromAPI?.doctorInactiveNoteListRes?.noteList.length > 0 ? (
          <DataGrid
            rows={dataGridArray}
            columns={columns}
            pageSize={10}
            hideFooter
            hideFooterPagination
            disableSelectionOnClick
            experimentalFeatures={{ newEditingApi: true }}
            autoHeight
            components={{ Toolbar: GridToolbar }}
            componentsProps={{
              toolbar: {
                csvOptions: { disableToolbarButton: true },
                printOptions: { disableToolbarButton: true },
                showQuickFilter: true,
                quickFilterProps: { debounceMs: 250 },
              },
            }}
            disableColumnFilter
            disableColumnSelector
            disableDensitySelector
          />
        ) : (
          <MWExceptionList />
        )
      ) : (
        <MWExceptionList />
      )}
    </Box>
  );


  return (
    <Box>
      {docInactiveNoteListFromAPI.loading === true ||
        changeDrNoteStatusLoadingvalue === true
        ? inactiveNoteLoader(true)
        : inactiveNoteLoader(false)}

      {getInActiveNoteList}
      {changeDrNoteStatusStatus! === 1
        ? toastChangeDrNoteStatusSuccessMarkup
        : ""}
      {changeDrNoteStatusStatus! === 0
        ? toastChangeDrNoteStatusErrorMarkup
        : ""}
    </Box>
  );
}
