import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../../ApiConfig";
import { ImportCallActionTypes } from "./ActionTypes";
import { ImportCallResponse } from "./Model";
import {
  importCallAPIResClearAction,
  importCallErrorAction,
  importCallLoadingAction,
  importCallSuccessAction,
  importCallUpdateAPIMsgAction,
} from "./Actions";
import { headers } from "../../../component/Utility";

let apiRes = {} as ImportCallResponse;
export const importCallApi = () => {
  return function (dispatch: Dispatch<ImportCallActionTypes>) {
    dispatch(importCallLoadingAction(true));
    axios
      .get(
        Url.baseUrl + "/ehrapp/api/incomeCall/get_all_incoming_calls",
        { headers: headers }
      )
      .then((res) => {
        apiRes = res.data;
        dispatch(importCallLoadingAction(false));
        dispatch(importCallSuccessAction(res.data));
      })
      .catch((error) => {
        dispatch(importCallLoadingAction(false));
        dispatch(
          importCallErrorAction(
            {} as ImportCallResponse,
            error.response !== undefined
              ? error.response.data.message || error.response.data.detail
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || -1 : -1
          )
        );
      });
  };
};

export const importCallAPIResMsg = () => {
  return function (dispatch: Dispatch<ImportCallActionTypes>) {
    dispatch(importCallUpdateAPIMsgAction(apiRes as ImportCallResponse, "", 0));
  };
};

export const clearimportCallAPIRes = () => {
  return function (dispatch: Dispatch<ImportCallActionTypes>) {
    dispatch(importCallAPIResClearAction({} as ImportCallResponse, "", 0));
  };
};
