import {
  FindUserActionTypes,
  FIND_USER_FAIL,
  FIND_USER_LOADING,
  FIND_USER_SUCCESS,
  FindUserState,
  FIND_USER_UPDATE_API_MSG,
  FIND_USER_UPDATE_API_RES,
} from "./ActionTypes";
import { FindUserResponse } from "./Model";

const initialStateGetPosts: FindUserState = {
  loading: false,
  findUserRes: {} as FindUserResponse,
  message: "",
  status: 0,
};
export const findUserReducer = (
  state = initialStateGetPosts,
  action: FindUserActionTypes
): FindUserState => {
  switch (action.type) {
    case FIND_USER_LOADING:
      return {
        ...state,
        loading: true,
        findUserRes: {} as FindUserResponse,
      };
    case FIND_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        findUserRes: action.payload,
      };
    case FIND_USER_FAIL:
      return {
        ...state,
        loading: false,
        findUserRes: {} as FindUserResponse,
        message: action.message,
        status: action.status,
      };
    case FIND_USER_UPDATE_API_MSG:
      return {
        ...state,
        loading: false,
        findUserRes: action.payload,
        message: action.message,
        status: action.status,
      };
    case FIND_USER_UPDATE_API_RES:
      return {
        ...state,
        loading: false,
        findUserRes: {} as FindUserResponse,
        message: action.message,
        status: action.status,
      };
    default:
      return state;
  }
};
