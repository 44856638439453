import React from "react";
import {
  Box,
  Card,
  CardContent,
  Container,
  Divider,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import { GetAdminDetailsRes } from "../redux/jwtAdminLogIn/adminLoginDetails/Model";
import MWPageTitle from "../component/MWPageTitle";
import { t } from "i18next";

export default function AdminDetails() {
  const adminDetails = localStorage.getItem("loginDetails");
  const userDetails = JSON.parse(adminDetails!) as GetAdminDetailsRes;
  const adminDetailsMarkup = (
    <Box pt={4}>
      <Card>
        <CardContent>
          <Stack
            direction="column"
            justifyContent="flex-start"
            alignItems="stretch"
            spacing={2}
            sx={{ pt: 2 }}
          >
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="flex-start"
              spacing={2}
            >
              <Grid item xs={4} sm={4} md={4} lg={4}>
                <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                  {t("common.firstname", { ns: ["home"] })}:
                </Typography>
              </Grid>
              <Grid item xs={8} sm={8} md={8} lg={8}>
                <Typography variant="h6">
                  {userDetails?.user?.first_name !== undefined
                    ? userDetails?.user?.first_name
                    : null}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Divider />
              </Grid>
              <Grid item xs={4} sm={4} md={4} lg={4}>
                <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                  {t("common.lastname", { ns: ["home"] })}:
                </Typography>
              </Grid>
              <Grid item xs={8} sm={8} md={8} lg={8}>
                <Typography variant="h6">
                  {userDetails?.user?.last_name !== undefined
                    ? userDetails?.user?.last_name
                    : null}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Divider />
              </Grid>
              <Grid item xs={4} sm={4} md={4} lg={4}>
                <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                {t("common.emailorphno", { ns: ["home"] })}:
                </Typography>
              </Grid>
              <Grid item xs={8} sm={8} md={8} lg={8}>
                <Typography variant="h6">
                  {userDetails?.user?.email !== undefined
                    ? userDetails?.user?.email
                    : null}
                </Typography>
              </Grid>
            </Grid>
          </Stack>
        </CardContent>
      </Card>
    </Box>
  );
  return (
    <Box>
      <Container maxWidth="xl" sx={{ mt: 3 }}>
        <MWPageTitle title={t("adminDetails.adminDetail", { ns: ["home"] })} />
        {adminDetailsMarkup}
      </Container>
    </Box>
  );
}
