export const ACCEPT_OR_REJECT_BOOKING = 'ACCEPT_OR_REJECT_BOOKING';
export const ACCEPT_OR_REJECT_BOOKING_LOADING = "ACCEPT_OR_REJECT_BOOKING_LOADING";
export const ACCEPT_OR_REJECT_BOOKING_SUCCESS = "ACCEPT_OR_REJECT_BOOKING_SUCCESS";
export const ACCEPT_OR_REJECT_BOOKING_FAIL = "ACCEPT_OR_REJECT_BOOKING_FAIL";

// Get Active Corporate Package List
export interface GetAcceportRejectBookingStateType {
    loading: boolean;
    message: string;
    status: number;
}
interface AcceportRejectBookingActionType {
    type: typeof ACCEPT_OR_REJECT_BOOKING;
    message: string;
    status: number;
}
export interface AcceportRejectBookingLoading {
    type: typeof ACCEPT_OR_REJECT_BOOKING_LOADING;
}
export interface AcceportRejectBookingSuccess {
    type: typeof ACCEPT_OR_REJECT_BOOKING_SUCCESS;
    message: string;
    status: number;
}
export interface AcceportRejectBookingFail {
    type: typeof ACCEPT_OR_REJECT_BOOKING_FAIL;
    message: string;
    status: number;
}
export type AcceportRejectBookingTypes = AcceportRejectBookingActionType | AcceportRejectBookingLoading | AcceportRejectBookingSuccess | AcceportRejectBookingFail;