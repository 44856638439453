import React, { useEffect, useState } from "react";
import {  useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../../redux/store/Store";
import {
  InactiveCorporateList,
  InActiveCorporatePatientList,
} from "../../../../redux/interfaces/Model";
import {
  getInactiveCorporateList,
  getInactiveCorporatePatinetList,
} from "../../../../redux/effects/ApiCall";
import * as _ from "lodash";
import AdminLoader from "../../../AdminLoader";
import InActivePatientListUnderPatientTeam from "./InActivePatientListUnderPatientTeam";
import InActivePatientTeamPaymentDetails from "./InActivePatientTeamPaymentDetails";
import { Box, Container, Grid, Stack, Tab } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import MWPageTitle from "../../../../component/MWPageTitle";

export default function InactivePatientDetails() {
  const { packageId } = useParams() as {
    packageId: string;
  };
  const { profileId } = useParams() as {
    profileId: string;
  };

  const dispatch = useDispatch();

  const loadingMarkup = <AdminLoader />;

  const [
    inActivePatientListUnderPatientTeamLoader,
    setInActivePatientListUnderPatientTeamLoader,
  ] = useState(false);
  const [
    inActivePatientTeamPaymentDetailsLoader,
    setInActivePatientTeamPaymentDetailsLoader,
  ] = useState(false);

  const [selected, setSelected] = useState("1");
  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setSelected(newValue);
    localStorage.setItem("selctedOnboardingtab", String(newValue));
  };

  //call Inactive Patient List Store
  const inActiveCorporatePatientList = useSelector(
    (state: AppState) => state.inactiveCorporatePatientValue
  );
  const inActiveCorporatePatientListValues: InActiveCorporatePatientList =
    _.filter(inActiveCorporatePatientList.inActiveCorporatePatientListValue, [
      "profileId",
      Number(profileId),
    ])[0];

  // call Inactive Package List Store
  const inActiveCorporatePackageList = useSelector(
    (state: AppState) => state.inactiveCorporateListValue
  );
  const inActiveCorporateListDetails: InactiveCorporateList = _.filter(
    inActiveCorporatePackageList.inactiveCorporateListValue,
    ["id", Number(packageId)]
  )[0];
  useEffect(
    () => {
      // if (
      //   inActiveCorporateListDetails === undefined &&
      //   inActiveCorporateListDetails === undefined
      // ) {
      dispatch(getInactiveCorporateList());
      dispatch(getInactiveCorporatePatinetList(packageId));
      // }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      // dispatch,
      // inActiveCorporateListDetails,
      // inActiveCorporatePatientListValues,
    ]
  );
  const tabs = [
    <TabContext value={selected}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <TabList onChange={handleTabChange}>
          <Tab label="Patient List" value="1" />
          <Tab label="Payment List" value="2" />
        </TabList>
      </Box>
      <TabPanel value="1">
        {
          <InActivePatientListUnderPatientTeam
            inActivePatientListUnderPatientTeamLoader={
              setInActivePatientListUnderPatientTeamLoader
            }
          />
        }
      </TabPanel>
      <TabPanel value="2">
        {
          <InActivePatientTeamPaymentDetails
            inActivePatientTeamPaymentDetailsLoader={
              setInActivePatientTeamPaymentDetailsLoader
            }
          />
        }
      </TabPanel>
    </TabContext>,
  ];

  return (
    <Box>
      {inActivePatientListUnderPatientTeamLoader === true ||
      inActivePatientTeamPaymentDetailsLoader === true
        ? loadingMarkup
        : ""}
      <Container maxWidth="xl">
        <Stack
          direction="column"
          justifyContent="flex-start"
          alignItems="stretch"
          spacing={2}
          pt={2}
        >
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="flex-start"
          >
            <Grid item>
              <MWPageTitle
                backButton={true}
                title={`${
                  inActiveCorporatePatientListValues === undefined
                    ? ""
                    : inActiveCorporatePatientListValues.firstname +
                      " " +
                      inActiveCorporatePatientListValues.lastname +
                      "'s Details"
                }`}
                enableSubtitle={true}
                subtitle={`${
                  inActiveCorporateListDetails === undefined
                    ? ""
                    : "For " + inActiveCorporateListDetails.name
                }`}
              />
            </Grid>
          </Grid>
        </Stack>
        {tabs}
      </Container>
    </Box>
  );
}
