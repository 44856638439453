import {
  PreviewListActionTypes,
  GetPatientDemographicsState,
  PREVIEW_SUCCESS,
  PREVIEW_LOADING,
  PREVIEW_FAIL,
} from "./ActionTypes";
import { PreviewListResponse } from "./Model";

const initialStateGetPosts: GetPatientDemographicsState = {
  loading: false,
  previewList: {} as PreviewListResponse,
};
export const previewListReducer = (
  state = initialStateGetPosts,
  action: PreviewListActionTypes
): GetPatientDemographicsState => {
  switch (action.type) {
    case PREVIEW_SUCCESS:
      return {
        ...state,
        loading: false,
        previewList: action.payload,
      };
    case PREVIEW_LOADING:
      return {
        ...state,
        loading: true,
        previewList: {} as PreviewListResponse,
      };
    case PREVIEW_FAIL:
      return {
        ...state,
        previewList: {} as PreviewListResponse,
        loading: false,
      };
    default:
      return state;
  }
};
