import {
  PATIENT_NOTE_LIST_FAIL,
  PATIENT_NOTE_LIST_LOADING,
  PATIENT_NOTE_LIST_SUCCESS,
  PATIENT_NOTE_LIST_UPDATE_API_MSG,
  PATIENT_NOTE_LIST_UPDATE_API_RES,
  GetPatientNoteListActionTypes,
} from "./ActionTypes";
import { GetPatientNoteListRes } from "./Model";

export const getAdminNoteListByPatientLoadingAction = (
  loading: boolean
): GetPatientNoteListActionTypes => {
  return {
    type: PATIENT_NOTE_LIST_LOADING,
    loading: loading,
  };
};

export const getAdminNoteListByPatientSuccessAction = (
  getPatientNoteListponse: GetPatientNoteListRes,
  successMsg: string
): GetPatientNoteListActionTypes => {
  return {
    type: PATIENT_NOTE_LIST_SUCCESS,
    payload: getPatientNoteListponse,
    successMsg: successMsg,
  };
};

export const getAdminNoteListByPatientErrorAction = (
  getPatientNoteListponse: GetPatientNoteListRes,
  errMsg: string,
  status: number
): GetPatientNoteListActionTypes => {
  return {
    type: PATIENT_NOTE_LIST_FAIL,
    payload: getPatientNoteListponse,
    errorMsg: errMsg,
    status: status,
  };
};
export const getAdminNoteListByPatientUpdateAPIMsgAction = (
  getPatientNoteListponse: GetPatientNoteListRes,
  successMsg: string,
  errMsg: string,
  status: number
): GetPatientNoteListActionTypes => {
  return {
    type: PATIENT_NOTE_LIST_UPDATE_API_MSG,
    payload: getPatientNoteListponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};

export const getAdminNoteListByPatientAPIResClearAction = (
  getPatientNoteListponse: GetPatientNoteListRes,
  successMsg: string,
  errMsg: string,
  status: number
): GetPatientNoteListActionTypes => {
  return {
    type: PATIENT_NOTE_LIST_UPDATE_API_RES,
    payload: getPatientNoteListponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};
