import {
    ScheduleMeetingActionTypes,
    SCHEDULE_MEETING_FAIL,
    SCHEDULE_MEETING_LOADING,
    SCHEDULE_MEETING_SUCCESS,
    ScheduleMeetingState,
    SCHEDULE_MEETING_UPDATE_API_MSG,
    SCHEDULE_MEETING_UPDATE_API_RES,
 } from "./ActionTypes";
 import { CreateScheduleMeetingValueRes } from "./Model";
 
 const initialStateGetPosts:  ScheduleMeetingState = {
   loading: false,
   scheduleMeetingRes: {} as CreateScheduleMeetingValueRes,
   successMsg: "",
   errorMsg: "",
   status: 0,
 };
 export const scheduleMeetingReducer = (
   state = initialStateGetPosts,
   action:  ScheduleMeetingActionTypes
 ):  ScheduleMeetingState => {
   switch (action.type) {
     case  SCHEDULE_MEETING_LOADING:
       return {
         ...state,
         loading: action.loading,
       };
     case  SCHEDULE_MEETING_SUCCESS:
       return {
         ...state,
         scheduleMeetingRes: action.payload,
         successMsg: action.successMsg,
       };
     case  SCHEDULE_MEETING_FAIL:
       return {
         ...state,
         scheduleMeetingRes: action.payload,
         errorMsg: action.errorMsg,
         status: action.status,
       };
     case  SCHEDULE_MEETING_UPDATE_API_MSG:
       return {
         ...state,
         scheduleMeetingRes: action.payload,
         successMsg: action.successMsg,
         errorMsg: action.errorMsg,
         status: action.status,
       };
     case  SCHEDULE_MEETING_UPDATE_API_RES:
       return {
         ...state,
         scheduleMeetingRes: action.payload,
         successMsg: action.successMsg,
         errorMsg: action.errorMsg,
         status: action.status,
       };
     default:
       return state;
   }
 };
 
 