import {
  TELEHEALTH_LIST_CLEAR_API_RES,
  TELEHEALTH_LIST_FAIL,
  TELEHEALTH_LIST_LOADING,
  TELEHEALTH_LIST_SUCCESS,
  TELEHEALTH_LIST_API_MSG,
  TelehealthListActionTypes,
} from "./ActionTypes";
import { TelehealthMeetingListResponse } from "./Model";

export const teleHealthLoadingAction = (
  loading: boolean
): TelehealthListActionTypes => {
  return {
    type: TELEHEALTH_LIST_LOADING,
    loading: loading,
  };
};

export const teleHealthSuccessAction = (
  teleHealthResponse: TelehealthMeetingListResponse
): TelehealthListActionTypes => {
  return {
    type: TELEHEALTH_LIST_SUCCESS,
    payload: teleHealthResponse,
  };
};

export const teleHealthErrorAction = (
  teleHealthResponse: TelehealthMeetingListResponse,
  errMsg: string,
  status: number
): TelehealthListActionTypes => {
  return {
    type: TELEHEALTH_LIST_FAIL,
    payload: teleHealthResponse,
    message: errMsg,
    status: status,
  };
};
export const teleHealthUpdateAPIMsgAction = (
  teleHealthResponse: TelehealthMeetingListResponse,
  errMsg: string,
  status: number
): TelehealthListActionTypes => {
  return {
    type: TELEHEALTH_LIST_API_MSG,
    payload: teleHealthResponse,
    message: errMsg,
    status: status,
  };
};

export const teleHealthAPIResClearAction = (
  teleHealthResponse: TelehealthMeetingListResponse,
  errMsg: string,
  status: number
): TelehealthListActionTypes => {
  return {
    type: TELEHEALTH_LIST_CLEAR_API_RES,
    payload: teleHealthResponse,
    message: errMsg,
    status: status,
  };
};
