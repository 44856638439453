import React, { useEffect, useState } from "react";
import {
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  addPatientCase,
  editPatientCase,
  getcaseDetails,
} from "../../../redux/ConsultationOption/ApiCall";
import { AppState } from "../../../redux/store/Store";
import { useParams } from "react-router-dom";
import { getConsultationTypeList } from "../../../redux/ConsultationOption/ConsultationType/ApiCall";
import { ConsulatationTyeList } from "../../../redux/ConsultationOption/ConsultationType/Model";
import PageLayout from "../../Layout/PageLayout";
import { t } from "i18next";

export default function ConsultationType() {
  const dispatch = useDispatch();

  const { caseid } = useParams() as {
    caseid: string;
  };

  const ConsultationTypeListValue = useSelector(
    (state: AppState) => state.consultationTypeValue
  );

  const PatientCaseDetailsValue = useSelector(
    (state: AppState) => state.getPatientCaseDetails.patientCaseDetail
  );

  const [getConsultationTypeValue, setConsultationTypeValue] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [isEnableSubmit, setEnableSubmit] = useState(true);
  const [value, setValue] = React.useState("");

  const addBody = {
    caseId: caseid,
    consultationTypeId: value,
  };

  const createCase = async () => {
    if (caseid !== "") {
      await dispatch(
        editPatientCase(Object.assign(addBody, { caseId: caseid }))
      );
      await setIsEdit(false);
      await setEnableSubmit((isEnableSubmit) => !isEnableSubmit);
      await dispatch(getcaseDetails(JSON.stringify({ caseId: caseid })));
    } else {
      await dispatch(addPatientCase(addBody));
      await dispatch(getcaseDetails(JSON.stringify({ caseId: caseid })));
    }
  };

  useEffect(() => {
    dispatch(getConsultationTypeList());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(
      getcaseDetails(
        JSON.stringify({
          caseId: caseid,
        })
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setValue(
      String(
        PatientCaseDetailsValue === undefined
          ? ""
          : PatientCaseDetailsValue?.consultationType?.value
      )
    );
    setConsultationTypeValue(PatientCaseDetailsValue?.consultationType?.label!);
  }, [PatientCaseDetailsValue]);

  const secondComponent = (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="stretch"
      spacing={2}
    >
      <Grid item>
        {ConsultationTypeListValue?.consultationTypeRes?.consulatationTyeList?.map(
          (opinion: ConsulatationTyeList) => (
            <FormControl
              key={opinion.id}
              disabled={getConsultationTypeValue ? !isEdit : isEdit}
            >
              <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                value={value}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  setValue((event.target as HTMLInputElement).value)
                }
              >
                <Typography variant="h6" fontWeight="bold">
                  {opinion.title}
                </Typography>
                <FormControlLabel
                  value={opinion.id}
                  control={<Radio />}
                  label={opinion.description}
                />
              </RadioGroup>
            </FormControl>
          )
        )}
      </Grid>
      <Grid item>
        {getConsultationTypeValue ? (
          <Grid
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            spacing={1}
          >
            <Grid item>
              <Button
                size="large"
                variant="outlined"
                onClick={() => {
                  setIsEdit((isEdit) => !isEdit);
                  setEnableSubmit((isEnableSubmit) => !isEnableSubmit);
                }}
              >
                {isEdit
                  ? `${t("accountdetail.cancel", { ns: ["home"] })}`
                  : `${t("userprofilepage.edit", { ns: ["home"] })}`}
              </Button>
            </Grid>
            <Grid item>
              <Button
                size="large"
                variant="contained"
                disabled={isEnableSubmit}
                onClick={createCase}
              >
                {t("demographicdetailpage.update", { ns: ["home"] })}
              </Button>
            </Grid>
          </Grid>
        ) : (
          <Grid
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
          >
            <Button size="large" variant="contained" onClick={createCase}>
              {t("common.add", { ns: ["home"] })}
            </Button>
          </Grid>
        )}
      </Grid>
    </Grid>
  );

  return <PageLayout>{secondComponent}</PageLayout>;
}
