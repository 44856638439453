import {
  GET_DOCTOR_LIST_BY_CATEGORY_FAIL,
  GET_DOCTOR_LIST_BY_CATEGORY_LOADING,
  GET_DOCTOR_LIST_BY_CATEGORY_SUCCESS,
  GET_DOCTOR_LIST_BY_CATEGORY_UPDATE_API_MSG,
  GET_DOCTOR_LIST_BY_CATEGORY_UPDATE_API_RES,
  GetDoctorListByCategoryActionTypes,
} from "./ActionTypes";
import { GetDoctorListByCategoryRes } from "./Model";

export const getDoctorListByCategoryLoadingAction = (
  loading: boolean
): GetDoctorListByCategoryActionTypes => {
  return {
    type: GET_DOCTOR_LIST_BY_CATEGORY_LOADING,
    loading: loading,
  };
};

export const getDoctorListByCategorySuccessAction = (
  getDoctorListByCategoryResponse: GetDoctorListByCategoryRes,
  successMsg: string
): GetDoctorListByCategoryActionTypes => {
  return {
    type: GET_DOCTOR_LIST_BY_CATEGORY_SUCCESS,
    payload: getDoctorListByCategoryResponse,
    successMsg: successMsg,
  };
};

export const getDoctorListByCategoryErrorAction = (
  getDoctorListByCategoryResponse: GetDoctorListByCategoryRes,
  errMsg: string,
  status: number
): GetDoctorListByCategoryActionTypes => {
  return {
    type: GET_DOCTOR_LIST_BY_CATEGORY_FAIL,
    payload: getDoctorListByCategoryResponse,
    errorMsg: errMsg,
    status: status,
  };
};
export const getDoctorListByCategoryUpdateAPIMsgAction = (
  getDoctorListByCategoryResponse: GetDoctorListByCategoryRes,
  successMsg: string,
  errMsg: string,
  status: number
): GetDoctorListByCategoryActionTypes => {
  return {
    type: GET_DOCTOR_LIST_BY_CATEGORY_UPDATE_API_MSG,
    payload: getDoctorListByCategoryResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};

export const getDoctorListByCategoryAPIResClearAction = (
  getDoctorListByCategoryResponse: GetDoctorListByCategoryRes,
  successMsg: string,
  errMsg: string,
  status: number
): GetDoctorListByCategoryActionTypes => {
  return {
    type: GET_DOCTOR_LIST_BY_CATEGORY_UPDATE_API_RES,
    payload: getDoctorListByCategoryResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};
