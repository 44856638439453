import {
  TELEHEALTH_LIST_API_MSG,
  TELEHEALTH_LIST_CLEAR_API_RES,
  TELEHEALTH_LIST_FAIL,
  TELEHEALTH_LIST_LOADING,
  TELEHEALTH_LIST_SUCCESS,
  TeleHealthDataState,
  TelehealthListActionTypes,
} from "./ActionTypes";
import { TelehealthMeetingListResponse } from "./Model";

const initialStateGetPosts: TeleHealthDataState = {
  loading: false,
  teleHealthRes: {} as TelehealthMeetingListResponse,
  message: "",
  status: 0,
};

export const getTelehealthMeetingReducer = (
  state = initialStateGetPosts,
  action: TelehealthListActionTypes
): TeleHealthDataState => {
  switch (action.type) {
    case TELEHEALTH_LIST_LOADING:
      return {
        ...state,
        loading: action.loading,
      };
    case TELEHEALTH_LIST_SUCCESS:
      return {
        ...state,
        teleHealthRes: action.payload,
      };
    case TELEHEALTH_LIST_FAIL:
      return {
        ...state,
        teleHealthRes: action.payload,
        message: action.message,
        status: action.status,
      };
    case TELEHEALTH_LIST_API_MSG:
      return {
        ...state,
        loading: false,
        teleHealthRes: action.payload,
        message: action.message,
        status: action.status,
      };
    case TELEHEALTH_LIST_CLEAR_API_RES:
      return {
        ...state,
        loading: false,
        teleHealthRes: action.payload,
        message: action.message,
        status: action.status,
      };
    default:
      return state;
  }
};
