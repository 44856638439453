import {
  PATIENT_CASE_NOTE_LIST_FAIL,
  PATIENT_CASE_NOTE_LIST_LOADING,
  PATIENT_CASE_NOTE_LIST_SUCCESS,
  PATIENT_CASE_NOTE_LIST_UPDATE_API_MSG,
  PATIENT_CASE_NOTE_LIST_UPDATE_API_RES,
  GetPatientCaseNoteListActionTypes,
} from "./ActionTypes";
import { GetPatientCaseNoteListRes } from "./Model";

export const getPatientCaseNoteListLoadingAction = (
  loading: boolean
): GetPatientCaseNoteListActionTypes => {
  return {
    type: PATIENT_CASE_NOTE_LIST_LOADING,
    loading: loading,
  };
};

export const getPatientCaseNoteListSuccessAction = (
  getPatientCaseNoteListResponse: GetPatientCaseNoteListRes
): GetPatientCaseNoteListActionTypes => {
  return {
    type: PATIENT_CASE_NOTE_LIST_SUCCESS,
    payload: getPatientCaseNoteListResponse,
  };
};

export const getPatientCaseNoteListErrorAction = (
  getPatientCaseNoteListResponse: GetPatientCaseNoteListRes,
  errMsg: string,
  status: number
): GetPatientCaseNoteListActionTypes => {
  return {
    type: PATIENT_CASE_NOTE_LIST_FAIL,
    payload: getPatientCaseNoteListResponse,
    errorMsg: errMsg,
    status: status,
  };
};
export const getPatientCaseNoteListUpdateAPIMsgAction = (
  getPatientCaseNoteListResponse: GetPatientCaseNoteListRes,
  errMsg: string,
  status: number
): GetPatientCaseNoteListActionTypes => {
  return {
    type: PATIENT_CASE_NOTE_LIST_UPDATE_API_MSG,
    payload: getPatientCaseNoteListResponse,
    errorMsg: errMsg,
    status: status,
  };
};

export const getPatientCaseNoteListAPIResClearAction = (
  getPatientCaseNoteListResponse: GetPatientCaseNoteListRes,
  errMsg: string,
  status: number
): GetPatientCaseNoteListActionTypes => {
  return {
    type: PATIENT_CASE_NOTE_LIST_UPDATE_API_RES,
    payload: getPatientCaseNoteListResponse,
    errorMsg: errMsg,
    status: status,
  };
};
