import { UpdatePatientDataDetailsRes } from "./Model";
export const UPDATE_PATIENT_DATA_DETAILS_LOADING =
  "UPDATE_PATIENT_DATA_DETAILS_LOADING";
export const UPDATE_PATIENT_DATA_DETAILS_SUCCESS =
  "UPDATE_PATIENT_DATA_DETAILS_SUCCESS";
export const UPDATE_PATIENT_DATA_DETAILS_FAIL =
  "UPDATE_PATIENT_DATA_DETAILS_FAIL";
export const UPDATE_PATIENT_DATA_DETAILS_DATA =
  "UPDATE_PATIENT_DATA_DETAILS_DATA";
export const UPDATE_PATIENT_DATA_DETAILS_UPDATE_API_MSG =
  "UPDATE_PATIENT_DATA_DETAILS_UPDATE_API_MSG";
export const UPDATE_PATIENT_DATA_DETAILS_UPDATE_API_RES =
  "UPDATE_PATIENT_DATA_DETAILS_UPDATE_API_RES";

export interface UpdatePatientDataDetailsState {
  updatePatientDataDetailsRes: UpdatePatientDataDetailsRes;
  loading: boolean;
  message: string;
  status: number;
}
export interface UpdatePatientDataDetailsLoading {
  type: typeof UPDATE_PATIENT_DATA_DETAILS_LOADING;
  loading: boolean;
}
export interface UpdatePatientDataDetailsSuccess {
  type: typeof UPDATE_PATIENT_DATA_DETAILS_SUCCESS;
  payload: UpdatePatientDataDetailsRes;
}
export interface UpdatePatientDataDetailsFail {
  type: typeof UPDATE_PATIENT_DATA_DETAILS_FAIL;
  payload: UpdatePatientDataDetailsRes;
  message: string;
  status: number;
}
export interface UpdatePatientDataDetailsUpdateAPIMsg {
  type: typeof UPDATE_PATIENT_DATA_DETAILS_UPDATE_API_MSG;
  payload: UpdatePatientDataDetailsRes;
  message: string;
  status: number;
}
export interface UpdatePatientDataDetailsUpdateAPIRes {
  type: typeof UPDATE_PATIENT_DATA_DETAILS_UPDATE_API_RES;
  payload: UpdatePatientDataDetailsRes;
  message: string;
  status: number;
}
interface UpdatePatientDataDetailsAction {
  type: typeof UPDATE_PATIENT_DATA_DETAILS_DATA;
  payload: UpdatePatientDataDetailsRes;
}

export type UpdatePatientDataDetailsActionTypes =
  | UpdatePatientDataDetailsAction
  | UpdatePatientDataDetailsLoading
  | UpdatePatientDataDetailsSuccess
  | UpdatePatientDataDetailsFail
  | UpdatePatientDataDetailsUpdateAPIMsg
  | UpdatePatientDataDetailsUpdateAPIRes;
