import { AddDoctorResponse } from "./Model";
export const ADD_DOCTOR_LOADING = "ADD_DOCTOR_LOADING";
export const ADD_DOCTOR_SUCCESS = "ADD_DOCTOR_SUCCESS";
export const ADD_DOCTOR_FAIL = "ADD_DOCTOR_FAIL";
export const ADD_DOCTOR_API_MSG = "ADD_DOCTOR_API_MSG";
export const ADD_DOCTOR_API_RES = "ADD_DOCTOR_API_RES";

export interface AddDoctorState {
  addDoctorRes: AddDoctorResponse;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface AddDoctorLoading {
  type: typeof ADD_DOCTOR_LOADING;
  loading: boolean;
}
export interface AddDoctorSuccess {
  type: typeof ADD_DOCTOR_SUCCESS;
  payload: AddDoctorResponse;
  successMsg: string;
}
export interface AddDoctorFail {
  type: typeof ADD_DOCTOR_FAIL;
  payload: AddDoctorResponse;
  errorMsg: string;
  status: number;
}
export interface AddDoctorAPIMsg {
  type: typeof ADD_DOCTOR_API_MSG;
  payload: AddDoctorResponse;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface AddDoctorAPIRes {
  type: typeof ADD_DOCTOR_API_RES;
  payload: AddDoctorResponse;
  successMsg: string;
  errorMsg: string;
  status: number;
}

export type AddDoctorActionTypes =
  | AddDoctorLoading
  | AddDoctorSuccess
  | AddDoctorFail
  | AddDoctorAPIMsg
  | AddDoctorAPIRes;