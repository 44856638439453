import {
  GET_APPOINTMENT_PAYMENT_LIST_FAIL,
  GET_APPOINTMENT_PAYMENT_LIST_LOADING,
  GET_APPOINTMENT_PAYMENT_LIST_SUCCESS,
  GET_APPOINTMENT_PAYMENT_LIST_UPDATE_API_MSG,
  GET_APPOINTMENT_PAYMENT_LIST_UPDATE_API_RES,
  GetAppointmentPaymentListActionTypes,
} from "./ActionTypes";
import { GetAppointmentPaymentListRes } from "./Model";

export const getAppointmentPaymentListLoadingAction = (
  loading: boolean
): GetAppointmentPaymentListActionTypes => {
  return {
    type: GET_APPOINTMENT_PAYMENT_LIST_LOADING,
    loading: loading,
  };
};

export const getAppointmentPaymentListSuccessAction = (
  getAppointmentPaymentListResponse: GetAppointmentPaymentListRes,
  successMsg: string
): GetAppointmentPaymentListActionTypes => {
  console.log("getAppointmentPaymentListResponse",getAppointmentPaymentListResponse)
  return {
    type: GET_APPOINTMENT_PAYMENT_LIST_SUCCESS,
    payload: getAppointmentPaymentListResponse,
    successMsg: successMsg,
  };
};

export const getAppointmentPaymentListErrorAction = (
  getAppointmentPaymentListResponse: GetAppointmentPaymentListRes,
  errMsg: string,
  status: number
): GetAppointmentPaymentListActionTypes => {
  return {
    type: GET_APPOINTMENT_PAYMENT_LIST_FAIL,
    payload: getAppointmentPaymentListResponse,
    errorMsg: errMsg,
    status: status,
  };
};
export const getAppointmentPaymentListUpdateAPIMsgAction = (
  getAppointmentPaymentListResponse: GetAppointmentPaymentListRes,
  successMsg: string,
  errMsg: string,
  status: number
): GetAppointmentPaymentListActionTypes => {
  return {
    type: GET_APPOINTMENT_PAYMENT_LIST_UPDATE_API_MSG,
    payload: getAppointmentPaymentListResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};

export const getAppointmentPaymentListAPIResClearAction = (
  getAppointmentPaymentListResponse: GetAppointmentPaymentListRes,
  successMsg: string,
  errMsg: string,
  status: number
): GetAppointmentPaymentListActionTypes => {
  return {
    type: GET_APPOINTMENT_PAYMENT_LIST_UPDATE_API_RES,
    payload: getAppointmentPaymentListResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};
