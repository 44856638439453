import {
  ASSIGN_EXECUTIVE_API_MSG,
  ASSIGN_EXECUTIVE_CLEAR_API_RES,
  ASSIGN_EXECUTIVE_FAIL,
  ASSIGN_EXECUTIVE_LOADING,
  ASSIGN_EXECUTIVE_SUCCESS,
  AssignExecutiveDataState,
  AssignExecutiveListActionTypes,
} from "./ActionTypes";
import { AssignExecutiveResponse } from "./Model";

const initialStateGetPosts: AssignExecutiveDataState = {
  loading: false,
  assignExecutiveRes: {} as AssignExecutiveResponse,
  message: "",
  status: 0,
};

export const assigntaskToExecutiveReducer = (
  state = initialStateGetPosts,
  action: AssignExecutiveListActionTypes
): AssignExecutiveDataState => {
  switch (action.type) {
    case ASSIGN_EXECUTIVE_LOADING:
      return {
        ...state,
        loading: action.loading,
      };
    case ASSIGN_EXECUTIVE_SUCCESS:
      return {
        ...state,
        assignExecutiveRes: action.payload,
      };
    case ASSIGN_EXECUTIVE_FAIL:
      return {
        ...state,
        assignExecutiveRes: action.payload,
        message: action.message,
        status: action.status,
      };
    case ASSIGN_EXECUTIVE_API_MSG:
      return {
        ...state,
        loading: false,
        assignExecutiveRes: action.payload,
        message: action.message,
        status: action.status,
      };
    case ASSIGN_EXECUTIVE_CLEAR_API_RES:
      return {
        ...state,
        loading: false,
        assignExecutiveRes: action.payload,
        message: action.message,
        status: action.status,
      };
    default:
      return state;
  }
};
