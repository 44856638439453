import {
  ConfirmAppointmentActionTypes,
  CONFIRM_APPOINTMENT_FAIL,
  CONFIRM_APPOINTMENT_LOADING,
  CONFIRM_APPOINTMENT_SUCCESS,
  ConfirmAppointmentState,
  CONFIRM_APPOINTMENT_UPDATE_API_MSG,
  CONFIRM_APPOINTMENT_UPDATE_API_RES,
} from "./ActionTypes";
import { ConfirmAppointmentRes } from "./Model";

const initialStateCheckPosts: ConfirmAppointmentState = {
  loading: false,
  confirmAppointmentRes: {} as ConfirmAppointmentRes,
  successMsg: "",
  errorMsg: "",
  status: 0,
};
export const confirmAppointmentReducer = (
  state = initialStateCheckPosts,
  action: ConfirmAppointmentActionTypes
): ConfirmAppointmentState => {
  switch (action.type) {
    case CONFIRM_APPOINTMENT_LOADING:
      return {
        ...state,
        loading: action.loading,
      };
    case CONFIRM_APPOINTMENT_SUCCESS:
      return {
        ...state,
        confirmAppointmentRes: action.payload,
        successMsg: action.successMsg,
      };
    case CONFIRM_APPOINTMENT_FAIL:
      return {
        ...state,
        confirmAppointmentRes: action.payload,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case CONFIRM_APPOINTMENT_UPDATE_API_MSG:
      return {
        ...state,
        confirmAppointmentRes: action.payload,
        successMsg: action.successMsg,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case CONFIRM_APPOINTMENT_UPDATE_API_RES:
      return {
        ...state,
        confirmAppointmentRes: action.payload,
        successMsg: action.successMsg,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    default:
      return state;
  }
};
