import {
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { t } from "i18next";

interface Props {
  dialogEnable?: boolean | any;
  dialogUpdateState: boolean | any;
  docUrl?: string;
  docName?: string;
}

export default function ViewDocument({
  dialogEnable,
  dialogUpdateState,
  docUrl,
  docName,
}: Props) {
  const [dialogOpen, setDialogOpen] = useState(false);
  const handleClose = () => {
    setDialogOpen(false);
    dialogUpdateState(false);
  };
  useEffect(() => {
    dialogEnable !== undefined
      ? setDialogOpen(dialogEnable)
      : setDialogOpen(false);
  }, [dialogEnable]);
  return (
    <Dialog
      open={dialogOpen}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      maxWidth="lg"
    >
      <DialogTitle>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item>{`${t("common.viewDocuOf", {
            ns: ["home"],
          })} ${docName}`}</Grid>
          <Grid item>
            <IconButton onClick={() => handleClose()}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="flex-start"
        >
          <Grid item>
            <iframe
              src={docUrl}
              title="Small document"
              width="100%"
              height="100%"
              frameBorder="0"
              style={{
                minHeight: "50vh",
                minWidth: "50vw",
              }}
            >
              This is an embedded
              <a
                target="_blank"
                href="http://office.com"
                rel="noopener noreferrer"
              >
                Microsoft Office
              </a>
              document, powered by
              <a
                target="_blank"
                href="http://office.com/webapps"
                rel="noopener noreferrer"
              >
                Office Online
              </a>
              .
            </iframe>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}
