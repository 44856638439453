import React, { useState,  useEffect } from "react";
import { Redirect, useHistory, useParams } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch, useSelector } from "react-redux";
import { getAllTaskListValue } from "../../redux/effects/ApiCall";
import { AppState } from "../../redux/store/Store";
import * as _ from "lodash";
import AdminLoader from "../AdminLoader";
import { acceptorRejectBooking } from "../../redux/AppointmentBooking/ApiCall";
import {
  assignTaskToExecutive,
  getExecutiveDropdownList,
  taskClose,
} from "../../redux/Task/API";
import {
  Alert,
  Box,
  Button,
  ButtonGroup,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";
import MWPageTitle from "../../component/MWPageTitle";
import { TaskDataList } from "../../redux/TaskAdmin/List/Model";
import { t } from "i18next";

export default function TaskDetails() {
  const dispatch = useDispatch();

  const [toSignin] = useState(false);
  const { currentPage } = useParams() as {
    currentPage: string;
  };
  const { rowIndex } = useParams() as {
    rowIndex: string;
  };

  // Call Store
  const adminAllTaskList = useSelector(
    (state: AppState) => state.alltaskListValue
  );
  const taskValues: TaskDataList = _.filter(
    adminAllTaskList.adminTaskRes.taskDataList,
    ["id", Number(rowIndex)]
  )[0];

  const history = useHistory();
  const loadingMarkup = <AdminLoader />;

  // Call Accept or Reject Booking Appointment Store
  const acceptrejectBookingLoadingvalue = useSelector(
    (state: AppState) => state.acceptOrRejectBookingAppointmentValue.loading
  );
  const acceptrejectBookingStatus = useSelector(
    (state: AppState) => state.acceptOrRejectBookingAppointmentValue.status
  );
  const acceptrejectBookingMessage = useSelector(
    (state: AppState) => state.acceptOrRejectBookingAppointmentValue.message
  );

  const [
    acceptRejectBookingSuccessToastActive,
    setAcceptRejectBookingSuccessToastActive,
  ] = useState(false);
  const toggleAcceptRejectBookingSuccessActive = () => {
    setAcceptRejectBookingSuccessToastActive(
      (acceptRejectBookingSuccessToastActive) =>
        !acceptRejectBookingSuccessToastActive
    );
    dispatch(getAllTaskListValue(Number(currentPage)));
  };
  const acceptRejectBookingSuccessToastmarkup =
    acceptRejectBookingSuccessToastActive ? (
      // <Toast
      //   content={acceptrejectBookingMessage}
      //   onDismiss={toggleAcceptRejectBookingSuccessActive}
      //   duration={1500}
      // />
      <Snackbar
        open={acceptRejectBookingSuccessToastActive}
        autoHideDuration={6000}
        onClose={toggleAcceptRejectBookingSuccessActive}
      >
        <Alert
          severity="success"
          onClose={toggleAcceptRejectBookingSuccessActive}
          sx={{ width: "100%" }}
        >
          {acceptrejectBookingMessage}
        </Alert>
      </Snackbar>
    ) : null;

  const [
    acceptRejectBookingErrorToastActive,
    setAcceptRejectBookingErrorToastActive,
  ] = useState(false);
  const toggleAcceptRejectBookingErrorActive = () => {
    setAcceptRejectBookingErrorToastActive(
      (acceptRejectBookingErrorToastActive) =>
        !acceptRejectBookingErrorToastActive
    );
    dispatch(getAllTaskListValue(Number(currentPage)));
  };
  const acceptRejectBookingErrorToastmarkup =
    acceptRejectBookingErrorToastActive ? (
      // <Toast
      //   content={acceptrejectBookingMessage}
      //   error
      //   onDismiss={toggleAcceptRejectBookingErrorActive}
      //   duration={1500}
      // />
      <Snackbar
        open={acceptRejectBookingErrorToastActive}
        autoHideDuration={6000}
        onClose={toggleAcceptRejectBookingErrorActive}
      >
        <Alert
          severity="success"
          onClose={toggleAcceptRejectBookingErrorActive}
          sx={{ width: "100%" }}
        >
          {acceptrejectBookingMessage}
        </Alert>
      </Snackbar>
    ) : null;

  // Call Accept or Reject Booking Appointment Store
  const executiveDropDownListLoadingvalue = useSelector(
    (state: AppState) => state.executiveDropdownListvalue.loading
  );

  const [selectedExecutive, setSelectedExecutive] = useState(null);

  const [executiveModalActive, setExecutiveModalActive] = useState(false);
  const handleExecutiveModalChange = () => {
    setExecutiveModalActive((executiveModalActive) => !executiveModalActive);
    setSelectedExecutive(null);
  };
  // Call Accept or Reject Booking Appointment Store
  const assigntaskToExecutiveLoadingValue = useSelector(
    (state: AppState) => state.assignExecutiveValues.loading
  );
  const assigntaskToExecutiveStatus = useSelector(
    (state: AppState) => state.assignExecutiveValues.status
  );
  // const assigntaskToExecutiveMessage = useSelector((state: AppState) => state.assignExecutiveValues.message);

  const [
    assigntaskToExecutiveSuccessToastActive,
    setAssigntaskToExecutiveSuccessToastActive,
  ] = useState(false);
  const toggleAssignTaskToExecutiveSuccessActive = () => {
    setAssigntaskToExecutiveSuccessToastActive(
      (assigntaskToExecutiveSuccessToastActive) =>
        !assigntaskToExecutiveSuccessToastActive
    );
    dispatch(getAllTaskListValue(Number(currentPage)));
  };
  const assignTaskToExecutveSuccessToastMarkup =
    assigntaskToExecutiveSuccessToastActive ? (
      // <Toast
      //   content="Task assigned successfully"
      //   onDismiss={toggleAssignTaskToExecutiveSuccessActive}
      //   duration={1500}
      // />
      <Snackbar
        open={assigntaskToExecutiveSuccessToastActive}
        autoHideDuration={6000}
        onClose={toggleAssignTaskToExecutiveSuccessActive}
      >
        <Alert
          severity="success"
          onClose={toggleAssignTaskToExecutiveSuccessActive}
          sx={{ width: "100%" }}
        >
          Task assigned successfully
        </Alert>
      </Snackbar>
    ) : null;

  const [
    assigntaskToExecutiveErrorToastActive,
    setAssigntaskToExecutiveErrorToastActive,
  ] = useState(false);
  console.log(assigntaskToExecutiveErrorToastActive);
  const toggleAssignTaskToExecutiveErrorActive = () => {
    setAssigntaskToExecutiveErrorToastActive(
      (assigntaskToExecutiveErrorToastActive) =>
        !assigntaskToExecutiveErrorToastActive
    );
    dispatch(getAllTaskListValue(Number(currentPage)));
  };
  const assignTaskToExecutveErrorToastMarkup =
    acceptRejectBookingErrorToastActive ? (
      // <Toast
      //   content="Something went wrong"
      //   error
      //   onDismiss={toggleAssignTaskToExecutiveErrorActive}
      //   duration={1500}
      // />
      <Snackbar
        open={acceptRejectBookingErrorToastActive}
        autoHideDuration={6000}
        onClose={toggleAssignTaskToExecutiveErrorActive}
      >
        <Alert
          severity="error"
          onClose={toggleAssignTaskToExecutiveErrorActive}
          sx={{ width: "100%" }}
        >
          Something went wrong
        </Alert>
      </Snackbar>
    ) : null;

  // Call task Close Store
  const taskCloseLoadingvalue = useSelector(
    (state: AppState) => state.taskCloseValues.loading
  );
  const taskCloseStatus = useSelector(
    (state: AppState) => state.taskCloseValues.status
  );
  const taskCloseMessage = useSelector(
    (state: AppState) => state.taskCloseValues.message
  );

  const [taskCloseSuccessToastActive, setTaskCloseSuccessToastActive] =
    useState(false);
  const toggletaskCloseSuccessActive = () => {
    setTaskCloseSuccessToastActive(
      (taskCloseSuccessToastActive) => !taskCloseSuccessToastActive
    );
    dispatch(getAllTaskListValue(Number(currentPage)));
  };
  const taskCloseSuccessToastMarkup = taskCloseSuccessToastActive ? (
    // <Toast
    //   content={taskCloseMessage}
    //   onDismiss={toggletaskCloseSuccessActive}
    //   duration={1500}
    // />
    <Snackbar
      open={taskCloseSuccessToastActive}
      autoHideDuration={6000}
      onClose={toggletaskCloseSuccessActive}
    >
      <Alert
        severity="success"
        onClose={toggletaskCloseSuccessActive}
        sx={{ width: "100%" }}
      >
        {taskCloseMessage}
      </Alert>
    </Snackbar>
  ) : null;

  const [taskCloseErrorToastActive, setTaskCloseErrorToastActive] =
    useState(false);
  const toggletaskCloseErrorActive = () => {
    setTaskCloseErrorToastActive(
      (taskCloseErrorToastActive) => !taskCloseErrorToastActive
    );
    dispatch(getAllTaskListValue(Number(currentPage)));
  };
  const taskCloseErrorToastMarkup = taskCloseErrorToastActive ? (
    // <Toast
    //   content={taskCloseMessage}
    //   error
    //   onDismiss={toggletaskCloseErrorActive}
    //   duration={1500}
    // />
    <Snackbar
      open={taskCloseErrorToastActive}
      autoHideDuration={6000}
      onClose={toggletaskCloseErrorActive}
    >
      <Alert
        severity="error"
        onClose={toggletaskCloseErrorActive}
        sx={{ width: "100%" }}
      >
        {taskCloseMessage}
      </Alert>
    </Snackbar>
  ) : null;

  function gotoCaseDetails() {
    history.push(
      `/casedetails/${taskValues.patientId}/${taskValues.caseId}`
    );
  }

  const acceptbokingBody = {
    taskId: Number(rowIndex),
    requestStatus: Number(1),
  };
  // Accept Booking Appointment API
  const acceptBooking = () => {
    dispatch(acceptorRejectBooking(acceptbokingBody));
    setAcceptRejectBookingSuccessToastActive(
      (acceptRejectBookingSuccessToastActive) =>
        !acceptRejectBookingSuccessToastActive
    );
    setAcceptRejectBookingErrorToastActive(
      (acceptRejectBookingErrorToastActive) =>
        !acceptRejectBookingErrorToastActive
    );
  };

  const rejectBookingBody = {
    taskId: Number(rowIndex),
    requestStatus: Number(-1),
  };
  // Receject Appointment Appointment API
  const rejectBooking = () => {
    dispatch(acceptorRejectBooking(rejectBookingBody));
    setAcceptRejectBookingSuccessToastActive(
      (acceptRejectBookingSuccessToastActive) =>
        !acceptRejectBookingSuccessToastActive
    );
    setAcceptRejectBookingErrorToastActive(
      (acceptRejectBookingErrorToastActive) =>
        !acceptRejectBookingErrorToastActive
    );
  };

  // Get Executive List API
  const getExecutiveList = () => {
    dispatch(getExecutiveDropdownList());
  };

  let assignExecutiveBody = {
    taskId: Number(rowIndex),
    executiveId: Number(selectedExecutive),
  };
  // Assign Executive API
  const assignExecutive = () => {
    dispatch(assignTaskToExecutive(assignExecutiveBody));
    setExecutiveModalActive((executiveModalActive) => !executiveModalActive);
    setAssigntaskToExecutiveSuccessToastActive(
      (assigntaskToExecutiveSuccessToastActive) =>
        !assigntaskToExecutiveSuccessToastActive
    );
    setAssigntaskToExecutiveErrorToastActive(
      (assigntaskToExecutiveErrorToastActive) =>
        !assigntaskToExecutiveErrorToastActive
    );
  };
  let taskCompleteBody = {
    taskId: String(rowIndex),
    taskCloseComment: "taskCloseByAdmin",
  };
  const handleTaskComplete = () => {
    dispatch(taskClose(taskCompleteBody));
    setTaskCloseSuccessToastActive(
      (taskCloseSuccessToastActive) => !taskCloseSuccessToastActive
    );
    setTaskCloseErrorToastActive(
      (taskCloseErrorToastActive) => !taskCloseErrorToastActive
    );
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getExecutiveList();
    if (taskValues === undefined) {
      dispatch(getAllTaskListValue(Number(currentPage)));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, taskValues]);
  const assignExecutiveModalMarkup = (
    <Dialog
      open={executiveModalActive}
      onClose={handleExecutiveModalChange}
      fullWidth
    >
      <DialogTitle>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start"
        >
          <Grid item>
            <Typography variant="h6"> {t("taskDetailsPage.executive", { ns: ["home"] })!}</Typography>
          </Grid>
          <Grid item>
            <IconButton onClick={handleExecutiveModalChange}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
        >
          <Grid item xs={12} sm={6} lg={8} xl={6}>
            <Typography>
            {t("taskDetailsPage.selectExecutive", { ns: ["home"] })!} "
              {taskValues === undefined ? "" : taskValues.title}"
            </Typography>
          </Grid>
        </Grid>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Grid
          container
          direction="row"
          justifyContent="flex-end"
          alignItems="flex-end"
          spacing={1}
        >
          <Grid item>
            <Button variant="outlined" onClick={handleExecutiveModalChange}>
            {t("accountdetail.cancel", { ns: ["home"] })!} 
            </Button>
          </Grid>
          <Grid item>
            <Button variant="contained" onClick={assignExecutive}>
            {t("taskDetailsPage.assign", { ns: ["home"] })!}
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );

  const taskTable = (
    <Box pt={4}>
      <Card>
        <CardHeader
          title={
            <Grid
              container
              direction="row"
              justifyContent="flex-end"
              alignItems="flex-start"
              spacing={2}
            >
              <Grid item>
                <ButtonGroup
                  variant="outlined"
                  aria-label="outlined button group"
                >
                  <Button
                    variant="outlined"
                    onClick={acceptBooking}
                    sx={{ textTransform: "none", fontSize: "bold" }}
                  >
                     {t("taskDetailsPage.acceptAppoinment", { ns: ["home"] })!}
                  </Button>
                  <Button
                    variant="outlined"
                    onClick={rejectBooking}
                    sx={{ textTransform: "none" }}
                  >
                  {t("taskDetailsPage.rejectAppoinment", { ns: ["home"] })!}
                  </Button>
                  <Button
                    variant="contained"
                    onClick={gotoCaseDetails}
                    sx={{ textTransform: "none" }}
                  >
                    {t("taskDetailsPage.viewcaseDetail", { ns: ["home"] })!}
                  </Button>
                </ButtonGroup>
              </Grid>
            </Grid>
          }
        />
        <CardContent>
          <TableContainer>
            <Table>
              <TableBody>
                {[
                  {
                    term: `${t("taskDetailsPage.title", { ns: ["home"] })!}`,
                    description: `${
                      taskValues === undefined ? "" : taskValues.title
                    }`,
                  },
                  {
                    term: `${t("taskDetailsPage.tskfor", { ns: ["home"] })!}`,
                    description: `${
                      taskValues === undefined ? "" : taskValues.patientName
                    }`,
                  },
                  {
                    term: `${t("taskDetailsPage.whyOpinion", { ns: ["home"] })!}`,
                    description: `${
                      taskValues === undefined
                        ? ""
                        : taskValues.whyWant2ndOpinion
                    }`,
                  },
                  {
                    term:  `${t("taskDetailsPage.reasonOpinion", { ns: ["home"] })!}`,
                    description: `${
                      taskValues === undefined
                        ? ""
                        : taskValues.secondOpinionReasons
                    }`,
                  },
                  {
                    term: `${t("taskDetailsPage.consultationTyp", { ns: ["home"] })!}`,
                    description: `${
                      taskValues === undefined
                        ? ""
                        : taskValues.consultationType
                    }`,
                  },
                  {
                    term: `${t("taskDetailsPage.taskCrtDate", { ns: ["home"] })!}`,
                    description: `${
                      taskValues === undefined ? "" : taskValues.created_date
                    }`,
                  },
                  {
                    term: `${t("taskDetailsPage.note", { ns: ["home"] })!}`,
                    description: `${
                      taskValues === undefined
                        ? ""
                        : taskValues.note === null
                        ? ""
                        : taskValues.note
                    }`,
                  },
                  {
                    term: `${t("taskDetailsPage.completed", { ns: ["home"] })!}`,
                    description: `${
                      taskValues === undefined || taskValues === null
                        ? ""
                        : taskValues.completed === false
                        ? "No"
                        : "yes"
                    }`,
                  },
                  {
                    term: `${t("taskpage.assignto", { ns: ["home"] })!}`,
                    description: `${
                      taskValues === undefined || taskValues === null
                        ? ""
                        : taskValues.assigned_to
                    }`,
                  },
                ].map((row) => (
                  <TableRow key={row.term}>
                    <TableCell component="th" scope="row">
                      <Typography variant="h5">{row.term}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="h5">{row.description}</Typography>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </CardContent>
        <CardActions>
          <Grid
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="flex-end"
            spacing={1}
          >
            <Grid item>
              <Button
                variant="outlined"
                sx={{ backgroundColor: "#f70000", color: "#ffffff" }}
                onClick={handleTaskComplete}
              >
               {t("taskDetailsPage.markascomplt", { ns: ["home"] })!}
              </Button>
            </Grid>
            <Grid item>
              <Button variant="contained" onClick={handleExecutiveModalChange}>
              {t("taskDetailsPage.assignExecutive", { ns: ["home"] })!}
              </Button>
            </Grid>
          </Grid>
        </CardActions>
      </Card>
    </Box>
  );

  return (
    <Box>
      {taskValues === undefined ||
      acceptrejectBookingLoadingvalue === true ||
      executiveDropDownListLoadingvalue === true ||
      assigntaskToExecutiveLoadingValue === true ||
      taskCloseLoadingvalue === true
        ? loadingMarkup
        : ""}
      {toSignin ? <Redirect to="/signin" /> : null}
      <Container maxWidth="xl" sx={{ pt: 2 }}>
        <MWPageTitle backButton={true} title= {t("taskDetailsPage.tskdetail", { ns: ["home"] })!} />
        {assignExecutiveModalMarkup}
        {taskTable}
        {acceptrejectBookingStatus! === 1
          ? acceptRejectBookingSuccessToastmarkup
          : ""}
        {acceptrejectBookingStatus! === -1
          ? acceptRejectBookingErrorToastmarkup
          : ""}
        {assigntaskToExecutiveStatus! === 1
          ? assignTaskToExecutveSuccessToastMarkup
          : ""}
        {assigntaskToExecutiveStatus! !== 1
          ? assignTaskToExecutveErrorToastMarkup
          : ""}
        {taskCloseStatus! === 1 ? taskCloseSuccessToastMarkup : ""}
        {taskCloseStatus! === -2 || taskCloseStatus! === -3
          ? taskCloseErrorToastMarkup
          : ""}
      </Container>
    </Box>
  );
}
