import {
  PatientImportConfigListActionTypes,
  ADD_PATIENT_IMPORT_CONFIG_LIST_FAIL,
  ADD_PATIENT_IMPORT_CONFIG_LIST_LOADING,
  ADD_PATIENT_IMPORT_CONFIG_LIST_SUCCESS,
  PatientImportConfigListState,
  ADD_PATIENT_IMPORT_CONFIG_LIST_UPDATE_API_MSG,
  ADD_PATIENT_IMPORT_CONFIG_LIST_UPDATE_API_RES,
} from "./ActionTypes";
import { AddPatientImportConfigValueRes } from "./Model";

const initialStateGetPosts:  PatientImportConfigListState = {
 loading: false,
 addPatientImportConfigListRes: {} as AddPatientImportConfigValueRes,
 successMsg: "",
 errorMsg: "",
 status: 0,
};
export const addPatientImportConfigReducer = (
 state = initialStateGetPosts,
 action:  PatientImportConfigListActionTypes
):  PatientImportConfigListState => {
 switch (action.type) {
   case  ADD_PATIENT_IMPORT_CONFIG_LIST_LOADING:
     return {
       ...state,
       loading: action.loading,
     };
   case  ADD_PATIENT_IMPORT_CONFIG_LIST_SUCCESS:
     return {
       ...state,
       addPatientImportConfigListRes: action.payload,
       successMsg: action.successMsg,
     };
   case  ADD_PATIENT_IMPORT_CONFIG_LIST_FAIL:
     return {
       ...state,
       addPatientImportConfigListRes: action.payload,
       errorMsg: action.errorMsg,
       status: action.status,
     };
   case  ADD_PATIENT_IMPORT_CONFIG_LIST_UPDATE_API_MSG:
     return {
       ...state,
       addPatientImportConfigListRes: action.payload,
       successMsg: action.successMsg,
       errorMsg: action.errorMsg,
       status: action.status,
     };
   case  ADD_PATIENT_IMPORT_CONFIG_LIST_UPDATE_API_RES:
     return {
       ...state,
       addPatientImportConfigListRes: action.payload,
       successMsg: action.successMsg,
       errorMsg: action.errorMsg,
       status: action.status,
     };
   default:
     return state;
 }
};


