import axios from "axios";
import { Dispatch } from "redux";
import { PatientImportConfigListActionTypes } from "./ActionTypes";
import { EditPatientImportConfigDetailsBody, EditPatientImportConfigValueRes } from "./Model";
import {
  editPatientImportConfigListAPIResClearAction,
  editPatientImportConfigListErrorAction,
  editPatientImportConfigListLoadingAction,
  editPatientImportConfigListSuccessAction,
  editPatientImportConfigListUpdateAPIMsgAction,
} from "./Actions";
import baseUrl from "../../ApiConfig";
 import { headers } from "../../component/Utility";
 import { setupInterceptorsTo } from "../Authentication/Interceptors";

let apiRes = {} as EditPatientImportConfigValueRes;
export const editPatientImportConfigApi = (payload: EditPatientImportConfigDetailsBody) => {
  return function (dispatch: Dispatch<PatientImportConfigListActionTypes>) {
    dispatch(editPatientImportConfigListLoadingAction(true));
    setupInterceptorsTo(axios)
      .post(
        baseUrl.baseUrl +
          `/patientapp/api/dataimport/edit_patient_import_config`,
        payload,
        {
          headers: headers,
        }
      )
      .then((res) => {
        apiRes = res.data;
        dispatch(editPatientImportConfigListLoadingAction(false));
        dispatch(
          editPatientImportConfigListSuccessAction(
            res.data,
            res.data.message !== undefined
              ? res.data.message
              : "Imported Config List Edited successfully"
          )
        );
      })
      .catch((error) => {
        dispatch(editPatientImportConfigListLoadingAction(false));
        dispatch(
          editPatientImportConfigListErrorAction(
            {} as EditPatientImportConfigValueRes,
            error.response !== undefined
              ? error.response.data.message || error.response.data.detail
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || 0 : 0
          )
        );
      });
  };
};

export const updatEditPatientImportConfigListAPIResMsg = () => {
  return function (dispatch: Dispatch<PatientImportConfigListActionTypes>) {
    dispatch(
      editPatientImportConfigListUpdateAPIMsgAction(
        apiRes as EditPatientImportConfigValueRes,
        "",
        "",
        0
      )
    );
  };
};

export const clearEditPatientImportConfigListAPIRes = () => {
  return function (dispatch: Dispatch<PatientImportConfigListActionTypes>) {
    dispatch(
      editPatientImportConfigListAPIResClearAction({} as EditPatientImportConfigValueRes, "", "", 0)
    );
  };
};


