import axios from "axios";
import Url from "../../../ApiConfig";
import { Dispatch } from "react";
import { CorporatePackagesTypes, CORPORATE_LIST_FAIL, CORPORATE_LIST_LOADING, CORPORATE_LIST_SUCCESS } from "./ActionTypes";
import { getCorporateListActions } from "./Actions";
import { setupInterceptorsTo } from "../../Authentication/Interceptors";
import { headers } from "../../../component/Utility";

// Active Corporate List API
export const getCorporatePackageList = (body: object) => {
    return function (dispatch: Dispatch<CorporatePackagesTypes>) {
        dispatch({
            type: CORPORATE_LIST_LOADING,
        });
        setupInterceptorsTo(axios)
            .post(
                Url.baseUrl +
                `/paymentapp/api/corporatePackageForAdmin/corporatePackageList`,
                body,
        { headers: headers }
            )
            .then((res) => {
                let corporatePackageList = res.data.corporatePackageList;
                dispatch(getCorporateListActions(corporatePackageList));
                dispatch({
                    type: CORPORATE_LIST_SUCCESS,
                    payload: corporatePackageList,
                });
            })
            .catch((error) => {
                dispatch({
                    type: CORPORATE_LIST_FAIL,
                });
            });
    };
};