import {
    ADMIN_PAYMENT_DATA,
    ADMIN_PAYMENT_FAIL,
    ADMIN_PAYMENT_LOADING,
    ADMIN_PAYMENT_SUCCESS,
    AdminPaymentActionTypes,
  } from "./ActionTypes";
  import { AdminPaymentResponse } from "./Model";
  
  export const adminPaymentAction = (
    adminPaymentRes: AdminPaymentResponse
  ): AdminPaymentActionTypes => {
    return {
      type: ADMIN_PAYMENT_DATA,
      payload: adminPaymentRes,
    };
  };
  
  export const adminPaymentLoadingAction = (
    loading: boolean
  ): AdminPaymentActionTypes => {
    return {
      type: ADMIN_PAYMENT_LOADING,
      loading: loading,
    };
  };
  
  export const adminPaymentSuccessAction = (
    adminPaymentRes: AdminPaymentResponse
  ): AdminPaymentActionTypes => {
    return {
      type: ADMIN_PAYMENT_SUCCESS,
      payload: adminPaymentRes,
    };
  };
  
  export const adminPaymentErrorAction = (
    adminPaymentRes: AdminPaymentResponse,
    errMsg: string,
    status: number
  ): AdminPaymentActionTypes => {
    return {
      type: ADMIN_PAYMENT_FAIL,
      payload: adminPaymentRes,
      message: errMsg,
      status: status,
    };
  };
  export const adminPaymentUpdateAPIMsgAction = (
    adminPaymentRes: AdminPaymentResponse,
    errMsg: string,
    status: number
  ): AdminPaymentActionTypes => {
    return {
      type: ADMIN_PAYMENT_FAIL,
      payload: adminPaymentRes,
      message: errMsg,
      status: status,
    };
  };
  
  export const adminPaymentAPIResClearAction = (
    adminPaymentRes: AdminPaymentResponse,
    errMsg: string,
    status: number
  ): AdminPaymentActionTypes => {
    return {
      type: ADMIN_PAYMENT_FAIL,
      payload: adminPaymentRes,
      message: errMsg,
      status: status,
    };
  };
  