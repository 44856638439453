import { GetDoctorListByCategoryRes } from "./Model";
export const GET_DOCTOR_LIST_BY_CATEGORY_LOADING =
  "GET_DOCTOR_LIST_BY_CATEGORY_LOADING";
export const GET_DOCTOR_LIST_BY_CATEGORY_SUCCESS =
  "GET_DOCTOR_LIST_BY_CATEGORY_SUCCESS";
export const GET_DOCTOR_LIST_BY_CATEGORY_FAIL =
  "GET_DOCTOR_LIST_BY_CATEGORY_FAIL";
export const GET_DOCTOR_LIST_BY_CATEGORY_UPDATE_API_MSG =
  "GET_DOCTOR_LIST_BY_CATEGORY_UPDATE_API_MSG";
export const GET_DOCTOR_LIST_BY_CATEGORY_UPDATE_API_RES =
  "GET_DOCTOR_LIST_BY_CATEGORY_UPDATE_API_RES";

export interface GetDoctorListByCategoryState {
  getDoctorListByCategoryRes: GetDoctorListByCategoryRes;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface GetDoctorListByCategoryLoading {
  type: typeof GET_DOCTOR_LIST_BY_CATEGORY_LOADING;
  loading: boolean;
}
export interface GetDoctorListByCategorySuccess {
  type: typeof GET_DOCTOR_LIST_BY_CATEGORY_SUCCESS;
  payload: GetDoctorListByCategoryRes;
  successMsg: string;
}
export interface GetDoctorListByCategoryFail {
  type: typeof GET_DOCTOR_LIST_BY_CATEGORY_FAIL;
  payload: GetDoctorListByCategoryRes;
  errorMsg: string;
  status: number;
}
export interface GetDoctorListByCategoryUpdateAPIMsg {
  type: typeof GET_DOCTOR_LIST_BY_CATEGORY_UPDATE_API_MSG;
  payload: GetDoctorListByCategoryRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface GetDoctorListByCategoryUpdateAPIRes {
  type: typeof GET_DOCTOR_LIST_BY_CATEGORY_UPDATE_API_RES;
  payload: GetDoctorListByCategoryRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}

export type GetDoctorListByCategoryActionTypes =
  | GetDoctorListByCategoryLoading
  | GetDoctorListByCategorySuccess
  | GetDoctorListByCategoryFail
  | GetDoctorListByCategoryUpdateAPIMsg
  | GetDoctorListByCategoryUpdateAPIRes;
