import React, { useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import {
  Box,
  Button,
  Stepper,
  Step,
  StepButton,
  StepConnector,
  StepLabel,
  Grid,
  Card,
  Stack,
  IconButton,
  CardContent,
  CardActions,
} from "@mui/material";
import { StepIconProps } from "@mui/material/StepIcon";
import SettingsIcon from "@mui/icons-material/Settings";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import GroupIcon from "@mui/icons-material/Group";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import PaymentIcon from "@mui/icons-material/Payment";
import PostAddIcon from "@mui/icons-material/PostAdd";
import ContactPhoneIcon from "@mui/icons-material/ContactPhone";
import EmojiPeopleIcon from "@mui/icons-material/EmojiPeople";
import CallIcon from "@mui/icons-material/Call";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import { Theme } from "@mui/material/styles";
import { makeStyles } from "tss-react/mui";
import { withStyles } from "tss-react/mui";
import MWPageTitle from "../../component/MWPageTitle";
import PageLayout from "../Layout/PageLayout";
import AdminLoader from "../AdminLoader";
import AppointmentSelectPatient from "./AppointmentSelectPatient";
import ConsultationOption from "./ConsultationOption";
import FilePreparation from "./FilePreparation";
import AppointmentSelectDoctor from "./AppointmentSelectDoctor";
import AppointmentPayment from "./AppointmentPayment";
import AddPatientTeam from "./AddPatientTeam";
import DoctorPatientInteraction from "./DoctorPatientInteraction";
import FinalOpinion from "./FinalOpinion";
import * as _ from "lodash";
import axios from "axios";
import baseUrl from "../../ApiConfig";
import { setupInterceptorsTo } from "../../redux/Authentication/Interceptors";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../redux/store/Store";
import { getPatientCaseList } from "../../redux/effects/ApiCall";
import { CaseList } from "../../redux/interfaces/Model";
import { getSteps } from "../../redux/Patient/FilePreparation/ApiCall";
// import { sectionandstepList } from "../../redux/StepsAndSection/API"; // This store is working but it is not using because the ui is loading before the api gets success so the steps is not updated
import { SecondOpinionUi } from "../../redux/StepsAndSection/Model";
import DeleteCaseDialog from "./DeleteCaseDialog";
import RequestAppointment from "./RequestAppointment";
import { headers } from "../../component/Utility";
import { t } from "i18next";

declare module "@mui/material" {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

const ColorlibConnector = withStyles(
  StepConnector,
  (_theme, _params, classes) => ({
    alternativeLabel: {
      top: 22,
    },
    completed: {
      [`& .${classes.line}`]: {
        backgroundImage:
          "linear-gradient( 95deg,rgb(71 151 232) 0%,rgb(18 126 236) 50%,rgb(11 142 226) 100%)",
      },
    },
    line: {
      height: 3,
      border: 0,
      backgroundColor: "#eaeaf0",
      borderRadius: 1,
    },
  })
);

const useColorlibStepIconStyles = makeStyles()({
  root: {
    backgroundColor: "#ccc",
    zIndex: 1,
    color: "#fff",
    width: 50,
    height: 50,
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
  },
  active: {
    backgroundImage:
      "linear-gradient( 136deg,rgb(16, 128, 19) 0%,rgb(49 156 197) 100%)",
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
  },
  completed: {
    backgroundImage:
      "linear-gradient( 136deg,rgb(18, 149, 243) 60%,rgb(26, 225, 234) 100%)",
  },
});

function ColorlibStepIcon(props: StepIconProps) {
  const { classes, cx } = useColorlibStepIconStyles();
  const { active, completed } = props;

  const icons: { [index: string]: React.ReactElement } = {
    1: <SettingsIcon />,
    2: <GroupIcon />,
    3: <ContactPhoneIcon />,
    4: <FileCopyIcon />,
    5: <GroupAddIcon />,
    6: <PostAddIcon />,
    7: <PaymentIcon />,
    8: <EmojiPeopleIcon />,
    9: <CallIcon />,
  };

  return (
    <div
      className={cx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {icons[String(props.icon)]}
    </div>
  );
}

const useStyles = makeStyles()((theme) => {
  return {
    root: {
      width: "100%",
      //backgroundColor: '#ccc',
    },
    button: {
      marginRight: theme.spacing(1),
    },
    instructions: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  };
});

export default function CaseDetails() {
  const { patientid } = useParams() as {
    patientid: string;
  };
  const { caseid } = useParams() as {
    caseid: string;
  };

  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const { classes } = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const [steps, setSteps] = useState<any[]>([]);
  const [stepDetails, setStepDetails] = useState<SecondOpinionUi[]>([]);

  const handleNext = () => {
    if (
      localStorage.getItem("consultationopt") === "Quick Video Consultation"
    ) {
      setActiveStep(3);
      localStorage.setItem("consultationopt", "");
    } else if (activeStep === steps.length - 1) {
      window.scrollTo(0, 0);
      history.goBack();
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };
  const [deleteDialog, setDeleteDialogOpen] = useState(false);
  const handleClick = () => {
    setDeleteDialogOpen(true);
  };
  const updateDeleteCaseDialogValFromChild = (passedVal: boolean) => {
    setDeleteDialogOpen(passedVal);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (step: number) => () => {
    setActiveStep(step);
  };
  const [completed] = React.useState(new Set<number>());
  function isStepComplete(step: number) {
    return completed.has(step);
  }

  const PatientListFromAPI = useSelector((state: AppState) => state.caseValue);
  const caseDetails: CaseList = _.filter(PatientListFromAPI.caseValue, [
    "caseId",
    Number(caseid),
  ])[0];

  const casehistoryname = useSelector(
    (state: AppState) => state.caseValue.patientname.patientName
  );

  const [appointmentPatientTeamLoader, setAppointmentPatientTeamLoader] =
    useState(false);
  const [appointmentSelectPatientLoader, setAppointmentSelectPatientLoader] =
    useState(false);
  // Get Loading value from Select Patient step section
  const appointmentSelectPatientLoaderUpdatedState = (passedVal: boolean) => {
    setAppointmentSelectPatientLoader(passedVal);
  };
  const [consultationLoader, setConsultationLoader] = useState(false);
  const [filePreparationLoader, setFilePreparationLoader] = useState(false);
  // Get Loading value from File Preparation step section
  const filePreparationLoaderUpdatedState = (passedVal: boolean) => {
    setFilePreparationLoader(passedVal);
  };

  const [selectDoctorLoader, setSelectDoctorLoader] = useState(false);

  // Get Loading value from File Preparation step section
  const selectDoctorLoaderLoaderUpdatedState = (passedVal: boolean) => {
    setSelectDoctorLoader(passedVal);
  };
  const [
    appointmentDoctorPatientInteractionLoader,
    setAppointmentDoctorPatientInteractionLoader,
  ] = useState(false);
  const [appointmentRequestLoader, setAppointmentRequestLoader] =
    useState(false);
  // Get Loading value from Appointment Request step section
  const appointmentRequestLoaderUpdatedState = (passedVal: boolean) => {
    setAppointmentRequestLoader(passedVal);
  };
  const [appointmentPaymentLoader, setAppointmentPaymentLoader] =
    useState(false);
  // Get Loading value from Appointment Payment step section
  const appointmentPaymentLoaderUpdatedState = (passedVal: boolean) => {
    setAppointmentPaymentLoader(passedVal);
  };
  const [appointmentFinalOpinionLoader, setAppointmentFinalOpinionLoader] =
    useState(false);
  const [doctorId] = useState(false);
  const loadingMarkup = <AdminLoader />;

  const urlpath = useLocation();

  useEffect(() => {
    if (urlpath.search.includes("selectPatient")) {
      setActiveStep(0);
    } else if (urlpath.search.includes("consultationType")) {
      setActiveStep(1);
    } else if (urlpath.search.includes("filePreparation")) {
      setActiveStep(2);
    } else if (urlpath.search.includes("selectDoctor")) {
      setActiveStep(3);
    } else if (urlpath.search.includes("bookAppointments")) {
      setActiveStep(4);
    } else if (urlpath.search.includes("payment")) {
      setActiveStep(5);
    } else if (urlpath.search.includes("patientTeam")) {
      setActiveStep(6);
    } else if (urlpath.search.includes("doctorpatientinteraction")) {
      setActiveStep(7);
    } else if (urlpath.search.includes("secondopinion")) {
      setActiveStep(8);
    } else {
      setActiveStep(0);
    }
    // eslint-disable-next-line
  }, [urlpath]);

  const getStepsAPI = () => {
    setIsLoading(true);
    dispatch(getSteps());
    setupInterceptorsTo(axios)
      .get(
        baseUrl.baseUrl +
          "/secondopinionapp/api/patientcaseui/steps_and_sections",
        { headers: headers }
      )
      .then((res) => {
        setIsLoading(false);
        setStepDetails(res.data.SecondOpinionUi);
        setSteps(
          res.data.SecondOpinionUi.map(function (el: { displayValue: any }) {
            return el.displayValue;
          })
        );
        // if (patientid) {
        //   setActiveStep(0);
        // }
      })
      .catch((error) => {
        // setIsLoading(false);
        // Error 😨
        if (error.response) {
          /*
           * The request was made and the server responded with a
           * status code that falls out of the range of 2xx
           */
          if (error.response.status === 401) {
            localStorage.clear();
            window.location.reload();
          }
        } else if (error.request) {
          /*
           * The request was made but no response was received, `error.request`
           * is an instance of XMLHttpRequest in the browser and an instance
           * of http.ClientRequest in Node.js
           */
        } else {
          // Something happened in setting up the request and triggered an Error
        }
      });
  };

  const getStepContent = (stepper: number) => {
    if (stepDetails[stepper] !== undefined) {
      switch (stepDetails[stepper].name) {
        case "selectPatient":
          return (
            <AppointmentSelectPatient
              appointmentSelectPatientLoader={
                appointmentSelectPatientLoaderUpdatedState
              }
              urlTab={stepDetails[stepper].name}
            />
          );
        case "consultationType":
          return (
            <ConsultationOption
              consultationLoader={setConsultationLoader}
              urlTab={stepDetails[stepper].name}
            />
          );
        case "filePreparation":
          return (
            <FilePreparation
              filePreparationLoader={filePreparationLoaderUpdatedState}
              urlTab={stepDetails[stepper].name}
            />
          );
        case "selectDoctor":
          return (
            <AppointmentSelectDoctor
              selectDoctorLoader={selectDoctorLoaderLoaderUpdatedState}
              urlTab={stepDetails[stepper].name}
            />
          );
        case "bookAppointments":
          return (
            <RequestAppointment
              appointmentBookLoader={appointmentRequestLoaderUpdatedState}
              urlTab={stepDetails[stepper].name}
            />
          );
        case "payment":
          return (
            <AppointmentPayment
              appointmentPaymentLoader={appointmentPaymentLoaderUpdatedState}
              urlTab={stepDetails[stepper].name}
            />
          );
        case "patientTeam":
          return (
            <AddPatientTeam
              appointmentPatientTeamLoader={setAppointmentPatientTeamLoader}
              urlTab={stepDetails[stepper].name}
            />
          );
        case "doctorpatientinteraction":
          return (
            <DoctorPatientInteraction
              appointmentDoctorPatientInteractionLoader={
                setAppointmentDoctorPatientInteractionLoader
              }
              urlTab={stepDetails[stepper].name}
            />
          );
        case "secondopinion":
          return (
            <FinalOpinion
              appointmentFinalOpinionLoader={setAppointmentFinalOpinionLoader}
              urlTab={stepDetails[stepper].name}
            />
          );
        default:
          return "Unknown step";
      }
    }
  };
  const history = useHistory();

  // const StepResponse = useSelector((state: AppState) => state.stepAndSection);

  const pageBody = (
    <Card>
      <CardContent>
        <Stack
          direction="column"
          justifyContent="flex-start"
          alignItems="stretch"
          spacing={2}
        >
          <div className={classes.root}>
            <Stepper
              alternativeLabel
              nonLinear
              activeStep={activeStep}
              connector={<ColorlibConnector />}
              style={{ backgroundColor: "transparent" }}
            >
              {steps?.map((label, index) => {
                const stepProps: { completed?: boolean } = {};
                return (
                  <Step
                    key={label}
                    {...stepProps}
                    completed={isStepComplete(index)}
                  >
                    <StepButton onClick={handleStep(index)}>
                      <StepLabel StepIconComponent={ColorlibStepIcon}>
                        {label}
                      </StepLabel>
                    </StepButton>
                  </Step>
                );
              })}
            </Stepper>
          </div>
          {activeStep === steps.length ? null : (
            <Box>{getStepContent(activeStep)}</Box>
          )}
        </Stack>
      </CardContent>
      <CardActions>
        {activeStep === steps.length ? null : (
          <Grid
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="flex-start"
            spacing={1}
          >
            <Grid item>
              <Button
                variant="outlined"
                disabled={activeStep === 0}
                onClick={handleBack}
                sx={{ textTransform: "none" }}
              >
                {t("appointmentpage.back", { ns: ["home"] })!}
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                onClick={handleNext}
                sx={{ textTransform: "none" }}
              >
                {activeStep === steps.length - 1
                  ? `${t("appointmentpage.close", { ns: ["home"] })!}`
                  : `${t("appointmentpage.next", { ns: ["home"] })!}`}
              </Button>
            </Grid>
          </Grid>
        )}
      </CardActions>
    </Card>
  );

  // Call the following on page rendering
  useEffect(() => {
    window.scrollTo(0, 0);
    getStepsAPI();
    dispatch(getPatientCaseList(patientid));
    // dispatch(sectionandstepList()); // This store is working but it is not using because the ui is loading before the api gets success so the steps is not updated
    // dispatch(sectionandstepList());
    // if (patientid) {
    //   setActiveStep(0);
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box>
      {doctorId === true ||
      appointmentSelectPatientLoader ||
      consultationLoader === true ||
      appointmentPaymentLoader === true ||
      appointmentPatientTeamLoader === true ||
      filePreparationLoader ||
      appointmentDoctorPatientInteractionLoader === true ||
      appointmentFinalOpinionLoader === true ||
      appointmentRequestLoader ||
      selectDoctorLoader ||
      isLoading
        ? loadingMarkup
        : null}
      <PageLayout>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item>
            <MWPageTitle
              backButton={true}
              title={`${
                caseDetails === undefined
                  ? `${
                      casehistoryname === undefined
                        ? ""
                        : casehistoryname + "'s Details"
                    }`
                  : `${
                      casehistoryname === undefined
                        ? ""
                        : casehistoryname + "'s Details"
                    }`
              }`}
              enableSubtitle={true}
              subtitle={`${
                caseDetails === undefined ? "" : caseDetails.whyWant2ndOpinion
              }`}
            />
          </Grid>
          <Grid item>
            <Grid
              container
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
              spacing={2}
            >
              <Grid item>
                <IconButton onClick={handleClick}>
                  <SettingsOutlinedIcon sx={{ color: "#8a877f" }} />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {pageBody}
      </PageLayout>
      <Box>
        {deleteDialog ? (
          <DeleteCaseDialog
            deleteCaseDialogEnable={deleteDialog}
            deleteCaseDialogUpdateState={updateDeleteCaseDialogValFromChild}
          />
        ) : null}
      </Box>
    </Box>
  );
}
