import {
  GET_DOCTOR_APPOINTMENT_LIST_FAIL,
  GET_DOCTOR_APPOINTMENT_LIST_LOADING,
  GET_DOCTOR_APPOINTMENT_LIST_SUCCESS,
  GET_DOCTOR_APPOINTMENT_LIST_API_MSG,
  GET_DOCTOR_APPOINTMENT_LIST_UPDATE_API_RES,
  GetDoctorAppointmentListActionTypes,
} from "./ActionTypes";
import { GetDoctorAppointmentListRes } from "./Model";

export const getDoctorAppointmentListLoadingAction = (
  loading: boolean
): GetDoctorAppointmentListActionTypes => {
  return {
    type: GET_DOCTOR_APPOINTMENT_LIST_LOADING,
    loading: loading,
  };
};

export const getDoctorAppointmentListSuccessAction = (
  getDoctorAppointmentListResponse: GetDoctorAppointmentListRes,
  successMsg: string
): GetDoctorAppointmentListActionTypes => {
  return {
    type: GET_DOCTOR_APPOINTMENT_LIST_SUCCESS,
    payload: getDoctorAppointmentListResponse,
    successMsg: successMsg,
  };
};

export const getDoctorAppointmentListErrorAction = (
  getDoctorAppointmentListResponse: GetDoctorAppointmentListRes,
  errMsg: string,
  status: number
): GetDoctorAppointmentListActionTypes => {
  return {
    type: GET_DOCTOR_APPOINTMENT_LIST_FAIL,
    payload: getDoctorAppointmentListResponse,
    errorMsg: errMsg,
    status: status,
  };
};
export const getDoctorAppointmentListAPIMsgAction = (
  getDoctorAppointmentListResponse: GetDoctorAppointmentListRes,
  successMsg: string,
  errMsg: string,
  status: number
): GetDoctorAppointmentListActionTypes => {
  return {
    type: GET_DOCTOR_APPOINTMENT_LIST_API_MSG,
    payload: getDoctorAppointmentListResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};

export const getDoctorAppointmentListAPIResClearAction = (
  getDoctorAppointmentListResponse: GetDoctorAppointmentListRes,
  successMsg: string,
  errMsg: string,
  status: number
): GetDoctorAppointmentListActionTypes => {
  return {
    type: GET_DOCTOR_APPOINTMENT_LIST_UPDATE_API_RES,
    payload: getDoctorAppointmentListResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};
