import {
  GetDoctorListForCaseActionTypes,
  GET_DOCTOR_LIST_FOR_CASE_FAIL,
  GET_DOCTOR_LIST_FOR_CASE_LOADING,
  GET_DOCTOR_LIST_FOR_CASE_SUCCESS,
  GetDoctorListForCaseState,
  GET_DOCTOR_LIST_FOR_CASE_UPDATE_API_MSG,
  GET_DOCTOR_LIST_FOR_CASE_UPDATE_API_RES,
} from "./ActionTypes";
import { GetDoctorListForCaseRes } from "./Model";

const initialStateGetPosts: GetDoctorListForCaseState = {
  loading: false,
  getDoctorListForCaseRes: {} as GetDoctorListForCaseRes,
  successMsg: "",
  errorMsg: "",
  status: 0,
};
export const getDoctorListForCaseReducer = (
  state = initialStateGetPosts,
  action: GetDoctorListForCaseActionTypes
): GetDoctorListForCaseState => {
  switch (action.type) {
    case GET_DOCTOR_LIST_FOR_CASE_LOADING:
      return {
        ...state,
        loading: action.loading,
      };
    case GET_DOCTOR_LIST_FOR_CASE_SUCCESS:
      return {
        ...state,
        getDoctorListForCaseRes: action.payload,
        successMsg: action.successMsg,
      };
    case GET_DOCTOR_LIST_FOR_CASE_FAIL:
      return {
        ...state,
        getDoctorListForCaseRes: action.payload,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case GET_DOCTOR_LIST_FOR_CASE_UPDATE_API_MSG:
      return {
        ...state,
        getDoctorListForCaseRes: action.payload,
        successMsg: action.successMsg,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case GET_DOCTOR_LIST_FOR_CASE_UPDATE_API_RES:
      return {
        ...state,
        getDoctorListForCaseRes: action.payload,
        successMsg: action.successMsg,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    default:
      return state;
  }
};
