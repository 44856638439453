import {
  ImportCallActionTypes,
  IMPORT_CALL_FAIL,
  IMPORT_CALL_LOADING,
  IMPORT_CALL_SUCCESS,
  ImportCallState,
  IMPORT_CALL_UPDATE_API_MSG,
  IMPORT_CALL_UPDATE_API_RES,
} from "./ActionTypes";
import { ImportCallResponse } from "./Model";

const initialStateGetPosts: ImportCallState = {
  loading: false,
  importCallRes: {} as ImportCallResponse,
  message: "",
  status: 0,
};
export const importCallReducer = (
  state = initialStateGetPosts,
  action: ImportCallActionTypes
): ImportCallState => {
  switch (action.type) {
    case IMPORT_CALL_LOADING:
      return {
        ...state,
        loading: true,
        importCallRes: {} as ImportCallResponse,
      };
    case IMPORT_CALL_SUCCESS:
      return {
        ...state,
        loading: false,
        importCallRes: action.payload,
      };
    case IMPORT_CALL_FAIL:
      return {
        ...state,
        loading: false,
        importCallRes: {} as ImportCallResponse,
        message: action.message,
        status: action.status,
      };
    case IMPORT_CALL_UPDATE_API_MSG:
      return {
        ...state,
        loading: false,
        importCallRes: action.payload,
        message: action.message,
        status: action.status,
      };
    case IMPORT_CALL_UPDATE_API_RES:
      return {
        ...state,
        loading: false,
        importCallRes: {} as ImportCallResponse,
        message: action.message,
        status: action.status,
      };
    default:
      return state;
  }
};
