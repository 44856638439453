import {
  GetViewOrganizationStaffListActionTypes,
  VIEW_ORG_STAFF_LIST_FAIL,
  VIEW_ORG_STAFF_LIST_LOADING,
  VIEW_ORG_STAFF_LIST_SUCCESS,
  ViewOrgStaffListState,
  VIEW_ORG_STAFF_LIST_API_MSG,
  VIEW_ORG_STAFF_LIST_API_RES,
} from "./ActionTypes";
import { GetViewOrganizationStaffListDetails } from "./Model";

const initialStateGetPosts: ViewOrgStaffListState = {
  loading: false,
  viewOrgStaffListRes: {} as GetViewOrganizationStaffListDetails,
  successMsg: "",
  errorMsg: "",
  status: 0,
};
export const getViewOrganizationStaffListReducer = (
  state = initialStateGetPosts,
  action: GetViewOrganizationStaffListActionTypes
): ViewOrgStaffListState => {
  switch (action.type) {
    case VIEW_ORG_STAFF_LIST_LOADING:
      return {
        ...state,
        loading: action.loading,
      };
    case VIEW_ORG_STAFF_LIST_SUCCESS:
      return {
        ...state,
        viewOrgStaffListRes: action.payload,
        successMsg: action.successMsg,
      };
    case VIEW_ORG_STAFF_LIST_FAIL:
      return {
        ...state,
        viewOrgStaffListRes: action.payload,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case VIEW_ORG_STAFF_LIST_API_MSG:
      return {
        ...state,
        viewOrgStaffListRes: action.payload,
        successMsg: action.successMsg,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case VIEW_ORG_STAFF_LIST_API_RES:
      return {
        ...state,
        viewOrgStaffListRes: action.payload,
        successMsg: action.successMsg,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    default:
      return state;
  }
};
