import React, { useState, useEffect } from "react";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Redirect, useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getPatientTeamList } from "../redux/PatientTeam/ApiCall";
import { AppState } from "../redux/store/Store";
import * as _ from "lodash";
import { PatientList } from "../redux/PatientTeam/Model";
import { getPatientList } from "../redux/CorporatePackage/PatientTeam/PatientList/ApiCall";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Container,
  Divider,
  Grid,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import MWPageTitle from "../component/MWPageTitle";
import MWExceptionList from "../component/MWExceptionList";
import { t } from "i18next";

export default function PatientTeamDetails() {
  const dispatch = useDispatch();
  const [toSignin] = useState(false);
  const { patientDetailsID } = useParams() as {
    patientDetailsID: string;
  };

  const { patientTeamCurrentPage } = useParams() as {
    patientTeamCurrentPage: string;
  };
  const history = useHistory();
  // Call Store
  const patientTeamList = useSelector(
    (state: AppState) => state.allPatientTeamListValue
  );
  const patientTeamValues: PatientList = _.filter(
    patientTeamList.allPatientTeamListValue.patientList,
    ["profileId", Number(patientDetailsID)]
  )[0];

  let patientTeamEmail =
    patientTeamValues === undefined ? "" : patientTeamValues.email;
  let patientTeamPhone =
    patientTeamValues === undefined ? "" : String(patientTeamValues.phone);

  const [patientTeamEmailPhone, setpatientTeamEmailPhone] = useState("");

  const truncate = (str: any) => {
    return str.length > 20 ? str.substring(0, 25) + "..." : str;
  };
  const DATE_OPTIONS = {
    weekday: "short",
    year: "numeric",
    month: "short",
    day: "numeric",
  } as const;

  function gotoAddPatient() {
    localStorage.setItem("PatientID", String(patientDetailsID));
    localStorage.setItem("PatientTeamID", String(patientTeamEmailPhone));
    history.push(`/demographicdetails`);
  }

  function gotoDetails(patientId: number) {
    history.push(`/patientcaselist/${patientId}`);
  }

  // Get Selected Doctor API
  const getPatientListByPatientTeam = () => {
    // setIsLoading(true);
    dispatch(getPatientList(patientDetailsID));
  };

  // call Patient list Store
  const patientList = useSelector(
    (state: AppState) => state.patientTeamPatientListvalue.patientListValue
  );

  let patientListConfig = [];
  for (var i = 0; i < patientList.length; i++) {
    // let taskSeqNo: number = i;
    let patientId: number = patientList[i].id;
    let firstname =
      patientList[i].firstname === "" || patientList[i].firstname === null
        ? " "
        : patientList[i].firstname;
    let lastname =
      patientList[i].lastname === "" || patientList[i].lastname === null
        ? " "
        : patientList[i].lastname;
    let dob =
      patientList[i].dob === "" || patientList[i].dob === null
        ? " "
        : new Date(patientList[i].dob).toLocaleDateString(
            "en-US",
            DATE_OPTIONS
          );
    let patientRelationshipWithOwner =
      patientList[i].patientRelationshipWithOwner?.displayValue;
    let gender = patientList[i].gender?.label;
    let bloodGroup = patientList[i].bloodGroup?.label;
    let description =
      patientList[i].description === "" || patientList[i].description === null
        ? " "
        : truncate(patientList[i].description);
    patientListConfig.push([
      <Button
        variant="outlined"
        startIcon={<VisibilityIcon />}
        onClick={() => gotoDetails(patientId)}
      >
        {t("common.view", { ns: ["home"] })}
      </Button>,
      patientId,
      firstname,
      lastname,
      dob,
      patientRelationshipWithOwner,
      gender,
      bloodGroup,
      description,
    ]);
  }
  const patientListTableValue: any[] = patientListConfig;

  useEffect(() => {
    window.scrollTo(0, 0);
    getPatientListByPatientTeam();
    if (patientTeamValues === undefined) {
      dispatch(getPatientTeamList(Number(patientTeamCurrentPage)));
    }
    if (patientTeamEmail === "") {
      setpatientTeamEmailPhone(String(patientTeamPhone));
    } else if (patientTeamPhone === "") {
      setpatientTeamEmailPhone(patientTeamEmail);
    } else {
      setpatientTeamEmailPhone(patientTeamEmail);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, patientTeamValues]);

  const patientTable = (
    <Box>
      <Card>
        <CardHeader
          title={
            patientTeamValues === undefined
              ? ""
              : patientTeamValues.firstname + " " + patientTeamValues.lastname
          }
        ></CardHeader>
        <CardActions>
          <Grid
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="flex-end"
          >
            <Button
              variant="contained"
              sx={{ textTransform: "none" }}
              onClick={() => gotoAddPatient()}
            >
              {t("demographicdetailpage.addpatient", { ns: ["home"] })}
            </Button>
          </Grid>
        </CardActions>
        <CardContent>
          {patientTeamValues ? (
            <Stack
              direction="column"
              justifyContent="flex-start"
              alignItems="stretch"
              spacing={2}
            >
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                spacing={2}
              >
                <Grid item xs={4} sm={4} md={4} lg={4}>
                  <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                    {t("common.emailorphno", { ns: ["home"] })}
                  </Typography>
                </Grid>
                <Grid item xs={8} sm={8} md={8} lg={8}>
                  <Typography component="dd" variant="body2">
                    {patientTeamValues === undefined
                      ? ""
                      : patientTeamValues.email}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Divider />
                </Grid>
                <Grid item xs={4} sm={4} md={4} lg={4}>
                  <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                    {t("accountdetail.phoneno", { ns: ["home"] })}
                  </Typography>
                </Grid>
                <Grid item xs={8} sm={8} md={8} lg={8}>
                  <Typography component="dd" variant="body2">
                    {patientTeamValues === undefined ||
                    patientTeamValues === null
                      ? ""
                      : patientTeamValues.phone}
                  </Typography>
                </Grid>
              </Grid>
            </Stack>
          ) : (
            <MWExceptionList />
          )}
        </CardContent>
      </Card>
      <br />
    </Box>
  );

  const patientListTable = patientListTableValue ? (
    <Card>
      <CardHeader
        title={t("patientlistpage.title", { ns: ["home"] })}
      ></CardHeader>
      <CardContent>
        {patientListTableValue && patientListTableValue.length ? (
          <TableContainer>
            <Table size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <TableCell>{t("common.view", { ns: ["home"] })}</TableCell>
                  <TableCell>
                    {t("patientlistpage.userid", { ns: ["home"] })}
                  </TableCell>
                  <TableCell>
                    {t("patientlistpage.firstname", { ns: ["home"] })}
                  </TableCell>
                  <TableCell>
                    {t("patientlistpage.lastname", { ns: ["home"] })}
                  </TableCell>
                  <TableCell>
                    {t("patientlistpage.dob", { ns: ["home"] })}
                  </TableCell>
                  <TableCell>
                    {t("patientlistpage.relation", { ns: ["home"] })}
                  </TableCell>
                  <TableCell>
                    {t("patientlistpage.gender", { ns: ["home"] })}
                  </TableCell>
                  <TableCell>
                    {t("patientlistpage.bloodgrp", { ns: ["home"] })}
                  </TableCell>
                  <TableCell>
                    {t("patientlistpage.des", { ns: ["home"] })}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {patientListTableValue.map((row: any) => (
                  <TableRow
                    key={row.description}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    <TableCell align="left">{row[0]}</TableCell>
                    <TableCell align="left">{row[1]}</TableCell>
                    <TableCell align="left">{row[2]}</TableCell>
                    <TableCell align="left">{row[3]}</TableCell>
                    <TableCell align="left">{row[4]}</TableCell>
                    <TableCell align="left">{row[5]}</TableCell>
                    <TableCell align="left">{row[6]}</TableCell>
                    <TableCell align="left">{row[7]}</TableCell>
                    <TableCell align="left">{row[8]}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <MWExceptionList />
        )}
      </CardContent>
    </Card>
  ) : (
    <Grid></Grid>
  );

  return (
    <Box>
      {toSignin ? <Redirect to="/signin" /> : null}
      <Container maxWidth="xl" sx={{ pt: 2 }}>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start"
        >
          <Grid item>
            <MWPageTitle
              backButton={true}
              title={t("common.patientTeamDetail", { ns: ["home"] })!}
            />
          </Grid>
          <Grid item></Grid>
        </Grid>
        <Box pt={4}>
          {patientTable}
          {patientListTable}
        </Box>
      </Container>
    </Box>
  );
}
