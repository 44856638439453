import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../../../ApiConfig";
import { GetCaseStatusListActionTypes } from "./ActionTypes";
import { GetCaseStatusListRes } from "./Model";
import {
  getCaseStatusListAPIResClearAction,
  getCaseStatusListErrorAction,
  getCaseStatusListLoadingAction,
  getCaseStatusListSuccessAction,
  getCaseStatusListUpdateAPIMsgAction,
} from "./Actions";
import { headers } from "../../../../component/Utility";

let apiRes = {} as GetCaseStatusListRes;
export const getCaseStatusList = () => {
  return function (dispatch: Dispatch<GetCaseStatusListActionTypes>) {
    dispatch(getCaseStatusListLoadingAction(true));
    axios
      .get(
        Url.baseUrl +
          `/adminapp/api/adminActions/patientCaseStatus_list_dropdown`,
        { headers: headers }
      )
      .then((res) => {
        apiRes = res.data;
        dispatch(getCaseStatusListLoadingAction(false));
        dispatch(getCaseStatusListSuccessAction(res.data));
      })
      .catch((error) => {
        dispatch(getCaseStatusListLoadingAction(false));
        dispatch(
          getCaseStatusListErrorAction(
            {} as GetCaseStatusListRes,
            error.response !== undefined
              ? error.response.data.message || error.response.data.detail
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || -1 : -1
          )
        );
      });
  };
};

export const updatePatientCaseDetailsEditAPIResMsg = () => {
  return function (dispatch: Dispatch<GetCaseStatusListActionTypes>) {
    dispatch(
      getCaseStatusListUpdateAPIMsgAction(apiRes as GetCaseStatusListRes, "", 0)
    );
  };
};

export const clearPatientCaseDetailsEditAPIRes = () => {
  return function (dispatch: Dispatch<GetCaseStatusListActionTypes>) {
    dispatch(
      getCaseStatusListAPIResClearAction({} as GetCaseStatusListRes, "", "", 0)
    );
  };
};
