import axios from "axios";
import { Dispatch } from "redux";
import { ScheduleMeetingActionTypes } from "./ActionTypes";
import { CreateScheduleMeetingDetailsBody, CreateScheduleMeetingValueRes } from "./Model";
import {
  scheduleMeetingAPIResClearAction,
  scheduleMeetingErrorAction,
  scheduleMeetingLoadingAction,
  scheduleMeetingSuccessAction,
  scheduleMeetingUpdateAPIMsgAction,
} from "./Action";
import baseUrl from "../../ApiConfig";
 import { headers } from "../../component/Utility";
 import { setupInterceptorsTo } from "../Authentication/Interceptors";

let apiRes = {} as CreateScheduleMeetingValueRes;
export const scheduleMeetingApi = (payload: CreateScheduleMeetingDetailsBody) => {
  return function (dispatch: Dispatch<ScheduleMeetingActionTypes>) {
    dispatch(scheduleMeetingLoadingAction(true));
    setupInterceptorsTo(axios)
      .post(
        baseUrl.baseUrl +
          `/telehealthapp/api/telehealth/createScheduleMeeting`,
        payload,
        {
          headers: headers,
        }
      )
      .then((res) => {
        apiRes = res.data;
        dispatch(scheduleMeetingLoadingAction(false));
        dispatch(
          scheduleMeetingSuccessAction(
            res.data,
            res.data.message !== undefined
              ? res.data.message
              : "Meeting schedule successfully"
          )
        );
      })
      .catch((error) => {
        dispatch(scheduleMeetingLoadingAction(false));
        dispatch(
          scheduleMeetingErrorAction(
            {} as CreateScheduleMeetingValueRes,
            error.response !== undefined
              ? error.response.data.message || error.response.data.detail
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || 0 : 0
          )
        );
      });
  };
};

export const updateScheduleMeetingAPIResMsg = () => {
  return function (dispatch: Dispatch<ScheduleMeetingActionTypes>) {
    dispatch(
      scheduleMeetingUpdateAPIMsgAction(
        apiRes as CreateScheduleMeetingValueRes,
        "",
        "",
        0
      )
    );
  };
};

export const clearScheduleMeetingAPIRes = () => {
  return function (dispatch: Dispatch<ScheduleMeetingActionTypes>) {
    dispatch(
      scheduleMeetingAPIResClearAction({} as CreateScheduleMeetingValueRes, "", "", 0)
    );
  };
};

