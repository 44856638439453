import {
  REQUEST_APPOINTMENT_FAIL,
  REQUEST_APPOINTMENT_LOADING,
  REQUEST_APPOINTMENT_SUCCESS,
  REQUEST_APPOINTMENT_API_MSG,
  REQUEST_APPOINTMENT_API_RES,
  RequestAppointmentActionTypes,
} from "./ActionTypes";
import { RequestAppointmentDataRes } from "./Model";

export const requestAppointmentLoadingAction = (
  loading: boolean
): RequestAppointmentActionTypes => {
  return {
    type: REQUEST_APPOINTMENT_LOADING,
    loading: loading,
  };
};

export const requestAppointmentSuccessAction = (
  addPatientDataResponse: RequestAppointmentDataRes,
  successMsg: string
): RequestAppointmentActionTypes => {
  return {
    type: REQUEST_APPOINTMENT_SUCCESS,
    payload: addPatientDataResponse,
    successMsg: successMsg,
  };
};

export const requestAppointmentErrorAction = (
  addPatientDataResponse: RequestAppointmentDataRes,
  errMsg: string,
  status: number
): RequestAppointmentActionTypes => {
  return {
    type: REQUEST_APPOINTMENT_FAIL,
    payload: addPatientDataResponse,
    errorMsg: errMsg,
    status: status,
  };
};
export const requestAppointmentAPIMsgAction = (
  addPatientDataResponse: RequestAppointmentDataRes,
  successMsg: string,
  errMsg: string,
  status: number
): RequestAppointmentActionTypes => {
  return {
    type: REQUEST_APPOINTMENT_API_MSG,
    payload: addPatientDataResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};

export const requestAppointmentAPIResClearAction = (
  addPatientDataResponse: RequestAppointmentDataRes,
  successMsg: string,
  errMsg: string,
  status: number
): RequestAppointmentActionTypes => {
  return {
    type: REQUEST_APPOINTMENT_API_RES,
    payload: addPatientDataResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};
