import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { AppState } from "../../redux/store/Store";
import { useDispatch, useSelector } from "react-redux";
import {
  AlertColor,
  Button,
  Card,
  CardContent,
  Stack,
} from "@mui/material";
import AdminLoader from "../AdminLoader";
import {
  getQuestionListValue,
  setVerifyValue,
} from "../../redux/QuestionAnswer/ApiCall";
import MWExceptionList from "../../component/MWExceptionList";
import MWPageTitle from "../../component/MWPageTitle";
import {
  DataGrid,
  GridColDef,
  GridCellParams,
  GridToolbar,
} from "@mui/x-data-grid";
import PageLayout from "../Layout/PageLayout";
import { t } from "i18next";
import AddAnswerDialog from "./AddAnswerDialog";
import MWSnackbar from "../../component/MWSnackbar";

export default function QuestionAnswer() {
  const loadingMarkup = <AdminLoader />;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getQuestionListValue());
  }, [dispatch]);

  const questionverify = (selectedResponse: any) => {
    const verifyBody = {
      questionId: selectedResponse,
      questionStatus: `${t("questionList.apiverify", { ns: ["home"] })!}`,
    };
    dispatch(setVerifyValue(verifyBody));
    dispatch(getQuestionListValue());
  };
  const [questionTextShow, setQuestionTextShow] = useState(false);
  const [selectedQuestionID, setSelectedQuestionID] = useState(false);
  const [selectedCaseID, setSelectedCaseID] = useState(false);
  const [countList, setCountList] = useState("");
  // popup open function..........
  function handleToggle(
    selectedResponse: any,
    questionText: any,
    selectedCaseID: any
  ) {
    setQuestionTextShow(questionText);
    setSelectedQuestionID(selectedResponse);
    setSelectedCaseID(selectedCaseID);
    setShowAddAnswerDialog(true);
  }

  const [showAddAnswerDialog, setShowAddAnswerDialog] = React.useState(false);
  const updateAddAnswerDialogValFromChild = (passedVal: boolean) => {
    setShowAddAnswerDialog(passedVal);
  };
  // CAll Verify Question Store
  const verifyQuestionAnswerLoadingvalue = useSelector(
    (state: AppState) => state.verifyQuestionValue
  );
  // Add Question Answer success
  const [verifyMsgType, setVerifyMsgType] =
    useState<AlertColor>("success");
  const [
    verifyQuestionSuccessMsg,
    setVerifyQuestionSuccessMsg,
  ] = useState("");

  useEffect(() => {
    if (verifyQuestionAnswerLoadingvalue?.payload?.message !== undefined) {
      setVerifyQuestionSuccessMsg(verifyQuestionAnswerLoadingvalue?.payload?.message);
      setVerifyMsgType("success");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [verifyQuestionAnswerLoadingvalue])

  const AdminQuestionList = useSelector(
    (state: AppState) => state.questionListValue
  );
  const questionListValues = AdminQuestionList.questionListValue;
  useEffect(() => {
    if (AdminQuestionList?.questionListValue !== undefined) {
      setCountList(JSON.stringify(AdminQuestionList?.questionListValue.length))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [AdminQuestionList]);
  const columns: GridColDef[] = [
    {
      field: "questionText",
      headerName: `${t("questionList.questiontxt", { ns: ["home"] })!}`,
      width: 600,
    },
    { field: "questionStatus", headerName: `${t("questionList.questionstatus", { ns: ["home"] })!}`, flex: 1 },

    {
      field: "selectedResponse",
      headerName: `${t("questionList.verify", { ns: ["home"] })!}`,
      flex: 1,
      renderCell: (params: GridCellParams) => (
        <Button
          variant="contained"
          sx={{ textTransform: "none" }}
          onClick={() => {
            questionverify(params.row.id);
          }}
        >
          {t("questionList.verify", { ns: ["home"] })!}
        </Button>
      ),
    },
    {
      field: "selectedCaseID",
      headerName: `${t("questionList.answer", { ns: ["home"] })!}`,
      flex: 1,
      renderCell: (params: GridCellParams) => (
        <Button
          variant="outlined"
          sx={{ textTransform: "none" }}
          onClick={() => {
            handleToggle(
              params.row.id,
              params.row.questionText,
              params.row.caseId
            );
          }}
        >
          {t("questionList.answer", { ns: ["home"] })!}
        </Button>
      ),
    },
  ];
  // Question Table
  const questionTableBody = (
    <Card>
      <CardContent>
        {questionListValues !== undefined ? (
          questionListValues?.length > 0 ? (
            <DataGrid
              rows={questionListValues}
              columns={columns}
              pageSize={10}
              hideFooter
              hideFooterPagination
              disableSelectionOnClick
              experimentalFeatures={{ newEditingApi: true }}
              autoHeight
              components={{ Toolbar: GridToolbar }}
              componentsProps={{
                toolbar: {
                  csvOptions: { disableToolbarButton: true },
                  printOptions: { disableToolbarButton: true },
                  showQuickFilter: true,
                  quickFilterProps: { debounceMs: 250 },
                },
              }}
              disableColumnFilter
              disableColumnSelector
              disableDensitySelector
            />
          ) : (
            <MWExceptionList />
          )
        ) : (
          <MWExceptionList />
        )}
      </CardContent>
    </Card>
  );

  const alertCloseAction = (alertState: boolean) => {
    if (alertState) {
        dispatch(getQuestionListValue());
    }
};

  return (
    <Box>
      {AdminQuestionList.loading ||
        verifyQuestionAnswerLoadingvalue.loading ? (
        <Stack>{loadingMarkup}</Stack>
      ) : null}
      <PageLayout>
        <MWPageTitle title={t("questionList.quesList", { ns: ["home"] })} enableCount={true}
          count={countList} />
        {questionTableBody}
        {showAddAnswerDialog ? (
          <AddAnswerDialog
            addAnswerDialogEnable={showAddAnswerDialog}
            addAnswerDialogUpdateState={updateAddAnswerDialogValFromChild}
            caseId={selectedCaseID}
            questionId={selectedQuestionID}
            questionTextShow={questionTextShow}
          />
        ) : null}
        {verifyQuestionSuccessMsg !== undefined ? (
          <Box my={2}>
            <MWSnackbar
              msg={verifyQuestionSuccessMsg}
              type={verifyMsgType}
              alertClose={alertCloseAction}
            />
          </Box>
        ) : null}
      </PageLayout>
    </Box>
  );
}
