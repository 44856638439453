import { GET_LIST } from "../types/ActionTypes";
import { GetAdministratorDetailsById } from "./Model";

export const ADMINISTRATOR_DETAILS_LOADING =
  "ADMINISTRATOR_DETAILS_LOADING";
export const ADMINISTRATOR_DETAILS_SUCCESS =
  "ADMINISTRATOR_DETAILS_SUCCESS";
export const ADMINISTRATOR_DETAILS_FAIL =
  "ADMINISTRATOR_DETAILS_FAIL";

export interface PatientDetailsState {
  GetAdministratorDetailsById: GetAdministratorDetailsById;
  loading: boolean;
}
export interface PatientDetailsDataLoading {
  type: typeof ADMINISTRATOR_DETAILS_LOADING;
}
export interface PatientDetailsDataSuccess {
  type: typeof ADMINISTRATOR_DETAILS_SUCCESS;
  payload: GetAdministratorDetailsById;
}
export interface PatientDetailsDataFail {
  type: typeof ADMINISTRATOR_DETAILS_FAIL;
}
interface PatientDetailsDataAction {
  type: typeof GET_LIST;
  payload: GetAdministratorDetailsById;
}

export type AdministratorDetailsActionTypes =
  | PatientDetailsDataAction
  | PatientDetailsDataLoading
  | PatientDetailsDataSuccess
  | PatientDetailsDataFail;
