import React, { useState, useEffect } from "react";
import {
  Box,
  Container,
  Grid,
  Stack,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import ActivePatientList from "./CorporatePatientList/ActivePatientList";
import ActiveAppointmentList from "./CorporateAppointmentList/ActivePaymentList";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../redux/store/Store";
import { ActiveCorporateList } from "../../redux/interfaces/Model";
import { getActiveCorporateList } from "../../redux/effects/ApiCall";
import * as _ from "lodash";
import MWPageTitle from "../../component/MWPageTitle";
import AdminLoader from "../AdminLoader";
import { t } from "i18next";
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </Box>
  );
}
function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
export default function ActiveCorporatePackageDetails() {
  const dispatch = useDispatch();
  const loadingMarkup = <AdminLoader />;

  const { id } = useParams() as {
    id: string;
  };
  const [selected, setSelected] = useState(0);
  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelected(newValue);
  };

  const [corporateActivePackageLoader, setCorporateActivePackageLoader] =
    useState(false);
  const [corporateInActivePackageLoader, setCorporateInActivePackageLoader] =
    useState(false);

  const corporatePage = (
    <Box>
      <Box>
        <Tabs value={selected} onChange={handleTabChange}>
          <Tab
            label={
              t("corporatepackage.patientTeamListCorpoPkg", { ns: ["home"] })!
            }
            {...a11yProps(0)}
          />
          <Tab
            label={t("corporatepackage.paymentListCorpoPkg", { ns: ["home"] })!}
            {...a11yProps(1)}
          />
        </Tabs>
      </Box>
      <TabPanel value={selected} index={0}>
        <ActivePatientList
          corporateActivePackageLoader={setCorporateActivePackageLoader}
          packageId={id}
        />
      </TabPanel>
      <TabPanel value={selected} index={1}>
        <ActiveAppointmentList
          corporateInActivePackageLoader={setCorporateInActivePackageLoader}
          packageId={id}
        />
      </TabPanel>
    </Box>
  );

  // call Store
  const activeCorporatePackageList = useSelector(
    (state: AppState) => state.activeCorporateListValue
  );
  const activeCorporateListDetails: ActiveCorporateList = _.filter(
    activeCorporatePackageList.activeCorporateListValue,
    ["id", Number(id)]
  )[0];

  useEffect(() => {
    window.scrollTo(0, 0);
    if (activeCorporateListDetails === undefined) {
      dispatch(getActiveCorporateList());
    }
  }, [dispatch, activeCorporateListDetails]);

  return (
    <Box>
      {corporateActivePackageLoader === true ||
      corporateInActivePackageLoader === true
        ? loadingMarkup
        : ""}
      <Container maxWidth="xl">
        <Stack
          direction="column"
          justifyContent="flex-start"
          alignItems="stretch"
          spacing={2}
          pt={2}
        >
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="flex-start"
          >
            <Grid item>
              <MWPageTitle
                backButton={true}
                title={
                  activeCorporateListDetails === undefined
                    ? ""
                    : activeCorporateListDetails.name + "'s Details"
                }
              />
            </Grid>
          </Grid>
          {corporatePage}
        </Stack>
      </Container>
    </Box>
  );
}
