import { GetProviderOrganizationListRes, PageCount } from "./Model";
export const GET_PROVIDER_ORGANIZATION_LIST_LOADING =
  "GET_PROVIDER_ORGANIZATION_LIST_LOADING";
export const GET_PROVIDER_ORGANIZATION_LIST_SUCCESS =
  "GET_PROVIDER_ORGANIZATION_LIST_SUCCESS";
export const GET_PROVIDER_ORGANIZATION_LIST_FAIL =
  "GET_PROVIDER_ORGANIZATION_LIST_FAIL";
export const GET_PROVIDER_ORGANIZATION_DATA = "GET_PROVIDER_ORGANIZATION_DATA";
export const GET_PROVIDER_ORGANIZATION_LIST_API_MSG =
  "GET_PROVIDER_ORGANIZATION_LIST_API_MSG";
export const GET_PROVIDER_ORGANIZATION_LIST_API_RES =
  "GET_PROVIDER_ORGANIZATION_LIST_API_RES";

export interface GetProviderOrganizationState {
  getProviderOrganizationRes: GetProviderOrganizationListRes;
  loading: boolean;
  errorMsg: string;
  status: number;
}
export interface GetProviderOrganizationLoading {
  type: typeof GET_PROVIDER_ORGANIZATION_LIST_LOADING;
  loading: boolean;
}
export interface GetProviderOrganizationSuccess {
  type: typeof GET_PROVIDER_ORGANIZATION_LIST_SUCCESS;
  payload: GetProviderOrganizationListRes;
}
export interface GetProviderOrganizationFail {
  type: typeof GET_PROVIDER_ORGANIZATION_LIST_FAIL;
  payload: GetProviderOrganizationListRes;
  errorMsg: string;
  status: number;
}
export interface GetProviderOrganizationAPIMsg {
  type: typeof GET_PROVIDER_ORGANIZATION_LIST_API_MSG;
  payload: GetProviderOrganizationListRes;
  errorMsg: string;
  status: number;
}
export interface GetProviderOrganizationAPIRes {
  type: typeof GET_PROVIDER_ORGANIZATION_LIST_API_RES;
  payload: GetProviderOrganizationListRes;
  errorMsg: string;
  status: number;
}

interface ProviderOrganizationListDataAction {
  type: typeof GET_PROVIDER_ORGANIZATION_DATA;
  payload: GetProviderOrganizationListRes;
  pageCount: PageCount;
}

export type GetProviderOriganizationListActionTypes =
  | GetProviderOrganizationLoading
  | GetProviderOrganizationSuccess
  | GetProviderOrganizationFail
  | GetProviderOrganizationAPIMsg
  | ProviderOrganizationListDataAction
  | GetProviderOrganizationAPIRes;
