import React, { useState, useEffect } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import { Box, Button, CardContent } from "@mui/material";
import dayjs from "dayjs";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../redux/store/Store";
import MWExceptionList from "../../../component/MWExceptionList";
import {
  GetDoctorWorkScheduleListBody,
  WorkSchedule,
} from "../../../redux/Doctor/Schedule/GetDoctorWorkScheduleList/Model";
import {
  DataGrid,
  GridCellParams,
  GridColDef,
  GridToolbar,
} from "@mui/x-data-grid";
import { getDoctorWorkScheduleList } from "../../../redux/Doctor/Schedule/GetDoctorWorkScheduleList/API";
import DeleteSchedule from "./DeleteSchedule";
import { getWeekDayList } from "../../../redux/Doctor/Schedule/WeekDayList/API";
import { t } from "i18next";

export default function Schedule() {
  const { id } = useParams() as {
    id: string;
  };
  const dispatch = useDispatch();

  const [deleteScheduleDialog, setDeleteScheduleDialog] = useState(false);
  const updateDeleteScheduleDialogUpdatedState = (passedVal: boolean) => {
    setDeleteScheduleDialog(passedVal);
  };
  const [deleteScheduleItem, setDeleteScheduleItem] = useState("");
  const deleteSchedule = (passedItem: string) => {
    if (passedItem !== "") {
      setDeleteScheduleDialog(true);
      setDeleteScheduleItem(passedItem);
    }
  };

  const getScheduleBody = {
    doctorId: id,
  } as GetDoctorWorkScheduleListBody;
  useEffect(() => {
    dispatch(getDoctorWorkScheduleList(getScheduleBody));
    dispatch(getWeekDayList());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const workSchedule = useSelector((state: AppState) => state.workSchedule);

  const [scheduleList, setScheduleList] = useState([] as any[]);

  useEffect(() => {
    if (
      workSchedule?.getDoctorWorkScheduleListRes?.workSchedule !== undefined
    ) {
      if (workSchedule?.getDoctorWorkScheduleListRes?.workSchedule.length > 0) {
        let scheduleListItem =
          workSchedule?.getDoctorWorkScheduleListRes?.workSchedule.map(
            (element: WorkSchedule) => {
              return {
                id: element.id,
                scheduleDay:
                  element?.dayOfTheWeek !== undefined
                    ? element?.dayOfTheWeek !== null
                      ? element?.dayOfTheWeek?.label
                      : ""
                    : "",
                startTime:
                  element.startTime !== undefined
                    ? element?.startTime !== null
                      ? element?.startTime.slice(0, 5)
                      : ""
                    : "",
                endTime:
                  element.endTime !== undefined
                    ? element?.endTime !== null
                      ? element?.endTime.slice(0, 5)
                      : ""
                    : "",
                startDateVal:
                  element?.startDateOfValidity !== undefined
                    ? element?.startDateOfValidity !== null
                      ? dayjs(element?.startDateOfValidity).format(
                          "ddd, MM, DD, YYYY"
                        )
                      : ""
                    : "",
                endDateVal:
                  element?.startDateOfValidity !== undefined
                    ? element?.startDateOfValidity !== null
                      ? dayjs(element?.startDateOfValidity).format(
                          "ddd, MM, DD, YYYY"
                        )
                      : ""
                    : "",
              };
            }
          );
        setScheduleList(scheduleListItem);
      } else setScheduleList([] as WorkSchedule[]);
    } else setScheduleList([] as WorkSchedule[]);
  }, [workSchedule]);

  const columns: GridColDef[] = [
    {
      field: "scheduleDay",
      headerName: `${t("Workschedulepage.scheday", { ns: ["home"] })!}`,
      flex: 1,
    },
    {
      field: "startTime",
      headerName: `${t("Workschedulepage.starttime", { ns: ["home"] })!}`,
      flex: 1,
    },
    {
      field: "endTime",
      headerName: `${t("Workschedulepage.endtime", { ns: ["home"] })!}`,
      flex: 1,
    },
    {
      field: "startDateVal",
      headerName: `${t("Workschedulepage.startvalidity", { ns: ["home"] })!}`,
      flex: 1,
    },
    {
      field: "endDateVal",
      headerName: `${t("Workschedulepage.endvalidity", { ns: ["home"] })!}`,
      flex: 1,
    },
    {
      field: "id",
      headerName: `${t("appointmentpage.del", { ns: ["home"] })!}`,
      flex: 1,
      renderCell: (params: GridCellParams) => (
        <Button
          startIcon={<DeleteIcon />}
          variant="outlined"
          onClick={() => {
            deleteSchedule(params.row.id);
          }}
          sx={{ textTransform: "none" }}
          // disabled={updateNotesVal?.loading}
        />
      ),
    },
  ];

  return (
    <CardContent>
      <Box>
        {scheduleList.length > 0 ? (
          <DataGrid
            rows={scheduleList}
            columns={columns}
            pageSize={10}
            hideFooter
            hideFooterPagination
            disableSelectionOnClick
            experimentalFeatures={{ newEditingApi: true }}
            autoHeight
            components={{ Toolbar: GridToolbar }}
            componentsProps={{
              toolbar: {
                csvOptions: { disableToolbarButton: true },
                printOptions: { disableToolbarButton: true },
                showQuickFilter: true,
                quickFilterProps: { debounceMs: 250 },
              },
            }}
            disableColumnFilter
            disableColumnSelector
            disableDensitySelector
          />
        ) : (
          <MWExceptionList />
        )}
        <DeleteSchedule
          itemId={deleteScheduleItem}
          deleteScheduleDialog={deleteScheduleDialog}
          updateDeleteScheduleDialogUpdatedState={
            updateDeleteScheduleDialogUpdatedState
          }
        />
      </Box>
    </CardContent>
  );
}
