import {
  GetAllUserListActionTypes,
  GET_ALL_USER_LIST_FAIL,
  GET_ALL_USER_LIST_LOADING,
  GET_ALL_USER_LIST_SUCCESS,
  GetAllUserListState,
  GET_ALL_USER_LIST_UPDATE_API_MSG,
  GET_ALL_USER_LIST_UPDATE_API_RES,
} from "./ActionTypes";
import { GetAllUserListRes } from "./Model";

const initialStateGetPosts: GetAllUserListState = {
  loading: false,
  getAllUserList: {} as GetAllUserListRes,
  successMsg: "",
  errorMsg: "",
  status: 0,
};
export const getAllUserListReducer = (
  state = initialStateGetPosts,
  action: GetAllUserListActionTypes
): GetAllUserListState => {
  switch (action.type) {
    case GET_ALL_USER_LIST_LOADING:
      return {
        ...state,
        loading: action.loading,
      };
    case GET_ALL_USER_LIST_SUCCESS:
      return {
        ...state,
        getAllUserList: action.payload,
        successMsg: action.successMsg,
      };
    case GET_ALL_USER_LIST_FAIL:
      return {
        ...state,
        getAllUserList: action.payload,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case GET_ALL_USER_LIST_UPDATE_API_MSG:
      return {
        ...state,
        getAllUserList: action.payload,
        successMsg: action.successMsg,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case GET_ALL_USER_LIST_UPDATE_API_RES:
      return {
        ...state,
        getAllUserList: action.payload,
        successMsg: action.successMsg,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    default:
      return state;
  }
};
