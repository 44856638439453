import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../../../ApiConfig";
import { ConfirmAppointmentActionTypes } from "./ActionTypes";
import { ConfirmAppointmentBody, ConfirmAppointmentRes } from "./Model";
import {
  confirmAppointmentAPIResClearAction,
  confirmAppointmentErrorAction,
  confirmAppointmentLoadingAction,
  confirmAppointmentSuccessAction,
  confirmAppointmentUpdateAPIMsgAction,
} from "./Actions";
import { setupInterceptorsTo } from "../../../Authentication/Interceptors";
import { headers } from "../../../../component/Utility";

let apiRes = {} as ConfirmAppointmentRes;
export const confirmAppointment = (payload: ConfirmAppointmentBody) => {
  return function (dispatch: Dispatch<ConfirmAppointmentActionTypes>) {
    dispatch(confirmAppointmentLoadingAction(true));
    setupInterceptorsTo(axios)
      .post(
        Url.baseUrl +
          `/adminapp/api/adminActions/confirm_appointment_for_patient_case`,
        payload,
        { headers: headers }
      )
      .then((res) => {
        apiRes = res.data;
        dispatch(confirmAppointmentLoadingAction(false));
        dispatch(
          confirmAppointmentSuccessAction(
            res.data !== undefined ? res.data : ({} as ConfirmAppointmentRes),
            "Appointment has been confirmed successfully"
          )
        );
      })
      .catch((error) => {
        dispatch(confirmAppointmentLoadingAction(false));
        dispatch(
          confirmAppointmentErrorAction(
            {} as ConfirmAppointmentRes,
            error.response !== undefined
              ? error.response.data.message || error.response.data.detail
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || 0 : 0
          )
        );
      });
  };
};

export const updateConfirmAppointmentAPIResMsg = () => {
  return function (dispatch: Dispatch<ConfirmAppointmentActionTypes>) {
    dispatch(
      confirmAppointmentUpdateAPIMsgAction(
        apiRes as ConfirmAppointmentRes,
        "",
        "",
        0
      )
    );
  };
};

export const clearConfirmAppointmentAPIRes = () => {
  return function (dispatch: Dispatch<ConfirmAppointmentActionTypes>) {
    dispatch(
      confirmAppointmentAPIResClearAction(
        {} as ConfirmAppointmentRes,
        "",
        "",
        0
      )
    );
  };
};
