import { PaymentStatusResponse } from "./Model";
export const PAYMENT_STATUS_LOADING =
  "PAYMENT_STATUS_LOADING";
export const PAYMENT_STATUS_SUCCESS =
  "PAYMENT_STATUS_SUCCESS";
export const PAYMENT_STATUS_FAIL =
  "PAYMENT_STATUS_FAIL";
export const PAYMENT_STATUS_DATA =
  "PAYMENT_STATUS_DATA";
export const PAYMENT_STATUS_UPDATE_API_MSG =
  "PAYMENT_STATUS_UPDATE_API_MSG";
export const PAYMENT_STATUS_UPDATE_API_RES =
  "PAYMENT_STATUS_UPDATE_API_RES";

export interface PaymentStatusState {
  statusPaymentRes: PaymentStatusResponse;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface PaymentStatusLoading {
  type: typeof PAYMENT_STATUS_LOADING;
  loading: boolean;
}
export interface PaymentStatusSuccess {
  type: typeof PAYMENT_STATUS_SUCCESS;
  payload: PaymentStatusResponse;
  successMsg: string;
  status: number;
}
export interface PaymentStatusFail {
  type: typeof PAYMENT_STATUS_FAIL;
  payload: PaymentStatusResponse;
  errorMsg: string;
  status: number;
}
export interface PaymentStatusUpdateAPIMsg {
  type: typeof PAYMENT_STATUS_UPDATE_API_MSG;
  payload: PaymentStatusResponse;
  errorMsg: string;
  status: number;
}
export interface PaymentStatusUpdateAPIRes {
  type: typeof PAYMENT_STATUS_UPDATE_API_RES;
  payload: PaymentStatusResponse;
  errorMsg: string;
  status: number;
}
interface PaymentStatusAction {
  type: typeof PAYMENT_STATUS_DATA;
  payload: PaymentStatusResponse;
}

export type PaymentStatusActionTypes =
  | PaymentStatusAction
  | PaymentStatusLoading
  | PaymentStatusSuccess
  | PaymentStatusFail
  | PaymentStatusUpdateAPIMsg
  | PaymentStatusUpdateAPIRes;
