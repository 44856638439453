import React, { useEffect, useState } from "react";
import {
  Box,
  Chip,
  Container,
  Grid,
  Stack,
  Tab,
  Typography,
} from "@mui/material";
import DoctorsOnboardingReject from "./DoctorsOnboardingReject";
import DoctorsOnboardingRequest from "./DoctorsOnboardingRequest";
import DoctorsOnboardingImcomplete from "./DoctorsOnboardingImcomplete";
import AdminLoader from "../AdminLoader";
import { t } from "i18next";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import RefreshIcon from "@mui/icons-material/Refresh";
import {
  getDoctorOnboardingIncompleteList,
  getDoctorOnboardingListValue,
  getDoctorOnboardingRejectList,
} from "../../redux/effects/ApiCall";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../redux/store/Store";

export default function DoctorsOnboarding() {
  const loadingMarkup = <AdminLoader />;
  const dispatch = useDispatch();
  const [docOndoardingRequestLoading, setDocOndoardRequestLoading] =
    useState(false);
  const [docOndoardingRejectLoading, setDocOndoardRejectLoading] =
    useState(false);
  const [docOndoardingIncompleteLoading, setDocOndoardIncompleteLoading] =
    useState(false);
  const [selected, setSelected] = useState("1");
  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setSelected(newValue);
  };
  const [listCount, setListCount] = React.useState("0");
  const [rejectListCount, setRejectListCount] = React.useState("0");
  const [incompleteListCount, setIncompleteListCount] = React.useState("0");

  const drRequestValue = useSelector(
    (state: AppState) => state.doctorRequestValue
  );

  const drRejectValue = useSelector(
    (state: AppState) => state.doctorRejectValue
  );

  const drIncompleteValue = useSelector(
    (state: AppState) => state.doctorIncompleteValue
  );

  useEffect(() => {
    dispatch(getDoctorOnboardingListValue());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    drRequestValue?.doctorRequestValue !== undefined
      ? setListCount(String(drRequestValue?.doctorRequestValue.length))
      : setListCount("0");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [drRequestValue, selected]);

  useEffect(() => {
    drRejectValue?.doctorRejectValue !== undefined
      ? setRejectListCount(String(drRejectValue?.doctorRejectValue.length))
      : setRejectListCount("0");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [drRejectValue, selected]);

  useEffect(() => {
    drIncompleteValue?.doctorIncompleteValue !== undefined
      ? setIncompleteListCount(
          String(drIncompleteValue?.doctorIncompleteValue.length)
        )
      : setIncompleteListCount("0");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [drIncompleteValue, selected]);

  const drRequestListApi = () => {
    dispatch(getDoctorOnboardingListValue());
  };

  const drRejectListApi = () => {
    dispatch(getDoctorOnboardingRejectList());
  };

  const drIncompleteListApi = () => {
    dispatch(getDoctorOnboardingIncompleteList());
  };

  const DoctorOnboardingRequestPage = (
    <Grid>
      <Grid item>
        <Box>
          <TabContext value={String(selected)}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList onChange={handleTabChange}>
                <Tab
                  label={
                    <Grid
                      container
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                      spacing={1}
                    >
                      <Grid item>
                        <Typography>
                          {t("docOnboarding.onboardReq", { ns: ["home"] })!}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Chip
                          label={listCount}
                          variant="outlined"
                          size="small"
                        />
                      </Grid>
                    </Grid>
                  }
                  icon={
                    <RefreshIcon
                      onClick={() => {
                        drRequestListApi();
                      }}
                    />
                  }
                  iconPosition="end"
                  value="1"
                />
                <Tab
                  label={
                    <Grid
                      container
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                      spacing={1}
                    >
                      <Grid item>
                        <Typography>
                          {t("docOnboarding.onboardReject", { ns: ["home"] })!}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Chip
                          label={rejectListCount}
                          variant="outlined"
                          size="small"
                        />
                      </Grid>
                    </Grid>
                  }
                  icon={
                    <RefreshIcon
                      onClick={() => {
                        drRejectListApi();
                      }}
                    />
                  }
                  iconPosition="end"
                  value="2"
                />
                <Tab
                  label={
                    <Grid
                      container
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                      spacing={1}
                    >
                      <Grid item>
                        <Typography>
                          {t("docOnboarding.onboardIncomp", { ns: ["home"] })!}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Chip
                          label={incompleteListCount}
                          variant="outlined"
                          size="small"
                        />
                      </Grid>
                    </Grid>
                  }
                  icon={
                    <RefreshIcon
                      onClick={() => {
                        drIncompleteListApi();
                      }}
                    />
                  }
                  iconPosition="end"
                  value="3"
                />
              </TabList>
            </Box>
            <TabPanel value="1">
              <DoctorsOnboardingRequest
                docOndoardingRequestLoading={setDocOndoardRequestLoading}
              />
            </TabPanel>
            <TabPanel value="2">
              <DoctorsOnboardingReject
                docOndoardingRejectLoading={setDocOndoardRejectLoading}
              />
            </TabPanel>
            <TabPanel value="3">
              <DoctorsOnboardingImcomplete
                docOndoardingIncompleteLoading={setDocOndoardIncompleteLoading}
              />
            </TabPanel>
          </TabContext>
        </Box>
      </Grid>
    </Grid>
  );
  return (
    <Box>
      {docOndoardingRequestLoading === true ||
      docOndoardingRejectLoading === true ||
      docOndoardingIncompleteLoading
        ? loadingMarkup
        : ""}
      <Container maxWidth="xl">
        <Stack
          direction="column"
          justifyContent="flex-start"
          alignItems="stretch"
          spacing={2}
          pt={2}
        >
          {DoctorOnboardingRequestPage}
        </Stack>
      </Container>
    </Box>
  );
}
