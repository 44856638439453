import {
  UPDATE_PATIENT_CASE_STATUS_FAIL,
  UPDATE_PATIENT_CASE_STATUS_LOADING,
  UPDATE_PATIENT_CASE_STATUS_SUCCESS,
  UPDATE_PATIENT_CASE_STATUS_UPDATE_API_MSG,
  UpdatePatientCaseStatusActionTypes,
} from "./ActionTypes";
import { UpdatePatientCaseStatusRes } from "./Model";

export const updatePatientCaseStatusLoadingAction = (
  loading: boolean
): UpdatePatientCaseStatusActionTypes => {
  return {
    type: UPDATE_PATIENT_CASE_STATUS_LOADING,
    loading: loading,
  };
};

export const updatePatientCaseStatusSuccessAction = (
  updatePatientCaseStatusResponse: UpdatePatientCaseStatusRes,
  successMsg: string
): UpdatePatientCaseStatusActionTypes => {
  return {
    type: UPDATE_PATIENT_CASE_STATUS_SUCCESS,
    payload: updatePatientCaseStatusResponse,
    successMsg: successMsg,
  };
};

export const updatePatientCaseStatusErrorAction = (
  updatePatientCaseStatusResponse: UpdatePatientCaseStatusRes,
  errMsg: string,
  status: number
): UpdatePatientCaseStatusActionTypes => {
  return {
    type: UPDATE_PATIENT_CASE_STATUS_FAIL,
    payload: updatePatientCaseStatusResponse,
    errorMsg: errMsg,
    status: status,
  };
};
export const updatePatientCaseStatusUpdateAPIMsgAction = (
  updatePatientCaseStatusResponse: UpdatePatientCaseStatusRes,
  successMsg: string,
  errMsg: string,
  status: number
): UpdatePatientCaseStatusActionTypes => {
  return {
    type: UPDATE_PATIENT_CASE_STATUS_UPDATE_API_MSG,
    payload: updatePatientCaseStatusResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};

export const updatePatientCaseStatusAPIResClearAction = (
  updatePatientCaseStatusResponse: UpdatePatientCaseStatusRes,
  errMsg: string,
  status: number
): UpdatePatientCaseStatusActionTypes => {
  return {
    type: UPDATE_PATIENT_CASE_STATUS_FAIL,
    payload: updatePatientCaseStatusResponse,
    errorMsg: errMsg,
    status: status,
  };
};
