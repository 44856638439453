import {
    DeletePastMedicalActionTypes,
    GetPatientDemographicsState,
    DELETE_PAST_MEDICAL_SUCCESS,
    DELETE_PAST_MEDICAL_LOADING,
    DELETE_PAST_MEDICAL_FAIL,
  } from "./ActionTypes";
  import { DeletePastMedicalResponse } from "./Model";
  
  const initialStateGetPosts: GetPatientDemographicsState = {
    loading: false,
    deleteIdentify: {} as DeletePastMedicalResponse,
  };
  export const deletePastMedicalReducer = (
    state = initialStateGetPosts,
    action: DeletePastMedicalActionTypes
  ): GetPatientDemographicsState => {
    switch (action.type) {
      case DELETE_PAST_MEDICAL_SUCCESS:
        return {
          ...state,
          loading: false,
          deleteIdentify: action.payload,
        };
      case DELETE_PAST_MEDICAL_LOADING:
        return {
          ...state,
          loading: true,
          deleteIdentify: {} as DeletePastMedicalResponse,
        };
      case DELETE_PAST_MEDICAL_FAIL:
        return {
          ...state,
          deleteIdentify: {} as DeletePastMedicalResponse,
          loading: false,
        };
      default:
        return state;
    }
  };