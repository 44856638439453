import { CreateScheduleMeetingValueRes } from "./Model";
export const  SCHEDULE_MEETING_LOADING = " SCHEDULE_MEETING_LOADING";
export const  SCHEDULE_MEETING_SUCCESS = " SCHEDULE_MEETING_SUCCESS";
export const  SCHEDULE_MEETING_FAIL = " SCHEDULE_MEETING_FAIL";
export const  SCHEDULE_MEETING_UPDATE_API_MSG =
  " SCHEDULE_MEETING_UPDATE_API_MSG";
export const  SCHEDULE_MEETING_UPDATE_API_RES =
  " SCHEDULE_MEETING_UPDATE_API_RES";

export interface  ScheduleMeetingState {
  scheduleMeetingRes: CreateScheduleMeetingValueRes;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface  ScheduleMeetingLoading {
  type: typeof  SCHEDULE_MEETING_LOADING;
  loading: boolean;
}
export interface  ScheduleMeetingSuccess {
  type: typeof  SCHEDULE_MEETING_SUCCESS;
  payload: CreateScheduleMeetingValueRes;
  successMsg: string;
}
export interface  ScheduleMeetingFail {
  type: typeof  SCHEDULE_MEETING_FAIL;
  payload: CreateScheduleMeetingValueRes;
  errorMsg: string;
  status: number;
}
export interface  ScheduleMeetingUpdateAPIMsg {
  type: typeof  SCHEDULE_MEETING_UPDATE_API_MSG;
  payload: CreateScheduleMeetingValueRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface  ScheduleMeetingUpdateAPIRes {
  type: typeof  SCHEDULE_MEETING_UPDATE_API_RES;
  payload: CreateScheduleMeetingValueRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}

export type  ScheduleMeetingActionTypes =
  |  ScheduleMeetingLoading
  |  ScheduleMeetingSuccess
  |  ScheduleMeetingFail
  |  ScheduleMeetingUpdateAPIMsg
  |  ScheduleMeetingUpdateAPIRes;
