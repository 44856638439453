import {
  GetInactiveCaseListActionTypes,
  GET_INACTIVE_CASE_LIST_FAIL,
  GET_INACTIVE_CASE_LIST_LOADING,
  GET_INACTIVE_CASE_LIST_SUCCESS,
  GetInactiveCaseListState,
  GET_INACTIVE_CASE_LIST_API_MSG,
  GET_INACTIVE_CASE_LIST_API_RES,
} from "./ActionTypes";
import { GetInactiveCaseListRes } from "./Model";

const initialStateUpdatePosts: GetInactiveCaseListState = {
  loading: false,
  getInactiveCaseListRes: {} as GetInactiveCaseListRes,
  errorMsg: "",
  status: 0,
};
export const getInactiveCaseListReducer = (
  state = initialStateUpdatePosts,
  action: GetInactiveCaseListActionTypes
): GetInactiveCaseListState => {
  switch (action.type) {
    case GET_INACTIVE_CASE_LIST_LOADING:
      return {
        ...state,
        loading: action.loading,
      };
    case GET_INACTIVE_CASE_LIST_SUCCESS:
      return {
        ...state,
        getInactiveCaseListRes: action.payload,
      };
    case GET_INACTIVE_CASE_LIST_FAIL:
      return {
        ...state,
        getInactiveCaseListRes: action.payload,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case GET_INACTIVE_CASE_LIST_API_MSG:
      return {
        ...state,
        getInactiveCaseListRes: action.payload,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case GET_INACTIVE_CASE_LIST_API_RES:
      return {
        ...state,
        getInactiveCaseListRes: action.payload,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    default:
      return state;
  }
};
