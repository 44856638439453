import React, { useEffect } from "react";
import { Container, Stack } from "@mui/material";
import DoctorTeamType from "./SelectDoctor/DoctorTeamType";
import SelectDoctorForCase from "./SelectDoctor/SelectDoctorForCase";
import SelectedDoctorList from "./SelectDoctor/SelectedDoctorList";
import { useHistory } from "react-router-dom";

type Props = {
  selectDoctorLoader: boolean | any;
  urlTab: string;
};

export default function AppointmentSelectDoctor({
  selectDoctorLoader,
  urlTab,
}: Props) {
  const history = useHistory();
  useEffect(() => {
    history.push(`${window.location.pathname + "?tab=" + urlTab}`);
    // eslint-disable-next-line
  }, [urlTab]);
  // Get Loading value from Doctor Team Type section
  const doctorTeamTypeLoaderValueFromChild = (passedVal: boolean) => {
    selectDoctorLoader(passedVal);
  };

  // Get Loading value from Select Doctor section
  const selectDoctorForCaseLoaderValueFromChild = (passedVal: boolean) => {
    selectDoctorLoader(passedVal);
  };

  // Get Loading value from Selected Doctor List section
  const selectedDoctorListLoaderValueFromChild = (passedVal: boolean) => {
    selectDoctorLoader(passedVal);
  };

  return (
    <Container maxWidth="xl">
      <Stack
        direction="column"
        justifyContent="flex-start"
        alignItems="stretch"
        spacing={2}
      >
        <DoctorTeamType
          doctorTeamTypeLoader={doctorTeamTypeLoaderValueFromChild}
        />
        <SelectDoctorForCase
          selectDoctorForCaseLoader={selectDoctorForCaseLoaderValueFromChild}
        />
        <SelectedDoctorList
          selectedDoctorListLoader={selectedDoctorListLoaderValueFromChild}
        />
      </Stack>
    </Container>
  );
}
