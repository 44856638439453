import { GET_LIST } from "../types/ActionTypes";
import { ImportFromPhoneResponse } from "./Model";

// Patient Demographics Loading State
export const IMPORT_FROM_PHONE_LOADING = "IMPORT_FROM_PHONE_LOADING";
export const IMPORT_FROM_PHONE_SUCCESS = "IMPORT_FROM_PHONE_SUCCESS";
export const IMPORT_FROM_PHONE_FAIL = "IMPORT_FROM_PHONE_FAIL";

export interface GetPatientDemographicsState {
  patientDemographicsRes: ImportFromPhoneResponse;
  loading: boolean;
}
export interface DoctorListLoading {
  type: typeof IMPORT_FROM_PHONE_LOADING;
}
export interface DoctorListSuccess {
  type: typeof IMPORT_FROM_PHONE_SUCCESS;
  payload: ImportFromPhoneResponse;
}
export interface DoctorListFail {
  type: typeof IMPORT_FROM_PHONE_FAIL;
}
interface GetDoctorAction {
  type: typeof GET_LIST;
  payload: ImportFromPhoneResponse;
}

export type ImportPhoneActionTypes =
  | GetDoctorAction
  | DoctorListLoading
  | DoctorListSuccess
  | DoctorListFail;
