import { PatientDataImportConfigList } from "./Model";

// Case Status
export const IMPORT_CONFIG_LIST_LOADING = "IMPORT_CONFIG_LIST_LOADING";
export const IMPORT_CONFIG_LIST_SUCCESS = "IMPORT_CONFIG_LIST_SUCCESS";
export const IMPORT_CONFIG_LIST_FAIL = "IMPORT_CONFIG_LIST_FAIL";
export const IMPORT_CONFIG_LIST_TYPE = 'IMPORT_CONFIG_LIST_TYPE';

export interface ImportConfigListStateType {
    loading: boolean;
    patientDataImportConfigList: PatientDataImportConfigList[];
    message: string;
    status: number;
}
export interface ImportConfigListLoading {
    type: typeof IMPORT_CONFIG_LIST_LOADING;
}
export interface ImportConfigListSuccess {
    type: typeof IMPORT_CONFIG_LIST_SUCCESS;
    patientDataImportConfigList: PatientDataImportConfigList[];
    message: string;
    status: number;
}
export interface ImportConfigListFail {
    type: typeof IMPORT_CONFIG_LIST_FAIL;
    message: string;
    status: number;
}

interface ImportConfigListType {
    type: typeof IMPORT_CONFIG_LIST_TYPE;
    patientDataImportConfigList: PatientDataImportConfigList[];
    message: string;
    status: number;
}

export type ImportConfigListTypes = ImportConfigListType | ImportConfigListLoading | ImportConfigListSuccess | ImportConfigListFail;
// Add Import Data
export const ADD_OR_EDIT_IMPORT_CONFIG_LIST_LOADING = "ADD_OR_EDIT_IMPORT_CONFIG_LIST_LOADING";
export const ADD_OR_EDIT_IMPORT_CONFIG_LIST_SUCCESS = "ADD_OR_EDIT_IMPORT_CONFIG_LIST_SUCCESS";
export const ADD_OR_EDIT_IMPORT_CONFIG_LIST_FAIL = "ADD_OR_EDIT_IMPORT_CONFIG_LIST_FAIL";
export const ADD_OR_EDIT_IMPORT_CONFIG_LIST_TYPE = 'ADD_OR_EDIT_IMPORT_CONFIG_LIST_TYPE';

export interface AddOrEditImportConfigStateType {
    loading: boolean;
    message: string;
    status: number;
}
export interface AddOrEditImportConfigLoading {
    type: typeof ADD_OR_EDIT_IMPORT_CONFIG_LIST_LOADING;
}
export interface AddOrEditImportConfigSuccess {
    type: typeof ADD_OR_EDIT_IMPORT_CONFIG_LIST_SUCCESS;
    message: string;
    status: number;
}
export interface AddOrEditImportConfigFail {
    type: typeof ADD_OR_EDIT_IMPORT_CONFIG_LIST_FAIL;
    message: string;
    status: number;
}

interface AddOrEditImportConfigType {
    type: typeof ADD_OR_EDIT_IMPORT_CONFIG_LIST_TYPE;
    message: string;
    status: number;
}

export type AddOrEditImportConfigTypes = AddOrEditImportConfigType | AddOrEditImportConfigLoading | AddOrEditImportConfigSuccess | AddOrEditImportConfigFail;
// Case Status
export const IMPORT_DATA_LIST_LOADING = "IMPORT_DATA_LIST_LOADING";
export const IMPORT_DATA_LIST_SUCCESS = "IMPORT_DATA_LIST_SUCCESS";
export const IMPORT_DATA_LIST_FAIL = "IMPORT_DATA_LIST_FAIL";
export const IMPORT_DATA_LIST_TYPE = 'IMPORT_DATA_LIST_TYPE';

export interface ImportDataListStateType {
    loading: boolean;
    patientDetailList: PatientDataImportConfigList[];
    message: string;
    status: number;
}
export interface ImportDataListLoading {
    type: typeof IMPORT_DATA_LIST_LOADING;
}
export interface ImportDataListSuccess {
    type: typeof IMPORT_DATA_LIST_SUCCESS;
    patientDetailList: PatientDataImportConfigList[];
    message: string;
    status: number;
}
export interface ImportDataListFail {
    type: typeof IMPORT_DATA_LIST_FAIL;
    message: string;
    status: number;
}

interface ImportDataListType {
    type: typeof IMPORT_DATA_LIST_TYPE;
    patientDetailList: PatientDataImportConfigList[];
    message: string;
    status: number;
}

export type ImportDataListTypes = ImportDataListType | ImportDataListLoading | ImportDataListSuccess | ImportDataListFail;