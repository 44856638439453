import {
  DELETE_PATIENT_CASE_FAIL,
  DELETE_PATIENT_CASE_LOADING,
  DELETE_PATIENT_CASE_SUCCESS,
  DELETE_PATIENT_CASE_UPDATE_API_MSG,
  DELETE_PATIENT_CASE_UPDATE_API_RES,
  DeletePatientCaseActionTypes,
} from "./ActionTypes";
import { DeletePatientCaseRes } from "./Model";

export const deletePatientCaseLoadingAction = (
  loading: boolean
): DeletePatientCaseActionTypes => {
  return {
    type: DELETE_PATIENT_CASE_LOADING,
    loading: loading,
  };
};

export const deletePatientCaseSuccessAction = (
  deletePatientCaseResponse: DeletePatientCaseRes
): DeletePatientCaseActionTypes => {
  return {
    type: DELETE_PATIENT_CASE_SUCCESS,
    payload: deletePatientCaseResponse,
  };
};

export const deletePatientCaseErrorAction = (
  deletePatientCaseResponse: DeletePatientCaseRes,
  errMsg: string,
  status: number
): DeletePatientCaseActionTypes => {
  return {
    type: DELETE_PATIENT_CASE_FAIL,
    payload: deletePatientCaseResponse,
    errorMsg: errMsg,
    status: status,
  };
};
export const deletePatientCaseUpdateAPIMsgAction = (
  deletePatientCaseResponse: DeletePatientCaseRes,
  errMsg: string,
  status: number
): DeletePatientCaseActionTypes => {
  return {
    type: DELETE_PATIENT_CASE_UPDATE_API_MSG,
    payload: deletePatientCaseResponse,
    errorMsg: errMsg,
    status: status,
  };
};

export const deletePatientCaseAPIResClearAction = (
  deletePatientCaseResponse: DeletePatientCaseRes,
  successMsg: string,
  errMsg: string,
  status: number
): DeletePatientCaseActionTypes => {
  return {
    type: DELETE_PATIENT_CASE_UPDATE_API_RES,
    payload: deletePatientCaseResponse,
    errorMsg: errMsg,
    status: status,
  };
};
