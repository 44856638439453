import {
  GET_DOCTOR_LIST_FOR_CASE_FAIL,
  GET_DOCTOR_LIST_FOR_CASE_LOADING,
  GET_DOCTOR_LIST_FOR_CASE_SUCCESS,
  GET_DOCTOR_LIST_FOR_CASE_UPDATE_API_MSG,
  GET_DOCTOR_LIST_FOR_CASE_UPDATE_API_RES,
  GetDoctorListForCaseActionTypes,
} from "./ActionTypes";
import { GetDoctorListForCaseRes } from "./Model";

export const getDoctorListForCaseLoadingAction = (
  loading: boolean
): GetDoctorListForCaseActionTypes => {
  return {
    type: GET_DOCTOR_LIST_FOR_CASE_LOADING,
    loading: loading,
  };
};

export const getDoctorListForCaseSuccessAction = (
  getDoctorListForCaseResponse: GetDoctorListForCaseRes,
  successMsg: string
): GetDoctorListForCaseActionTypes => {
  return {
    type: GET_DOCTOR_LIST_FOR_CASE_SUCCESS,
    payload: getDoctorListForCaseResponse,
    successMsg: successMsg,
  };
};

export const getDoctorListForCaseErrorAction = (
  getDoctorListForCaseResponse: GetDoctorListForCaseRes,
  errMsg: string,
  status: number
): GetDoctorListForCaseActionTypes => {
  return {
    type: GET_DOCTOR_LIST_FOR_CASE_FAIL,
    payload: getDoctorListForCaseResponse,
    errorMsg: errMsg,
    status: status,
  };
};
export const getDoctorListForCaseUpdateAPIMsgAction = (
  getDoctorListForCaseResponse: GetDoctorListForCaseRes,
  successMsg: string,
  errMsg: string,
  status: number
): GetDoctorListForCaseActionTypes => {
  return {
    type: GET_DOCTOR_LIST_FOR_CASE_UPDATE_API_MSG,
    payload: getDoctorListForCaseResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};

export const getDoctorListForCaseAPIResClearAction = (
  getDoctorListForCaseResponse: GetDoctorListForCaseRes,
  successMsg: string,
  errMsg: string,
  status: number
): GetDoctorListForCaseActionTypes => {
  return {
    type: GET_DOCTOR_LIST_FOR_CASE_UPDATE_API_RES,
    payload: getDoctorListForCaseResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};
