import {
  AdministratorDetailsActionTypes,
  ADMINISTRATOR_DETAILS_FAIL,
  ADMINISTRATOR_DETAILS_LOADING,
  ADMINISTRATOR_DETAILS_SUCCESS,
  PatientDetailsState,
} from "./ActionTypes";
import { GetAdministratorDetailsById } from "./Model";

const initialStateGetPosts: PatientDetailsState = {
  loading: false,
  GetAdministratorDetailsById: {} as GetAdministratorDetailsById,
};
export const getAdministratorDetailsReducer = (
  state = initialStateGetPosts,
  action: AdministratorDetailsActionTypes
): PatientDetailsState => {
  switch (action.type) {
    case ADMINISTRATOR_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        GetAdministratorDetailsById: action.payload,
      };
    case ADMINISTRATOR_DETAILS_LOADING:
      return {
        ...state,
        loading: true,
        GetAdministratorDetailsById: {} as GetAdministratorDetailsById,
      };
    case ADMINISTRATOR_DETAILS_FAIL:
      return {
        ...state,
        GetAdministratorDetailsById: {} as GetAdministratorDetailsById,
        loading: false,
      };
    default:
      return state;
  }
};
