import { GET_LIST } from "../../types/ActionTypes";
import { PatientDataDetailsRes } from "./Model";

// Patient Demographics Loading State
export const PATIENT_DATA_DETAILS_POINT_LOADING =
  "PATIENT_DATA_DETAILS_POINT_LOADING";
export const PATIENT_DATA_DETAILS_POINT_SUCCESS =
  "PATIENT_DATA_DETAILS_POINT_SUCCESS";
export const PATIENT_DATA_DETAILS_POINT_FAIL =
  "PATIENT_DATA_DETAILS_POINT_FAIL";

export interface AddPatientPointDataState {
  getPatientDataDetailsRes: PatientDataDetailsRes;
  loading: boolean;
  pageCount: number;
}
export interface PatientPointDataLoading {
  type: typeof PATIENT_DATA_DETAILS_POINT_LOADING;
}
export interface PatientPointDataSuccess {
  type: typeof PATIENT_DATA_DETAILS_POINT_SUCCESS;
  payload: PatientDataDetailsRes;
  pageCount: number;
}
export interface PatientPointDataFail {
  type: typeof PATIENT_DATA_DETAILS_POINT_FAIL;
}
interface PatientPointDataAction {
  type: typeof GET_LIST;
  payload: PatientDataDetailsRes;
  pageCount: number;
}

export type PatientPointDataListActionType =
  | PatientPointDataAction
  | PatientPointDataLoading
  | PatientPointDataSuccess
  | PatientPointDataFail;
