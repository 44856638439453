import {
  UpdatePatientDataPointActionTypes,
  DATA_POINT_HISTORY_SEARCH_FAIL,
  DATA_POINT_HISTORY_SEARCH_LOADING,
  DATA_POINT_HISTORY_SEARCH_SUCCESS,
  UpdatePatientDataPointState,
  PATIENT_SEARCH_UPDATE_API_MSG,
  PATIENT_SEARCH_UPDATE_API_RES,
} from "./ActionTypes";
import { SimpleHistoryRes } from "./Model";

const initialStateGetPosts: UpdatePatientDataPointState = {
  loading: false,
  updatePatientDataPointRes: {} as SimpleHistoryRes,
  message: "",
  status: 0,
};
export const simpleHistoryReducer = (
  state = initialStateGetPosts,
  action: UpdatePatientDataPointActionTypes
): UpdatePatientDataPointState => {
  switch (action.type) {
    case DATA_POINT_HISTORY_SEARCH_LOADING:
      return {
        ...state,
        loading: true,
        updatePatientDataPointRes: {} as SimpleHistoryRes,
      };
    case DATA_POINT_HISTORY_SEARCH_SUCCESS:
      return {
        ...state,
        loading: false,
        updatePatientDataPointRes: action.payload,
      };
    case DATA_POINT_HISTORY_SEARCH_FAIL:
      return {
        ...state,
        loading: false,
        updatePatientDataPointRes: {} as SimpleHistoryRes,
        message: action.message,
        status: action.status,
      };
    case PATIENT_SEARCH_UPDATE_API_MSG:
      return {
        ...state,
        loading: false,
        updatePatientDataPointRes: action.payload,
        message: action.message,
        status: action.status,
      };
    case PATIENT_SEARCH_UPDATE_API_RES:
      return {
        ...state,
        loading: false,
        updatePatientDataPointRes: {} as SimpleHistoryRes,
        message: action.message,
        status: action.status,
      };
    default:
      return state;
  }
};
