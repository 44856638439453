import {
  DR_APPROVE_REJECT_FAIL,
  DR_APPROVE_REJECT_LOADING,
  DR_APPROVE_REJECT_SUCCESS,
  DR_APPROVE_REJECT_API_MSG,
  DR_APPROVE_REJECT_API_RES,
  DrApproveRejectActionTypes,
} from "./ActionTypes";
import { DoctorOnboardIncompleteResponse } from "./Model";

export const drApproveRejectLoadingAction = (
  loading: boolean
): DrApproveRejectActionTypes => {
  return {
    type: DR_APPROVE_REJECT_LOADING,
    loading: loading,
  };
};

export const drApproveRejectSuccessAction = (
  drApproveRejectResponse: DoctorOnboardIncompleteResponse,
  successMsg: string
): DrApproveRejectActionTypes => {
  return {
    type: DR_APPROVE_REJECT_SUCCESS,
    payload: drApproveRejectResponse,
    successMsg: successMsg,
  };
};

export const drApproveRejectErrorAction = (
  drApproveRejectResponse: DoctorOnboardIncompleteResponse,
  errMsg: string,
  status: number
): DrApproveRejectActionTypes => {
  return {
    type: DR_APPROVE_REJECT_FAIL,
    payload: drApproveRejectResponse,
    errorMsg: errMsg,
    status: status,
  };
};
export const drApproveRejectAPIMsgAction = (
  drApproveRejectResponse: DoctorOnboardIncompleteResponse,
  successMsg: string,
  errMsg: string,
  status: number
): DrApproveRejectActionTypes => {
  return {
    type: DR_APPROVE_REJECT_API_MSG,
    payload: drApproveRejectResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};

export const drApproveRejectAPIResClearAction = (
  drApproveRejectResponse: DoctorOnboardIncompleteResponse,
  successMsg: string,
  errMsg: string,
  status: number
): DrApproveRejectActionTypes => {
  return {
    type: DR_APPROVE_REJECT_API_RES,
    payload: drApproveRejectResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};