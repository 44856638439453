import React, { useEffect, useState } from "react";
import ActiveTaskList from "./ActiveTaskList";
import InactiveTaskDetails from "./InactiveTaskList";
import AdminLoader from "../AdminLoader";
import { Tab, Box, Grid, Typography, Chip } from "@mui/material";
import MWPageTitle from "../../component/MWPageTitle";
import PageLayout from "../Layout/PageLayout";
import { t } from "i18next";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import RefreshIcon from "@mui/icons-material/Refresh";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../redux/store/Store";
import { getAllTaskListValue } from "../../redux/TaskAdmin/List/API";


export default function Task() {
  const loadingMarkup = <AdminLoader />;
  const dispatch = useDispatch();
  const [activeTaskLoader, setActiveTaskLoader] = useState(false);
  const [inactiveTaskLoader, setInactiveTaskLoader] = useState(false);
  const [selected, setSelected] = useState("1");
  const [activeTaskListCount, setActiveTaskListCount] = React.useState("");
  const [inactiveTaskListCount, setInactiveTaskListCount] = React.useState("");
  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setSelected(newValue);
    localStorage.setItem("selctedOnboardingtab", String(newValue));
  };

  // call Store
  const activeTaskList = useSelector(
    (state: AppState) => state.alltaskListValue
  );

  const inActiveTaskList = useSelector(
    (state: AppState) => state.allinactivetasklist
  );

  useEffect(() => {
    activeTaskList?.adminTaskRes?.count !== undefined
      ? setActiveTaskListCount(
        String(activeTaskList?.adminTaskRes?.count)
      )
      : setActiveTaskListCount("0");

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTaskList]);

  useEffect(() => {
    inActiveTaskList?.allinactivetasklist?.length !== undefined
      ? setInactiveTaskListCount(
        String(inActiveTaskList?.allinactivetasklist?.length)
      )
      : setInactiveTaskListCount("0");

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inActiveTaskList]);

  const reloadActiveTaskList = () => {
    dispatch(getAllTaskListValue(1, 1));
  };

  const reloadInactiveTaskList = () => {
    dispatch(getAllTaskListValue(1, 0));
  };

  const pageMarkUp = (
    <Grid>
      <Grid item>
        <Box>
          <TabContext value={String(selected)}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList onChange={handleTabChange}>
                <Tab
                  label={
                    <Grid
                      container
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                      spacing={1}
                    >
                      <Grid item>
                        <Typography>
                          {t("taskpage.activelist", { ns: ["home"] })!}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Chip
                          label={activeTaskListCount}
                          variant="outlined"
                          size="small"
                        />
                      </Grid>
                    </Grid>
                  }
                  icon={
                    <RefreshIcon
                      onClick={() => {
                        reloadActiveTaskList();
                      }}
                    />
                  }
                  iconPosition="end"
                  value="1"
                />
                <Tab
                  label={
                    <Grid
                      container
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                      spacing={1}
                    >
                      <Grid item>
                        <Typography>
                          {t("taskpage.inactivelist", { ns: ["home"] })!}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Chip
                          label={String(inactiveTaskListCount)}
                          variant="outlined"
                          size="small"
                        />
                      </Grid>
                    </Grid>
                  }
                  icon={
                    <RefreshIcon
                      onClick={() => {
                        reloadInactiveTaskList();
                      }}
                    />
                  }
                  iconPosition="end"
                  value="2"
                />
              </TabList>
            </Box>
            <TabPanel value="1">
              <ActiveTaskList activeTaskLoader={setActiveTaskLoader} />
            </TabPanel>
            <TabPanel value="2">
              <InactiveTaskDetails inactiveTaskLoader={setInactiveTaskLoader} />
            </TabPanel>
          </TabContext>
        </Box>
      </Grid>
    </Grid>
  );

  return (
    <Box>
      {activeTaskLoader === true || inactiveTaskLoader === true ? loadingMarkup : ""}
      <PageLayout>
        <MWPageTitle title={t("common.task", { ns: ["home"] })!} />
        {pageMarkUp}
      </PageLayout>
    </Box>
  );
}
