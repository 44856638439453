import { AppointmentDeactivateResponse } from "./Model";
export const APPOINTMENT_DEACTIVATE_LOADING =
  "APPOINTMENT_DEACTIVATE_LOADING";
export const APPOINTMENT_DEACTIVATE_SUCCESS =
  "APPOINTMENT_DEACTIVATE_SUCCESS";
export const APPOINTMENT_DEACTIVATE_FAIL =
  "APPOINTMENT_DEACTIVATE_FAIL";
export const APPOINTMENT_DEACTIVATE_DATA =
  "APPOINTMENT_DEACTIVATE_DATA";
export const APPOINTMENT_DEACTIVATE_UPDATE_API_MSG =
  "APPOINTMENT_DEACTIVATE_UPDATE_API_MSG";
export const APPOINTMENT_DEACTIVATE_UPDATE_API_RES =
  "APPOINTMENT_DEACTIVATE_UPDATE_API_RES";

export interface AppointmentDeactivateState {
  appointmentListRes: AppointmentDeactivateResponse;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface AppointmentDeactivateLoading {
  type: typeof APPOINTMENT_DEACTIVATE_LOADING;
  loading: boolean;
}
export interface AppointmentDeactivateSuccess {
  type: typeof APPOINTMENT_DEACTIVATE_SUCCESS;
  payload: AppointmentDeactivateResponse;
  successMsg: string;
  status: number;
}
export interface AppointmentDeactivateFail {
  type: typeof APPOINTMENT_DEACTIVATE_FAIL;
  payload: AppointmentDeactivateResponse;
  errorMsg: string;
  status: number;
}
export interface AppointmentDeactivateUpdateAPIMsg {
  type: typeof APPOINTMENT_DEACTIVATE_UPDATE_API_MSG;
  payload: AppointmentDeactivateResponse;
  errorMsg: string;
  status: number;
}
export interface AppointmentDeactivateUpdateAPIRes {
  type: typeof APPOINTMENT_DEACTIVATE_UPDATE_API_RES;
  payload: AppointmentDeactivateResponse;
  errorMsg: string;
  status: number;
}
interface AppointmentDeactivateAction {
  type: typeof APPOINTMENT_DEACTIVATE_DATA;
  payload: AppointmentDeactivateResponse;
}

export type AppointmentDeactivateActionTypes =
  | AppointmentDeactivateAction
  | AppointmentDeactivateLoading
  | AppointmentDeactivateSuccess
  | AppointmentDeactivateFail
  | AppointmentDeactivateUpdateAPIMsg
  | AppointmentDeactivateUpdateAPIRes;
