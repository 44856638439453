import { AdminHealthcampList } from "./Model";
export const HEALTHCAMP_LIST_LOADING = "HEALTHCAMP_LIST_LOADING";
export const HEALTHCAMP_LIST_SUCCESS = "HEALTHCAMP_LIST_SUCCESS";
export const HEALTHCAMP_LIST_FAIL = "HEALTHCAMP_LIST_FAIL";
export const GET_HEALTHCAMP_LIST = "GET_HEALTHCAMP_LIST";

export interface GetHealthcampListStateType {
  healthcampListValue: AdminHealthcampList;
  loading: boolean;
}

export interface HealthcampListLoading {
  type: typeof HEALTHCAMP_LIST_LOADING;
}
export interface HealthcampListSuccess {
  type: typeof HEALTHCAMP_LIST_SUCCESS;
  payload: AdminHealthcampList;
}
export interface HealthcampListFail {
  type: typeof HEALTHCAMP_LIST_FAIL;
}
interface AdminGetHealthcampListActionType {
  type: typeof GET_HEALTHCAMP_LIST;
  payload: AdminHealthcampList;
}

export type AdminGetHealthcampListActionTypes =
  | AdminGetHealthcampListActionType
  | HealthcampListLoading
  | HealthcampListSuccess
  | HealthcampListFail;