import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../../ApiConfig";
import { AddNoteActionTypes } from "./ActionTypes";
import { AddNoteBody, AddNoteRes } from "./Model";
import {
  addNoteAPIResClearAction,
  addNoteErrorAction,
  addNoteLoadingAction,
  addNoteSuccessAction,
  addNoteUpdateAPIMsgAction,
} from "./Actions";
import { headers } from "../../../component/Utility";

let apiRes = {} as AddNoteRes;
export const addNote = (payload: AddNoteBody) => {
  return function (dispatch: Dispatch<AddNoteActionTypes>) {
    dispatch(addNoteLoadingAction(true));
    axios
      .post(
        Url.baseUrl + `/erpapp/api/adminNote/add_adminNote_for_patient`,
        payload,
        { headers: headers }
      )
      .then((res) => {
        apiRes = res.data;
        dispatch(addNoteLoadingAction(false));
        dispatch(
          addNoteSuccessAction(res.data, "Note has been added successfully")
        );
      })
      .catch((error) => {
        dispatch(addNoteLoadingAction(false));
        dispatch(
          addNoteErrorAction(
            {} as AddNoteRes,
            error.response !== undefined
              ? error.response.data.message || error.response.data.detail
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || -1 : -1
          )
        );
      });
  };
};

export const updateAddNoteAPIResMsg = () => {
  return function (dispatch: Dispatch<AddNoteActionTypes>) {
    dispatch(addNoteUpdateAPIMsgAction(apiRes as AddNoteRes, "", "", 0));
  };
};

export const clearAddNoteAPIRes = () => {
  return function (dispatch: Dispatch<AddNoteActionTypes>) {
    dispatch(addNoteAPIResClearAction({} as AddNoteRes, "", "", 0));
  };
};
