import {
  PatientSearchByHealthCampIdActionTypes,
  PATIENT_SEARCH_BY_HEALTHCAMP_FAIL,
  PATIENT_SEARCH_BY_HEALTHCAMP_LOADING,
  PATIENT_SEARCH_BY_HEALTHCAMP_SUCCESS,
  PatientSearchByHealthcampIdDetailsState,
  PATIENT_SEARCH_UPDATE_API_MSG,
  PATIENT_SEARCH_UPDATE_API_RES,
} from "./ActionTypes";
import { PatientSearchByHealthCampIdDetails } from "./Model";

const initialStateGetPosts: PatientSearchByHealthcampIdDetailsState = {
  loading: false,
  PatientSearchByHealthcampIdRes: {} as PatientSearchByHealthCampIdDetails,
  message: "",
  status: 0,
};
export const patientSearchByHealthCampIdReducer = (
  state = initialStateGetPosts,
  action: PatientSearchByHealthCampIdActionTypes
): PatientSearchByHealthcampIdDetailsState => {
  switch (action.type) {
    case PATIENT_SEARCH_BY_HEALTHCAMP_LOADING:
      return {
        ...state,
        loading: true,
        PatientSearchByHealthcampIdRes: {} as PatientSearchByHealthCampIdDetails,
      };
    case PATIENT_SEARCH_BY_HEALTHCAMP_SUCCESS:
      return {
        ...state,
        loading: false,
        PatientSearchByHealthcampIdRes: action.payload,
      };
    case PATIENT_SEARCH_BY_HEALTHCAMP_FAIL:
      return {
        ...state,
        loading: false,
        PatientSearchByHealthcampIdRes: {} as PatientSearchByHealthCampIdDetails,
        message: action.message,
        status: action.status,
      };
    case PATIENT_SEARCH_UPDATE_API_MSG:
      return {
        ...state,
        loading: false,
        PatientSearchByHealthcampIdRes: action.payload,
        message: action.message,
        status: action.status,
      };
    case PATIENT_SEARCH_UPDATE_API_RES:
      return {
        ...state,
        loading: false,
        PatientSearchByHealthcampIdRes: {} as PatientSearchByHealthCampIdDetails,
        message: action.message,
        status: action.status,
      };
    default:
      return state;
  }
};
