import {
  GetExtractObservationDetails,
  GetExtractObservationActionTypes,
  GET_EXTRACT_OBSERVATION_FAIL,
  GET_EXTRACT_OBSERVATION_LOADING,
  GET_EXTRACT_OBSERVATION_SUCCESS,
} from "./ActionTypes";
import { GetExtractObservationRes } from "./Model";

const initialStateGetPosts: GetExtractObservationDetails = {
  loading: false,
  getExtractObservationRes: {} as GetExtractObservationRes,
  pageCount: 0,
  errRes: "",
};
export const GetExtractObservationByPatientIdReducer = (
  state = initialStateGetPosts,
  action: GetExtractObservationActionTypes
): GetExtractObservationDetails => {
  switch (action.type) {
    case GET_EXTRACT_OBSERVATION_SUCCESS:
      return {
        ...state,
        loading: false,
        getExtractObservationRes: action.payload,
        pageCount: action.pageCount,
        errRes: "",
      };
    case GET_EXTRACT_OBSERVATION_LOADING:
      return {
        ...state,
        loading: true,
        getExtractObservationRes: {} as GetExtractObservationRes,
      };
    case GET_EXTRACT_OBSERVATION_FAIL:
      return {
        ...state,
        getExtractObservationRes: {} as GetExtractObservationRes,
        loading: false,
        errRes: action.errRes,
      };
    default:
      return state;
  }
};
