import {
  Card,
  CardHeader,
  CardContent,
  Button,
  Box,
  Grid,
  Typography,
} from "@mui/material";
import {
  DataGrid,
  GridCellParams,
  GridColDef,
  GridToolbar,
} from "@mui/x-data-grid";
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { AppState } from "../../../../../redux/store/Store";
import { PatientDetailList } from "../../../../../redux/FilePreparation/GetPatientDataList/Model";
import MWExceptionList from "../../../../../component/MWExceptionList";
import ViewDocument from "../ViewDocument";
import dayjs from "dayjs";
import DescriptionIcon from "@mui/icons-material/Description";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import DeletePatientData from "../DeletePatientData";
import AddEditPatientData from "../AddEditPatientData";
import { t } from "i18next";
import { ModifiedPatientDetailList } from "./Model";

interface Props {
  label?: string;
  type?: string;
}

export default function DataList({ label, type }: Props) {
  const patientDataListRes = useSelector(
    (state: AppState) => state.patientDataListRes
  );
  const [patientDataList, setPatientDataList] = useState(
    [] as ModifiedPatientDetailList[]
  );
  const [sectionTye, setSectionType] = useState("");
  useEffect(() => {
    type !== undefined
      ? type !== ""
        ? setSectionType(type)
        : setSectionType("")
      : setSectionType("");
  }, [type]);
  useEffect(() => {
    if (
      patientDataListRes?.getPatientDataListRes?.patientDetailList !== undefined
    ) {
      let questionItem =
        patientDataListRes?.getPatientDataListRes?.patientDetailList.map(
          (item: PatientDetailList) => {
            return {
              id: item.id,
              description:
                item?.description !== undefined ? item.description : "",
              startDate:
                item?.startDate !== undefined
                  ? item?.startDate !== null
                    ? dayjs(item?.startDate).format("DD-MM-YYYY")
                    : `${t("common.notSet", { ns: ["home"] })!}`
                  : `${t("common.notSet", { ns: ["home"] })!}`,
              endDate:
                item?.endDate !== undefined
                  ? item?.endDate !== null
                    ? dayjs(item?.endDate).format("DD-MM-YYYY")
                    : `${t("common.notSet", { ns: ["home"] })!}`
                  : `${t("common.notSet", { ns: ["home"] })!}`,
              docUrl:
                item?.patientDocument !== undefined
                  ? item?.patientDocument?.document !== undefined
                    ? item?.patientDocument?.document
                    : ""
                  : "",
            } as ModifiedPatientDetailList;
          }
        );
      setPatientDataList(questionItem);
    } else setPatientDataList([] as ModifiedPatientDetailList[]);
  }, [patientDataListRes]);

  const [dialogOpen, setDialogOpen] = useState(false);
  const dialogUpdateState = (passedVal: boolean) => {
    setDialogOpen(passedVal);
  };
  const [addEditDialogOpen, setAddEditDialogOpen] = useState(false);
  const addEditDialogUpdateState = (passedVal: boolean) => {
    setAddEditDialogOpen(passedVal);
  };
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const deleteDialogUpdateState = (passedVal: boolean) => {
    setDeleteDialogOpen(passedVal);
  };

  const [docName, setDocName] = useState("");
  const [docUrl, setDocUrl] = useState("");
  const [itemId, setItemId] = useState(0);
  const [selectedItem, setSelectedItem] = useState({} as any);
  const selectedItemUpdateState = (passedVal: any) => {
    setSelectedItem(passedVal);
  };
  const columns: GridColDef[] = [
    {
      field: "docUrl",
      headerName: `${t("appointmentpage.doc", { ns: ["home"] })}`,
      minWidth: 80,
      renderCell: (params: GridCellParams) => (
        <Button
          variant="outlined"
          disabled={params.row.docUrl === "" ? true : false}
          onClick={() => {
            setDialogOpen(true);
            setDocUrl(params.row.docUrl);
            setDocName(params.row.description);
          }}
          sx={{ textTransform: "none" }}
        >
          <DescriptionIcon />
        </Button>
      ),
    },
    {
      field: "viewItem",
      headerName: `${t("common.view", { ns: ["home"] })}`,
      minWidth: 30,
      renderCell: (params: GridCellParams) => (
        <Button
          variant="outlined"
          onClick={() => {
            setAddEditDialogOpen(true);
            setSelectedItem(params.row);
          }}
          sx={{ textTransform: "none" }}
        >
          <VisibilityIcon />
        </Button>
      ),
    },
    { field: "description", headerName: `${t("appointmentpage.desc", { ns: ["home"] })}`, flex: 1 },
    { field: "startDate", headerName: `${t("userprofilepage.startdate", { ns: ["home"] })}`, flex: 1 },
    { field: "endDate", headerName: `${t("appointmentpage.enddate", { ns: ["home"] })}`, flex: 1 },
    {
      field: "id",
      headerName: `${t("appointmentpage.del", { ns: ["home"] })}`,
      minWidth: 20,
      renderCell: (params: GridCellParams) => (
        <Button
          variant="outlined"
          onClick={() => {
            setDeleteDialogOpen(true);
            setItemId(params.row.id);
          }}
          sx={{ textTransform: "none" }}
        >
          <DeleteIcon />
        </Button>
      ),
    },
  ];
  return (
    <Box>
      <Card>
        <CardHeader
          title={
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="flex-start"
            >
              <Grid item>
                <Typography variant="h5">{t("appointmentpage.docs", { ns: ["home"] })}</Typography>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  startIcon={<AddIcon />}
                  onClick={() => setAddEditDialogOpen(true)}
                  sx={{ textTransform: "none" }}
                >
                  {t("common.addData", { ns: ["home"] })}
                </Button>
              </Grid>
            </Grid>
          }
        />
        <CardContent>
          {patientDataList.length > 0 ? (
            <DataGrid
              rows={patientDataList}
              columns={columns}
              pageSize={10}
              hideFooter
              hideFooterPagination
              disableSelectionOnClick
              experimentalFeatures={{ newEditingApi: true }}
              autoHeight
              components={{ Toolbar: GridToolbar }}
              componentsProps={{
                toolbar: {
                  csvOptions: { disableToolbarButton: true },
                  printOptions: { disableToolbarButton: true },
                  showQuickFilter: true,
                  quickFilterProps: { debounceMs: 250 },
                },
              }}
              disableColumnFilter
              disableColumnSelector
              disableDensitySelector
            />
          ) : (
            <MWExceptionList />
          )}
        </CardContent>
      </Card>
      {dialogOpen ? (
        <ViewDocument
          dialogEnable={dialogOpen}
          dialogUpdateState={dialogUpdateState}
          docUrl={docUrl}
          docName={docName}
        />
      ) : null}
      {addEditDialogOpen ? (
        <AddEditPatientData
          dialogEnable={addEditDialogOpen}
          dialogUpdateState={addEditDialogUpdateState}
          docTypeName={label}
          itemId={itemId}
          type={sectionTye}
          selectedItem={selectedItem}
          selectedItemUpdateState={selectedItemUpdateState}
        />
      ) : null}
      {deleteDialogOpen ? (
        <DeletePatientData
          dialogEnable={deleteDialogOpen}
          dialogUpdateState={deleteDialogUpdateState}
          docTypeName={label}
          itemId={itemId}
          type={sectionTye}
        />
      ) : null}
    </Box>
  );
}
