import React, { useEffect, useState } from "react";
import {
  Box,
  Chip,
  Container,
  Grid,
  Tab,
  Typography
} from "@mui/material";
import ActiveNotes from "./ActiveNotes";
import InactiveNotes from "./InActiveNotes";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import RefreshIcon from "@mui/icons-material/Refresh";
import { t } from "i18next";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../redux/store/Store";
import { doctorActiveListApi } from "../../redux/Notes/DoctorActiveNote/API";
import { DoctorActiveListModel } from "../../redux/Notes/DoctorActiveNote/Model";
import { doctorInactiveListApi } from "../../redux/Notes/DoctorInactiveNote/API";
import { DoctorInactiveListModel } from "../../redux/Notes/DoctorInactiveNote/Model";

export default function Notes(props: any) {
  const { notesLoader } = props;
  const dispatch = useDispatch();
  const doctorId = localStorage.getItem("doctorID");
  const [activeNoteLoader, setActiveNoteLoader] = useState(false);
  const [inactiveNoteLoader, setInactiveNoteLoader] = useState(false);
  let isLoading =
    activeNoteLoader === true || inactiveNoteLoader === true ? true : false;
  notesLoader(isLoading);

  const [selected, setSelected] = useState("1");
  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setSelected(newValue);
    localStorage.setItem("selctedOnboardingtab", String(newValue));
  };
  const [activeNoteList, setActiveNoteList] = useState("");
  const [inactiveNoteList, setInactiveNoteList] = useState("");

  const docAactiveNoteListFromAPI = useSelector(
    (state: AppState) => state.doctorActiveNoteListValue
  );

  const docInactiveNoteListFromAPI = useSelector(
    (state: AppState) => state.doctorInactiveNoteListValue
  );

  useEffect(() => {
    if (docAactiveNoteListFromAPI?.doctorActiveNoteListRes?.noteList !== undefined) {
      setActiveNoteList(JSON.stringify(docAactiveNoteListFromAPI?.doctorActiveNoteListRes?.noteList?.length));
    } else {
      setActiveNoteList("0");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [docAactiveNoteListFromAPI]);

  useEffect(() => {
    if (docInactiveNoteListFromAPI?.doctorInactiveNoteListRes?.noteList !== undefined) {
      setInactiveNoteList(JSON.stringify(docInactiveNoteListFromAPI?.doctorInactiveNoteListRes?.noteList?.length));
    } else {
      setInactiveNoteList("0");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [docInactiveNoteListFromAPI]);

  // Get Doctor Note List Body
  const getDrNoteListBody = {
    doctorId: doctorId,
    isActive: true,
  } as DoctorActiveListModel;
  // Get Doctor Note List API
  const getdrActiveNotes = () => {
    dispatch(doctorActiveListApi(getDrNoteListBody));
  }

  const getDrInactiveNoteListBody = {
    doctorId: doctorId,
    isActive: false,
  } as DoctorInactiveListModel;

  const getdrInactiveNotes = () => {
    dispatch(doctorInactiveListApi(getDrInactiveNoteListBody));
  }

  return (
    <Container maxWidth="xl">
      <TabContext value={selected}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList onChange={handleTabChange}>
            <Tab label={
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={1}
              >
                <Grid item>
                  <Typography>
                    {t("docnotelist.active", { ns: ["home"] })!}
                  </Typography>
                </Grid>
                <Grid item>
                  <Chip
                    label={activeNoteList}
                    variant="outlined"
                    size="small"
                  />
                </Grid>
              </Grid>
            }
              icon={
                <RefreshIcon
                  onClick={() => {
                    getdrActiveNotes();
                  }}
                />
              }
              iconPosition="end"
              value="1" />
            <Tab label={
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={1}
              >
                <Grid item>
                  <Typography>
                    {t("docnotelist.inactive", { ns: ["home"] })!}
                  </Typography>
                </Grid>
                <Grid item>
                  <Chip
                    label={inactiveNoteList}
                    variant="outlined"
                    size="small"
                  />
                </Grid>
              </Grid>
            }
              icon={
                <RefreshIcon
                  onClick={() => {
                    getdrInactiveNotes();
                  }}
                />
              }
              iconPosition="end"
              value="2" />
          </TabList>
        </Box>
        <TabPanel value="1">
          {<ActiveNotes activeNoteLoader={setActiveNoteLoader} />}
        </TabPanel>
        <TabPanel value="2">
          {<InactiveNotes inactiveNoteLoader={setInactiveNoteLoader} />}
        </TabPanel>
      </TabContext>
    </Container>
  );
}
