import {
  TemplateListActionTypes,
  GetPatientDemographicsState,
  TEMPLATE_SUCCESS,
  TEMPLATE_LOADING,
  TEMPLATE_FAIL,
} from "./ActionTypes";
import { TemplateListResponse } from "./Model";

const initialStateGetPosts: GetPatientDemographicsState = {
  loading: false,
  templateList: {} as TemplateListResponse,
};
export const templateListReducer = (
  state = initialStateGetPosts,
  action: TemplateListActionTypes
): GetPatientDemographicsState => {
  switch (action.type) {
    case TEMPLATE_SUCCESS:
      return {
        ...state,
        loading: false,
        templateList: action.payload,
      };
    case TEMPLATE_LOADING:
      return {
        ...state,
        loading: true,
        templateList: {} as TemplateListResponse,
      };
    case TEMPLATE_FAIL:
      return {
        ...state,
        templateList: {} as TemplateListResponse,
        loading: false,
      };
    default:
      return state;
  }
};
