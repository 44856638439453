import {
  GetProviderOriganizationListActionTypes,
  GET_PROVIDER_ORGANIZATION_LIST_FAIL,
  GET_PROVIDER_ORGANIZATION_LIST_LOADING,
  GET_PROVIDER_ORGANIZATION_LIST_SUCCESS,
  GetProviderOrganizationState,
  GET_PROVIDER_ORGANIZATION_LIST_API_MSG,
  GET_PROVIDER_ORGANIZATION_LIST_API_RES,
} from "./ActionTypes";
import { GetProviderOrganizationListRes } from "./Model";

const initialStateUpdatePosts: GetProviderOrganizationState = {
  loading: false,
  getProviderOrganizationRes: {} as GetProviderOrganizationListRes,
  errorMsg: "",
  status: 0,
};
export const getProviderOrganizationListReducer = (
  state = initialStateUpdatePosts,
  action: GetProviderOriganizationListActionTypes
): GetProviderOrganizationState => {
  switch (action.type) {
    case GET_PROVIDER_ORGANIZATION_LIST_LOADING:
      return {
        ...state,
        loading: action.loading,
      };
    case GET_PROVIDER_ORGANIZATION_LIST_SUCCESS:
      return {
        ...state,
        getProviderOrganizationRes: action.payload,
      };
    case GET_PROVIDER_ORGANIZATION_LIST_FAIL:
      return {
        ...state,
        getProviderOrganizationRes: action.payload,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case GET_PROVIDER_ORGANIZATION_LIST_API_MSG:
      return {
        ...state,
        getProviderOrganizationRes: action.payload,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case GET_PROVIDER_ORGANIZATION_LIST_API_RES:
      return {
        ...state,
        getProviderOrganizationRes: action.payload,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    default:
      return state;
  }
};
