import { GetExtractObservationRes } from "./Model";

export const GET_EXTRACT_OBSERVATION_LOADING =
  "GET_EXTRACT_OBSERVATION_LOADING";
export const GET_EXTRACT_OBSERVATION_SUCCESS =
  "GET_EXTRACT_OBSERVATION_SUCCESS";
export const GET_EXTRACT_OBSERVATION_FAIL =
  "GET_EXTRACT_OBSERVATION_FAIL";
export const GET_EXTRACT_OBSERVATION_DATA =
  "GET_EXTRACT_OBSERVATION_DATA";

export interface GetExtractObservationDetails {
  getExtractObservationRes: GetExtractObservationRes;
  loading: boolean;
  pageCount: number;
  errRes: string;
}
export interface GetExtractObservationLoading {
  type: typeof GET_EXTRACT_OBSERVATION_LOADING;
}
export interface GetExtractObservationSuccess {
  type: typeof GET_EXTRACT_OBSERVATION_SUCCESS;
  payload: GetExtractObservationRes;
  pageCount: number;
  errRes: string;
}
export interface GetExtractObservationFail {
  type: typeof GET_EXTRACT_OBSERVATION_FAIL;
  payload: GetExtractObservationRes;
  errRes: string;
}
interface GetExtractObservationAction {
  type: typeof GET_EXTRACT_OBSERVATION_DATA;
  payload: GetExtractObservationRes;
  pageCount: number;
  errRes: string;
}

export type GetExtractObservationActionTypes =
  | GetExtractObservationAction
  | GetExtractObservationLoading
  | GetExtractObservationSuccess
  | GetExtractObservationFail;
