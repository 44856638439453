import React from "react";
import { Route, Redirect } from "react-router-dom";

export const PrivateRoute = ({
  component: Component,
  access,
  ...rest
}: any) => (
  <Route
    {...rest}
    render={(props) => {
      
      const access = localStorage.getItem("access");
      if (!access) {
        // not logged in so redirect to login page with the return url
        return (
          <Redirect
            to={{ pathname: "/signin", state: { from: props.location } }}
          />
        );
      }

      // authorised so return component
      return <Component {...props} />;
    }}
  />
);
