import { ExecutiveListData, Message, Status } from "./Model";
import { AdminGetExecutiveListActionTypes, EXECUTIVE_LIST, DeleteExecutiveTypes, DELETE_EXECUTIVE_STATUS, AddExecutiveTypes, ADD_EXECUTIVE_STATUS, EDIT_EXECUTIVE_STATUS, EditExecutiveTypes } from "./ActionTypes";


export const getExecutiveAction = (executiveListData: ExecutiveListData[], message: Message, status: Status): AdminGetExecutiveListActionTypes => {
  return {
    type: EXECUTIVE_LIST,
    payload: executiveListData,
    message: message,
    status: status
  };
};
// Delete Executive
export const DeleteExecutive = (DeleteExecutiveResponse: any): DeleteExecutiveTypes => {
  return {
    type: DELETE_EXECUTIVE_STATUS,
    payload: DeleteExecutiveResponse
  };
};
// Add Executive
export const AddExecutive = (AddExecutiveResponse: any): AddExecutiveTypes => {
  return {
    type: ADD_EXECUTIVE_STATUS,
    payload: AddExecutiveResponse
  };
};
// Edit Executive
export const EditExecutive = (EditExecutiveResponse: any): EditExecutiveTypes => {
  return {
    type: EDIT_EXECUTIVE_STATUS,
    payload: EditExecutiveResponse
  };
};