import {
  ADD_DOCTOR_FAIL,
  ADD_DOCTOR_LOADING,
  ADD_DOCTOR_SUCCESS,
  ADD_DOCTOR_API_MSG,
  ADD_DOCTOR_API_RES,
  AddDoctorActionTypes,
} from "./ActionTypes";
import { AddDoctorResponse } from "./Model";

export const addDoctorLoadingAction = (
  loading: boolean
): AddDoctorActionTypes => {
  return {
    type: ADD_DOCTOR_LOADING,
    loading: loading,
  };
};

export const addDoctorSuccessAction = (
  addDoctorResponse: AddDoctorResponse,
  successMsg: string
): AddDoctorActionTypes => {
  return {
    type: ADD_DOCTOR_SUCCESS,
    payload: addDoctorResponse,
    successMsg: successMsg,
  };
};

export const addDoctorErrorAction = (
  addDoctorResponse: AddDoctorResponse,
  errMsg: string,
  status: number
): AddDoctorActionTypes => {
  return {
    type: ADD_DOCTOR_FAIL,
    payload: addDoctorResponse,
    errorMsg: errMsg,
    status: status,
  };
};
export const addDoctorAPIMsgAction = (
  addDoctorResponse: AddDoctorResponse,
  successMsg: string,
  errMsg: string,
  status: number
): AddDoctorActionTypes => {
  return {
    type: ADD_DOCTOR_API_MSG,
    payload: addDoctorResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};

export const addDoctorAPIResClearAction = (
  addDoctorResponse: AddDoctorResponse,
  successMsg: string,
  errMsg: string,
  status: number
): AddDoctorActionTypes => {
  return {
    type: ADD_DOCTOR_API_RES,
    payload: addDoctorResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};