import { GetPatientNoteListRes } from "./Model";
export const PATIENT_NOTE_LIST_LOADING = "PATIENT_NOTE_LIST_LOADING";
export const PATIENT_NOTE_LIST_SUCCESS = "PATIENT_NOTE_LIST_SUCCESS";
export const PATIENT_NOTE_LIST_FAIL = "PATIENT_NOTE_LIST_FAIL";
export const PATIENT_NOTE_LIST_DATA = "PATIENT_NOTE_LIST_DATA";
export const PATIENT_NOTE_LIST_UPDATE_API_MSG =
  "PATIENT_NOTE_LIST_UPDATE_API_MSG";
export const PATIENT_NOTE_LIST_UPDATE_API_RES =
  "PATIENT_NOTE_LIST_UPDATE_API_RES";

export interface GetPatientNoteListState {
  getPatientNoteList: GetPatientNoteListRes;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface GetPatientNoteListLoading {
  type: typeof PATIENT_NOTE_LIST_LOADING;
  loading: boolean;
}
export interface GetPatientNoteListSuccess {
  type: typeof PATIENT_NOTE_LIST_SUCCESS;
  payload: GetPatientNoteListRes;
  successMsg: string;
}
export interface GetPatientNoteListFail {
  type: typeof PATIENT_NOTE_LIST_FAIL;
  payload: GetPatientNoteListRes;
  errorMsg: string;
  status: number;
}
export interface GetPatientNoteListUpdateAPIMsg {
  type: typeof PATIENT_NOTE_LIST_UPDATE_API_MSG;
  payload: GetPatientNoteListRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface GetPatientNoteListUpdateAPIRes {
  type: typeof PATIENT_NOTE_LIST_UPDATE_API_RES;
  payload: GetPatientNoteListRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}

export type GetPatientNoteListActionTypes =
  | GetPatientNoteListLoading
  | GetPatientNoteListSuccess
  | GetPatientNoteListFail
  | GetPatientNoteListUpdateAPIMsg
  | GetPatientNoteListUpdateAPIRes;
