import {
  AdminPatientTeamList,
  patientTeamCurrentPageCount,
  PatientTeamListPageCount,
} from "./Model";

export const ALL_PATIENT_TEAM_LIST = "ALL_PATIENT_TEAM_LIST";
export const ALL_PATIENT_TEAM_LOADING = "ALL_PATIENT_TEAM_LOADING";
export const ALL_PATIENT_TEAM_SUCCESS = "ALL_PATIENT_TEAM_SUCCESS";
export const ALL_PATIENT_TEAM_FAIL = "ALL_PATIENT_TEAM_FAIL";
// Admin All Patient Team List Interfaces
interface AdminAllPatientTeamListActionType {
  type: typeof ALL_PATIENT_TEAM_LIST;
  payload: AdminPatientTeamList;
  patientListPageCount: PatientTeamListPageCount;
}

export interface AdminAllPatientTeamListLoading {
  type: typeof ALL_PATIENT_TEAM_LOADING;
}
export interface AdminAllPatientTeamListSuccess {
  type: typeof ALL_PATIENT_TEAM_SUCCESS;
  payload: AdminPatientTeamList;
  patientListPageCount: PatientTeamListPageCount;
  patientTeamCurrentPageCount: patientTeamCurrentPageCount;
}

export interface AdminAllPatientTeamListFail {
  type: typeof ALL_PATIENT_TEAM_FAIL;
}
export interface GetAllPatientListStateType {
  allPatientTeamListValue: AdminPatientTeamList;
  loading: boolean;
  patientListPageCount: PatientTeamListPageCount;
  patientTeamCurrentPageCount: patientTeamCurrentPageCount;
}
export type AdminGetPatientTeamListActionTypes =
  | AdminAllPatientTeamListActionType
  | AdminAllPatientTeamListLoading
  | AdminAllPatientTeamListSuccess
  | AdminAllPatientTeamListFail;
// Add Patient team
export const ADD_PATIENT_TEAM_LOADING = "ADD_PATIENT_TEAM_LOADING";
export const ADD_PATIENT_TEAM_SUCCESS = "ADD_PATIENT_TEAM_SUCCESS";
export const ADD_PATIENT_TEAM_FAIL = "ADD_PATIENT_TEAM_FAIL";
export const ADD_PATIENT_TEAM_TYPE = "ADD_PATIENT_TEAM_TYPE";

export interface AnswerPatientTeamStateType {
  loading: boolean;
  message: string;
  status: number;
}
export interface AnswerPatientTeamLoading {
  type: typeof ADD_PATIENT_TEAM_LOADING;
}
export interface AnswerPatientTeamSuccess {
  type: typeof ADD_PATIENT_TEAM_SUCCESS;
  message: string;
  status: number;
}
export interface AnswerPatientTeamFail {
  type: typeof ADD_PATIENT_TEAM_FAIL;
  message: string;
  status: number;
}

interface AnswerPatientTeamType {
  type: typeof ADD_PATIENT_TEAM_TYPE;
  message: string;
  status: number;
}

export type AnswerPatientTeamTypes =
  | AnswerPatientTeamType
  | AnswerPatientTeamLoading
  | AnswerPatientTeamSuccess
  | AnswerPatientTeamFail;
