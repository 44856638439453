import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../../ApiConfig";
import { GetDoctorAppointmentListActionTypes } from "./ActionTypes";
import { GetDoctorAppointmentListBody, GetDoctorAppointmentListRes } from "./Model";
import {
  getDoctorAppointmentListAPIResClearAction,
  getDoctorAppointmentListErrorAction,
  getDoctorAppointmentListLoadingAction,
  getDoctorAppointmentListSuccessAction,
  getDoctorAppointmentListAPIMsgAction,
} from "./Actions";
import { headers } from "../../../component/Utility";

let apiRes = {} as GetDoctorAppointmentListRes;
export const getAdminAppointmentList = (payload: GetDoctorAppointmentListBody) => {
  return function (dispatch: Dispatch<GetDoctorAppointmentListActionTypes>) {
    dispatch(getDoctorAppointmentListLoadingAction(true));
    axios
      .post(
        Url.baseUrl +
          `/appointmentapp/api/appointment/get_appointment_list_for_doctor_by_admin`,
        payload,
        { headers: headers }
      )
      .then((res) => {
        apiRes = res.data;
        dispatch(getDoctorAppointmentListLoadingAction(false));
        dispatch(
          getDoctorAppointmentListSuccessAction(
            res.data,
            res?.data !== undefined
              ? res?.data?.message
                ? res.data.message
                : "Patient questionnaire list has been fetched successfully"
              : "Patient questionnaire list has been fetched successfully"
          )
        );
      })
      .catch((error) => {
        dispatch(getDoctorAppointmentListLoadingAction(false));
        dispatch(
          getDoctorAppointmentListErrorAction(
            {} as GetDoctorAppointmentListRes,
            error.response !== undefined
              ? error.response.data.message || error.response.data.detail
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || 0 : 0
          )
        );
      });
  };
};

export const updateGetDoctorAppointmentListAPIResMsg = () => {
  return function (dispatch: Dispatch<GetDoctorAppointmentListActionTypes>) {
    dispatch(
      getDoctorAppointmentListAPIMsgAction(
        apiRes as GetDoctorAppointmentListRes,
        "",
        "",
        0
      )
    );
  };
};

export const clearDoctorAppointmentListAPIRes = () => {
  return function (dispatch: Dispatch<GetDoctorAppointmentListActionTypes>) {
    dispatch(
      getDoctorAppointmentListAPIResClearAction(
        {} as GetDoctorAppointmentListRes,
        "",
        "",
        0
      )
    );
  };
};
