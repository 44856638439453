import React, { useEffect, useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import RefreshIcon from "@mui/icons-material/Refresh";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../../redux/store/Store";
import {
  AlertColor,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import {
  DataGrid,
  GridColDef,
  GridCellParams,
  GridToolbar,
} from "@mui/x-data-grid";
import MWExceptionList from "../../../../component/MWExceptionList";
import { GetDoctorListForCaseBody } from "../../../../redux/Patient/AppointmentSelectDoctor/DoctorListForCase/Model";
import { getDoctorListForCase } from "../../../../redux/Patient/AppointmentSelectDoctor/DoctorListForCase/ApiCall";
import MWSnackbar from "../../../../component/MWSnackbar";
import { clearDeleteDoctorForCaseAPIRes } from "../../../../redux/Patient/AppointmentSelectDoctor/DeleteDoctor/API";
import DeleteDoctor from "./DeleteDoctor";
import { t } from "i18next";

type Props = {
  selectedDoctorListLoader: boolean | any;
};

export default function SelectedDoctorList({
  selectedDoctorListLoader,
}: Props) {
  const dispatch = useDispatch();
  const history = useHistory();

  const { caseid } = useParams() as {
    caseid: string;
  };

  useEffect(() => {
    dispatch(
      getDoctorListForCase({ caseId: caseid } as GetDoctorListForCaseBody)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getDoctorListForCaseRes = useSelector(
    (state: AppState) => state.getDoctorListForCaseRes
  );
  useEffect(() => {
    selectedDoctorListLoader(getDoctorListForCaseRes?.loading);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getDoctorListForCaseRes]);

  const columns: GridColDef[] = [
    {
      field: "DoctorName",
      headerName: `${t("selDocPage.docName", { ns: ["home"] })!}`,
      flex: 1,
      renderCell: (params: GridCellParams) => (
        <Button
          variant="text"
          onClick={() => {
            history.push(`/doctordetails/${params.row.id}`);
          }}
        >
          {params.row.firstname || null} {params.row.lastname || null}
        </Button>
      ),
    },
    {
      field: "qualification",
      headerName: `${t("selDocPage.qual", { ns: ["home"] })!}`,
      flex: 1,
    },
    {
      field: "speciality",
      headerName: `${t("appointmentpage.speciality", { ns: ["home"] })!}`,
      flex: 1,
    },
    {
      field: "requestStatus",
      headerName: `${t("selDocPage.appStatus", { ns: ["home"] })!}`,
      minWidth: 150,
    },
    {
      field: "id",
      headerName: `${t("finalOpinion.delete", { ns: ["home"] })!}`,
      renderCell: (params: GridCellParams) => (
        <Button
          variant="outlined"
          onClick={() => {
            deleteDoctor(
              params.row.id,
              params.row.firstname,
              params.row.lastname
            );
          }}
        >
          <DeleteIcon />
        </Button>
      ),
    },
  ];

  const [doctorName, setDoctorName] = useState("");

  const [deleteDoctorDialog, setDeleteDoctorDialog] = useState(false);
  const [selectedItem, setSelectedItem] = useState(0);
  const deleteDoctor = (
    doctorId: number,
    docFName: string,
    docLName: string
  ) => {
    setSelectedItem(doctorId);
    setDeleteDoctorDialog((deleteDoctorDialog) => !deleteDoctorDialog);
    let doctorName = docFName + " " + docLName;
    setDoctorName(doctorName);
  };
  const updateDeleteDoctorDialogValFromChild = (passedVal: boolean) => {
    setDeleteDoctorDialog(passedVal);
  };

  const deleteDoctorForCaseRes = useSelector(
    (state: AppState) => state.deleteDoctorForCaseRes
  );
  const [deleteDocSuccessMsg, setDeleteDocSuccessMsg] = useState("");
  const [snackBarType, setSnackBarType] = useState<AlertColor>("success");
  useEffect(() => {
    if (deleteDoctorForCaseRes?.successMsg !== "") {
      setDeleteDocSuccessMsg(deleteDoctorForCaseRes?.successMsg);
      setSnackBarType("success");
      dispatch(
        getDoctorListForCase({ caseId: caseid } as GetDoctorListForCaseBody)
      );
    }
    if (deleteDoctorForCaseRes?.successMsg === "") {
      setDeleteDocSuccessMsg("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteDoctorForCaseRes]);

  const checkAlertClose = (passedVal: boolean) => {
    if (passedVal) {
      dispatch(clearDeleteDoctorForCaseAPIRes());
    }
  };

  return (
    <Box>
      <Card>
        <CardHeader
          title={
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              spacing={2}
            >
              <Grid item>
                <Typography variant="h5">
                  {t("selDocPage.selDocList", { ns: ["home"] })!}
                </Typography>
              </Grid>
              <Grid item>
                <IconButton
                  onClick={() =>
                    dispatch(
                      getDoctorListForCase({
                        caseId: caseid,
                      } as GetDoctorListForCaseBody)
                    )
                  }
                  disabled={getDoctorListForCaseRes?.loading}
                >
                  <RefreshIcon />
                </IconButton>
              </Grid>
            </Grid>
          }
        />
        <CardContent>
          {getDoctorListForCaseRes?.getDoctorListForCaseRes
            ?.patientCaseDoctorlist !== undefined ? (
            getDoctorListForCaseRes?.getDoctorListForCaseRes
              ?.patientCaseDoctorlist.length > 0 ? (
              <DataGrid
                rows={
                  getDoctorListForCaseRes?.getDoctorListForCaseRes
                    ?.patientCaseDoctorlist
                }
                columns={columns}
                pageSize={10}
                hideFooter
                hideFooterPagination
                disableSelectionOnClick
                experimentalFeatures={{ newEditingApi: true }}
                autoHeight
                components={{ Toolbar: GridToolbar }}
                componentsProps={{
                  toolbar: {
                    csvOptions: { disableToolbarButton: true },
                    printOptions: { disableToolbarButton: true },
                    showQuickFilter: true,
                    quickFilterProps: { debounceMs: 250 },
                  },
                }}
                disableColumnFilter
                disableColumnSelector
                disableDensitySelector
              />
            ) : (
              <MWExceptionList />
            )
          ) : (
            <MWExceptionList />
          )}
        </CardContent>
      </Card>
      {deleteDoctorDialog ? (
        <DeleteDoctor
          itemId={selectedItem}
          name={doctorName}
          deleteDoctorDialogEnable={deleteDoctorDialog}
          deleteDoctorDialogUpdateState={updateDeleteDoctorDialogValFromChild}
        />
      ) : null}
      <MWSnackbar
        msg={deleteDocSuccessMsg}
        type={snackBarType}
        alertClose={checkAlertClose}
      />
    </Box>
  );
}
