import { DeleteDoctorForCaseRes } from "./Model";
export const DELETE_DOCTOR_FOR_CASE_LOADING = "DELETE_DOCTOR_FOR_CASE_LOADING";
export const DELETE_DOCTOR_FOR_CASE_SUCCESS = "DELETE_DOCTOR_FOR_CASE_SUCCESS";
export const DELETE_DOCTOR_FOR_CASE_FAIL = "DELETE_DOCTOR_FOR_CASE_FAIL";
export const DELETE_DOCTOR_FOR_CASE_UPDATE_API_MSG =
  "DELETE_DOCTOR_FOR_CASE_UPDATE_API_MSG";
export const DELETE_DOCTOR_FOR_CASE_UPDATE_API_RES =
  "DELETE_DOCTOR_FOR_CASE_UPDATE_API_RES";

export interface DeleteDoctorForCaseState {
  deleteDoctorForCaseRes: DeleteDoctorForCaseRes;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface DeleteDoctorForCaseLoading {
  type: typeof DELETE_DOCTOR_FOR_CASE_LOADING;
  loading: boolean;
}
export interface DeleteDoctorForCaseSuccess {
  type: typeof DELETE_DOCTOR_FOR_CASE_SUCCESS;
  payload: DeleteDoctorForCaseRes;
  successMsg: string;
}
export interface DeleteDoctorForCaseFail {
  type: typeof DELETE_DOCTOR_FOR_CASE_FAIL;
  payload: DeleteDoctorForCaseRes;
  errorMsg: string;
  status: number;
}
export interface DeleteDoctorForCaseUpdateAPIMsg {
  type: typeof DELETE_DOCTOR_FOR_CASE_UPDATE_API_MSG;
  payload: DeleteDoctorForCaseRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface DeleteDoctorForCaseUpdateAPIRes {
  type: typeof DELETE_DOCTOR_FOR_CASE_UPDATE_API_RES;
  payload: DeleteDoctorForCaseRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}

export type DeleteDoctorForCaseActionTypes =
  | DeleteDoctorForCaseLoading
  | DeleteDoctorForCaseSuccess
  | DeleteDoctorForCaseFail
  | DeleteDoctorForCaseUpdateAPIMsg
  | DeleteDoctorForCaseUpdateAPIRes;
