import { DoctorOnboardIncompleteResponse } from "./Model";
export const DR_APPROVE_REJECT_LOADING = "DR_APPROVE_REJECT_LOADING";
export const DR_APPROVE_REJECT_SUCCESS = "DR_APPROVE_REJECT_SUCCESS";
export const DR_APPROVE_REJECT_FAIL = "DR_APPROVE_REJECT_FAIL";
export const DR_APPROVE_REJECT_API_MSG = "DR_APPROVE_REJECT_API_MSG";
export const DR_APPROVE_REJECT_API_RES = "DR_APPROVE_REJECT_API_RES";

export interface DrApproveRejectState {
  drApproveRejectRes: DoctorOnboardIncompleteResponse;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface DrApproveRejectLoading {
  type: typeof DR_APPROVE_REJECT_LOADING;
  loading: boolean;
}
export interface DrApproveRejectSuccess {
  type: typeof DR_APPROVE_REJECT_SUCCESS;
  payload: DoctorOnboardIncompleteResponse;
  successMsg: string;
}
export interface DrApproveRejectFail {
  type: typeof DR_APPROVE_REJECT_FAIL;
  payload: DoctorOnboardIncompleteResponse;
  errorMsg: string;
  status: number;
}
export interface DrApproveRejectAPIMsg {
  type: typeof DR_APPROVE_REJECT_API_MSG;
  payload: DoctorOnboardIncompleteResponse;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface DrApproveRejectAPIRes {
  type: typeof DR_APPROVE_REJECT_API_RES;
  payload: DoctorOnboardIncompleteResponse;
  successMsg: string;
  errorMsg: string;
  status: number;
}

export type DrApproveRejectActionTypes =
  | DrApproveRejectLoading
  | DrApproveRejectSuccess
  | DrApproveRejectFail
  | DrApproveRejectAPIMsg
  | DrApproveRejectAPIRes;