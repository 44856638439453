import {
  PatientImportConfigListActionTypes,
  EDIT_PATIENT_IMPORT_CONFIG_LIST_FAIL,
  EDIT_PATIENT_IMPORT_CONFIG_LIST_LOADING,
  EDIT_PATIENT_IMPORT_CONFIG_LIST_SUCCESS,
  PatientImportConfigListState,
  EDIT_PATIENT_IMPORT_CONFIG_LIST_UPDATE_API_MSG,
  EDIT_PATIENT_IMPORT_CONFIG_LIST_UPDATE_API_RES,
} from "./ActionTypes";
import { EditPatientImportConfigValueRes } from "./Model";

const initialStateGetPosts:  PatientImportConfigListState = {
 loading: false,
 editPatientImportConfigListRes: {} as EditPatientImportConfigValueRes,
 successMsg: "",
 errorMsg: "",
 status: 0,
};
export const editPatientImportConfigReducer = (
 state = initialStateGetPosts,
 action:  PatientImportConfigListActionTypes
):  PatientImportConfigListState => {
 switch (action.type) {
   case  EDIT_PATIENT_IMPORT_CONFIG_LIST_LOADING:
     return {
       ...state,
       loading: action.loading,
     };
   case  EDIT_PATIENT_IMPORT_CONFIG_LIST_SUCCESS:
     return {
       ...state,
       editPatientImportConfigListRes: action.payload,
       successMsg: action.successMsg,
     };
   case  EDIT_PATIENT_IMPORT_CONFIG_LIST_FAIL:
     return {
       ...state,
       editPatientImportConfigListRes: action.payload,
       errorMsg: action.errorMsg,
       status: action.status,
     };
   case  EDIT_PATIENT_IMPORT_CONFIG_LIST_UPDATE_API_MSG:
     return {
       ...state,
       editPatientImportConfigListRes: action.payload,
       successMsg: action.successMsg,
       errorMsg: action.errorMsg,
       status: action.status,
     };
   case  EDIT_PATIENT_IMPORT_CONFIG_LIST_UPDATE_API_RES:
     return {
       ...state,
       editPatientImportConfigListRes: action.payload,
       successMsg: action.successMsg,
       errorMsg: action.errorMsg,
       status: action.status,
     };
   default:
     return state;
 }
};



