import {
  PatientSearchByOrganizationIdActionTypes,
  PATIENT_SEARCH_BY_ORGANIZATION_FAIL,
  PATIENT_SEARCH_BY_ORGANIZATION_LOADING,
  PATIENT_SEARCH_BY_ORGANIZATION_SUCCESS,
  PatientSearchByOrganizationIdDetailsState,
  PATIENT_SEARCH_UPDATE_API_MSG,
  PATIENT_SEARCH_UPDATE_API_RES,
} from "./ActionTypes";
import { PatientSearchByOrganizationIdDetails } from "./Model";

const initialStateGetPosts: PatientSearchByOrganizationIdDetailsState = {
  loading: false,
  PatientSearchOrganizationIdRes: {} as PatientSearchByOrganizationIdDetails,
  message: "",
  status: 0,
};
export const patientSearchByOrganizationIdReducer = (
  state = initialStateGetPosts,
  action: PatientSearchByOrganizationIdActionTypes
): PatientSearchByOrganizationIdDetailsState => {
  switch (action.type) {
    case PATIENT_SEARCH_BY_ORGANIZATION_LOADING:
      return {
        ...state,
        loading: true,
        PatientSearchOrganizationIdRes:
          {} as PatientSearchByOrganizationIdDetails,
      };
    case PATIENT_SEARCH_BY_ORGANIZATION_SUCCESS:
      return {
        ...state,
        loading: false,
        PatientSearchOrganizationIdRes: action.payload,
      };
    case PATIENT_SEARCH_BY_ORGANIZATION_FAIL:
      return {
        ...state,
        loading: false,
        PatientSearchOrganizationIdRes:
          {} as PatientSearchByOrganizationIdDetails,
        message: action.message,
        status: action.status,
      };
    case PATIENT_SEARCH_UPDATE_API_MSG:
      return {
        ...state,
        loading: false,
        PatientSearchOrganizationIdRes: action.payload,
        message: action.message,
        status: action.status,
      };
    case PATIENT_SEARCH_UPDATE_API_RES:
      return {
        ...state,
        loading: false,
        PatientSearchOrganizationIdRes:
          {} as PatientSearchByOrganizationIdDetails,
        message: action.message,
        status: action.status,
      };
    default:
      return state;
  }
};
