import { DeleteResponse, OpinionList } from "./Model";

export const OPINION_LIST_LOADING = "OPINION_LIST_LOADING";
export const OPINION_LIST_SUCCESS = "OPINION_LIST_SUCCESS";
export const OPINION_LIST_FAIL = "OPINION_LIST_FAIL";
export const OPINION_LIST = 'STEPS_LIST';

export interface GetOpinionStateType {
  opinionValue: OpinionList[];
  loading: boolean;
}
export interface OpinionListLoading {
  type: typeof OPINION_LIST_LOADING;
}
export interface OpinionListSuccess {
  type: typeof OPINION_LIST_SUCCESS;
  payload: OpinionList[];
}
export interface OpinionListFail {
  type: typeof OPINION_LIST_FAIL;
}

interface GetOpinionActionType {
  type: typeof OPINION_LIST;
  payload: OpinionList[];
}

export type OpinionActionTypes = GetOpinionActionType | OpinionListLoading | OpinionListSuccess | OpinionListFail;

export const DELETE_OPINION_LIST_LOADING = "DELETE_OPINION_LIST_LOADING";
export const DELETE_OPINION_LIST_SUCCESS = "DELETE_OPINION_LIST_SUCCESS";
export const DELETE_OPINION_LIST_FAIL = "DELETE_OPINION_LIST_FAIL";
export const DELETE_OPINION_LIST = 'DELETE_OPINION_LIST';

export interface DeleteOpinionStateType {
  response: DeleteResponse;
  loading: boolean;
}
export interface DeleteOpinionListLoading {
  type: typeof DELETE_OPINION_LIST_LOADING;
}
export interface DeleteOpinionListSuccess {
  type: typeof DELETE_OPINION_LIST_SUCCESS;
  payload: DeleteResponse;
}
export interface DeleteOpinionListFail {
  type: typeof DELETE_OPINION_LIST_FAIL;
}

interface DeletOpinionActionType {
  type: typeof DELETE_OPINION_LIST;
  payload: DeleteResponse;
}

export type DeleteOpinionActionTypes = DeletOpinionActionType | DeleteOpinionListLoading | DeleteOpinionListSuccess | DeleteOpinionListFail;