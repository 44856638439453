import { EditPatientImportConfigValueRes } from "./Model";
export const  EDIT_PATIENT_IMPORT_CONFIG_LIST_LOADING = " EDIT_PATIENT_IMPORT_CONFIG_LIST_LOADING";
export const  EDIT_PATIENT_IMPORT_CONFIG_LIST_SUCCESS = " EDIT_PATIENT_IMPORT_CONFIG_LIST_SUCCESS";
export const  EDIT_PATIENT_IMPORT_CONFIG_LIST_FAIL = " EDIT_PATIENT_IMPORT_CONFIG_LIST_FAIL";
export const  EDIT_PATIENT_IMPORT_CONFIG_LIST_UPDATE_API_MSG =
  " EDIT_PATIENT_IMPORT_CONFIG_LIST_UPDATE_API_MSG";
export const  EDIT_PATIENT_IMPORT_CONFIG_LIST_UPDATE_API_RES =
  " EDIT_PATIENT_IMPORT_CONFIG_LIST_UPDATE_API_RES";

export interface  PatientImportConfigListState {
  editPatientImportConfigListRes: EditPatientImportConfigValueRes;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface  PatientImportConfigListLoading {
  type: typeof  EDIT_PATIENT_IMPORT_CONFIG_LIST_LOADING;
  loading: boolean;
}
export interface  PatientImportConfigListSuccess {
  type: typeof  EDIT_PATIENT_IMPORT_CONFIG_LIST_SUCCESS;
  payload: EditPatientImportConfigValueRes;
  successMsg: string;
}
export interface  PatientImportConfigListFail {
  type: typeof  EDIT_PATIENT_IMPORT_CONFIG_LIST_FAIL;
  payload: EditPatientImportConfigValueRes;
  errorMsg: string;
  status: number;
}
export interface  PatientImportConfigListUpdateAPIMsg {
  type: typeof  EDIT_PATIENT_IMPORT_CONFIG_LIST_UPDATE_API_MSG;
  payload: EditPatientImportConfigValueRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface  PatientImportConfigListUpdateAPIRes {
  type: typeof  EDIT_PATIENT_IMPORT_CONFIG_LIST_UPDATE_API_RES;
  payload: EditPatientImportConfigValueRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}

export type  PatientImportConfigListActionTypes =
  |  PatientImportConfigListLoading
  |  PatientImportConfigListSuccess
  |  PatientImportConfigListFail
  |  PatientImportConfigListUpdateAPIMsg
  |  PatientImportConfigListUpdateAPIRes;

