import {
  ASSIGN_EXECUTIVE_CLEAR_API_RES,
  ASSIGN_EXECUTIVE_FAIL,
  ASSIGN_EXECUTIVE_LOADING,
  ASSIGN_EXECUTIVE_SUCCESS,
  ASSIGN_EXECUTIVE_API_MSG,
  AssignExecutiveListActionTypes,
} from "./ActionTypes";
import { AssignExecutiveResponse } from "./Model";

export const assignExecutiveLoadingAction = (
  loading: boolean
): AssignExecutiveListActionTypes => {
  return {
    type: ASSIGN_EXECUTIVE_LOADING,
    loading: loading,
  };
};

export const assignExecutiveSuccessAction = (
  assignExecutiveRes: AssignExecutiveResponse,
): AssignExecutiveListActionTypes => {
  return {
    type: ASSIGN_EXECUTIVE_SUCCESS,
    payload: assignExecutiveRes,
  };
};

export const assignExecutiveErrorAction = (
  assignExecutiveRes: AssignExecutiveResponse,
  errMsg: string,
  status: number
): AssignExecutiveListActionTypes => {
  return {
    type: ASSIGN_EXECUTIVE_FAIL,
    payload: assignExecutiveRes,
    message: errMsg,
    status: status,
  };
};

export const assignExecutiveUpdateAPIMsgAction = (
  assignExecutiveRes: AssignExecutiveResponse,
  errMsg: string,
  status: number
): AssignExecutiveListActionTypes => {
  return {
    type: ASSIGN_EXECUTIVE_API_MSG,
    payload: assignExecutiveRes,
    message: errMsg,
    status: status,
  };
};

export const assignExecutiveAPIResClearAction = (
  assignExecutiveRes: AssignExecutiveResponse,
  errMsg: string,
  status: number
): AssignExecutiveListActionTypes => {
  return {
    type: ASSIGN_EXECUTIVE_CLEAR_API_RES,
    payload: assignExecutiveRes,
    message: errMsg,
    status: status,
  };
};
