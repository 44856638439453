import React, { useState, useCallback, useEffect } from "react";
import {
  Alert,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  addDrNotes,
  changeDrNoteStatus,
  getDoctorNoteList,
} from "../../../redux/Notes/ApiCall";
import { AppState } from "../../../redux/store/Store";
import MWExceptionList from "../../../component/MWExceptionList";
import { t } from "i18next";

export default function ActiveDrNotes(props: any) {
  const dispatch = useDispatch();
  const { activeNoteLoader } = props;

  const { patientid } = useParams() as {
    patientid: string;
  };

  const { caseid } = useParams() as {
    caseid: string;
  };

  const { doctorId } = useParams() as {
    doctorId: string;
  };

  const [notesError, setNotesError] = useState("");
  const notesErrorDiv = notesError ? <Typography>{notesError}</Typography> : "";

  const [notes, setNotes] = useState("");
  const handleNotesChange = useCallback((event: any) => {
    setNotes(event.target.value);
  }, []);

  const closeNotesSubmitSuccess = () => {
    setNotesSubmitSuccess("");
  };

  const [notesSubmitSuccess, setNotesSubmitSuccess] = useState("");
  const notesSubmitSuccessDiv = notesSubmitSuccess ? (
    <Alert onClick={closeNotesSubmitSuccess} severity="success">
      <Typography>{notesSubmitSuccess}</Typography>
    </Alert>
  ) : (
    ""
  );

  const closeNotesSubmitError = () => {
    setNotesSubmitError("");
  };

  const [notesSubmitError, setNotesSubmitError] = useState("");
  const notesSubmitErrorDiv = notesSubmitError ? (
    <Alert onClick={closeNotesSubmitError} severity="error">
      <Typography>{notesSubmitError}</Typography>
    </Alert>
  ) : (
    ""
  );

  const [notesModalActive, setNotesModalActive] = useState(false);
  const handleNotesModalToggle = () => {
    setNotesModalActive((notesModalActive) => !notesModalActive);
    setNotesError("");
    setNotesSubmitSuccess("");
    setNotesSubmitError("");
  };
  const closeModal = () => {
    setNotesModalActive((notesModalActive) => !notesModalActive);
    setNotesError("");
    setNotesSubmitSuccess("");
    setNotesSubmitError("");
    dispatch(getDoctorNoteList(getDrNoteListBody));
  };

  const [noteStatusSuccess] = useState("");
  const [noteStatusSuccessActive, setNoteStatusSuccessActive] = useState(false);
  const togglenoteStatusSuccessActive = useCallback(
    () =>
      setNoteStatusSuccessActive(
        (noteStatusSuccessActive) => !noteStatusSuccessActive
      ),
    []
  );
  const noteStatusSuccessMarkup = noteStatusSuccessActive ? (
    <Snackbar
      open={noteStatusSuccessActive}
      autoHideDuration={6000}
      onClose={togglenoteStatusSuccessActive}
    >
      <Alert
        severity="success"
        onClose={togglenoteStatusSuccessActive}
        sx={{ width: "100%" }}
      >
        {noteStatusSuccess}
      </Alert>
    </Snackbar>
  ) : null;

  // Call Add chief complaint store

  const AddDrNoteLoadingvalue = useSelector(
    (state: AppState) => state.addDrNote.loading
  );
  const AddChiefComplaintStatus = useSelector(
    (state: AppState) => state.addDrNote.response.status
  );

  // Add Chief complaint success toast
  const [
    successAddChiefComplaintToastActive,
    setSuccessAddChiefComplaintToastActive,
  ] = useState(false);
  const toggleSuccessAddChiefComplaintActive = () => {
    setSuccessAddChiefComplaintToastActive(
      (successAddChiefComplaintToastActive) =>
        !successAddChiefComplaintToastActive
    );
    dispatch(getDoctorNoteList(getDrNoteListBody));
  };

  const toastAddChiefComplaintSuccessMarkup =
    successAddChiefComplaintToastActive ? (
      <Snackbar
        open={successAddChiefComplaintToastActive}
        autoHideDuration={6000}
        onClose={toggleSuccessAddChiefComplaintActive}
      >
        <Alert
          severity="success"
          onClose={toggleSuccessAddChiefComplaintActive}
          sx={{ width: "100%" }}
        >
          Note added successfully
        </Alert>
      </Snackbar>
    ) : null;

  // Add Chief complaint error toast
  const [
    addChiefComplaintErrorToastActive,
    setAddChiefComplaintErrorToastActive,
  ] = useState(false);
  const handleAddChiefComplaintErrorToastChange = () => {
    setAddChiefComplaintErrorToastActive(
      (addChiefComplaintErrorToastActive) => !addChiefComplaintErrorToastActive
    );
  };
  const toastAddChiefComplaintErrorMarkup =
    addChiefComplaintErrorToastActive ? (
      <Snackbar
        open={addChiefComplaintErrorToastActive}
        autoHideDuration={6000}
        onClose={handleAddChiefComplaintErrorToastChange}
      >
        <Alert
          severity="error"
          onClose={handleAddChiefComplaintErrorToastChange}
          sx={{ width: "100%" }}
        >
          Something went wrong
        </Alert>
      </Snackbar>
    ) : null;

  const changeDrNoteStatusLoadingvalue = useSelector(
    (state: AppState) => state.changeDrNoteStatusValue.loading
  );
  const changeDrNoteStatusStatus = useSelector(
    (state: AppState) => state.changeDrNoteStatusValue.response.status
  );
  const changeDrNoteStatusMessage = useSelector(
    (state: AppState) => state.changeDrNoteStatusValue.response.message
  );

  // Add Chief complaint success toast
  const [
    successChangeDrNoteStatusToastActive,
    setSuccessChangeDrNoteStatusToastActive,
  ] = useState(false);
  const toggleSuccessChangeDrNoteStatusActive = () => {
    setSuccessChangeDrNoteStatusToastActive(
      (successChangeDrNoteStatusToastActive) =>
        !successChangeDrNoteStatusToastActive
    );
    dispatch(getDoctorNoteList(getDrNoteListBody));
  };

  const toastChangeDrNoteStatusSuccessMarkup =
    successChangeDrNoteStatusToastActive ? (
      <Snackbar
        open={successChangeDrNoteStatusToastActive}
        autoHideDuration={6000}
        onClose={toggleSuccessChangeDrNoteStatusActive}
      >
        <Alert
          severity="success"
          onClose={toggleSuccessChangeDrNoteStatusActive}
          sx={{ width: "100%" }}
        >
          {changeDrNoteStatusMessage}
        </Alert>
      </Snackbar>
    ) : null;

  // Add Chief complaint error toast
  const [changeDrNoteStatusToastActive, setErrorChangeDrNoteStatusToastActive] =
    useState(false);
  const handleErrorChangeDrNoteStatusToastChange = () => {
    setErrorChangeDrNoteStatusToastActive(
      (changeDrNoteStatusToastActive) => !changeDrNoteStatusToastActive
    );
  };
  const toastChangeDrNoteStatusErrorMarkup = changeDrNoteStatusToastActive ? (
    <Snackbar
      open={changeDrNoteStatusToastActive}
      autoHideDuration={6000}
      onClose={handleErrorChangeDrNoteStatusToastChange}
    >
      <Alert
        severity="success"
        onClose={handleErrorChangeDrNoteStatusToastChange}
        sx={{ width: "100%" }}
      >
        {changeDrNoteStatusMessage}
      </Alert>
    </Snackbar>
  ) : null;

  // Notes Status change API
  function toggleNoteStatus(response: any) {
    dispatch(changeDrNoteStatus(response));
    setSuccessChangeDrNoteStatusToastActive(
      (successChangeDrNoteStatusToastActive) =>
        !successChangeDrNoteStatusToastActive
    );
    setErrorChangeDrNoteStatusToastActive(
      (changeDrNoteStatusToastActive) => !changeDrNoteStatusToastActive
    );
  }

  const getDrNoteListBody = {
    patientId: patientid,
    caseId: caseid,
    doctorId: doctorId,
    isActive: true,
  };

  const DATE_OPTIONS = {
    weekday: "short",
    year: "numeric",
    month: "short",
    day: "numeric",
  } as const;
  // Dr Note LIST
  const DrNoteListFromAPI = useSelector(
    (state: AppState) => state.patientCaseNoteListRes
  );

  const drNoteValues = DrNoteListFromAPI.getPatientCaseNoteListRes.noteList;
  const noteListConfig: any = [];
  for (var i = 0; i < drNoteValues?.length; i++) {
    let selectedResponse = drNoteValues[i].id;
    let note = drNoteValues[i].note === "" ? "" : drNoteValues[i].note;
    let createdDate =
      drNoteValues[i].createdDate === null
        ? null
        : new Date(drNoteValues[i].createdDate).toLocaleDateString(
            "en-US",
            DATE_OPTIONS
          );
    let createBy =
      drNoteValues[i].createdBy === null
        ? ""
        : drNoteValues[i].createdBy.user.first_name +
          " " +
          drNoteValues[i].createdBy.user.last_name;
    let modifiedDate =
      drNoteValues[i].modifiedDate === null
        ? null
        : new Date(drNoteValues[i].modifiedDate).toLocaleDateString(
            "en-US",
            DATE_OPTIONS
          );
    let modifyBy =
      drNoteValues[i].modifiedBy === null
        ? ""
        : drNoteValues[i].modifiedBy.user.first_name +
          " " +
          drNoteValues[i].modifiedBy.user.last_name;
    noteListConfig.push([
      note,
      createdDate,
      createBy,
      <Button
        variant="outlined"
        onClick={() => toggleNoteStatus(selectedResponse)}
        startIcon={<EditIcon />}
      >
        {t("docnotelist.edit", { ns: ["home"] })!}
      </Button>,
      modifiedDate,
      modifyBy,
    ]);
  }
  const noteListValue: any[] = noteListConfig;

  const callGetDrNotes = () => dispatch(getDoctorNoteList(getDrNoteListBody));

  const addDrNoteListBody = {
    patientId: patientid,
    caseId: caseid,
    doctorId: doctorId,
    note: notes,
  };
  const addDrNote = () => {
    if (notes === "") {
      setNotesError(`${t("common.pleaseAddNote", { ns: ["home"] })!}`);
    } else {
      setNotesSubmitError("");
      dispatch(addDrNotes(addDrNoteListBody));
      setNotesModalActive((notesModalActive) => !notesModalActive);
      setSuccessAddChiefComplaintToastActive(
        (successAddChiefComplaintToastActive) =>
          !successAddChiefComplaintToastActive
      );
      setAddChiefComplaintErrorToastActive(
        (addChiefComplaintErrorToastActive) =>
          !addChiefComplaintErrorToastActive
      );
    }
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(getDoctorNoteList(getDrNoteListBody));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const notelistmarkup = (
    <Card sx={{ backgroundColor: "#142329" }}>
      <Grid>
        <CardHeader
          title={
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="flex-start"
            >
              <Grid item>{"Notes List"}</Grid>
            </Grid>
          }
        ></CardHeader>
        <CardActions>
          <Grid
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="flex-end"
            spacing={2}
          >
            <Grid item>
              <Button variant="outlined" onClick={callGetDrNotes}>
                {t("docnotelist.reloadNoteList", { ns: ["home"] })!}
              </Button>
            </Grid>
            <Grid item>
              <Button variant="contained" onClick={handleNotesModalToggle}>
                {t("common.addNote", { ns: ["home"] })!}
              </Button>
            </Grid>
          </Grid>
        </CardActions>
        <CardContent>
          <Box pl={3}>
            {noteListValue && noteListValue.length ? (
              <TableContainer>
                <Table size="small" aria-label="a dense table">
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        {t("common.note", { ns: ["home"] })!}
                      </TableCell>
                      <TableCell>
                        {t("admindashboardpage.createDate", { ns: ["home"] })!}
                      </TableCell>
                      <TableCell>
                        {t("common.createdBy", { ns: ["home"] })!}
                      </TableCell>
                      <TableCell>
                        {t("docnotelist.modifiedstatus", { ns: ["home"] })!}
                      </TableCell>
                      <TableCell>
                        {t("docnotelist.modifyby", { ns: ["home"] })!}
                      </TableCell>
                      <TableCell>
                        {t("docnotelist.modifydate", { ns: ["home"] })!}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {noteListValue.map((row: any) => (
                      <TableRow
                        key={row.description}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell align="left">{row[0]}</TableCell>
                        <TableCell align="left">{row[1]}</TableCell>
                        <TableCell align="left">{row[2]}</TableCell>
                        <TableCell align="left">{row[3]}</TableCell>
                        <TableCell align="left">{row[4]}</TableCell>
                        <TableCell align="left">{row[5]}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : (
              <Typography>
                <MWExceptionList />
              </Typography>
            )}
          </Box>
        </CardContent>
      </Grid>
    </Card>
  );

  //Dr. Notes Modal
  const drNotesModalMarkup = (
    <Dialog open={notesModalActive} onClose={handleNotesModalToggle} fullWidth>
      <DialogTitle>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start"
        >
          <Grid item>
            <Typography variant="h6">
              {t("common.addNote", { ns: ["home"] })!}
            </Typography>
          </Grid>
          <Grid item>
            <IconButton onClick={handleNotesModalToggle}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
        >
          <Grid item xs={12} sm={6} lg={8} xl={6}>
            <TextField
              value={notes}
              label={t("filePrepPage.patientNotes", { ns: ["home"] })}
              placeholder={t("docnotelist.placeholder", { ns: ["home"] })!}
              id="notesID"
              onChange={handleNotesChange}
              fullWidth
            />
            <Typography sx={{ color: "#c62828" }}>{notesErrorDiv}</Typography>
          </Grid>
        </Grid>
      </DialogContent>
      <Box pt={1}>{notesSubmitSuccessDiv}</Box>
      <Box pt={1}>{notesSubmitErrorDiv}</Box>
      <Divider />
      <DialogActions>
        <Grid
          container
          direction="row"
          justifyContent="flex-end"
          alignItems="flex-end"
          spacing={1}
        >
          <Grid item>
            <Button variant="outlined" onClick={closeModal}>
              {t("accountdetail.cancel", { ns: ["home"] })!}
            </Button>
          </Grid>
          <Grid item>
            <Button variant="contained" onClick={addDrNote}>
              {t("Workschedulepage.submit", { ns: ["home"] })!}
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );

  return (
    <Container maxWidth="xl">
      {notelistmarkup}
      {drNotesModalMarkup}
      {noteStatusSuccessMarkup}
      {DrNoteListFromAPI.loading === true ||
      AddDrNoteLoadingvalue === true ||
      changeDrNoteStatusLoadingvalue === true
        ? activeNoteLoader(true)
        : activeNoteLoader(false)}
      {AddChiefComplaintStatus! === 1
        ? toastAddChiefComplaintSuccessMarkup
        : null}
      {AddChiefComplaintStatus! === 0
        ? toastAddChiefComplaintErrorMarkup
        : null}
      {changeDrNoteStatusStatus! === 1
        ? toastChangeDrNoteStatusSuccessMarkup
        : null}
      {changeDrNoteStatusStatus! === 0
        ? toastChangeDrNoteStatusErrorMarkup
        : null}
    </Container>
  );
}
